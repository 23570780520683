import { Duration } from "luxon";

import { dt } from "@m/utils";

import { removeZeroes } from "./removeZeroes";

export const getTrackedTime = (
  startDate?: string,
  endDate?: string,
  roundUpUnits?: boolean
): string => {
  if (!startDate) return "";

  const endTime = endDate ? dt.fromISO(endDate) : dt.now(); // in system timezone
  const startTime = dt.fromISO(startDate).toLocal(); // match timezones

  // get total milliseconds difference since start time
  const { milliseconds } = endTime.diff(startTime).toObject();

  // returns a string "-" if the startDate is a future date
  if (milliseconds < 0) return "-";

  // create an object that shows months and days
  // this will look like { years: 1, months: 2, days: 3 }
  const durationObj = Duration.fromMillis(milliseconds)
    .shiftTo("years", "months", "day")
    .toObject();

  // If rounding is enabled, remove number of days if duration longer than a month
  if (roundUpUnits && durationObj.months > 0) delete durationObj.days;

  // If rounding is enabled, remove number of months if duration longer than a year
  if (roundUpUnits && durationObj.years > 0) delete durationObj.months;

  // remove any time units that are 0
  // to make it { days: 1 }
  const removedZeroes = removeZeroes(durationObj);

  // make it human readable
  const duration = Duration.fromObject(removedZeroes).toHuman({
    maximumFractionDigits: 0,
  });

  return duration;
};
