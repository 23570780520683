import { Button } from "@m/ui";

export const RemoveButton = ({
  connection,
  removeConnection,
  removeConnectionLoading,
}) => {
  const onRemove = () => removeConnection(connection);
  return (
    <Button
      kind="destructive"
      onClick={onRemove}
      loading={removeConnectionLoading}
    >
      Remove
    </Button>
  );
};
