import clsx from "clsx";
import { ButtonHTMLAttributes, ElementType, FC } from "react";
import { Link } from "react-router-dom";

import { Spinner, SpinnerProps } from "../Spinner";

// These have to be separate because they're optional in the prop declaration so we can't rely on them to type check elsewhere
export type ButtonSizes = "small" | "default" | "large";
export type ButtonKinds = "secondary" | "primary" | "destructive";
export type ButtonFills = "solid" | "subdued" | "none";

export interface ButtonPropsStrict {
  /** Set the component HTML element. Warning: Using non-standard markup can produce accessibility issues. */
  as?: "button" | "a" | "div" | "span";

  /** Additional classes */
  className?: string;

  /** Disabled button, unclickable */
  disabled?: boolean;

  /** Button fill style */
  fill?: ButtonFills;

  /** Additional classes applied to icons */
  iconClassName?: string;

  /** Button style */
  kind?: ButtonKinds;

  /** Show an icon before the button's label. */
  leftIcon?: ElementType;

  /** Show loading indicator and disable */
  loading?: boolean;

  /** Show an icon after the button's label. */
  rightIcon?: ElementType;

  /** Button size */
  size?: ButtonSizes;

  /** Button type */
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

interface ButtonProps extends ButtonPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

const defaultProps = {
  as: "button",
};

const loadingSizes: Record<ButtonSizes, SpinnerProps["size"]> = {
  small: 2,
  default: 2.5,
  large: 3,
};

export const Button: FC<ButtonProps> = ({
  as = defaultProps.as,
  children,
  className,
  disabled = false,
  fill = "solid",
  iconClassName,
  kind = "secondary",
  leftIcon: LeftIcon,
  loading,
  rightIcon: RightIcon,
  size = "default",
  type,
  ...props
}) => {
  const getTagName = () => {
    // If `as` is passed in and isn't default, it is always used
    if (as !== defaultProps.as) return as;

    // If `href` is passed in, the element becomes an anchor
    if (props.href) return "a";

    // If `to` is passed in, the element becomes a Link
    if (props.to) return Link;

    // Otherwise use default
    return as;
  };

  const getTypeAttribute = () => {
    const tagName = getTagName();
    if (tagName !== "button") return undefined;
    if (type) return type;
    return "button";
  };

  const preventClick = disabled || loading;

  const Element: any = getTagName();

  const classes = clsx(
    "leading-tight font-bold rounded-md border focus-visible:ring-4 focus-visible:outline-0 ring-blue-700/[.15] inline-block select-none relative cursor-pointer",
    {
      // Secondary (default) styles
      "bg-zinc-50 border-zinc-200 text-default":
        kind === "secondary" && fill === "solid",
      "bg-gray-500 border-gray-500 text-default":
        kind === "secondary" && fill === "subdued",
      "text-subdued": kind === "secondary" && fill === "none",

      // Primary styles
      "bg-form-active border-form-active text-inverse antialiased":
        kind === "primary" && fill === "solid",
      "bg-form-active border-form-active text-action":
        kind === "primary" && fill === "subdued",
      "text-action": kind === "primary" && fill === "none",

      // Destructive styles
      "bg-form-negative border-form-negative text-inverse antialiased":
        kind === "destructive" && fill === "solid",
      "bg-form-negative border-form-negative text-negative":
        kind === "destructive" && fill === "subdued",
      "text-negative": kind === "destructive" && fill === "none",

      // Style overrides
      "bg-opacity-[.07] border-opacity-[.03]": fill === "subdued",
      "bg-none border-transparent font-semibold": fill === "none",
      "!font-medium": fill === "none" && size === "small",

      // Hover styles
      "hover:opacity-[.85]": fill === "solid" || fill === "none", // Can be improved later
      "hover:bg-opacity-[.12]": fill === "subdued",

      // Sizes
      "py-0.5 px-1 text-sm": size === "small", // Not sure about this yet
      "py-1 px-2 text-sm": size === "default",
      "py-2 px-2 text-base leading-none": size === "large",

      // Disabled styles
      "opacity-60 cursor-default pointer-events-none": preventClick,
    },
    className
  );

  const contentClsx = clsx(
    "flex gap-0.5 items-center text-center justify-center",
    {
      "opacity-0": loading,
    }
  );

  const iconClsx = clsx(iconClassName, "shrink-0", {
    "h-2 w-2": size !== "large",
    "h-2.5 w-2.5": size === "large",
  });

  return (
    <Element
      className={classes}
      data-component="Button"
      disabled={preventClick}
      role="button"
      type={getTypeAttribute()}
      {...props}
    >
      <span className={contentClsx}>
        {LeftIcon && <LeftIcon className={iconClsx} />}
        {children}
        {RightIcon && <RightIcon className={iconClsx} />}
      </span>
      {loading && (
        <Spinner
          size={loadingSizes[size]}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        />
      )}
    </Element>
  );
};
