import { gql, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import {
  EngagementTemplateFill,
  EngagementTemplateFillEdge,
  EngagementTemplateFillField,
} from "@m/api/public/types";

import { MESSAGES_ENGAGEMENTS_TEMPLATE_FILL } from "../constants";

export const GET_ENGAGEMENT_TEMPLATE_FILL = gql`
  query EngagementTemplateFill(
    $engagementType: EngagementType!
    $engagementSubtype: EngagementSubtype!
  ) {
    engagementTemplateFill(
      engagementType: $engagementType
      engagementSubtype: $engagementSubtype
    ) {
      edges {
        node {
          id
          order
          fillFields {
            edges {
              node {
                id
                fieldName
                value
              }
            }
          }
        }
      }
    }
  }
`;

export interface IModifiedEngagementTemplateFill
  extends EngagementTemplateFill {
  fill: EngagementTemplateFillField[];
}

type Return = {
  data: {
    templateFill: IModifiedEngagementTemplateFill[];
  };
  loading: boolean;
};

export const useEngagementTemplateFill = (
  engagementType: string,
  engagementSubtype: string
): Return => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_TEMPLATE_FILL.GET_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENT_TEMPLATE_FILL, {
    variables: {
      engagementType,
      engagementSubtype,
    },
    skip: !engagementType || !engagementSubtype,
    onError,
  });

  const templateFill =
    data?.engagementTemplateFill?.edges.map(
      ({ node: { id, order, fillFields } }: EngagementTemplateFillEdge) => {
        const fill = fillFields?.edges.map((edge) => edge.node);
        return {
          id,
          order,
          fill,
        };
      }
    ) || [];

  return { data: { templateFill }, loading };
};
