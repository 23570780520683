import { gql, useMutation } from "@m/api";
import { Engagement } from "@m/api/public/types";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS } from "../constants";

import { GET_ENGAGEMENT_DETAILS } from "./useEngagementDetails";
import { GET_ENGAGEMENTS_LIST } from "./useEngagementsList";

export const UPDATE_ENGAGEMENT = gql`
  mutation UpdateEngagement($input: UpdateEngagementInput!) {
    updateEngagement(input: $input) {
      ok
      message
      engagement {
        title
        visible
        id
        poolHours
      }
    }
  }
`;

export const useUpdateEngagement = (id: string) => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS.UPDATE_ENGAGEMENT_ERROR);

  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS.UPDATE_ENGAGEMENT_SUCCESS);

  const [mutate] = useMutation(UPDATE_ENGAGEMENT, {
    refetchQueries: [
      { query: GET_ENGAGEMENT_DETAILS, variables: { id } },
      {
        query: GET_ENGAGEMENTS_LIST,
        // GET_ENGAGEMENTS_LIST includes a $where variable which we use to filter by {visible: true} when
        // the user is NOT allowed to update engagements as a filter so we need to include where value here
        // as well.
        variables: { id, where: {} },
      },
    ],
    onCompleted,
    onError,
  });

  const updateEngagement = ({
    id,
    visible,
    title,
    poolHours,
  }: Pick<Engagement, "id" | "title" | "visible" | "poolHours">) => {
    return mutate({
      variables: {
        input: {
          id,
          visible,
          title,
          poolHours,
        },
      },
    });
  };

  return [updateEngagement];
};
