import { RadialCloudScoreGaugeSegment } from "../components/RadialCloudScoreGauge";

export function getSegmentsWithCommandsAndOffsets(
  segments: RadialCloudScoreGaugeSegment[],
  radius: number,
  svgWidth: number,
  svgHeight: number
) {
  let previousPercent = 0;
  return segments.map((segment) => {
    const segmentWithCommands = {
      ...segment,
      commands: getPathCommands(segment.percent, radius, svgWidth, svgHeight),
      offset: percentToDegrees(previousPercent),
    };
    previousPercent -= segment.percent;
    return segmentWithCommands;
  });
}

function getPathCommands(
  percent: number,
  radius: number,
  svgWidth: number,
  svgHeight: number
): string {
  const moveTo = `M ${svgWidth / 2 + radius} ${svgHeight / 2}`;

  const angle = percentToDegrees(percent);
  const x = getXCoordFromDegrees(angle, radius, svgWidth);
  const y = getYCoordFromDegrees(angle, radius, svgHeight);
  const arc = `A ${radius} ${radius} 0 0 0 ${x} ${y}`;

  return [moveTo, arc].join(" ");
}

function getXCoordFromDegrees(
  angle: number,
  radius: number,
  svgWidth: number
): number {
  const x = Math.cos((angle * Math.PI) / 180) * radius;
  const coordX = x + svgWidth / 2;
  return coordX;
}

function getYCoordFromDegrees(
  angle: number,
  radius: number,
  svgHeight: number
): number {
  const y = Math.sin((angle * Math.PI) / 180) * -radius;
  const coordY = y + svgHeight / 2;
  return coordY;
}

function percentToDegrees(percent: number): number {
  return (percent / 100) * 180;
}
