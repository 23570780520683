export const SCORECARD_TREND_TIME_RANGES = {
  ONE_MONTH: {
    key: "ONE_MONTH",
    shortText: "1 Mo",
    fullText: "One Month",
    interval: "day",
    intervalCount: 30, // days
  },
  THREE_MONTHS: {
    key: "THREE_MONTHS",
    shortText: "3 Mo",
    fullText: "Three Month",
    interval: "week",
    intervalCount: 12, // weeks
  },
  SIX_MONTHS: {
    key: "SIX_MONTHS",
    shortText: "6 Mo",
    fullText: "Six Month",
    interval: "week",
    intervalCount: 24, // weeks
  },
  ONE_YEAR: {
    key: "ONE_YEAR",
    shortText: "1 Yr",
    fullText: "One Year",
    interval: "month",
    intervalCount: 12, // months
  },
};

export const SCORECARD_TREND_OPTIONS = Object.values(
  SCORECARD_TREND_TIME_RANGES
);
