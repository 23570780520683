import { ChangeEvent, useMemo } from "react";

import { EngagementTypeDetailsNode, Workspace } from "@m/api/public/types";
import { Field, Input, Select } from "@m/ui";
import { fromDollars, toProperCase } from "@m/utils";

import { TConfigurationDetails } from "../pages";

export const EngagementsConfigurationTemplate = ({
  details,
  onDetailsChange,
  types,
  workspaces,
}: {
  details: TConfigurationDetails;
  onDetailsChange: (_details: Partial<TConfigurationDetails>) => void;
  types: EngagementTypeDetailsNode[];
  workspaces: Workspace[];
}) => {
  const { template, title, linkedProjectId } = details;

  const handleDetailsChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { name, value },
    } = e;

    if (name === "linkedProject") {
      const selectedProject = workspaces.find(
        (workspace) => workspace.id === value
      );

      return onDetailsChange({
        startDate: selectedProject.startDate || "",
        dueDate: selectedProject.dueDate || "",
        linkedProjectId: value,
        originalBudget: fromDollars(selectedProject.budget) || 0,
      });
    }

    onDetailsChange({
      [name]: value,
    });
  };

  const templateOptions = useMemo(() => {
    return types.map((type) => (
      <option key={type.id} value={type.id}>
        {toProperCase(type.id)}
      </option>
    ));
  }, [types]);

  const workspacesOptions = useMemo(() => {
    return workspaces.map((workspace) => {
      if (!workspace.isAssociatedWithEngagement) {
        return (
          <option key={workspace.id} value={workspace.id}>
            {workspace.title}
          </option>
        );
      }
    });
  }, [workspaces]);

  return (
    <div className="flex w-8/12 flex-col gap-4 p-2 pt-2">
      <Field
        htmlFor="engagement-configuration-template"
        label="Select Template"
        flag="required"
      >
        <Select
          className="w-full px-2 py-1"
          id="engagement-configuration-template"
          name="template"
          onChange={handleDetailsChange}
          options={templateOptions}
          placeholder="Select a Project Template"
          value={template}
        />
      </Field>
      <Field
        htmlFor="engagement-link-project"
        label="Link Kantata Project"
        flag="required"
      >
        <Select
          id="engagement-link-project"
          className="w-full px-2 py-1"
          disabled={!template}
          name="linkedProject"
          value={linkedProjectId}
          onChange={handleDetailsChange}
          options={workspacesOptions}
          disabledMsg="You must first select a project template."
          placeholder="Select a Kantata Project"
        />
      </Field>
      <Field
        label="Project Title"
        htmlFor="engagement-configuration-title"
        flag="required"
      >
        <Input
          disabled={!linkedProjectId}
          id="engagement-configuration-title"
          onChange={handleDetailsChange}
          name="title"
          placeholder={
            !linkedProjectId
              ? "You must first link a Kantata Project."
              : "Enter a Project Title"
          }
          required={true}
          value={title}
        />
      </Field>
    </div>
  );
};
