import { parseSort } from "./parseSort";

export const mapHeaders = (headers) =>
  headers.map((header) => {
    const label = header.label ? header.label : header;
    const sort = header.sort ? parseSort(header.sort) : "";
    let mapped = {
      Header: label,
      sort: sort,
      Icon: header.Icon,
      onIconClick: header.onIconClick,
      accessor: header.accessor || createAccessorFromLabel(label),
      align: header.align,
    };
    if (header.width) mapped.width = header.width;
    if (header.cellClasses) mapped.cellClasses = header.cellClasses;
    return mapped;
  });

export const createAccessorFromLabel = (label) => {
  return label
    .toLowerCase()
    .replace(/ /g, "")
    .replace("-", "")
    .replace("?", "")
    .replace(".", "");
};
