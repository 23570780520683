import {
  NavigateOptions,
  useNavigate as useBaseNavigate,
} from "react-router-dom";

import { useAuth } from "@m/login";

import { generateCompanyPath } from "@mc/utils/companyPath";

/**
 * Custom useNavigate hook that prefixes paths passed into the `to`
 * arg with company slug.
 */
export const useNavigate = () => {
  const { user } = useAuth();

  const _navigate = useBaseNavigate();

  const navigate = (to: string, options?: NavigateOptions) => {
    return _navigate(generateCompanyPath(to, user.company.slug), options);
  };

  return navigate;
};
