import { EmailNotificationCadence } from "@m/api/public/types";

export const NOTIFICATIONS_SETTINGS_OPTIONS = {
  RECOMMENDATIONS_TOGGLE: {
    CHECKED: EmailNotificationCadence.Daily,
    UNCHECKED: EmailNotificationCadence.Never,
  },
  MONTHLY_SUMMARY_TOGGLE: {
    CHECKED: EmailNotificationCadence.Monthly,
    UNCHECKED: EmailNotificationCadence.Never,
  },
  PROJECT_INVITES_TOGGLE: {
    CHECKED: EmailNotificationCadence.Immediate,
    UNCHECKED: EmailNotificationCadence.Never,
  },
  PROJECT_SUMMARY_TOGGLE: {
    CHECKED: EmailNotificationCadence.Weekly,
    UNCHECKED: EmailNotificationCadence.Never,
  },
};
