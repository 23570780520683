import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

import { PillarDetailsRedirect } from "./redirects";

export const scorecardRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.CLOUD_SCORE} />,
  },
  {
    path: ":pillarId",
    element: <PillarDetailsRedirect />,
  },
];
