import clsx from "clsx";
import { FC } from "react";

import { Blue, Full, Mark } from "./logo-svgs";

const LOGOS = {
  default: Full,
  mark: Mark,
  bluemark: Blue,
};

export interface MissionLogoPropsStrict {
  /** Logo variations */
  logo?: "default" | "mark" | "bluemark";

  /** Additional classes */
  className?: string;

  /** Color of logo text */
  color?: "default" | "white";
}

interface MissionLogoProps extends MissionLogoPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

export const MissionLogo: FC<MissionLogoProps> = ({
  logo = "default",
  color = "default",
  className,
  style,
  ...props
}) => {
  const Logo = LOGOS[logo];
  const colorClass = color === "default" ? "text-blue-700" : "text-inverse";
  return (
    <Logo
      alt="Mission Cloud Logo"
      className={clsx(className, colorClass)}
      style={{ ...style, width: "auto" }}
      {...props}
    />
  );
};
