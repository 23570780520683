const APP_ID = process.env.REACT_APP_SERVICES_INTERCOM_APP_ID;

export type StartIntercomSettings = Omit<
  Intercom_.IntercomSettings,
  "app_id" | "hide_default" | "api_base"
>;

export const isIntercomLoaded = () =>
  typeof window.Intercom !== "undefined" ? true : false;

export const getIntercom = () => {
  if (!isIntercomLoaded()) return null;
  return window.Intercom;
};

export function loadIntercom(callback?: any) {
  if (isIntercomLoaded()) callback?.();
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = `https://widget.intercom.io/widget/${APP_ID}`;
  s.onload = callback || null;
  document.head.appendChild(s);
}

export const bootIntercom = (settings: StartIntercomSettings) => {
  if (!isIntercomLoaded()) return;
  const intercom = getIntercom();
  const intercomSettings = {
    app_id: APP_ID,
    hide_default_launcher: true,
    api_base: "https://api-iam.intercom.io",
    ...settings,
  };
  intercom?.("boot", intercomSettings);
  intercom?.("show");
};

export function startIntercom(settings: StartIntercomSettings) {
  if (!settings?.user_id || !settings?.user_hash) return;

  if (!isIntercomLoaded())
    loadIntercom(() => {
      bootIntercom(settings);
    });

  bootIntercom(settings);
}

export function shutdownIntercom() {
  getIntercom()?.("shutdown");
}

export function hideIntercom() {
  getIntercom()?.("hide");
}
