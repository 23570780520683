import { useEffect, useState } from "react";

/**
 * Hook to manage a single user preference in local storage.
 */
export function useUserPreference<T>(
  preferenceKey: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [preference, setPreference] = useState<T>(
    // On initial render, get preference value from local storage (or use default value)
    function getInitialPreferenceValue() {
      const userPreferences = getPreferencesFromLocalStorage();
      return (userPreferences[preferenceKey] as T) ?? defaultValue;
    }
  );

  useEffect(
    // On mount, if a default value was used, save it to local storage with other preferences
    // After mount, when a preference is changed, update local storage with new value
    function handlePreferenceChanged() {
      const userPreferences = getPreferencesFromLocalStorage();
      userPreferences[preferenceKey] = preference;
      updatePreferencesInLocalStorage(userPreferences);
    },
    [preference, preferenceKey]
  );

  return [preference, setPreference];
}

type UserPreferences = Record<string, unknown>;

const USER_PREFERENCES_KEY = "userPreferences";

const getPreferencesFromLocalStorage = (): UserPreferences =>
  JSON.parse(localStorage.getItem(USER_PREFERENCES_KEY) || "{}");

const updatePreferencesInLocalStorage = (preferences: UserPreferences): void =>
  localStorage.setItem(USER_PREFERENCES_KEY, JSON.stringify(preferences));
