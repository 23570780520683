import { createContext, createRef, useContext } from "react";

import { UserSession } from "@m/types";

interface AuthContextValue {
  user: UserSession | null;
  updateUserSession: (user: UserSession) => void;
  logout: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextValue>(undefined!);

export const AuthContextRef = createRef();

export const useAuth = () => useContext(AuthContext);
