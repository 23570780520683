import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES } from "../constants";

import { GET_CLOUD_SCORE_RECOMMENDATIONS } from "./useCloudScoreRecommendations";

export const DELETE_SCORECARD_RECOMMENDATION = gql(/* GraphQL */ `
  mutation deleteRecommendation($input: DeleteScorecardRecommendationInput!) {
    deleteScorecardRecommendation(input: $input) {
      ok
      message
    }
  }
`);

export const useDeleteScorecardRecommendation = () => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.DELETE_CLOUD_SCORE_RECOMMENDATION_FAILURE);

  const onCompleted = () =>
    toast.success(
      CLOUD_SCORE_MESSAGES.DELETE_CLOUD_SCORE_RECOMMENDATION_SUCCESS
    );

  const [mutate, result] = useMutation(DELETE_SCORECARD_RECOMMENDATION, {
    refetchQueries: [{ query: GET_CLOUD_SCORE_RECOMMENDATIONS }],
    onError,
    onCompleted,
  });

  const deleteRecommendation = (recommendationId: string) => {
    mutate({
      variables: {
        input: {
          scorecardRecommendationId: recommendationId,
        },
      },
    });
  };

  return [deleteRecommendation, result] as const;
};
