import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { ApplicationLoadBalancerFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import {
  ALB_LABEL,
  GET_RESOURCES_FAILURE,
  LOAD_BALANCER_TYPES,
} from "../constants";

export const GET_APPLICATION_LOAD_BALANCERS = gql(/* GraphQL */ `
  query ApplicationLoadBalancers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $loadBalancerType: String
    $searchTerm: String
    $sort: [ApplicationLoadBalancerSortEnum]
    $where: ApplicationLoadBalancerFilter
  ) {
    loadBalancers(
      after: $after
      before: $before
      first: $first
      last: $last
      loadBalancerType: $loadBalancerType
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          name
          stateCode
          account
          accountId
          accountName
          region
          vpcId
          endpoint
          createTime
          monitoringPolicy
          tagTotal
          tags
        }
      }
    }
  }
`);

export const useApplicationLoadBalancers = (query) => {
  const where: ApplicationLoadBalancerFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;
  if (query.regions) where.regionIn = query.regions;
  if (query.states) where.stateCodeIn = query.states;

  const onError = () => {
    toast.error(GET_RESOURCES_FAILURE(ALB_LABEL));
  };

  const { data, loading, error, fetchMore } = useQuery(
    GET_APPLICATION_LOAD_BALANCERS,
    {
      onError,
      variables: {
        sort: query.sort,
        ...INITIAL_PAGINATION,
        loadBalancerType: LOAD_BALANCER_TYPES.APPLICATION,
        searchTerm: query.search,
        where,
      },
    }
  );

  const { edges, pageInfo, totalCount } = data?.loadBalancers || {};
  const applicationLoadBalancers = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { applicationLoadBalancers },
    error,
    loading,
    pagination,
  };
};
