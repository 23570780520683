import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { ScorecardResult } from "@m/api/public/types";
import { Badge, BaseFilter, Card, FilterBar, Spinner } from "@m/ui";

import { PATHS } from "@mc/constants";
import { sortNameAsc } from "@mc/utils/sort";

import { useCheckResults } from "../api";
import {
  CHECK_LIST_CATEGORY_FILTERS,
  CHECK_LIST_FILTERS,
  CHECK_STATUS,
  CLOUD_SCORE_MESSAGES,
  CloudScorePillarIds,
} from "../constants";
import { CheckStatusIcon } from "../icons";
import { getPillar, sortStatusAsc } from "../utils";

import { MonthlySavings } from "./MonthlySavings";

export interface CheckListProps {
  pillarId: CloudScorePillarIds;
}

type CheckResultType = Pick<
  ScorecardResult,
  | "id"
  | "name"
  | "status"
  | "ignored"
  | "totalResources"
  | "potentialMonthlySavings"
>;

export const CloudScoreCheckList = ({
  pillarId = CloudScorePillarIds.AllChecks,
}: CheckListProps) => {
  const {
    data: { checks },
    loading,
    error,
  } = useCheckResults();

  const [filters, setFilters] = useState(
    CHECK_LIST_CATEGORY_FILTERS[pillarId] || []
  );

  const showRestFilters = filters.some((f) => f.selected.length);

  const resetFilters = () =>
    setFilters(CHECK_LIST_CATEGORY_FILTERS[pillarId] || []);

  const resetFilter = (i: number) => {
    setFilters((prev) => {
      const newFilters = structuredClone(prev);
      newFilters[i].selected = [];
      return newFilters;
    });
  };

  const setFilterSelection = (i: number, selected: string | string[]) => {
    setFilters((prev) => {
      const newFilters = structuredClone(prev);
      newFilters[i].selected = selected;
      return newFilters;
    });
  };

  const getDisplayValue = (i: number, selected: string | string[]) => {
    if (Array.isArray(selected)) return;
    return filters[i].options.find((op) => op.id === selected)?.label;
  };

  const applyFilters = useCallback(
    (checks: CheckResultType[]) => {
      const activeFilters = filters.filter((filter) => filter.selected.length);
      return checks.filter((check) => {
        return activeFilters.every((filter) => {
          const isMultiple = filter.multiple;
          const isStatus = filter.key === CHECK_LIST_FILTERS.STATUS.key;
          let showIgnored = false;

          if (isStatus && isMultiple) {
            showIgnored = !!filter.selected.includes(CHECK_STATUS.IGNORED);
          } else if (isStatus) {
            showIgnored = filter.selected === CHECK_STATUS.IGNORED;
          }

          if (isStatus && showIgnored && check.ignored) return true;
          else if (isStatus && !showIgnored && check.ignored) return false;
          else if (isMultiple) {
            return filter.selected.includes(check[filter.key]);
          } else {
            return check[filter.key] === filter.selected;
          }
        });
      });
    },
    [filters]
  );

  const checkList = useMemo(() => {
    const sectionPillars = checks.filter((check) => {
      if (pillarId === CloudScorePillarIds.AllChecks) return true;
      return getPillar(pillarId)?.id === getPillar(check.pillar)?.id;
    });

    const sortedChecks = sectionPillars.sort(sortNameAsc).sort(sortStatusAsc);

    return applyFilters(sortedChecks);
  }, [checks, pillarId, applyFilters]);

  const empty = !loading && (checkList.length < 1 || error);
  const ready = !loading && !empty;

  useEffect(() => {
    setFilters(CHECK_LIST_CATEGORY_FILTERS[pillarId] || []);
  }, [pillarId]);

  return (
    <Card aria-label="Cloud Score Check List" title="Checks">
      <div className="px-3 py-2 ">
        <FilterBar
          className="mb-2"
          isActive={showRestFilters}
          onResetFilters={resetFilters}
        >
          {filters?.map((filter, filterIndex) => (
            <BaseFilter
              key={filter.key}
              ariaLabel={filter.id}
              options={filter.options}
              multiple={filter.multiple}
              selection={filter.selected}
              initialValue={filter.label}
              onClear={() => resetFilter(filterIndex)}
              onChange={(v) => setFilterSelection(filterIndex, v)}
              displayValue={getDisplayValue(filterIndex, filter.selected)}
            />
          ))}
        </FilterBar>

        {ready &&
          checkList.map((check) => (
            <CloudScoreCheckListItem key={check.id} check={check} />
          ))}

        {empty && (
          <div className="flex justify-center p-3 text-subdued">
            {CLOUD_SCORE_MESSAGES.CHECK_LIST_EMPTY}
          </div>
        )}

        {loading && (
          <div className="flex justify-center">
            <Spinner className="m-4" />
          </div>
        )}
      </div>
    </Card>
  );
};

const CloudScoreCheckListItem = ({ check }: { check: CheckResultType }) => {
  const {
    id: checkId,
    name,
    status,
    ignored,
    totalResources,
    potentialMonthlySavings: { estimatedMonthlySavings },
  } = check;

  const navigate = useNavigate();

  const handleClick = () =>
    navigate(generatePath(PATHS._CLOUD_SCORE_CHECK_DETAILS, { checkId }));

  const checkStatus = ignored ? CHECK_STATUS.IGNORED : status.toUpperCase();

  return (
    <button
      data-testid="cloud-score-check-list-item"
      className="mb-2 flex w-full items-center justify-between space-x-2 border-b pb-2 text-left last:mb-0 last:border-b-0 last:pb-0"
      onClick={handleClick}
    >
      <div aria-label="Check Status">
        <CheckStatusIcon status={checkStatus} />
      </div>
      <div
        aria-label="Check Name"
        className={clsx("grow cursor-pointer font-medium", {
          "text-subdued": ignored,
        })}
      >
        {name}
      </div>
      {estimatedMonthlySavings > 0.0 && (
        <MonthlySavings amount={estimatedMonthlySavings} />
      )}
      {totalResources > 0 && checkStatus !== CHECK_STATUS.IGNORED && (
        <Badge
          aria-label="Flagged Resources"
          label={totalResources}
          size="small"
        />
      )}
    </button>
  );
};
