import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

export const RESEND_INVITATION = gql`
  mutation useResendInvitation($input: ResendInvitationInput!) {
    resendInvitation(input: $input) {
      ok
      message
      account {
        id
        email
      }
    }
  }
`;

export const useResendInvitation = () => {
  const onError = () => toast.error(MESSAGES.RESEND_USER_INVITE_ERROR);

  const onCompleted = (data) =>
    toast.success(
      `${
        data.resendInvitation?.account?.email || "User"
      } has been sent an invitation to join Mission Control.`
    );

  const [mutate] = useMutation(RESEND_INVITATION, {
    onError,
    onCompleted,
  });

  function resendUserInvite(accountId) {
    mutate({
      variables: {
        input: { accountId },
      },
    });
  }

  return [resendUserInvite];
};
