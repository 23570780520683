import { Outlet, RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { PATHS, ROLES } from "@mc/constants";
import {
  EditEngagementsStatusDrawer,
  EngagementsDetailItemAddModal,
  EngagementsDetailItemEditModal,
  EngagementsDetailItemViewModal,
} from "@mc/features/Engagements/components";
import { DETAIL_ITEM_TYPE } from "@mc/features/Engagements/constants";
import { EngagementActivityFeedDrawer } from "@mc/features/EngagementsActivityFeed/components";

export const engagementModalRoutes: RouteObject[] = [
  {
    path: PATHS._ENGAGEMENT_MILESTONE_DETAILS,
    element: (
      <EngagementsDetailItemViewModal detailType={DETAIL_ITEM_TYPE.MILESTONE} />
    ),
  },
  {
    path: PATHS._ENGAGEMENT_RISK_LOG_DETAILS,
    element: (
      <EngagementsDetailItemViewModal detailType={DETAIL_ITEM_TYPE.RISK} />
    ),
  },
  {
    path: PATHS._ENGAGEMENT_CHECKLIST_DETAILS,
    element: (
      <EngagementsDetailItemViewModal
        detailType={DETAIL_ITEM_TYPE.ACTION_ITEM}
      />
    ),
  },
  {
    path: PATHS._ENGAGEMENT_DECISION_DETAILS,
    element: (
      <EngagementsDetailItemViewModal detailType={DETAIL_ITEM_TYPE.DECISION} />
    ),
  },
  {
    path: PATHS._ENGAGEMENT_ACTIVITY_FEED,
    element: <EngagementActivityFeedDrawer />,
  },
  {
    path: "*",
    element: (
      <AccessRequired role={ROLES.ENGAGEMENT_MANAGER}>
        <Outlet />
      </AccessRequired>
    ),
    children: [
      {
        path: PATHS._ENGAGEMENT_MILESTONE_ADD,
        element: (
          <EngagementsDetailItemAddModal
            detailType={DETAIL_ITEM_TYPE.MILESTONE}
          />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_RISK_LOG_ADD,
        element: (
          <EngagementsDetailItemAddModal detailType={DETAIL_ITEM_TYPE.RISK} />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_CHECKLIST_ADD,
        element: (
          <EngagementsDetailItemAddModal
            detailType={DETAIL_ITEM_TYPE.ACTION_ITEM}
          />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_DECISION_ADD,
        element: (
          <EngagementsDetailItemAddModal
            detailType={DETAIL_ITEM_TYPE.DECISION}
          />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_STATUS_EDIT,
        element: <EditEngagementsStatusDrawer />,
      },
      {
        path: PATHS._ENGAGEMENT_MILESTONE_EDIT,
        element: (
          <EngagementsDetailItemEditModal
            detailType={DETAIL_ITEM_TYPE.MILESTONE}
          />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_CHECKLIST_EDIT,
        element: (
          <EngagementsDetailItemEditModal
            detailType={DETAIL_ITEM_TYPE.ACTION_ITEM}
          />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_RISK_LOG_EDIT,
        element: (
          <EngagementsDetailItemEditModal detailType={DETAIL_ITEM_TYPE.RISK} />
        ),
      },
      {
        path: PATHS._ENGAGEMENT_DECISION_EDIT,
        element: (
          <EngagementsDetailItemEditModal
            detailType={DETAIL_ITEM_TYPE.DECISION}
          />
        ),
      },
    ],
  },
];
