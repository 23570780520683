import { generatePath, useParams } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

export const PillarDetailsRedirect = () => {
  const params = useParams();

  return (
    <Navigate
      replace
      to={generatePath(PATHS.CLOUD_SCORE_PILLAR_DETAILS, {
        pillarId: params.pillarId,
      })}
    />
  );
};
