import { EngagementBudgetStatus } from "@m/api/public/types";

export const getRemainingBudgetPercent = (
  budget: EngagementBudgetStatus
): string => {
  if (!budget) return "";

  const budgetSpent = Number(budget.budgetSpent);
  if (budgetSpent === 0) return "100";

  const remainingBudget = Number(budget.remainingBudget);
  const totalBudget = Number(budget.totalBudget);

  const percentageRemaining = (remainingBudget / totalBudget) * 100;
  return String(Math.max(Math.round(percentageRemaining), 0));
};
