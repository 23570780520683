import PT from "prop-types";

import { ActivityFeedPerson } from "@mc/features/ActivityFeed/components";
import { CHECK_IGNORE_STATUS_MAP } from "@mc/features/ActivityFeed/utils";
import { CheckMonitorIcon } from "@mc/features/CloudScore/icons";

import { OptimizationHistoryItem } from "./OptimizationHistoryItem";

export const OptimizationHistoryItemCheckIgnore = ({
  item: { actor, operation, timestamp },
}) => {
  const ignoredString = `${CHECK_IGNORE_STATUS_MAP[operation]}`.toLowerCase();

  const message = (
    <>
      Check was set to <span className="font-bold">{ignoredString}</span> by{" "}
      <ActivityFeedPerson actor={actor} />
    </>
  );

  const icon = <CheckMonitorIcon operation={operation} />;

  return (
    <OptimizationHistoryItem
      icon={icon}
      message={message}
      timestamp={timestamp}
    />
  );
};

OptimizationHistoryItemCheckIgnore.propTypes = {
  item: PT.shape({
    actor: PT.object.isRequired,
    operation: PT.string.isRequired,
    timestamp: PT.string.isRequired,
  }).isRequired,
};
