export const createFilterOptions = (filters) => {
  if (!filters) return [];

  return filters.map((filter) => {
    return {
      id: filter,
      label: filter,
    };
  });
};
