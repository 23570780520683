import clsx from "clsx";
import PT from "prop-types";
import { useState } from "react";

import { Badge } from "@m/ui";

const Tag = ({ label, onClick, active }) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <Badge
      className={clsx("cursor-pointer whitespace-nowrap", {
        "!bg-layout-active/10 !text-action outline outline-1 outline-offset-[-1px] outline-layout-active":
          active,
      })}
      aria-label="Email Tag"
      key={label}
      onClick={handleClick}
      size="small"
      label={
        <>
          {label}
          <button className="border-none pl-[5px]">✕</button>
        </>
      }
    />
  );
};

export const TagInputField = ({
  inputValue,
  onEnter,
  removeTag,
  selectedUsers,
  setInput,
  tags,
}) => {
  const [selectedTag, setSelectedTag] = useState("");

  const onChange = ({ target }) => {
    const { value } = target;
    const cleanTrim = value.replace(/[, ]+/g, "");
    setInput(cleanTrim);
  };

  const handleClick = () => {
    if (selectedTag) setSelectedTag("");
  };

  const onKeyDown = (e) => {
    const { code } = e;

    if (code === "Enter" || code === "Space" || code === "Comma") {
      e.preventDefault();
      onEnter();
    }

    if (code === "Backspace") {
      if (tags.size > 0 && inputValue === "") {
        if (selectedTag) {
          removeTag(selectedTag);
          setSelectedTag("");
        } else {
          const lastTag = Array.from(tags).pop();
          setSelectedTag(lastTag);
        }
      }
    }
  };

  const inputPlaceholder =
    selectedUsers.size === 0 ? "Add emails to invite" : "";

  return (
    <label
      htmlFor="Email Input"
      className="form-control-container form-control__focus-within flex flex-wrap items-center gap-1 p-1"
    >
      {[...tags].map((tag) => (
        <Tag
          key={tag}
          label={tag}
          onClick={removeTag}
          active={selectedTag === tag}
        />
      ))}
      <input
        id="Email Input"
        aria-label="Email Input"
        className="form-control-input form-control__no-focus !p-0"
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={inputPlaceholder}
        size={inputValue.length}
        type="text"
        value={inputValue}
        onClick={handleClick}
      />
    </label>
  );
};

TagInputField.propTypes = {
  inputValue: PT.string.isRequired,
  onEnter: PT.func.isRequired,
  removeTag: PT.func.isRequired,
  selectedUsers: PT.instanceOf(Set).isRequired,
  setInput: PT.func.isRequired,
  tags: PT.instanceOf(Set).isRequired,
};
