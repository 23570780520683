import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
  title: ReactNode;
  onClose: () => void;
}

export const DrawerHeader = ({ title, onClose }: Props) => {
  return (
    <div
      data-testid="drawer-header"
      className={clsx("flex justify-between", {
        "border-b": !!title,
      })}
    >
      <div data-testid="drawer-title" className="m-2 mr-0 w-full font-medium">
        {title}
      </div>

      <div className="flex items-center">
        <button
          aria-label="Close Drawer"
          onClick={onClose}
          className="m-2"
          autoFocus={true}
          tabIndex={0}
        >
          <XMarkIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};
