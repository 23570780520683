import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { EngagementSubtype, EngagementType } from "@m/api/public/types";
import { Button } from "@m/ui";
import { dt } from "@m/utils";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";

import {
  useCreateEngagementWithTemplate,
  useEngagementTemplateFill,
  useEngagementTypes,
  useEngagementWorkspaces,
} from "../api";
import {
  EngagementsConfigurationDetails,
  EngagementsConfigurationReview,
  EngagementsConfigurationTemplate,
  WizardStagesCreateProject,
} from "../components";
import { CONFIGURATION_STAGES } from "../constants";

const { TEMPLATE, DETAILS, REVIEW } = CONFIGURATION_STAGES;

export type TConfigurationDetails = {
  dueDate: string;
  linkedProjectId: string;
  originalBudget: number;
  startDate: string;
  template: EngagementType | "";
  title: string;
};

const DEFAULT_CONFIGURATION_DETAILS: TConfigurationDetails = {
  dueDate: "",
  linkedProjectId: "",
  originalBudget: 0,
  startDate: "",
  template: "",
  title: "",
};

export const EngagementsConfigurationPage = () => {
  const detailsFormRef = useRef(null);
  const navigate = useNavigate();

  const {
    data: { types },
  } = useEngagementTypes();

  const {
    data: { workspaces },
  } = useEngagementWorkspaces();

  const [createEngagement, { loading: createEngagementLoading }] =
    useCreateEngagementWithTemplate();

  const crumbs = [
    { path: PATHS.ENGAGEMENTS_PROJECTS, name: "Projects" },
    { path: PATHS.ENGAGEMENTS_PROJECTS_CONFIGURATION, name: "New Project" },
  ];

  const [currentStage, setCurrentStage] =
    useState<CONFIGURATION_STAGES>(TEMPLATE);

  const [details, setDetails] = useState<TConfigurationDetails>(
    DEFAULT_CONFIGURATION_DETAILS
  );

  const {
    data: { templateFill },
    loading: templateFillLoading,
  } = useEngagementTemplateFill(
    details.template,
    // TODO: remove hardcoded engagement subtype when subtype selection is available
    EngagementSubtype.Milestone
  );

  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (
      currentStage === TEMPLATE &&
      details.template &&
      details.linkedProjectId &&
      details.title
    ) {
      return setCanContinue(true);
    }

    if (
      currentStage === DETAILS &&
      detailsFormRef.current &&
      detailsFormRef.current.checkValidity()
    ) {
      return setCanContinue(true);
    }

    if (currentStage === REVIEW && !createEngagementLoading)
      return setCanContinue(true);

    setCanContinue(false);
  }, [details, currentStage, createEngagementLoading]);

  const handleDetails = (details: TConfigurationDetails) => {
    setDetails((prev) => ({
      ...prev,
      ...details,
    }));
  };

  const handleCancel = () => {
    if (currentStage === TEMPLATE) {
      navigate(PATHS.ENGAGEMENTS_PROJECTS);
    } else {
      setCurrentStage((prevStage) => updateToPrevStage(prevStage));
    }
  };

  const handleContinue = () => {
    if (currentStage === REVIEW) {
      const formattedDueDate = dt.fromISO(details.dueDate).toUTC().toISO();
      const formattedStartDate = dt.fromISO(details.startDate).toUTC().toISO();

      createEngagement({
        ...details,
        dueDate: formattedDueDate,
        startDate: formattedStartDate,
      })
        .then(({ data }) => {
          navigate(
            generatePath(PATHS.ENGAGEMENTS_PROJECTS_DETAILS, {
              engagementId: data?.createEngagementWithTemplate?.engagement?.id,
            })
          );
        })
        .catch(() => {
          navigate(PATHS.ENGAGEMENTS_PROJECTS);
        });
    } else {
      setCurrentStage((currStage) => updateToNextStage(currStage));
    }
  };

  return (
    <Page
      data-testid="engagements-configuration-page"
      title={<PageTitle breadcrumbs={crumbs} padding="small" />}
      padding={false}
      width="full"
    >
      <div className="flex h-full flex-col">
        <div className="grid grow grid-cols-[minmax(0,_1fr)_minmax(0,_4fr)]">
          <div className="p-3">
            <WizardStagesCreateProject stage={currentStage} />
          </div>
          <div className="flex flex-col border-l p-3">
            {currentStage === TEMPLATE && (
              <EngagementsConfigurationTemplate
                details={details}
                onDetailsChange={handleDetails}
                types={types}
                workspaces={workspaces}
              />
            )}
            {currentStage === DETAILS && (
              <EngagementsConfigurationDetails
                details={details}
                templateFill={templateFill}
                loading={templateFillLoading}
                onDetailsChange={handleDetails}
                ref={detailsFormRef}
              />
            )}
            {currentStage === REVIEW && (
              <EngagementsConfigurationReview
                details={details}
                loading={templateFillLoading}
                templateFill={templateFill}
                workspaces={workspaces}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between border-t p-2">
          <Button onClick={handleCancel} aria-label="Cancel" fill="none">
            {getCancelText(currentStage)}
          </Button>
          <Button
            onClick={handleContinue}
            aria-label="Continue"
            kind="primary"
            disabled={!canContinue}
          >
            {getContinueText(currentStage)}
          </Button>
        </div>
      </div>
    </Page>
  );
};

const getContinueText = (stage: CONFIGURATION_STAGES) => {
  if (stage === REVIEW) return "Add Project";
  return "Continue";
};

const getCancelText = (stage: CONFIGURATION_STAGES) => {
  if (stage === TEMPLATE) return "Back to Projects";
  if (stage === DETAILS) return "Back to Template";
  if (stage === REVIEW) return "Back to Project Details";
};

const updateToNextStage = (stage: CONFIGURATION_STAGES) => {
  if (stage === TEMPLATE) return DETAILS;
  if (stage === DETAILS) return REVIEW;
};

const updateToPrevStage = (stage: CONFIGURATION_STAGES) => {
  if (stage === DETAILS) return TEMPLATE;
  if (stage === REVIEW) return DETAILS;
};
