import { Sidebar, SidebarProvider } from "@mc/components/Sidebar";

export const DefaultLayout = ({ children }) => (
  <SidebarProvider>
    <div className="flex h-screen">
      <Sidebar />
      <div
        className="flex grow flex-col overflow-y-auto"
        id="default-layout-content"
      >
        {children}
      </div>
    </div>
  </SidebarProvider>
);
