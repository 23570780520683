import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { OnboardingRequestStatus } from "@m/api/public/types";

import { usePendingOnboarding } from "@mc/features/Onboarding/hooks";

export const COMPLETE_ONBOARDING = gql(/* GraphQL */ `
  mutation CompleteOnboarding($input: UpdateRegistrationInput!) {
    updateMarketplaceRegistration(input: $input) {
      ok
      message
    }
  }
`);

export const useCompleteOnboarding = (sku: string) => {
  const pendingOnboarding = usePendingOnboarding(sku);

  const [completeOnboarding, result] = useMutation(COMPLETE_ONBOARDING, {
    variables: {
      input: {
        customerIdentifier: pendingOnboarding?.customerIdentifier,
        onboardingStatus: OnboardingRequestStatus.Complete,
        productCode: pendingOnboarding?.marketplaceProductCode,
      },
    },
    refetchQueries: ["GetMarketplaceRegistrations"],
  });

  return [completeOnboarding, result] as const;
};
