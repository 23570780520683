import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { Link } from "@m/ui";

import { KNOWLEDGE_BASE_URL } from "@mc/constants";

export const AwsManagementConsoleInfo = () => {
  return (
    <div className="flex gap-1">
      <div>
        <InformationCircleIcon className="h-2.5 w-2.5" />
      </div>
      <div className="text-sm font-medium text-default">
        Clicking on this button, you will be redirected to the AWS Management
        Console to set up Mission's access to your account. If you need
        additional information on configuring your account, check out our{" "}
        <Link to={KNOWLEDGE_BASE_URL} target="_blank">
          knowledge base
        </Link>
        .
      </div>
    </div>
  );
};
