export const MAX_ENGAGEMENT_TITLE_LENGTH = 28;

export enum ENGAGEMENT_SOURCE_TYPE {
  KANTATA_WORKSPACE = "KANTATA_WORKSPACE",
}

export const ENGAGEMENT_STATUS = {
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  ON_HOLD: "On Hold",
  OKAY_TO_START: "Okay to Start",
};

export const MESSAGES_ENGAGEMENTS = {
  TITLE_DESCRIPTION:
    "Give this engagement a title. Customers will see this engagement once the customer view is enabled in settings.",
  UPDATE_ENGAGEMENT_ERROR: "Unable to update project details.",
  UPDATE_ENGAGEMENT_SUCCESS: "Successfully updated the project details.",
  GET_ENGAGEMENT_ERROR: "Unable to retrieve project details.",
  GET_ENGAGEMENT_TYPES_ERROR: "Unable to retrieve engagement types.",
  GET_ENGAGEMENT_WORKSPACES_ERROR: "Unable to retrieve engagement workspaces.",
  CREATE_ENGAGEMENT_WITH_TEMPLATE_ERROR:
    "There was an error in creating your project.",
  CREATE_ENGAGEMENT_WITH_TEMPLATE_SUCCESS:
    "You’re all set! This project was added successfully to Engagements",
};

export const ENGAGEMENTS_DETAILS_SECTIONS = {
  RISKS: "risks",
  MILESTONES: "milestones",
  STATUS: "status",
  ACTION_ITEMS: "actionItems",
};

export const ENGAGEMENTS_DETAILS_ACTIONS = {
  ADD: "add",
  DETAILS: "details",
};

export const ENGAGEMENT_STATUS_FILTER_CRITERIA = {
  ALL: "All",
  IN_PROGRESS: "In Progress",
};
