import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import pathBrowserify from "path-browserify";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Link, generatePath } from "react-router-dom";

import { useAuth } from "@m/login";

export type BreadcrumbType = {
  name: string | ReactNode;
  path: string | null;
};

export interface BreadcrumbsProps extends ComponentPropsWithoutRef<"nav"> {
  ariaLabel?: string;
  crumbs: BreadcrumbType[];
}

export const Breadcrumbs = ({ crumbs, ...props }: BreadcrumbsProps) => {
  const { user } = useAuth();

  if (crumbs.length < 1) return null;

  return (
    <nav
      aria-label="Breadcrumbs"
      className="text-sm"
      data-component="Breadcrumbs"
      {...props}
    >
      <ol className="flex flex-row items-center">
        {crumbs.map(({ name, path }, index) => {
          const LinkEl: any = path ? Link : "span";
          const current = index === crumbs.length - 1;

          const linkPath = pathBrowserify.join(
            generatePath("/:companyId", {
              companyId: user?.company.slug || "",
            }),
            path || ""
          );

          return (
            <li
              key={`${name}-${index}`}
              aria-current={current ? "page" : undefined}
              className="inline-flex items-center"
            >
              {current ? (
                <span
                  className="cursor-default font-semibold text-default"
                  aria-current="page"
                >
                  {name}
                </span>
              ) : (
                <>
                  <LinkEl
                    className={clsx("font-medium text-accent", {
                      "pointer-events-none cursor-auto": path === null,
                    })}
                    to={linkPath}
                  >
                    {name}
                  </LinkEl>
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="mx-0.5 h-1.5 w-1.5 text-accent"
                  />
                </>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
