import { EngagementWorkStatus } from "@m/api/public/types";
import { Badge, Banner, Card, Spinner } from "@m/ui";
import { formatFullDate, fromSnakeCaseToProperCase } from "@m/utils";

import { ENGAGEMENT_WORK_BADGE_STATUS } from "../constants";

import { WidgetDetailSection } from "./WidgetDetailSection";
import { WidgetMainSection } from "./WidgetMainSection";

interface Props {
  workStatus?: EngagementWorkStatus;
  loading: boolean;
}

export const EngagementWorkWidget = ({ workStatus, loading }: Props) => {
  const { status, startDate, percentageComplete, dueDate } = workStatus || {};

  const badgeStatus = ENGAGEMENT_WORK_BADGE_STATUS[status];

  const startDateFormat = formatFullDate(startDate, "LLL d, yyyy");
  const dueDateFormat = formatFullDate(dueDate, "LLL d, yyyy");

  return (
    <Card data-testid="work-widget">
      <div className="flex h-full flex-col gap-2 p-3">
        {loading && (
          <div className="m-auto flex justify-center p-5">
            <Spinner />
          </div>
        )}
        {!loading && !workStatus && (
          <div className="flex h-full items-center">
            <div className="m-auto w-full">
              <Banner
                label="No work status"
                className="text-center"
                icon={null}
              />
            </div>
          </div>
        )}
        {!loading && workStatus && (
          <>
            <WidgetMainSection
              label="Work Completed"
              badge={
                <Badge
                  data-testid="work-status-badge"
                  strong={true}
                  label={fromSnakeCaseToProperCase(status)}
                  status={badgeStatus}
                  size="small"
                />
              }
              detail={`${percentageComplete}%`}
            />
            <div className="grid grid-cols-2">
              <WidgetDetailSection
                label="Start Date"
                detail={startDateFormat}
              />
              <WidgetDetailSection label="Due Date" detail={dueDateFormat} />
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
