import { ReactNode } from "react";

import { Button } from "@m/ui";

interface Props {
  error: ReactNode;
  onRetry: () => void;
}

export const LoginError = ({ error, onRetry }: Props) => {
  return (
    <div className="flex h-screen items-center justify-center bg-layout-inverse">
      <div className="container mx-auto max-w-lg">
        <img className="block" src="/logo-full.png" alt="Mission Control" />
        <div className="w-full text-center text-white">
          <p>{error}</p>
          <Button onClick={onRetry}>Try Again</Button>
        </div>
      </div>
    </div>
  );
};
