import clsx from "clsx";

import { toProperCase } from "@m/utils";

export function RolePill({ user }) {
  if (!user.registered) return null;
  let role = toProperCase(user.role);
  if (user.role === "READONLY") role = "Read-Only";
  return (
    <span
      aria-label="user-role"
      className={clsx("pill", {
        "bg-gray-400": role === "Read-Only",
        "bg-blue-700": role === "Member",
        "bg-blue-500": role === "Admin",
      })}
    >
      {role}
    </span>
  );
}
