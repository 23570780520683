import clsx from "clsx";
import PT from "prop-types";

import { THRESHOLDS } from "../constants";

const { FAIR_THRESHOLD, EXCELLENT_THRESHOLD } = THRESHOLDS;

export const ScoreDot = ({ score }) => {
  return (
    <div
      style={{ width: 5, height: 5 }}
      className={clsx("rounded-full", {
        "bg-red-500": score < FAIR_THRESHOLD,
        "bg-yellow-500": score >= FAIR_THRESHOLD && score < EXCELLENT_THRESHOLD,
        "bg-green-500": score >= EXCELLENT_THRESHOLD,
      })}
    />
  );
};

ScoreDot.propTypes = {
  score: PT.number.isRequired,
};
