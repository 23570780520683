import PT from "prop-types";

import { Modal } from "@m/ui";

import { ROLE_PERMISSIONS } from "../constants";

import { FlexItem } from "./FlexItem";

export const RolesOverviewModal = ({ isOpen, onClose }) => (
  <Modal
    open={isOpen}
    size="2xl"
    onClose={onClose}
    header={<Modal.Title>Understanding Roles in Mission Control</Modal.Title>}
  >
    <Modal.Description className="mb-3 text-gray-500">
      Only admins can change roles for other users
    </Modal.Description>
    <div className="flex space-x-4">
      <FlexItem title="Read Only" items={ROLE_PERMISSIONS.slice(0, 1)} />
      <FlexItem title="Member" items={ROLE_PERMISSIONS.slice(0, 2)} />
      <FlexItem title="Admin" items={ROLE_PERMISSIONS.slice(0, 3)} />
    </div>
  </Modal>
);

RolesOverviewModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
};
