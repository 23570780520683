import { PlusIcon } from "@heroicons/react/24/outline";

import { generateUniqueId } from "@m/api";
import { Textarea, TextareaProps } from "@m/ui";

import { EngagementsDetailsNote } from "../types";

export const TEMP_NOTE_ID_PREFIX = "temp-note-id";

export interface IEditEngagementsDetailsNotesProps {
  notes: EngagementsDetailsNote[];
  onUpdate: (notes: Partial<EngagementsDetailsNote>[]) => void;
}

export const EditEngagementsDetailsNotes = ({
  notes = [],
  onUpdate,
}: IEditEngagementsDetailsNotesProps) => {
  const handleUpdateNote: TextareaProps["onChange"] = (e) => {
    const {
      target: { id, value },
    } = e;

    const updatedNotes = updateNotes(notes, id, {
      id,
      content: value,
    });
    onUpdate(updatedNotes);
  };

  const handleAddNote = () => {
    const updatedNotes = [
      ...notes,
      {
        id: `${TEMP_NOTE_ID_PREFIX}-${generateUniqueId()}`,
        content: "",
      },
    ];

    onUpdate(updatedNotes);
  };

  if (notes.length === 0) {
    notes.push({
      id: `${TEMP_NOTE_ID_PREFIX}-${generateUniqueId()}`,
      content: "",
      createdAt: null,
      createdBy: null,
      updatedAt: null,
    });
  }

  return (
    <>
      {notes.map((note: EngagementsDetailsNote) => {
        const { id, content } = note;
        return (
          <div className="relative !mt-1 mb-2 items-center" key={id}>
            <Textarea
              data-testid={`engagement-detail-note-${id}`}
              aria-label="Notes"
              id={id}
              onChange={handleUpdateNote}
              onInput={handleUpdateNote}
              placeholder="Write notes here"
              value={content || ""}
            />
          </div>
        );
      })}
      <div
        data-testid="add-note"
        className="flex w-fit cursor-pointer flex-row items-center font-semibold text-action"
        onClick={handleAddNote}
        onKeyDown={handleAddNote}
      >
        <PlusIcon className="h-2.5 w-2.5" />
        <div className="ml-1">Add Note</div>
      </div>
    </>
  );
};

export const updateNotes = (
  notes: EngagementsDetailsNote[],
  noteId: string,
  updates: Partial<EngagementsDetailsNote>
) => {
  return notes.map((note) =>
    note.id === noteId ? { ...note, ...updates } : note
  );
};
