import { ActivityFeed } from "@mc/features/ActivityFeed";

import { useScorecardActivityStream } from "../api";
import { getScoreChanges } from "../utils";

export const ScorecardActivityFeed = () => {
  const {
    data: { activities, scorecards },
    loading,
    error,
  } = useScorecardActivityStream();

  const changes = getScoreChanges(scorecards);

  return (
    <ActivityFeed
      activities={activities}
      loading={loading}
      error={error}
      changes={changes}
      itemTitleColor="action"
    />
  );
};
