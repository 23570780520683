import { useQuery } from "@apollo/client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import { SpinnerScreen } from "@m/ui";

import { gql } from "../public";
import { ApiExtensions } from "../types";
import { saveExtensionsSetter } from "../utils";

export const ExtensionsContext = createContext<ApiExtensions>(undefined);

export const useExtensions = () => useContext(ExtensionsContext);

export const EXTENSIONS = gql(/* GraphQL */ `
  query Extensions {
    __schema {
      queryType {
        name
      }
      mutationType {
        name
      }
    }
  }
`);

export const ExtensionsProvider = ({ children }: PropsWithChildren) => {
  const { loading } = useQuery(EXTENSIONS);

  // extensions data is not loaded through the graphql query response data,
  // but rather through the extensionsLink ApolloLink component
  const [data, setExtensionsData] = useState<ApiExtensions | null>(null);

  // save the state setter function in a javascript variable that we can import
  // in the link component to save the extensions response data
  saveExtensionsSetter(setExtensionsData);

  const extensions = useMemo(() => getExtensions(data), [data]);

  return (
    <ExtensionsContext.Provider value={extensions}>
      {loading ? <SpinnerScreen /> : children}
    </ExtensionsContext.Provider>
  );
};

const getExtensions = (data: ApiExtensions | null) => {
  const rbac = data?.rbac || {};
  let featureFlags = data?.featureFlags || [];

  /**
   * Feature flags can be manually set in .env.development.local using
   * csv format with the REACT_APP_FEATURE_FLAGS environment variable
   *
   * e.g. REACT_APP_FEATURE_FLAGS=flag1,flag2,flag3
   */
  if (
    process.env.REACT_APP_FEATURE_FLAGS &&
    process.env.NODE_ENV === "development" &&
    !process.env.REACT_APP_STORYBOOK_SERVICES_API
  ) {
    featureFlags = process.env.REACT_APP_FEATURE_FLAGS.split(",");
  }

  return { featureFlags, rbac };
};
