import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { SpinnerScreen } from "@m/ui";

import { useMarketplaceRegistrations } from "@mc/features/Onboarding";
import { FullScreenLayout } from "@mc/layouts";

import { useStartOnboarding, useValidateRegistrationToken } from "../api";
import { SubscriptionDoesNotExistError } from "../components";

export const AuthenticatedSignupPage = () => {
  const { token } = useParams<{ token: string }>();

  const { newRegistrations } = useMarketplaceRegistrations();
  const registration = newRegistrations?.[0]; // todo(djbowers): what if there are multiple new registrations?

  const { data: tokenValidationResponse, error: tokenValidationError } =
    useValidateRegistrationToken(token);

  const [startOnboarding, { called: startOnboardingCalled }] =
    useStartOnboarding();

  const isTokenValid = tokenValidationResponse?.success;

  useEffect(() => {
    if (isTokenValid && registration && !startOnboardingCalled)
      startOnboarding(
        registration.customerIdentifier,
        registration.marketplaceProductCode
      );
  }, [isTokenValid, startOnboarding, registration, startOnboardingCalled]);

  if (tokenValidationError || !registration)
    return (
      <FullScreenLayout>
        <SubscriptionDoesNotExistError />
      </FullScreenLayout>
    );

  return <SpinnerScreen data-testid="authenticated-signup-page" />;
};
