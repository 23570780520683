import PT from "prop-types";
import { useState } from "react";

import { Field, Select } from "@m/ui";

import {
  COMPANY_SIZES,
  COMPANY_SIZE_OPTIONS,
  DEFAULT_COMPANY_SIZE_SELECTION,
} from "../constants";

export function CompanySizeInput({ companySize, onCompanySizeChange }) {
  const [companySizeDisplayValue, setCompanySizeDisplayValue] =
    useState(companySize);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setCompanySizeDisplayValue(newValue);
    onCompanySizeChange && onCompanySizeChange(newValue);
  };

  return (
    <Field htmlFor="companySize" label="Company Size">
      <Select
        id="companySize"
        className="w-full px-2 py-1"
        value={
          !companySizeDisplayValue
            ? DEFAULT_COMPANY_SIZE_SELECTION
            : companySizeDisplayValue
        }
        onChange={handleChange}
        options={COMPANY_SIZE_OPTIONS.map(({ disabled, value }) => (
          <option key={value} disabled={disabled}>
            {value}
          </option>
        ))}
      />
    </Field>
  );
}

CompanySizeInput.propTypes = {
  companySize: PT.oneOf(Object.values(COMPANY_SIZES)),
};
