import { generatePath, useNavigate } from "react-router-dom";

import { ScorecardResult } from "@m/api/public/types";
import { Badge } from "@m/ui";

import { PATHS } from "@mc/constants";

import { CheckStatusIcon } from "../icons";

export interface RecommendationCheckFieldProps {
  check: Pick<ScorecardResult, "id" | "status" | "name" | "pillar">;
}

export const RecommendationCheckField = ({
  check,
}: RecommendationCheckFieldProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const relativePath = generatePath(PATHS._CLOUD_SCORE_CHECK_DETAILS, {
      checkId: check.id,
    });
    navigate("../" + relativePath);
  };

  return (
    <div className="flex flex-col gap-0.5">
      <label
        className="text-xs font-semibold text-default"
        htmlFor="recommendation-check"
      >
        Check
      </label>
      {check && (
        <button
          className="flex items-center gap-x-2 text-left"
          id="recommendation-check"
          onClick={handleClick}
        >
          <CheckStatusIcon status={check.status} className="w-3" />
          <div className="flex flex-wrap gap-x-2 gap-y-0.5">
            <div className="text-md font-bold text-default">{check.name}</div>
            <Badge label={check.pillar} />
          </div>
        </button>
      )}
    </div>
  );
};
