import clsx from "clsx";
import { ReactNode } from "react";
import FocusLock from "react-focus-lock";

import { useEscapeListener } from "@m/utils/hooks";

import { Backdrop } from "../Backdrop";

import { DrawerHeader } from "./components";

type Direction = "right" | "bottom";
type Size = "small" | "default" | "large";

type Props = {
  backdrop?: boolean;
  children: ReactNode;
  direction?: Direction;
  onClose: () => void;
  open: boolean;
  size?: Size;
  title?: ReactNode;
};

export const Drawer = ({
  backdrop = true,
  children,
  direction = "right",
  onClose,
  open,
  size = "default",
  title,
}: Props) => {
  useEscapeListener(onClose);

  if (!open) return null;

  return (
    <>
      {backdrop && <Backdrop onMouseDown={onClose} />}
      <FocusLock disabled={!backdrop}>
        <div
          className={clsx(
            "fixed right-0 z-30 overflow-y-auto bg-white shadow-lg",
            {
              // Right drawer styles
              "top-0 h-screen border-l": direction === "right",
              "w-[300px]": direction === "right" && size === "small",
              "w-[600px]": direction === "right" && size === "default",
              "w-[95vw]": direction === "right" && size === "large",

              // Bottom drawer styles
              "bottom-0 w-screen border-t": direction === "bottom",
              "h-[300px]": direction === "bottom" && size === "small",
              "h-[600px]": direction === "bottom" && size === "default",
              "h-[95vh]": direction === "bottom" && size === "large",
            }
          )}
          data-testid="drawer"
        >
          <DrawerHeader title={title} onClose={onClose} />
          {children}
        </div>
      </FocusLock>
    </>
  );
};
