import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { SpringValue, animated, useTransition } from "@react-spring/web";
import { useEffect, useState } from "react";

import { generateUniqueId } from "@m/api";
import { Bookmark } from "@m/api/public/types";
import {
  Button,
  Confirm,
  Field,
  Input,
  Link,
  Spinner,
  SpinnerScreen,
} from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { SaveIcon } from "@mc/icons";

import { useBookmarks, useDeleteBookmark, useSaveBookmark } from "../api";

export const BookmarkSettingsPage = () => {
  const {
    data: { bookmarks: savedBookmarks },
    loading,
  } = useBookmarks();

  const [newBookmarks, setNewBookmarks] = useState<LocalBookmark[]>([]);
  const [bookmarkToDelete, setBookmarkToDelete] = useState<Bookmark | null>(
    null
  );

  const handleAddBookmark = () => {
    const newBookmark = {
      id: generateUniqueId(),
      linkDisplayName: "",
      linkShortDescription: "",
      url: "",
      isNew: true,
    } as LocalBookmark;
    setNewBookmarks((prev) => [...prev, newBookmark]);
  };

  const handleRemoveBookmark = (bookmark: LocalBookmark) => {
    if (bookmark.isNew)
      // If a new, unsaved bookmark is removed, we can skip the confirmation modal
      return setNewBookmarks((prev) =>
        prev.filter((addedBookmark) => addedBookmark.id !== bookmark.id)
      );

    setBookmarkToDelete(bookmark);
  };

  const bookmarks = [...savedBookmarks, ...newBookmarks];

  let height = 0;
  const gap = 20;
  const transitions = useTransition(
    bookmarks.map((bookmark) => ({
      ...bookmark,
      y: (height += gap) - gap,
    })),
    {
      key: (bookmark: LocalBookmark) => bookmark.id,
      enter: ({ y }) => ({ y }),
      update: ({ y }) => ({ y }),
    }
  );

  return (
    <Page data-testid="bookmark-settings-page">
      <PageHeading
        heading="Bookmarks"
        description="These links will be visible on the Dashboard to all users in your company."
        actions={
          <Button kind="primary" size="small" onClick={handleAddBookmark}>
            Add Bookmark
          </Button>
        }
      />

      {loading && <SpinnerScreen fitToParent />}

      {transitions((style, bookmark, _, index) => (
        <BookmarkForm
          key={bookmark.id}
          bookmark={bookmark}
          onRemove={handleRemoveBookmark}
          order={index + 1}
          savedCount={savedBookmarks.length}
          style={style}
        />
      ))}

      {bookmarkToDelete?.id && (
        <DeleteConfirmation
          bookmark={bookmarkToDelete}
          onClose={() => setBookmarkToDelete(null)}
        />
      )}
    </Page>
  );
};

const BookmarkForm = ({
  bookmark,
  onRemove,
  order,
  savedCount,
  style,
}: {
  bookmark: LocalBookmark;
  onRemove: (bookmark: LocalBookmark) => void;
  order: number;
  savedCount: number;
  style: { y: SpringValue<number> };
}) => {
  const [saveBookmark, { data: bookmarkSaved, loading: saveLoading }] =
    useSaveBookmark();

  const [name, setName] = useState(bookmark.linkDisplayName);
  const [url, setUrl] = useState(bookmark.url);
  const [description, setDescription] = useState(bookmark.linkShortDescription);
  const [isDirty, setIsDirty] = useState(false);

  const isInvalid = !name || !url;
  const isFirst = order === 1;
  const isLast = order === savedCount;

  const movementDisabled = isInvalid || isDirty || bookmark.isNew;
  const moveUpDisabled = isFirst || movementDisabled;
  const moveDownDisabled = isLast || movementDisabled;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSaveBookmark();
  };

  const handleSaveBookmark = (newOrder?: number) => {
    saveBookmark(
      name,
      url,
      description,
      newOrder ?? order,
      bookmark.isNew ? undefined : bookmark.id
    );

    setIsDirty(false);
  };

  const handleMoveUp = () => handleSaveBookmark(order - 1.5);
  const handleMoveDown = () => handleSaveBookmark(order + 1.5);

  useEffect(() => {
    // Remove the new bookmark so that we can refetch it from the API with the updated ID
    if (bookmark.isNew && bookmarkSaved) onRemove(bookmark);
  }, [bookmarkSaved, onRemove, bookmark]);

  return (
    <animated.form
      key={bookmark.id}
      className="flex gap-2.5"
      data-testid="bookmark-form"
      onSubmit={onSubmit}
      style={style}
    >
      <div className="flex items-center gap-1">
        <Link
          disabled={moveUpDisabled}
          fill="none"
          kind="secondary"
          onClick={handleMoveUp}
          size="small"
          className="h-fit"
        >
          <ArrowUpIcon className="h-2.5 w-2.5" />
        </Link>
        <Link
          disabled={moveDownDisabled}
          fill="none"
          kind="secondary"
          onClick={handleMoveDown}
          size="small"
          className="h-fit"
        >
          <ArrowDownIcon className="h-2.5 w-2.5" />
        </Link>
      </div>

      <Field label="Display Name" className="grow">
        <Input
          data-testid={`bookmark-name-${bookmark.id}`}
          onChange={({ target: { value } }) => {
            setIsDirty(true);
            setName(value);
          }}
          value={name}
        />
      </Field>

      <Field label="URL" className="grow">
        <Input
          data-testid={`bookmark-url-${bookmark.id}`}
          onChange={({ target: { value } }) => {
            setIsDirty(true);
            setUrl(value);
          }}
          value={url}
        />
      </Field>

      <Field label="Description" flag="optional" className="grow">
        <Input
          data-testid={`bookmark-description-${bookmark.id}`}
          onChange={({ target: { value } }) => {
            setIsDirty(true);
            setDescription(value);
          }}
          maxLength={MAX_DESCRIPTION_LENGTH}
          value={description}
        />
        <div className="flex justify-end text-xs text-subdued">
          {description.length}/{MAX_DESCRIPTION_LENGTH}
        </div>
      </Field>

      <div className="flex w-[60px] items-center gap-1">
        {saveLoading && <Spinner className="h-2.5 w-2.5 text-subdued" />}

        {isDirty && (
          <Link
            data-testid="save-bookmark"
            disabled={isInvalid}
            fill="none"
            kind="primary"
            size="small"
            type="submit"
          >
            <SaveIcon
              className="h-2.5 w-2.5 fill-form-active"
              ariaLabel="Save Bookmark"
            />
          </Link>
        )}

        <Link
          data-testid="delete-bookmark"
          className="ml-auto"
          fill="none"
          size="small"
          kind="secondary"
          onClick={() => onRemove(bookmark)}
        >
          <TrashIcon className="h-2.5 w-2.5" />
        </Link>
      </div>
    </animated.form>
  );
};

const DeleteConfirmation = ({
  bookmark,
  onClose,
}: {
  bookmark: Bookmark;
  onClose: () => void;
}) => {
  const [deleteBookmark, { data: bookmarkDeleted, loading: deleteLoading }] =
    useDeleteBookmark();

  useEffect(() => {
    if (bookmarkDeleted) onClose();
  }, [bookmarkDeleted, onClose]);

  return (
    <Confirm
      onClose={onClose}
      aria-label="Delete Bookmark Dialog"
      open={!!bookmark.id}
      actions={[
        <Button
          key="delete-button"
          kind="primary"
          onClick={() => deleteBookmark(bookmark.id)}
          loading={deleteLoading}
        >
          Delete
        </Button>,
        <Button key="cancel-button" fill="none" onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this link?</p>
      <p className="font-semibold">This action will apply for all users</p>
    </Confirm>
  );
};

const MAX_DESCRIPTION_LENGTH = 30;

type LocalBookmark = Bookmark & { isNew?: boolean };
