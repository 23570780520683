import {
  Account,
  ActivityStreamOperation,
  Engagement,
  EngagementDecision,
} from "@m/api/public/types";

export const ENGAGEMENT_ACTIVITY_FEED_GROUPS = {
  projectUpdates: {
    id: "projectUpdates",
    title: "Project Updates",
  },
  budget: {
    id: "budget",
    title: "Budget",
  },
  schedule: {
    id: "schedule",
    title: "Schedule",
  },
  milestone: {
    id: "milestone",
    title: "Milestone",
  },
  actionItem: {
    id: "actionItem",
    title: "Action Item",
  },
  risk: {
    id: "risk",
    title: "Risk",
  },
  decision: {
    id: "decision",
    title: "Decision",
  },
} as const;

export type EngagementActivityFeedComponent = React.FC<{
  id: string;
  model?: Engagement | EngagementDecision;
  changes?: any;
  before?: any;
  after?: any;
  timestamp?: any;
  actor?: Account;
  operation?: ActivityStreamOperation;
}>;

export type EngagementActivityFeedGroupConfig =
  keyof typeof ENGAGEMENT_ACTIVITY_FEED_GROUPS;
