import { RouteObject, generatePath } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  CreateRequestPage,
  RequestDetailsPage,
  RequestListPage,
} from "@mc/features/EngineerAssist/pages";
import { RequestType } from "@mc/features/EngineerAssist/types";
import { Navigate } from "@mc/router";

export const engineerAssistRoutes: RouteObject[] = [
  {
    index: true,
    element: <RequestListPage />,
  },
  {
    index: true,
    path: ":sysId",
    element: <RequestDetailsPage />,
  },
  {
    path: "create",
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace
            to={generatePath(
              PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE_REQUESTTYPE,
              {
                requestType: RequestType.DevOpsDesk,
              }
            )}
          />
        ),
      },
      {
        index: true,
        path: ":requestType",
        element: <CreateRequestPage />,
        errorElement: (
          <Navigate
            replace
            to={generatePath(
              PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE_REQUESTTYPE,
              {
                requestType: RequestType.DevOpsDesk,
              }
            )}
          />
        ),
      },
    ],
  },
];
