import { ArrowUpRightIcon } from "@heroicons/react/20/solid";

import { Button } from "@m/ui";

export const EngineerAssistPopup = () => {
  return (
    <div className="flex max-w-[250px] flex-col gap-2 text-default">
      <div className="flex flex-col gap-1">
        <h3 className="text-base font-semibold">
          Handle common DevOps tasks in your environment
        </h3>
        <ul className="list-inside list-disc px-1 text-sm font-medium">
          <li>Application Troubleshooting</li>
          <li>CI/CD</li>
          <li>Containerization</li>
          <li>Infrastructure & Networking</li>
          <li>Lambda & Python Scripts</li>
          <li>Security</li>
          <li>VPN</li>
          <li>and more!</li>
        </ul>
      </div>
      <Button
        fill="subdued"
        href="https://www.missioncloud.com/engineer-assist"
        kind="primary"
        rightIcon={ArrowUpRightIcon}
        size="small"
        target="_blank"
      >
        Learn more
      </Button>
    </div>
  );
};
