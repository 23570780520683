import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ELASTICACHE_LABEL, GET_FILTERS_ERROR } from "../constants";

export const GET_ELASTICACHE_CLUSTER_FILTERS = gql(/* GraphQL */ `
  query ElasticacheClusterFilters {
    elasticacheClusterFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
    }
  }
`);

export const useElasticacheClusterFilters = () => {
  const onError = () => toast.error(GET_FILTERS_ERROR(ELASTICACHE_LABEL));

  const { data, loading, error } = useQuery(GET_ELASTICACHE_CLUSTER_FILTERS, {
    onError,
  });

  const filters = data?.elasticacheClusterFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];

  return {
    data: { filters: { accounts } },
    error,
    loading,
  };
};
