import clsx from "clsx";
import { FC, useState } from "react";

import { Company, CurrentCompanyNode } from "@m/api/public/types";
import { Company as v4Company } from "@m/api/v4/types";
import { abbreviateLastName, getFullName, getInitials } from "@m/utils";

import { getAvatarColorByName } from "./getAvatarColorByName";
import { getAvatarUrlFromAccount } from "./getAvatarUrlFromAccount";

export type AccountAvatar = {
  company?: Company | v4Company;
  currentCompany?: CurrentCompanyNode;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  [key: string]: any;
};

export interface AvatarPropsStrict {
  //** Account */
  account?: AccountAvatar | null;

  /** Additional classes */
  className?: string;

  /** Include name as a string */
  includeName?: boolean;

  /** Use company name */
  useCompanyName?: boolean;

  /** Size options */
  size?: "small" | "medium" | "large";
}

interface AvatarProps extends AvatarPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

export const Avatar: FC<AvatarProps> = ({
  account,
  className,
  includeName = false,
  size = "small",
  useCompanyName = false,
}) => {
  const imageUrl = getAvatarUrlFromAccount(account);

  // Fallback to initials if image should exist but doesn't
  const [hasImage, setImage] = useState(imageUrl);

  const name = getName(account, useCompanyName);

  const initials = getInitials(name);

  const showPlaceholder =
    !account ||
    !name ||
    !initials ||
    (account.lastActive === null && account.registered);

  const containerClasses = clsx(
    "shrink-0",
    { "inline-flex items-center gap-1": includeName },
    { "inline-block": !includeName },
    className
  );
  const avatarClasses = clsx(
    "rounded-full text-center align-middle font-bold whitespace-nowrap",
    !showPlaceholder && getAvatarColorByName(name),
    {
      "border border-dashed border-slate-400": showPlaceholder,
      "bg-gray-100": hasImage,
      "w-3 h-3 leading-[24px] text-[10px]": size === "small",
      "w-4 h-4 leading-[34px] text-[12px]": size === "medium",
      "w-5 h-5 leading-[48px] text-xl": size === "large",
    }
  );

  const handleImageError = () => {
    setImage("");
  };

  return (
    <span
      className={containerClasses}
      data-component="Avatar"
      data-testid="avatar"
    >
      {useCompanyName && initials && (
        <Initials
          initials={initials}
          name={name}
          avatarClasses={avatarClasses}
        />
      )}
      {!useCompanyName && (
        <>
          {!hasImage && (
            <Initials
              initials={initials}
              name={name}
              avatarClasses={avatarClasses}
            />
          )}
          {hasImage && imageUrl && (
            <img
              src={imageUrl}
              alt={name}
              title={name}
              className={avatarClasses}
              onError={handleImageError}
            />
          )}
        </>
      )}
      {includeName && <span>{name}</span>}
    </span>
  );
};

const Initials = ({
  initials,
  name,
  avatarClasses,
}: {
  initials: string;
  name: string;
  avatarClasses: string;
}) => {
  return (
    <div data-testid="avatar-initials" className={avatarClasses}>
      <div title={name} className="no-underline">
        {initials}
      </div>
    </div>
  );
};

const getName = (account?: AccountAvatar | null, useCompanyName?: boolean) => {
  const { email, firstName, lastName, company, currentCompany } = account || {};

  if (useCompanyName && company) return company.name; // From user useAuth object
  if (useCompanyName && currentCompany) return currentCompany.name; // From account api object

  if (email?.includes("@missioncloud.com"))
    return abbreviateLastName(firstName, lastName);

  return getFullName(firstName, lastName);
};
