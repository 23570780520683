import { useLazyQuery } from "@apollo/client";

import { gql } from "@m/api";

export const VALIDATE_BOOTSTRAP_ROLE_DEPLOYED = gql(/* GraphQL */ `
  query ValidateBootstrapRoleDeployedToAwsAccount(
    $awsAccountId: String!
    $externalId: String
    $roleName: String
  ) {
    validateBootstrapRoleDeployedToAwsAccount(
      awsAccountId: $awsAccountId
      externalId: $externalId
      roleName: $roleName
    )
  }
`);

export const useValidateBootstrapRoleDeployed = (
  awsAccountId: string,
  roleName: string,
  iamExternalId: string,
  pollInterval: number
) => {
  const [validate, { data, error, networkStatus, ...result }] = useLazyQuery(
    VALIDATE_BOOTSTRAP_ROLE_DEPLOYED,
    {
      variables: { awsAccountId, roleName, externalId: iamExternalId },
      notifyOnNetworkStatusChange: true, // update network status when polling
      pollInterval,
    }
  );

  const bootstrapRoleValidated =
    data?.validateBootstrapRoleDeployedToAwsAccount || false;

  const isPolling = networkStatus === 6; // indicates that a polling query is currently in flight

  const errorCode = error?.graphQLErrors?.[0]?.extensions?.code;

  return [
    validate,
    {
      data: bootstrapRoleValidated,
      error: errorCode,
      isPolling,
      ...result,
    },
  ] as const;
};
