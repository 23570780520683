export const ROLE_PERMISSIONS = [
  "Can View Data",
  "Can Add or Modify Data",
  "Can Manage Account Access",
];

export const USER_MANAGEMENT_ROLES = {
  Admin: "ADMIN",
  Member: "MEMBER",
  "Read-Only": "READONLY",
};
