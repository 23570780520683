import { gql, useQuery } from "@mc/utils/graphql";

export const GET_CASE_FILTERS = gql`
  query GetCaseFilters {
    caseFilters {
      assignees
    }
    cases {
      edges {
        node {
          assignedTo {
            name
            id
            databaseId
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const useCaseFilters = () => {
  const { data, loading } = useQuery(GET_CASE_FILTERS);

  const assigneesIds = data?.caseFilters?.assignees || [];
  const assignedToCases =
    data?.cases?.edges?.map((edge) => edge.node.assignedTo) || [];

  const usedIds = new Set();
  const assigneesWithCases = [];

  for (const assignedToCase of assignedToCases) {
    if (!assignedToCase) continue;

    const { databaseId, email, firstName, lastName, id } = assignedToCase;

    if (assigneesIds.includes(databaseId) && !usedIds.has(databaseId))
      assigneesWithCases.push({ databaseId, email, firstName, lastName, id }) &&
        usedIds.add(databaseId);
  }

  return { data: { assignees: assigneesWithCases }, loading };
};
