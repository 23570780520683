import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";
import { USER_MANAGEMENT_ROLES } from "../constants/roles";

import { GET_TEAM_MEMBERS } from "./useTeamMembers";

export const UPDATE_ROLE = gql`
  mutation useUpdateRole($input: CompanyMembershipInput!) {
    updateCompanyTeamMemberRole(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateRole = (user, role) => {
  const onError = () => toast.error(MESSAGES.MODIFY_USER_ACCESS_ERROR);

  const onCompleted = () =>
    toast.success(MESSAGES.UPDATE_ROLE_SUCCESS(user.email, role));

  const [mutate, { loading, error }] = useMutation(UPDATE_ROLE, {
    refetchQueries: [{ query: GET_TEAM_MEMBERS }],
    onCompleted,
    onError,
  });

  function updateRole() {
    mutate({
      variables: {
        input: {
          accountId: user.accountId,
          roleName: USER_MANAGEMENT_ROLES[role],
        },
      },
    });
  }

  return [updateRole, { loading, error }];
};
