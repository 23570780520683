import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { MESSAGES_CASE_METRICS } from "../constants";

export const GET_CASE_METRICS = gql`
  query useCaseMetrics {
    activeCases: cases(
      where: {
        stateIn: [
          OPEN_UNASSIGNED
          OPEN_ASSIGNED
          NEW
          WORK_IN_PROGRESS
          AWAITING_INFO
        ]
        not: { categoryIn: [DEVOPS_DESK] }
      }
    ) {
      totalCount
    }
    needResponse: cases(
      where: { state: AWAITING_INFO, not: { categoryIn: [DEVOPS_DESK] } }
    ) {
      totalCount
    }
    alerts: cases(
      where: {
        stateIn: [
          OPEN_UNASSIGNED
          OPEN_ASSIGNED
          NEW
          WORK_IN_PROGRESS
          AWAITING_INFO
        ]
        or: [{ categoryIn: [ALERTS, AWS] }]
        not: { categoryIn: [DEVOPS_DESK] }
      }
    ) {
      totalCount
    }
  }
`;

export const useCaseMetrics = () => {
  const onError = () =>
    toast.error(MESSAGES_CASE_METRICS.GET_CASE_METRICS_FAILURE);

  const { data, loading, error } = useQuery(GET_CASE_METRICS, { onError });

  const activeCases = data?.activeCases?.totalCount;
  const needResponse = data?.needResponse?.totalCount;
  const alerts = data?.alerts?.totalCount;

  return {
    data: { activeCases, needResponse, alerts },
    loading,
    error: error !== undefined,
  };
};
