import { useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { ScorecardSummary } from "@m/api/public/types";
import { useAuth } from "@m/login";

import { TabData, Tabs } from "@mc/components/Tabs";
import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { SCORECARD_DETAILS } from "../constants";

import { ScoreDot } from "./ScoreDot";

interface Props {
  summaries: Partial<ScorecardSummary>[];
}

export const ScorecardTabs = ({ summaries }: Props) => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const { pillarId } = useParams<{ pillarId: string }>();

  const tabsData = useMemo(() => {
    const tabs = [];

    // Loop over details and find matching summaries
    for (const pillar of Object.values(SCORECARD_DETAILS)) {
      const summary = summaries.find(
        (summary) => summary.pillar === pillar.pillar
      );
      if (summary) {
        const tab: TabData = {
          id: pillar.id,
          name: pillar.name,
          label: <TabLabel label={pillar.name} score={summary.score} />,
        };
        tabs.push(tab);
      }
    }

    return tabs.sort((a, b) => a.order - b.order);
  }, [summaries]);

  const handleTabChange = (tab: TabData) => {
    const path = generateCompanyPath(
      generatePath(PATHS.CLOUD_SCORE_PILLAR_DETAILS, {
        pillarId: tab.id,
      }),
      user.company.slug
    );
    navigate(path);
  };

  return (
    <Tabs
      activeTabName={pillarId}
      ariaLabel="Cloud Score Pillar Tabs"
      onTabChange={handleTabChange}
      tabsData={tabsData}
    />
  );
};

const TabLabel = ({ label, score }) => (
  <div className="flex items-center space-x-1">
    <div>{label}</div>
    <ScoreDot score={score} />
  </div>
);
