export const MESSAGES = {
  USER_LIST_ERROR: "Sorry, we ran into some trouble loading the list of users.",
  USER_LIST_EMPTY: "No users found",
  SEND_EMAIL_INVITES_ERROR:
    "We encountered an issue inviting one or more of the selected users.",
  UPDATE_ROLE: {
    ADMIN: "Admin",
    MEMBER: "Member",
    READONLY: "Read-Only",
  },
  REMOVE_USER_CONFIRMATION: (userName) => `Remove ${userName}?`,
  UPDATE_USER_CONFIRMATION: (role, userName) =>
    `Change ${userName} to ${role}?`,
  UPDATE_ROLE_SUCCESS: (email, role) =>
    `The access for ${email} has been changed to ${role}.`,

  MODIFY_USER_ACCESS_ERROR: "Failed to modify the user's role.",
  REMOVE_USER_ACCESS_ERROR: "Failed to remove access for the selected user.",
  RESEND_USER_INVITE_ERROR: "Failed to resend invite.",
};
