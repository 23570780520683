import { LineSvgProps, Serie, SliceTooltip } from "@nivo/line";
import { useCallback, useContext } from "react";

import { Activity, LineGraph } from "@mc/components/LineGraph";

import { CloudScoreTrendContext } from "../contexts";

import { CloudScoreTrendActivityPanel } from "./CloudScoreTrendActivityPanel";
import { CloudScoreTrendTooltip } from "./CloudScoreTrendTooltip";

interface CloudScoreTrendGraphBaseProps {
  activeTrendLine: Serie;
  activities: Activity[];
  allTrendLines: Serie[];
  ariaLabel: string;
  axisBottom: LineSvgProps["axisBottom"];
  className?: string;
  emptyMessage: string;
  loading: boolean;
  xScale: LineSvgProps["xScale"];
}

/**
 * Base component to be used by all Cloud Score Trend Graphs.
 */
export const CloudScoreTrendGraphBase = ({
  activeTrendLine,
  activities,
  allTrendLines,
  ariaLabel,
  axisBottom,
  emptyMessage,
  loading,
  xScale,
}: CloudScoreTrendGraphBaseProps) => {
  const { className } = useContext(CloudScoreTrendContext);

  const sliceTooltip: SliceTooltip = useCallback(
    ({ slice }) => (
      <CloudScoreTrendTooltip
        points={slice.points}
        allTrendLines={allTrendLines}
      />
    ),
    [allTrendLines]
  );

  return (
    <LineGraph
      activities={activities}
      ActivityPanelComponent={CloudScoreTrendActivityPanel}
      areaOpacity={0.05}
      ariaLabel={ariaLabel}
      axisBottom={axisBottom}
      axisLeft={{
        tickValues: 5,
      }}
      className={className}
      colors={["#11A1FD"]}
      data={[activeTrendLine]}
      emptyMessage={emptyMessage}
      enableArea={true}
      enableCrosshair={false}
      fill={[{ match: "*", id: "fadeOut" }]}
      gridYValues={[0, 2, 4, 6, 8, 10]}
      loading={loading}
      sliceTooltip={sliceTooltip}
      xScale={xScale}
      yScale={{
        type: "linear",
        stacked: false,
        min: 0,
        max: 10,
      }}
    />
  );
};
