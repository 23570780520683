import { gql, useQuery } from "@mc/utils/graphql";

export const GET_SCORECARD_CONFIGURATIONS = gql`
  query ScorecardCompanyConfiguration {
    scorecardCompanyConfiguration {
      scorecardAccountConfigurationCounts {
        notIgnoredCount
      }
      scorecardAccountConfigurations {
        account {
          databaseId
          name
        }
        description
        included
      }
    }
  }
`;

export const useScorecardCompanyConfiguration = () => {
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_SCORECARD_CONFIGURATIONS, {});

  const companyConfiguration = data?.scorecardCompanyConfiguration;

  const accountConfigurations =
    companyConfiguration?.scorecardAccountConfigurations || [];

  const configurationCounts =
    companyConfiguration?.scorecardAccountConfigurationCounts;

  const notIgnoredCount = configurationCounts?.notIgnoredCount;

  const sortConfigs = (a, b) =>
    +b.included - +a.included || a.description.localeCompare(b.description);

  const sortedAcctConfigs = [...accountConfigurations].sort(sortConfigs);

  return {
    data: {
      scorecardAcctConfigs: sortedAcctConfigs,
      notIgnoredCount,
    },
    error,
    loading,
  };
};
