import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { EC2_LABEL, GET_FILTERS_ERROR } from "../constants";

export const GET_EC2_INSTANCES_FILTERS = gql(/* GraphQL */ `
  query Ec2InstanceFilters {
    ec2InstanceFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      instanceTypes
      states
      availabilityZones
    }
  }
`);

export const useEc2InstancesFilters = () => {
  const onError = () => {
    toast.error(GET_FILTERS_ERROR(EC2_LABEL));
  };

  const { data, loading, error } = useQuery(GET_EC2_INSTANCES_FILTERS, {
    onError,
  });

  const filters = data?.ec2InstanceFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const types = filters.instanceTypes || [];
  const states = filters.states || [];
  const zones = filters.availabilityZones || [];

  return {
    data: { filters: { accounts, types, states, zones } },
    error,
    loading,
  };
};
