import { Button } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { PATHS } from "@mc/constants";

export const NewCaseButton = ({ className }: { className: string }) => {
  return (
    <AccessRequired mutation="createCase">
      <Button
        className={className}
        data-testid="create-case-button"
        kind="primary"
        to={PATHS.SUPPORT_CASES_CREATE}
      >
        New Case
      </Button>
    </AccessRequired>
  );
};
