import { CheckSource, ScorecardResultStatus } from "@m/api/public/types";

export enum IgnoredStatus {
  Ignored = "IGNORED",
}

export const CHECK_STATUS: Record<
  string,
  ScorecardResultStatus | IgnoredStatus
> = {
  ERROR: ScorecardResultStatus.Error,
  IGNORED: IgnoredStatus.Ignored,
  OK: ScorecardResultStatus.Ok,
  WARNING: ScorecardResultStatus.Warning,
};

export const CHECK_SOURCES: Record<string, { key: CheckSource; name: string }> =
  {
    AWS_TRUSTED_ADVISOR: {
      key: CheckSource.AwsTrustedAdvisor,
      name: "AWS Trusted Advisor",
    },
    AWS_SECURITY_HUB: {
      key: CheckSource.AwsSecurityHub,
      name: "AWS Security Hub",
    },
    AWS_COMPUTE_OPTIMIZER: {
      key: CheckSource.AwsComputeOptimizer,
      name: "AWS Compute Optimizer",
    },
  };

export const CHECK_IGNORE_STATES = {
  IGNORE: "Ignore",
  MONITOR: "Monitor",
};
