import { useQuery } from "@m/api";
import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const GET_COMPANY_WORKSPACES = gql(/* GraphQL */ `
  query GetCompanyWorkspaces {
    userProfile {
      currentCompany {
        kantataWorkspaces {
          edges {
            node {
              id
              title
              companyId
              isAssociatedWithEngagement
              projectType
            }
          }
        }
      }
    }
  }
`);

export const useCompanyWorkspaces = () => {
  const onError = () =>
    toast.error("Unable to retrieve Kantata workspaces for company.");

  const { data, loading, error } = useQuery(GET_COMPANY_WORKSPACES, {
    onError,
  });

  const workspaces =
    data?.userProfile?.currentCompany?.kantataWorkspaces?.edges?.map(
      (edge) => edge.node
    ) || [];

  return { data: { workspaces }, loading, error };
};
