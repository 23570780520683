import { gql } from "@m/api/public";

import { useQuery } from "@mc/utils/graphql";

export const GET_USER_PROFILE = gql(`
  query UserProfile {
    userProfile {
      businessPhone
      currentCompany {
        databaseId
        managedServiceBundle {
          description
          name
          displayName
        }
        mcn
        name
        types
      }
      email
      firstName
      jobLevel
      jobRole
      lastName
      name
    }
  }
`);

export const useUserProfile = () => {
  const { loading, error, data } = useQuery(GET_USER_PROFILE);

  const profile = data?.userProfile || {};

  return { loading, error, data: { profile } };
};
