import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const GET_RECOMMENDATION_DETAILS = gql(/* GraphQL */ `
  query useRecommendationDetails($id: ID!) {
    scorecardRecommendationDetails(id: $id) {
      __typename
      id
      title
      notes
      scorecardRecStatus
      scorecardResult {
        id
        pillar
        status
        name
        flaggedResourceFields
      }
      createdTime
      updatedTime
      createdBy {
        id
        name
        firstName
        lastName
        email
      }
      flaggedResources {
        totalCount
      }
    }
  }
`);

export const useRecommendationDetails = (recommendationId: string) => {
  const { data, ...result } = useQuery(GET_RECOMMENDATION_DETAILS, {
    variables: { id: recommendationId },
  });

  const recommendation = data?.scorecardRecommendationDetails;
  const check = recommendation?.scorecardResult;

  return {
    data: { recommendation, check },
    ...result,
  };
};
