import path from "path-browserify";
import { generatePath } from "react-router-dom";

export const generateCompanyPath = (
  pathname: string,
  companyId: string,
  params?: Record<string, string>
) => {
  const fullPath = path.join(`/:companyId`, pathname);
  return generatePath(fullPath, { companyId, ...params });
};

export const createCompanyPath = (pathname: string) =>
  path.join("/:companyId", pathname);
