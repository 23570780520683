import PT from "prop-types";
import { useState } from "react";

import { Field, Select } from "@m/ui";

import {
  DEFAULT_JOB_LEVEL_SELECTION,
  JOB_LEVELS,
  JOB_LEVEL_OPTIONS,
} from "../constants";

export function JobLevelInput({ jobLevel, onJobLevelChange }) {
  const [jobLevelDisplayValue, setJobLevelDisplayValue] = useState(jobLevel);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setJobLevelDisplayValue(newValue);
    onJobLevelChange && onJobLevelChange(newValue);
  };

  const options = JOB_LEVEL_OPTIONS.map(({ disabled, value }) => (
    <option key={value} disabled={disabled}>
      {value}
    </option>
  ));

  return (
    <Field htmlFor="user-profile-job-level" label="Job Level">
      <Select
        id="user-profile-job-level"
        value={
          !jobLevelDisplayValue
            ? DEFAULT_JOB_LEVEL_SELECTION
            : jobLevelDisplayValue
        }
        onChange={handleChange}
        options={options}
      />
    </Field>
  );
}

JobLevelInput.propTypes = {
  jobLevel: PT.oneOf(Object.values(JOB_LEVELS)),
};
