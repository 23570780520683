import { SpinnerScreen, useBreadcrumbs, useModalState } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { SettingsButton } from "@mc/components/SettingsButton";
import { FEATURE_FLAGS, PATHS } from "@mc/constants";
import { createCompanyPath } from "@mc/utils/companyPath";

import { useCloudScoreSummary } from "../api";
import {
  CloudScoreConfigurationError,
  CloudScoreRecommendationList,
  CurrentScoreSection,
  PillarSummaryCard,
  ScorecardActivityFeed,
  ScorecardSettingsModal,
  TalkToExpertBanner,
} from "../components";
import { CLOUD_SCORE_BREADCRUMBS } from "../constants";
import { getPillar, sortPillars } from "../utils";

export const CloudScoreSummaryPage = () => {
  const {
    data: {
      score,
      potentialMonthlySavings,
      totalChecks,
      optimizedChecks,
      criticalChecks,
      warningChecks,
      pillars,
    },
    loading,
  } = useCloudScoreSummary();

  const accountConfigModal = useModalState();
  const breadcrumbs = useBreadcrumbs(
    createCompanyPath(PATHS.CLOUD_SCORE),
    CLOUD_SCORE_BREADCRUMBS
  );

  const summaryAvailable = score !== undefined;

  return (
    <>
      <Page
        data-testid="cloud-score-summary-page"
        title={
          <PageTitle
            actions={<SettingsButton onClick={accountConfigModal.open} />}
            breadcrumbs={breadcrumbs}
            padding="small"
          />
        }
      >
        {!loading && summaryAvailable && (
          <div className="grid gap-y-3">
            <AccessRequired
              children={null}
              fallback={<TalkToExpertBanner />}
              feature={FEATURE_FLAGS.CLOUD_SCORE_RECOMMENDATIONS_ALLOW_ACCESS}
            />

            <CurrentScoreSection
              criticalChecks={criticalChecks}
              okChecks={optimizedChecks}
              potentialMonthlySavings={potentialMonthlySavings}
              score={score}
              totalChecks={totalChecks}
              warningChecks={warningChecks}
            />

            <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
              {pillars.sort(sortPillars).map((pillar) => (
                <PillarSummaryCard
                  key={pillar.id}
                  criticalChecks={pillar.error}
                  name={getPillar(pillar.pillar)?.name}
                  okChecks={pillar.ok}
                  pillar={pillar.pillar}
                  score={pillar.score}
                  scoreChange={pillar.scoreChange}
                  totalChecks={pillar.totalChecks}
                  warningChecks={pillar.warning}
                />
              ))}
            </div>

            <AccessRequired
              feature={FEATURE_FLAGS.CLOUD_SCORE_RECOMMENDATIONS_ALLOW_ACCESS}
            >
              <CloudScoreRecommendationList />
            </AccessRequired>

            <ScorecardActivityFeed />
          </div>
        )}

        {!loading && !summaryAvailable && <CloudScoreConfigurationError />}

        {loading && <SpinnerScreen fitToParent={true} />}
      </Page>

      <ScorecardSettingsModal
        isOpen={accountConfigModal.isOpen}
        onClose={accountConfigModal.close}
      />
    </>
  );
};
