/** @type {import('tailwindcss').Config} */
import headlessUI from "@headlessui/tailwindcss";

const textColor = {
  accent: "#94A3B8", // slate-400
  action: "#004c97", // blue-700
  default: "#202945", // blue-900
  inverse: "#ffffff", // white
  negative: "#EF4444", // red-500
  subdued: "#6B7280", // gray-500
};

export default {
  darkMode: "media",
  theme: {
    boxShadow: {
      DEFAULT: "0 5px 20px rgba(0, 0, 0, 0.05)",
      lg: "0 0 20px rgba(0,0,0,.2)",
    },
    spacing: {
      px: "1px",
      0: "0",
      0.5: "4px", // Rare
      1: "8px",
      1.5: "12px",
      2: "16px",
      2.5: "20px", // Only use for icons
      3: "24px",
      4: "32px", // After this point, 8px steps is too small to be significant, and moves to 16px steps
      5: "48px",
      6: "64px",
      7: "80px",
    },
    fontFamily: {
      sans: ["Noto Sans", "sans-serif"],
      mono: [
        "ui-monospace",
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace",
      ],
    },
    fontSize: {
      "2xs": ["11px", { lineHeight: "1" }],
      xs: ["0.75rem", { lineHeight: "1rem" }],
      sm: ["0.875rem", { lineHeight: "1.25rem" }],
      base: ["1rem", { lineHeight: "1.5rem" }],
      lg: ["1.125rem", { lineHeight: "1.75rem" }],
      xl: ["1.25rem", { lineHeight: "1.75rem" }],
      "2xl": ["1.5rem", { lineHeight: "2rem" }],
      "3xl": ["1.875rem", { lineHeight: "2.25rem" }],
      "4xl": ["2.25rem", { lineHeight: "2.5rem" }],
      "5xl": ["3rem", { lineHeight: "1" }],
      "6xl": ["3.75rem", { lineHeight: "1" }],
      "7xl": ["4.5rem", { lineHeight: "1" }],
      "8xl": ["6rem", { lineHeight: "1" }],
      "9xl": ["8rem", { lineHeight: "1" }],
    },
    extend: {
      animation: {
        spin: "spin .7s linear infinite",
      },
      borderColor: {
        DEFAULT: "#E4E4E7", // zinc-200
      },
      colors: {
        layout: {
          background: "#ffffff",
          border: "#E4E4E7", // zinc-200
          panel: "#F4F4F5", // zinc-100
          active: "#004c97", // blue-700
          inverse: "#202945", // blue-900
        },
        status: {
          good: "#10B981", // emerald-500
          neutral: "#D1D5DB", // gray-400
          warning: "#f59e0b", // amber-500
          error: "#ef4444", // red-500
        },
        form: {
          border: "#D4D4D8", // zinc-300
          active: "#004c97", // blue-700
          negative: "#dc2626", // red-600
          placeholder: "#94a3b8", // placeholder-accent
        },
        blue: {
          50: "#f2fafe",
          100: "#e4f2fd",
          200: "#c2e7fb",
          300: "#92d5f9",
          400: "#49b8ec",
          500: "#009ade",
          600: "#017dbb",
          700: "#004c97",
          800: "#113b6f",
          900: "#202945",
        },
        orange: {
          50: "#fef7ee",
          100: "#fb33d9",
          200: "#f6d9b1",
          300: "#f0be82",
          400: "#e99a53",
          500: "#f37920",
          600: "#d4662d",
          700: "#af4d25",
          800: "#8b3e21",
          900: "#70341d",
        },
      },
      textColor: textColor,
      placeholderColor: textColor,
    },
  },
  variants: {
    extend: {},
  },
  plugins: [headlessUI],
};
