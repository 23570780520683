import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const GET_MISSING_IAM_ROLES = gql(/* GraphQL */ `
  query GetMissingIamRoles {
    userProfile {
      currentCompany {
        marketplaceRegistrations {
          edges {
            node {
              awsAccountId
              missingIamRoles
              iamExternalId
            }
          }
        }
      }
    }
  }
`);

export const useMissingIamRoles = () => {
  const { data, loading } = useQuery(GET_MISSING_IAM_ROLES);

  const marketplaceRegistrations =
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges
      .map((edge) => edge.node)
      .filter((registration) => registration.missingIamRoles.length > 0) || [];

  const {
    awsAccountId,
    missingIamRoles = [],
    iamExternalId,
  } = marketplaceRegistrations?.[0] || {}; // todo(djbowers): what if there is more than one registration with missing iam roles?

  return {
    data: {
      awsAccountId,
      missingIamRoles,
      iamExternalId,
    },
    loading,
  };
};
