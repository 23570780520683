import { EngagementBudgetStatus } from "@m/api/public/types";
import { Badge, Banner, Card, Spinner } from "@m/ui";
import { toDollars } from "@m/utils";

import {
  ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME,
  ENGAGEMENT_BUDGET_BADGE_STATUS,
} from "../constants";
import { getRemainingBudgetPercent } from "../utils";

import { WidgetDetailSection } from "./WidgetDetailSection";
import { WidgetMainSection } from "./WidgetMainSection";

export const EngagementBudgetWidget = ({
  budgetStatus,
  loading,
}: {
  budgetStatus?: EngagementBudgetStatus;
  loading: boolean;
}) => {
  const remainingPercent = getRemainingBudgetPercent(budgetStatus);

  return (
    <Card>
      <div className="flex flex-col gap-2 p-3" data-testid="engagement budget">
        {loading && (
          <div className="flex justify-center p-5">
            <Spinner />
          </div>
        )}
        {!loading && !budgetStatus && (
          <div className="flex h-full items-center">
            <div className="m-auto w-full">
              <Banner
                label="No budget status"
                className="text-center"
                icon={null}
              />
            </div>
          </div>
        )}
        {!loading && budgetStatus && (
          <>
            <WidgetMainSection
              label="Budget Available"
              badge={
                <Badge
                  strong={true}
                  label={
                    ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME[budgetStatus?.status]
                  }
                  status={ENGAGEMENT_BUDGET_BADGE_STATUS[budgetStatus?.status]}
                  size="small"
                />
              }
              detail={remainingPercent && `${remainingPercent}%`}
            />
            <div className="grid grid-cols-2">
              <WidgetDetailSection
                label="Total Budget"
                detail={toDollars(budgetStatus?.totalBudget)}
              />
              <WidgetDetailSection
                label="Remaining"
                detail={toDollars(budgetStatus?.remainingBudget)}
              />
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
