import { useAuth } from "@m/login";

import { gql, useMutation } from "@mc/utils/graphql";

export const UPDATE_JOB_LEVEL = gql`
  mutation UpdateJobLevel($input: UpdateViewerInput!) {
    updateViewer(input: $input) {
      ok
      viewer {
        jobLevel
      }
    }
  }
`;

export const useUpdateJobLevel = (userProfile) => {
  const { user, updateUserSession } = useAuth();

  const onCompleted = (data) => {
    const { jobLevel } = data.updateViewer.viewer;
    updateUserSession({ ...user, jobLevel });
  };

  const [mutate, { data, loading }] = useMutation(UPDATE_JOB_LEVEL, {
    onCompleted,
  });

  function updateJobLevel(jobLevel) {
    if (jobLevel === userProfile.jobLevel) return;

    mutate({
      variables: {
        input: { jobLevel },
      },
    });
  }

  const ok = data?.updateViewer?.ok;

  return [updateJobLevel, { ok, loading }];
};
