export const CheckMarkIcon = ({
  ariaLabel,
  className,
}: {
  ariaLabel?: string;
  className: string;
}) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-mark-icon"
      aria-label={ariaLabel}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7488 1.96182C12.9096 2.1227 13 2.34087 13 2.56836C13 2.79584 12.9096 3.01402 12.7488 3.1749L5.88554 10.0382C5.72466 10.199 5.50649 10.2894 5.279 10.2894C5.05152 10.2894 4.83334 10.199 4.67246 10.0382L1.24083 6.60653C1.08455 6.44473 0.998078 6.22802 1.00003 6.00308C1.00199 5.77814 1.09221 5.56297 1.25128 5.4039C1.41034 5.24484 1.62551 5.15461 1.85045 5.15266C2.0754 5.1507 2.2921 5.23718 2.45391 5.39345L5.279 8.21855L11.5357 1.96182C11.6966 1.80098 11.9148 1.71063 12.1423 1.71063C12.3698 1.71063 12.5879 1.80098 12.7488 1.96182Z"
        className={className}
        strokeWidth="1.5"
      />
    </svg>
  );
};
