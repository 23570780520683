import { CaseCategory, CaseState } from "@m/api/public/types";

import { sortOrderAsc } from "@mc/utils/sort";

export const CASE_LIST_PRIORITY = {
  CRITICAL: "CRITICAL",
  HIGH: "HIGH",
  MODERATE: "MODERATE",
  LOW: "LOW",
};

export const CASE_LIST_SOURCE = {
  MISSION: "MISSION",
  CUSTOMER: "CUSTOMER",
  NEW_RELIC: "NEWRELIC",
  CLOUDHEALTH: "CLOUDHEALTH",
  ALERT_LOGIC: "ALERTLOGIC",
  UNKNOWN: "UNKNOWN",
};

export const CASE_LIST_STATUS_FILTERS = {
  open: {
    value: "open",
    label: "Open",
    where: [
      CaseState.New,
      CaseState.AwaitingInfo,
      CaseState.WorkInProgress,
      CaseState.OpenAssigned,
      CaseState.OpenUnassigned,
    ],
  },
  needResponse: {
    value: "needResponse",
    label: "Need Response",
    where: [CaseState.AwaitingInfo],
  },
  closed: {
    value: "closed",
    label: "Closed",
    order: 2,
    where: [CaseState.Resolved, CaseState.Closed, CaseState.Cancelled],
  },
};

export const CASE_LIST_STATUS_OPTIONS = Object.values(
  CASE_LIST_STATUS_FILTERS
).sort(sortOrderAsc);

export const CASE_LIST_TYPE_FILTERS = {
  all: {
    value: "all",
    label: "All",
    where: {
      not: {
        categoryIn: [CaseCategory.DevopsDesk],
      },
    },
  },
  support: {
    value: "support",
    label: "Support",
    order: 2,
    where: {
      not: {
        categoryIn: [
          CaseCategory.DevopsDesk,
          CaseCategory.Alerts,
          CaseCategory.Aws,
        ],
      },
    },
  },
  alerts: {
    value: "alerts",
    label: "Alerts",
    order: 4,
    where: {
      or: [
        {
          categoryIn: [CaseCategory.Alerts, CaseCategory.Aws],
        },
      ],
    },
  },
};

export const CASE_LIST_TYPE_OPTIONS = Object.values(
  CASE_LIST_TYPE_FILTERS
).sort(sortOrderAsc);
