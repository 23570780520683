import { useParams } from "react-router-dom";

import { toast } from "@m/ui";

import { GET_CASE_DETAILS } from "@mc/features/SupportCases/api/useCaseDetails";
import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

export const DELETE_ATTACHMENT = gql`
  mutation useDeleteAttachment($input: CaseAttachmentDeletionInput!) {
    deleteCaseAttachment(input: $input) {
      message
    }
  }
`;

export const useDeleteAttachment = (activity, onCompleted) => {
  const { id: caseId } = useParams<{ id?: string }>();

  const onError = () => toast.error(MESSAGES.ATTACHMENT_DELETE_FAILURE);

  const [deleteAttachment, { loading }] = useMutation(DELETE_ATTACHMENT, {
    variables: { input: { sysId: activity.sysId } },
    refetchQueries: [{ query: GET_CASE_DETAILS, variables: { sysId: caseId } }],
    onError,
    onCompleted,
  });

  return [deleteAttachment, { loading }] as const;
};
