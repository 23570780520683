import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import {
  ActionMeta,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValue,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

import tailwindConfig from "@m/tailwind-config";
import { Checkbox } from "@m/ui";

const placeholderColor = tailwindConfig.theme.extend.colors?.form?.placeholder;

type OptionType = {
  value: string | number;
  label: string;
  subLabel?: string;
  __isNew__?: boolean; // This comes from react-select to note a custom option that can be created by a user
  isUserCreated?: boolean; // Used on our end too keep track of user created options after they have been selected
};

interface DynamicSelectProps
  extends CreatableProps<OptionType, true, GroupBase<OptionType>> {
  initialOptions: OptionType[];
  selectedOptions?: OptionType[];
  onSelectedOptionsChange?: (selectedOptions: OptionType[]) => void;
}

// TODO(jamesmoody): Rename to Select once usage of current Select component has been fully removed from the codebase
export const DynamicSelect = ({
  id,
  name,
  value,
  placeholder,
  isMulti,
  initialOptions,
  selectedOptions,
  onSelectedOptionsChange = () => {},
  ...props
}: DynamicSelectProps) => {
  const [userCreatedOptions, setUserCreatedOptions] = useState<OptionType[]>(
    []
  );

  const handleSelectChange = (
    selectedOptions: MultiValue<OptionType>,
    { action, removedValue }: ActionMeta<OptionType>
  ) => {
    if (action === "remove-value" && removedValue?.isUserCreated) {
      // If a de-selected option is user created, we need to remove it from the options list
      const updatedUserCreatedOptions = userCreatedOptions.filter(
        (option: OptionType) => option.value !== removedValue.value
      );
      setUserCreatedOptions(updatedUserCreatedOptions);
    }
    onSelectedOptionsChange(selectedOptions as OptionType[]);
  };

  const handleUserCreatedOption = (newValue: string) => {
    const newOption = {
      value: newValue,
      label: newValue,
      isUserCreated: true,
    };
    const updatedUserCreatedOptions = [...userCreatedOptions, newOption];
    setUserCreatedOptions(updatedUserCreatedOptions);
    onSelectedOptionsChange([...(selectedOptions || []), newOption]);
  };

  const selectStyle: StylesConfig<OptionType, true> = {
    // Overwrite a few default styles from react-select
    multiValue: (style) => ({
      ...style,
      borderRadius: 15,
    }),
    control: (style) => ({
      // Removes the default focus highlighting so we can add our own
      ...style,
      border: 0,
      boxShadow: "none",
    }),
    placeholder: (style) => ({
      ...style,
      color: placeholderColor,
    }),
  };

  return (
    <CreatableSelect
      id={id}
      name={name}
      value={selectedOptions || value}
      onChange={handleSelectChange}
      onCreateOption={handleUserCreatedOption}
      options={[...initialOptions, ...userCreatedOptions]}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable={isMulti}
      isClearable={isMulti}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      styles={selectStyle}
      components={{
        Option,
        Control,
        MultiValueContainer,
        Menu,
        MultiValueLabel,
        MultiValueRemove,
        DropdownIndicator,
        IndicatorSeparator: null,
      }}
      {...props}
    />
  );
};

const Control = (props: ControlProps<OptionType>) => {
  const { innerRef, innerProps, isDisabled } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={clsx(
        "form-control-container form-control__focus-within form-placeholder w-full",
        {
          "opacity-60": isDisabled,
        }
      )}
    >
      <components.Control {...props} />
    </div>
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<OptionType>) => {
  const { innerProps, data } = props;
  return (
    <div
      {...innerProps}
      data-testid={`select-option-pill-${data.value}`}
      className={"w-fit items-center rounded-full text-left"}
    >
      <components.MultiValueContainer {...props} />
    </div>
  );
};

const MultiValueLabel = (props: MultiValueGenericProps<OptionType>) => {
  const { data, innerProps } = props;
  return (
    <div {...innerProps} className="pl-1 text-xs font-semibold">
      {data.label}
    </div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  const { selectProps } = props;
  return (
    <div data-testid={`${selectProps.id}-select-dropdown-button`}>
      <ChevronDownIcon className="h-2 w-2" />
    </div>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => {
  const { innerProps, data } = props;
  return (
    <div
      {...innerProps}
      className="mx-0.5 hover:bg-transparent"
      data-testid={`select-clear-value-${data.value}`}
    >
      <XMarkIcon className="h-2 w-2 opacity-50 hover:opacity-80" />
    </div>
  );
};

const Option = (props: OptionProps<OptionType>) => {
  const { isSelected, innerRef, innerProps, data, isMulti } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex cursor-pointer items-center rounded-lg p-1 hover:bg-gray-100"
    >
      {isMulti && !data.__isNew__ && (
        // __isNew__ comes from react-select and is true when the 'create option' prompt is shown to the user
        <Checkbox
          checked={isSelected}
          className="mx-1"
          iconClassName="text-white"
        />
      )}
      <div className="font-semibold" data-testid={`option-${data.value}`}>
        {data.label}
        {data.subLabel && (
          <p className="text-xs font-semibold text-subdued">{data.subLabel}</p>
        )}
      </div>
    </div>
  );
};

const Menu = (props: MenuProps<OptionType>) => {
  return (
    <components.Menu {...props}>
      <div className="px-1 py-0.5">{props.children}</div>
    </components.Menu>
  );
};
