import { gql, useMutation } from "@mc/utils/graphql";

import { GET_USER_PROFILE } from "./useUserProfile";

export const UPDATE_PHONE_NUMBER = gql`
  mutation UpdatePhoneNumber($input: UpdateViewerInput!) {
    updateViewer(input: $input) {
      ok
      viewer {
        businessPhone
      }
    }
  }
`;

export const useUpdatePhoneNumber = (userProfile) => {
  const [mutate, { data, loading }] = useMutation(UPDATE_PHONE_NUMBER, {
    refetchQueries: [GET_USER_PROFILE],
  });

  const updatePhoneNumber = (businessPhone) => {
    if (businessPhone === userProfile.businessPhone) return;

    mutate({
      variables: {
        input: { businessPhone },
      },
    });
  };

  const ok = data?.updateViewer?.ok;

  return [updatePhoneNumber, { ok, loading }] as const;
};
