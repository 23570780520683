import { useState } from "react";

export const useOverlayState = (options = {}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = options?.onOpen;
  const onClose = options?.onClose;

  const handleOpen = (...args) => {
    onOpen && onOpen(...args);
    setIsOpen(true);
  };

  const handleClose = (...args) => {
    setIsOpen(false);
    onClose && onClose(...args);
  };

  return {
    isOpen,
    open: handleOpen,
    close: handleClose,
  };
};
