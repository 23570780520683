import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { CLOUD_SCORE_MESSAGES, LABELS } from "../constants";

export const FLAGGED_CHECK_RESOURCES_QUERY = gql(/* GraphQL */ `
  query FlaggedCheckResources(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    scorecardCheckResult(id: $id) {
      __typename
      id
      flaggedResources(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
        edges {
          node {
            id
            accountId
            accountName
            status
            created
            region
            resourceMetadata
          }
        }
      }
    }
  }
`);

export const useFlaggedCheckResources = (checkId: string) => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.GET_RESOURCES_FAILURE(LABELS.FLAGGED));

  const { data, fetchMore, ...result } = useQuery(
    FLAGGED_CHECK_RESOURCES_QUERY,
    {
      variables: {
        id: checkId,
        ...INITIAL_PAGINATION,
      },
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } =
    data?.scorecardCheckResult?.flaggedResources || {};
  const flaggedResources = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    fetchMore,
    pageInfo,
    totalCount,
  });

  return {
    data: flaggedResources,
    pagination,
    ...result,
  };
};

export const FLAGGED_CHECK_RESOURCES_DOWNLOAD_QUERY = gql(/* GraphQL */ `
  query FlaggedCheckResourcesDownload($id: ID!) {
    scorecardCheckResult(id: $id) @csv {
      flaggedResources @csv {
        edges {
          node {
            id
            accountId
            accountName
            status
            created
            region
            resourceMetadata
          }
        }
      }
    }
  }
`);
