import React, { useContext } from "react";

export const DialogContext = React.createContext([{}, () => {}]);
export const DialogContextRef = React.createRef();

export function GlobalDialog() {
  const [dialog] = useContext(DialogContext);
  if (dialog) return <>{dialog}</>;
  return null;
}

export function showDialog(component) {
  const [, showDialog] = DialogContextRef.current || [{}, () => {}];
  return showDialog(component);
}

export function hideDialog() {
  showDialog(undefined);
}
