import PT from "prop-types";

import { CheckStatusIcon } from "@mc/features/CloudScore/icons";

import { CHECK_STATUS_MESSAGES } from "../constants";

import { OptimizationHistoryItem } from "./OptimizationHistoryItem";

export const OptimizationHistoryItemCheckStatus = ({
  item: { changes, timestamp },
}) => {
  const statusChange = changes.status[1];
  const changedTo = CHECK_STATUS_MESSAGES[statusChange];

  const message = (
    <>
      Check status changed to <span className="font-bold">{changedTo}</span>
    </>
  );

  const icon = <CheckStatusIcon status={statusChange} />;

  return (
    <OptimizationHistoryItem
      icon={icon}
      message={message}
      timestamp={timestamp}
    />
  );
};

OptimizationHistoryItemCheckStatus.propTypes = {
  item: PT.shape({
    changes: PT.shape({ status: PT.array.isRequired }).isRequired,
    timestamp: PT.string.isRequired,
  }).isRequired,
};
