import { dt } from "@m/utils";

import { GREETINGS } from "../constants";

interface Props {
  firstName: string;
}

export const Greeting = ({ firstName }: Props) => {
  const greeting = getGreeting();
  const title = firstName ? `${greeting}, ${firstName}!` : `${greeting}!`;
  return <span className="text-2xl font-semibold">{title}</span>;
};

const getGreeting = () => {
  const hours = dt.local().hour;

  switch (true) {
    case hours < 12:
      return GREETINGS.MORNING;
    case hours < 17:
      return GREETINGS.AFTERNOON;
    default:
      return GREETINGS.EVENING;
  }
};
