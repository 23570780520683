import { Link, Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";

import { ChangeIndicator } from "@mc/components/ChangeIndicator";
import { PATHS } from "@mc/constants";
import { useCloudScoreSummary } from "@mc/features/CloudScore/api";
import {
  MissionCloudScore,
  MissionCloudScoreText,
  ScoreGaugeLinear,
} from "@mc/features/CloudScore/components";

import { MESSAGES_CLOUD_SCORE } from "../constants";

export const CloudScoreWidget = () => {
  const {
    data: {
      score,
      scoreChange,
      totalChecks,
      optimizedChecks,
      warningChecks,
      criticalChecks,
    },
    loading,
    error,
  } = useCloudScoreSummary();

  const scorecardAvailable = !loading && !error && score !== undefined;
  const scorecardEmpty = !loading && (!scorecardAvailable || error);

  return (
    <Card
      className="flex flex-col"
      data-testid="scorecard-widget"
      title={
        <div className="flex items-center justify-between">
          Cloud Score
          {scorecardAvailable && (
            <Link to={PATHS.CLOUD_SCORE} fontSize="sm">
              View Summary
            </Link>
          )}
        </div>
      }
    >
      {scorecardAvailable && (
        <>
          <div className="order-first">
            <ScoreGaugeLinear
              okChecks={optimizedChecks}
              warningChecks={warningChecks}
              criticalChecks={criticalChecks}
              totalChecks={totalChecks}
            />
          </div>
          <div className="flex flex-col items-center space-y-1 px-3 py-2">
            <MissionCloudScore score={score} className="text-5xl" />
            <ChangeIndicator
              change={scoreChange}
              aria-label="Mission Cloud Score Change"
            />
            <MissionCloudScoreText score={score} />
          </div>
        </>
      )}

      {scorecardEmpty && (
        <div className="flex flex-col items-center space-y-2 p-5 text-center text-sm text-default">
          <div className="font-semibold">
            {MESSAGES_CLOUD_SCORE.GET_SCORECARD_EMPTY}
          </div>
          <div className="font-normal">
            {MESSAGES_CLOUD_SCORE.GET_SCORECARD_EMPTY_EXTRA}
          </div>
        </div>
      )}

      {loading && <Spinner className="mx-auto my-4" />}
    </Card>
  );
};
