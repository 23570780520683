import { LIST_TEAMS } from "../api";

import { DownloadableReport } from "./DownloadableReport";

export function TeamAssignmentReport() {
  return (
    <DownloadableReport
      query={LIST_TEAMS}
      context={{ clientName: "v4" }}
      title="Team Assignments"
      description={`CSV data which includes a list of all "My Team" assignments`}
    />
  );
}
