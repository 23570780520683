import { ComputedSerie, CustomLayerProps } from "@nivo/line";
import { useContext } from "react";

import { dt } from "@m/utils";

import { ActivityContext } from "../contexts";

import { ActivityLine } from "./ActivityLine";

interface ActivityLayerProps extends CustomLayerProps {
  height: number;
  margin: { top: number; bottom: number };
  series: ComputedSerie[];
}

export const ActivityLayer = ({
  height,
  margin,
  series,
}: ActivityLayerProps) => {
  const { activities } = useContext(ActivityContext);

  const firstSeries = series[0];
  const points = firstSeries.data;
  const lines = [];

  // iterate through points to map activities to x values based on timestamp
  points.forEach((point) => {
    const pointISODate = dt
      .fromISO((point.data.x as Date).toISOString())
      .toISODate();

    // iterate through activities to find those that match the timestamp of the current point
    activities.forEach((activity, index) => {
      const activityISODate = dt.fromISO(activity.timestamp).toISODate();

      // found a matching activity, use x value of point to plot activity line
      if (activityISODate === pointISODate) {
        const { x } = point.position;
        const verticalMargin = margin.top + margin.bottom;
        const y = height - verticalMargin;

        lines.push(
          <ActivityLine
            key={`activity-${activityISODate}`}
            activity={activity}
            index={index}
            x={x}
            y={y}
          />
        );
      }
    });
  });

  return lines;
};
