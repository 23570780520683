import { dt, formatFullDate } from "@m/utils";

export const getDateRangeLabel = (interval, timestamp) => {
  if (!interval || !timestamp) return null;

  const startDatetime = dt.fromISO(timestamp).startOf(interval);
  const endDatetime = dt.fromISO(timestamp).endOf(interval);

  let label = formatFullDate(startDatetime, "LLLL d");

  if (interval === "week") {
    if (startDatetime.month === endDatetime.month) {
      label += ` - ${formatFullDate(endDatetime, "d")}`;
    } else {
      label = formatFullDate(startDatetime, "LLL d");
      label += ` - ${formatFullDate(endDatetime, "LLL d")}`;
    }
  }

  if (interval === "month") {
    label += ` - ${formatFullDate(endDatetime, "d")}`;
  }

  return label;
};
