import clsx from "clsx";
import PT from "prop-types";

import { Button } from "@m/ui";

export const EmptyMessage = ({
  buttonAction,
  buttonText,
  className,
  message,
}) => {
  return (
    <div
      data-testid="empty-message"
      className={clsx(
        "m-2 rounded-md border bg-white p-2 text-center",
        className
      )}
    >
      <p className="mx-5 cursor-default whitespace-pre-wrap text-center text-sm">
        {message}
      </p>
      {buttonAction && buttonText && (
        <Button onClick={buttonAction} size="small" fill="none" kind="primary">
          {buttonText}
        </Button>
      )}
    </div>
  );
};

EmptyMessage.propTypes = {
  buttonAction: PT.func,
  buttonText: PT.string,
  className: PT.string,
  message: PT.string,
};
