import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { CaseState } from "@m/api/public/types";
import { toast } from "@m/ui";

import { MESSAGES } from "../constants";

import { GET_CASE_DETAILS } from "./useCaseDetails";

export const CLOSE_CASE = gql(/* GraphQL */ `
  mutation CloseCase($input: CaseUpdateInput!) {
    updateCase(input: $input) {
      ok
      message
      case {
        sysId
        state
      }
    }
  }
`);

export const useCloseCase = (sysId: string) => {
  const onError = () => toast.error(MESSAGES.CASE_CLOSE_FAILURE);

  const [closeCase, { loading }] = useMutation(CLOSE_CASE, {
    variables: {
      input: {
        sysId: sysId,
        state: CaseState.Closed,
      },
    },
    onError,
    refetchQueries: [{ query: GET_CASE_DETAILS, variables: { sysId } }],
  });

  return [closeCase, { loading }] as const;
};
