import { ReactNode, useMemo } from "react";

import { Workspace } from "@m/api/public/types";
import { Banner, Suspensed } from "@m/ui";
import { formatFullDate, toDollars, toProperCase } from "@m/utils";

import { IModifiedEngagementTemplateFill } from "../api";
import { TConfigurationDetails } from "../pages";

type Props = {
  details: TConfigurationDetails;
  loading: boolean;
  templateFill: IModifiedEngagementTemplateFill[];
  workspaces: Workspace[];
};

export const EngagementsConfigurationReview = ({
  details,
  loading,
  templateFill,
  workspaces,
}: Props) => {
  const {
    linkedProjectId,
    template,
    title,
    startDate,
    dueDate,
    originalBudget,
  } = details;

  const linkedProject = workspaces.find(
    (workspace) => workspace.id === linkedProjectId
  );

  const formattedTemplate = toProperCase(template);
  const formattedStartDate = formatFullDate(startDate);
  const formattedDueDate = formatFullDate(dueDate);
  const formattedBudget = toDollars(originalBudget);

  const memoedFill = useMemo(
    () =>
      templateFill
        .sort((a, b) => a.order - b.order)
        .map(({ fill, id }) => {
          const titleField = fill.find(
            (fillField) => fillField.fieldName === "title"
          );

          return (
            <Suspensed
              className="mt-1"
              key={id}
              loading={loading}
              width="300px"
              height="30px"
            >
              <FillLabel label={titleField?.value} />
            </Suspensed>
          );
        }),
    [templateFill, loading]
  );

  return (
    <>
      <Banner
        className="mb-2"
        label="You’re almost done! Please review the data entered and confirm that it is correct before adding the project. Projects will be hidden from customers by default and will become visible only when the customer view is enabled."
      />
      <div className="flex flex-col">
        <ReviewSection label="Template">{formattedTemplate}</ReviewSection>
        <ReviewSection label="Kantana Project">
          {linkedProject?.title}
        </ReviewSection>
        <ReviewSection label="Project Title">{title}</ReviewSection>
        <ReviewSection label="Start Date">{formattedStartDate}</ReviewSection>
        <ReviewSection label="Due Date">{formattedDueDate}</ReviewSection>
        <ReviewSection label="Original Budget">{formattedBudget}</ReviewSection>
        <ReviewSection label="Milestones">{memoedFill}</ReviewSection>
      </div>
    </>
  );
};

const ReviewSection = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex flex-row py-2">
      <div className="font-subdued w-1/5 uppercase tracking-wider text-gray-500">
        {label}
      </div>
      <div className="w-4/5 font-semibold text-default">{children}</div>
    </div>
  );
};

const FillLabel = ({ label }: { label: string }) => {
  return (
    <div data-testid="fill-title" className="mb-2 flex flex-row items-center">
      <div className="mr-2 h-[10px] w-[10px] rounded-full bg-layout-active" />
      <div className="h-8 font-semibold leading-5">{label}</div>
    </div>
  );
};
