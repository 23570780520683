import { useMemo, useState } from "react";
import { Link, generatePath } from "react-router-dom";

import { Button, ButtonRadioGroup } from "@m/ui";
import { dt } from "@m/utils";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { MUTATIONS, PATHS } from "@mc/constants";

import { EngagementsProjectsCard } from "../components/EngagementsProjectsCard";
import {
  ENGAGEMENT_STATUS,
  ENGAGEMENT_STATUS_FILTER_CRITERIA,
} from "../constants";
import { IEngagement } from "../types";

type StatusFilterCriteriaType =
  | typeof ENGAGEMENT_STATUS_FILTER_CRITERIA.ALL
  | typeof ENGAGEMENT_STATUS_FILTER_CRITERIA.IN_PROGRESS;

export const EngagementsProjectsPage = ({
  engagements,
}: {
  engagements: IEngagement[];
}) => {
  const [filterCriteria, setFilterCriteria] =
    useState<StatusFilterCriteriaType>(ENGAGEMENT_STATUS_FILTER_CRITERIA.ALL);

  const filteredEngagements = useMemo(
    () => filterAndSortEngagements({ engagements, filterCriteria }),
    [engagements, filterCriteria]
  );

  const handleFilterTypeChange = (filterCriteria: StatusFilterCriteriaType) => {
    setFilterCriteria(filterCriteria);
  };

  return (
    <Page
      data-testid="engagements-projects-page"
      title={
        <PageTitle
          actions={
            <AccessRequired
              mutation={MUTATIONS.CREATE_ENGAGEMENT_WITH_TEMPLATE}
            >
              <Button
                fill="subdued"
                kind="primary"
                size="small"
                to={PATHS.ENGAGEMENTS_PROJECTS_CONFIGURATION}
              >
                Create Project
              </Button>
            </AccessRequired>
          }
          title="Projects"
          padding="small"
        />
      }
      width="large"
    >
      <div className="flex justify-end pb-4">
        <ButtonRadioGroup
          name="milestone-owner"
          onChange={handleFilterTypeChange}
          options={[
            {
              value: ENGAGEMENT_STATUS_FILTER_CRITERIA.ALL,
              label: ENGAGEMENT_STATUS_FILTER_CRITERIA.ALL,
            },
            {
              value: ENGAGEMENT_STATUS_FILTER_CRITERIA.IN_PROGRESS,
              label: ENGAGEMENT_STATUS_FILTER_CRITERIA.IN_PROGRESS,
            },
          ]}
          value={filterCriteria}
        />
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
        {filteredEngagements.map((engagement) => {
          const { id } = engagement;
          return (
            <Link
              key={id}
              to={generatePath(PATHS.ENGAGEMENTS_PROJECTS_DETAILS, {
                engagementId: id,
              })}
            >
              <EngagementsProjectsCard engagement={engagement} />
            </Link>
          );
        })}
      </div>
    </Page>
  );
};

export const filterAndSortEngagements = ({
  engagements,
  filterCriteria,
}: {
  engagements: IEngagement[];
  filterCriteria: StatusFilterCriteriaType;
}) => {
  const inProgressEngagements = [];
  const completedEngagements = [];

  const sortedEngagements = [...engagements].sort((a, b) => {
    return (
      Number(dt.fromISO(a?.workStatus?.dueDate)) -
      Number(dt.fromISO(b?.workStatus?.dueDate))
    );
  });

  for (const engagement of sortedEngagements) {
    if (engagement.source.status === ENGAGEMENT_STATUS.COMPLETED) {
      completedEngagements.push(engagement);
    } else {
      inProgressEngagements.push(engagement);
    }
  }

  return filterCriteria === ENGAGEMENT_STATUS_FILTER_CRITERIA.ALL
    ? [...inProgressEngagements, ...completedEngagements]
    : inProgressEngagements;
};
