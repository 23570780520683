import { ACTIVITY_NAMESPACES } from "../constants";

import { ActivityFeedItem } from "./ActivityFeedItem";

export const ActivityFeedItemAwsAccountResourcesAvailable = ({ activity }) => {
  const { timestamp, awsAccount } = activity;

  // There's an edgecase where an AWS account was moved to a different owner but the activity stream record still belongs to the original, so AWS Account comes back null
  if (!awsAccount) return null;

  // AwsAccountResourcesAvailableActivity model will always create a mock ID for awsAccount, so setting `awsAccount: null` in our example stream object will still output `awsAccount: { id: xxx }`. Just checking for this, but we should probably adjust the model.
  if (!awsAccount.name) return null;

  const { title: category } = ACTIVITY_NAMESPACES.account;

  const message = (
    <>
      Resources from AWS account <strong>{awsAccount.name}</strong> are now{" "}
      <strong>available</strong>
    </>
  );

  return (
    <ActivityFeedItem
      message={message}
      timestamp={timestamp}
      category={category}
    />
  );
};
