interface Props {
  className: string;
  height?: string | number;
  width?: string | number;
}

export const DotIcon = ({
  className,
  height = "10",
  width = "10",
  ...props
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="dot-icon"
    {...props}
  >
    <circle cx="5" cy="5" r="5" className={className} />
  </svg>
);
