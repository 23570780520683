import { clsx } from "clsx";
import PT from "prop-types";

import { THRESHOLDS } from "../constants";

const { FAIR_THRESHOLD, EXCELLENT_THRESHOLD } = THRESHOLDS;

export const MissionCloudScoreText = ({ className, score, text }) => {
  const baseText = text ? text : "Your AWS Environment";
  let msg;

  if (score < FAIR_THRESHOLD) {
    msg = (
      <>
        {baseText} <strong>can be optimized</strong>
      </>
    );
  } else if (score >= FAIR_THRESHOLD && score < EXCELLENT_THRESHOLD) {
    msg = (
      <>
        {baseText} is <strong>well optimized</strong>
      </>
    );
  } else if (score >= EXCELLENT_THRESHOLD) {
    msg = (
      <>
        {baseText} is <strong>highly optimized</strong>
      </>
    );
  }
  return (
    <div
      aria-label="Mission Cloud Score Text"
      className={clsx("text-center", className)}
    >
      {msg}
    </div>
  );
};

MissionCloudScoreText.propTypes = {
  className: PT.string,
  score: PT.number.isRequired,
  text: PT.string,
};
