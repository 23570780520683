import { AxisTickProps } from "@nivo/axes";
import {
  BarCustomLayerProps,
  BarLayer,
  BarTooltipProps,
  ComputedDatum,
  ResponsiveBar,
} from "@nivo/bar";
import { animated } from "@react-spring/web";

import { RioReportDetail, RioReportGranularityType } from "@m/api/public/types";
import { EmptyMessage, Spinner } from "@m/ui";
import { dt, formatFullDate } from "@m/utils";

import { RioReportBarDatum } from "../types";

import { CostLabel, SavingsLabel } from "./RioGraphLabels";
import { RioGraphTooltip } from "./RioGraphTooltip";

interface RioGraphProps {
  granularity?: RioReportGranularityType;
  loading?: boolean;
  onBarClick?: (date: Date) => void;
  rioReportDetails?: RioReportDetail[];
}

export const RioGraph = ({
  granularity = RioReportGranularityType.Daily,
  loading = false,
  onBarClick,
  rioReportDetails = [],
}: RioGraphProps) => {
  const rioReportBarData: RioReportBarDatum[] = rioReportDetails.map(
    ({ cost, savings, reportKey }) => ({
      cost,
      savings,
      date: dt.fromISO(reportKey).toISO(),
    })
  );

  const empty = rioReportBarData.length === 0;

  return (
    <div className="flex flex-col gap-y-1">
      <div className="ml-0.5 flex gap-2 text-xs font-semibold">
        <CostLabel />
        <SavingsLabel />
      </div>
      <div className="h-[250px]">
        {loading && (
          <div className="mt-2 flex h-5/6 items-center justify-center rounded-md border-2 border-dashed">
            <Spinner />
          </div>
        )}

        {empty && !loading && (
          <EmptyMessage
            message="No RIO reporting data to display."
            className="!mx-0 flex h-5/6 flex-col justify-center text-subdued"
          />
        )}

        {!empty && !loading && (
          <BarGraph
            granularity={granularity}
            onBarClick={onBarClick}
            rioReportBarData={rioReportBarData}
          />
        )}
      </div>
    </div>
  );
};

const BarGraph = ({
  granularity,
  onBarClick,
  rioReportBarData,
}: {
  granularity: RioReportGranularityType;
  onBarClick: (date: Date) => void;
  rioReportBarData: RioReportBarDatum[];
}) => {
  const startDate = rioReportBarData[0].date;
  const endDate = rioReportBarData[rioReportBarData.length - 1].date;

  const dateFormat = getDateFormat(granularity, new Date(startDate));
  const padding = granularity === RioReportGranularityType.Daily ? 0.5 : 0.2;

  const handleBarClick = (datum: ComputedDatum<RioReportBarDatum>) => {
    onBarClick(dt.fromISO(datum.data.date).toJSDate());
  };

  return (
    <ResponsiveBar
      borderRadius={6}
      colors={["#CBD5E1", "#6366F1"]}
      data={rioReportBarData}
      enableGridY={false}
      indexBy="date"
      innerPadding={2}
      keys={["cost", "savings"]}
      label={null}
      axisLeft={null}
      layers={RIO_GRAPH_LAYERS}
      margin={{ top: 24, bottom: 24, left: 48, right: 48 }}
      padding={padding}
      onClick={handleBarClick}
      onMouseEnter={(_datum, event) => {
        if (startDate !== endDate) {
          event.currentTarget.style.cursor = "pointer";
        }
      }}
      onMouseLeave={(_datum, event) => {
        event.currentTarget.style.cursor = "default";
      }}
      axisBottom={{
        tickValues: startDate === endDate ? [startDate] : [startDate, endDate],
        renderTick: (tick: AxisTickProps<string>) => (
          <RioDateTick
            tick={tick}
            date={formatFullDate(tick.value, dateFormat)}
          />
        ),
      }}
      tooltip={({ data }: BarTooltipProps<RioReportBarDatum>) => (
        <RioGraphTooltip rioReportBarDatum={data} dateFormat={dateFormat} />
      )}
    />
  );
};

const RioDateTick = ({ tick, date }) => (
  <animated.g style={{ opacity: tick.animatedProps.opacity }}>
    <animated.text
      transform={`translate(${tick.x},${tick.y + 16})`}
      style={{ fill: "#6b7280", fontWeight: 700, fontSize: 12 }}
      dominantBaseline="middle"
      textAnchor="middle"
    >
      {date}
    </animated.text>
  </animated.g>
);

const ZeroCostLayer = ({ bars }: BarCustomLayerProps<RioReportBarDatum>) => (
  <g>
    {bars.map((bar) => {
      if (bar.data.id === "cost" && bar.data.value === 0) {
        return (
          <rect
            key={`${bar.key}-zero-cost`}
            x={bar.x}
            y={bar.y - 7}
            width={bar.width}
            height={4}
            rx={2}
            ry={2}
            fill="#E2E8F0"
          >
            <title>No cost</title>
          </rect>
        );
      }
      return null;
    })}
  </g>
);

const RIO_GRAPH_LAYERS: BarLayer<RioReportBarDatum>[] = [
  "grid",
  "axes",
  "bars",
  "totals",
  "markers",
  "legends",
  "annotations",
  ZeroCostLayer,
];

const getDateFormat = (
  granularity: RioReportGranularityType,
  startDate: Date
) => {
  const currentYear = new Date().getFullYear();
  const isStartDatePriorToCurrentYear = startDate.getFullYear() < currentYear;

  switch (granularity) {
    case RioReportGranularityType.Daily:
      return isStartDatePriorToCurrentYear ? "LLLL d, yyyy" : "LLLL d";
    case RioReportGranularityType.Monthly:
      return isStartDatePriorToCurrentYear ? "LLLL yyyy" : "LLLL";
    default:
      return "yyyy";
  }
};
