import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { MESSAGES_MY_TEAM } from "../constants";

export const GET_TEAM_MEMBERS = gql`
  query useTeamMembers {
    teamMembers {
      id
      jobRole {
        name
        description
      }
      account {
        id
        name
        email
        firstName
        lastName
      }
    }
  }
`;

export const useTeamMembers = () => {
  const onError = () => toast.error(MESSAGES_MY_TEAM.GET_TEAM_MEMBERS_FAILURE);

  const { data, loading, error } = useQuery(GET_TEAM_MEMBERS, { onError });

  const teamMembers = data?.teamMembers || [];

  return {
    data: { teamMembers },
    loading,
    error: error !== undefined,
  };
};
