import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { RioReportGranularityType } from "@m/api/public/types";
import { toast } from "@m/ui";
import { dt } from "@m/utils";

export const GET_RIO_REPORT = gql(/* GraphQL */ `
  query RioReport(
    $granularity: RioReportGranularityType!
    $startDate: Date!
    $endDate: Date!
  ) {
    rioReportDetail(
      granularity: $granularity
      startDate: $startDate
      endDate: $endDate
    ) {
      firstReportDate
      lastUpdated
      intervalBasedCost
      intervalBasedSavings
      intervalBasedSavingsRate
      rioReportDetails {
        reportKey
        cost
        savings
      }
      rioReportAccounts {
        accountId
        cost
        savings
      }
      rioReportServices {
        serviceName
        cost
        savings
      }
      rioReportInstanceTypes {
        instanceType
        cost
        savings
        operatingSystem
        service
      }
      rioReportRegions {
        region
        cost
        savings
      }
      rioReportOperatingSystems {
        cost
        savings
        operatingSystem
      }
    }
  }
`);

export const useRioReport = (
  granularity: RioReportGranularityType,
  startDate: Date | null,
  endDate: Date | null
) => {
  const { data, ...result } = useQuery(GET_RIO_REPORT, {
    variables: {
      granularity,
      startDate: dt.fromJSDate(startDate).toISODate(),
      endDate: dt.fromJSDate(endDate).toISODate(),
    },
    onError: () => toast.error("Unable to fetch RIO reporting data"),
  });

  const rioReport = data?.rioReportDetail;

  const cost = parseFloat(rioReport?.intervalBasedCost ?? "0");
  const savings = parseFloat(rioReport?.intervalBasedSavings ?? "0");
  const onDemandEquivalent = cost + savings;
  const savingsRate =
    onDemandEquivalent > 0 ? savings / onDemandEquivalent : undefined;

  const firstReportDate = rioReport?.firstReportDate
    ? dt.fromISO(rioReport.firstReportDate).toJSDate()
    : null;
  const lastUpdated = rioReport?.lastUpdated
    ? dt.fromISO(rioReport.lastUpdated).toISO()
    : null;

  return {
    data: {
      rioReportDetails: rioReport?.rioReportDetails ?? [],
      firstReportDate,
      lastUpdated,
      cost,
      savings,
      savingsRate,
      onDemandEquivalent,
      accounts: rioReport?.rioReportAccounts ?? [],
      services: rioReport?.rioReportServices ?? [],
      instanceTypes: rioReport?.rioReportInstanceTypes ?? [],
      regions: rioReport?.rioReportRegions ?? [],
      operatingSystems: rioReport?.rioReportOperatingSystems ?? [],
    },
    ...result,
  };
};
