import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

import { Dropdown, DropdownItem, Suspensed } from "@m/ui";

export const EngagementsPageTitleActions = ({
  loading,
  shouldShowAllItems,
  onShowAllItems,
  onStatusUpdateClick,
  onEditClick,
}: {
  loading: boolean;
  shouldShowAllItems: boolean;
  onShowAllItems: () => void;
  onStatusUpdateClick?: () => void;
  onEditClick: () => void;
}) => {
  return (
    <Suspensed loading={loading} width="95px" height="27px">
      <Dropdown
        trigger={
          <EllipsisVerticalIcon
            data-testid="engagement-settings-menu"
            aria-label="Engagement Settings"
            className="h-3 w-3"
          />
        }
      >
        <DropdownItem onClick={onShowAllItems}>
          {shouldShowAllItems ? "Hide" : "Show"} Completed Items
        </DropdownItem>
        {onStatusUpdateClick && (
          <DropdownItem onClick={onStatusUpdateClick}>
            Update Status
          </DropdownItem>
        )}
        <DropdownItem onClick={onEditClick}>Project Settings</DropdownItem>
      </Dropdown>
    </Suspensed>
  );
};
