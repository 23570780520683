import clsx from "clsx";
import { ReactNode } from "react";

interface DropdownGroupProps {
  /** Dropdown Group Content */
  children: ReactNode;

  /** Additional classes */
  className?: string;

  /** Unstrict Props */
  [propName: string]: any;
}

export const DropdownGroup = ({
  children,
  className,
  ...props
}: DropdownGroupProps) => {
  const classes = clsx(
    "-mx-0.5 border-b border-slate-100 first:-mt-0.5 last:-mb-0.5 last:border-0",
    className
  );
  return (
    <div className={classes} {...props}>
      <div className="p-0.5">{children}</div>
    </div>
  );
};
