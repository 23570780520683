import { SparklesIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Button, Card } from "@m/ui";

import { useUserPreference } from "@mc/hooks";
import { useUsersnapEvent } from "@mc/providers";

export const TalkToExpertCard = () => {
  const [openForm, isLoaded] = useUsersnapEvent("talk-to-expert");
  const [showTalkToExpert, setShowTalkToExpert] = useUserPreference<boolean>(
    "showTalkToExpert",
    true
  );

  const handleClickClose = () => setShowTalkToExpert(false);

  if (!showTalkToExpert) return null;

  return (
    <Card data-testid="talk-to-expert-card" className="mx-auto w-full max-w-xs">
      <div className="flex flex-col gap-1.5 px-1 py-1.5">
        <div className="flex items-start justify-between">
          <div className="w-[135px] text-xs font-semibold text-subdued">
            Need more tools and insights?
          </div>
          <button
            onClick={handleClickClose}
            className="text-subdued"
            aria-label="Close Talk To Expert Card"
          >
            <XMarkIcon className="h-2.5 w-2.5" />
          </button>
        </div>
        <Button
          kind="primary"
          fill="subdued"
          className="w-full whitespace-nowrap"
          leftIcon={SparklesIcon}
          onClick={openForm}
          disabled={!isLoaded}
        >
          Talk to an expert
        </Button>
      </div>
    </Card>
  );
};
