import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const GET_BOOKMARKS = gql(/* GraphQL */ `
  query Bookmarks {
    bookmarks {
      id
      linkDisplayName
      linkShortDescription
      url
      createTime
      updateTime
    }
  }
`);

export const useBookmarks = () => {
  const { data, ...result } = useQuery(GET_BOOKMARKS, {
    onError: () => toast.error("Unable to access bookmark data"),
  });

  const bookmarks = data?.bookmarks || [];

  return {
    data: { bookmarks },
    ...result,
  };
};
