import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_SEARCH_TERMS_ERROR, RDS_LABEL } from "../constants";

export const RDS_SEARCH_TERMS_QUERY = gql(/* GraphQL */ `
  query RdsInstancesSearchTerms($searchTerm: String) {
    rdsInstanceSearchTerms(searchTerm: $searchTerm) {
      searchTerms
    }
  }
`);

export const useRdsInstancesSearchTerms = (searchTerm: string) => {
  const onError = () => toast.error(GET_SEARCH_TERMS_ERROR(RDS_LABEL));

  const { data, loading, error, refetch } = useQuery(RDS_SEARCH_TERMS_QUERY, {
    variables: { searchTerm },
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const searchTerms = data?.rdsInstanceSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    error,
    loading,
    refetch,
  };
};
