import clsx from "clsx";

import { Toggle } from "@m/ui";

import { ScorecardConfigurationsTooltip } from "./ScorecardConfigurationsTooltip";

export const ScorecardConfigurationsListItem = ({
  config,
  isAdminOrAnalyst,
  selectedRowIds,
  toggleRowSelected,
}) => {
  const {
    account: { databaseId, name },
    description,
  } = config;

  return (
    <div className="flex items-center justify-between py-1" key={databaseId}>
      <div className="flex flex-col text-left font-medium">
        <div
          className={clsx("text-sm text-default", {
            "text-subdued": description === "Warning",
          })}
        >
          {name === databaseId ? "-" : name}
        </div>
        <div className="text-xs font-light text-subdued">{databaseId}</div>
      </div>
      {description === "Warning" ? (
        <ScorecardConfigurationsTooltip />
      ) : (
        <Toggle
          aria-label="Toggle Account Selected"
          disabled={!isAdminOrAnalyst}
          onChange={() =>
            toggleRowSelected(databaseId, selectedRowIds.includes(databaseId))
          }
          checked={selectedRowIds.includes(databaseId)}
        />
      )}
    </div>
  );
};
