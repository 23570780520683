import PT from "prop-types";

import { Button, Confirm } from "@m/ui";

import { useRemoveUser } from "../api";
import { MESSAGES } from "../constants";

const ConfirmButton = ({ accountId, onClose }) => {
  const [removeUser] = useRemoveUser();

  const handleRemove = () => {
    removeUser(accountId);
    onClose();
  };

  return (
    <Button kind="destructive" onClick={handleRemove}>
      Remove
    </Button>
  );
};

export const RemoveUserModal = ({ onClose, open, user }) => (
  <Confirm
    open={open}
    onClose={onClose}
    aria-label="Remove User Dialog"
    title={MESSAGES.REMOVE_USER_CONFIRMATION(user.name)}
    actions={[
      <ConfirmButton
        key="confirm-button"
        accountId={user.accountId}
        onClose={onClose}
      />,
      <Button key="cancel-button" onClick={onClose} fill="none" kind="primary">
        Cancel
      </Button>,
    ]}
  >
    {user.firstName} will no longer have access to Mission Control
  </Confirm>
);

RemoveUserModal.propTypes = {
  onClose: PT.func.isRequired,
  open: PT.bool.isRequired,
  user: PT.object.isRequired,
};
