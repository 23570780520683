import { useAuth } from "@m/login";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_USER_PROFILE } from "./useUserProfile";

export const UPDATE_FIRST_NAME = gql`
  mutation UpdateFirstName($input: UpdateViewerInput!) {
    updateViewer(input: $input) {
      ok
      viewer {
        firstName
      }
    }
  }
`;

export const useUpdateFirstName = (userProfile) => {
  const { user, updateUserSession } = useAuth();

  const onCompleted = (data) => {
    const { firstName } = data.updateViewer.viewer;
    updateUserSession({ ...user, firstName });
  };

  const [mutate, { data, loading }] = useMutation(UPDATE_FIRST_NAME, {
    onCompleted,
    refetchQueries: [GET_USER_PROFILE],
  });

  const updateFirstName = (firstName) => {
    if (firstName === userProfile.firstName) return;

    mutate({
      variables: {
        input: { firstName },
      },
    });
  };

  const ok = data?.updateViewer?.ok;

  return [updateFirstName, { ok, loading }] as const;
};
