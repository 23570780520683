import { useState } from "react";

import { ITEMS_PER_PAGE } from "../constants";

export const usePaginationProps = (
  queryResult: {
    fetchMore: (...args: any) => Promise<object>;
    pageInfo: object;
    totalCount: number;
  },
  pageSize: number = ITEMS_PER_PAGE
) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { fetchMore, pageInfo, totalCount } = queryResult;

  return {
    currentPage,
    fetchMore,
    pageInfo,
    pageSize,
    setCurrentPage,
    showPagination: true,
    totalCount,
  };
};
