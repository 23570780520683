const CreditCard = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 16.5H43.5M4.5 18H43.5M10.5 28.5H22.5M10.5 33H16.5M9 39H39C41.4853 39 43.5 36.9853 43.5 34.5V13.5C43.5 11.0147 41.4853 9 39 9H9C6.51472 9 4.5 11.0147 4.5 13.5V34.5C4.5 36.9853 6.51472 39 9 39Z"
        stroke="#202945"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreditCard;
