import { InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import PT from "prop-types";
import { useState } from "react";

import { Avatar, Table, useModalState } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { StaffOnly } from "@mc/components/StaffOnly";

import { MESSAGES } from "../constants";

import { RemoveUserModal } from "./RemoveUserModal";
import { RolePill } from "./RolePill";
import { RolesOverviewModal } from "./RolesOverviewModal";
import { UpdateRoleModal } from "./UpdateRoleModal";
import { UserActionsKabob } from "./UserActionsKabob";

const UserInfo = ({ user }) => {
  const inactive = user.lastActive === null;
  return (
    <div className="flex items-center gap-1">
      <Avatar account={user} />
      <div className="text-left leading-none">
        <p
          className={clsx("font-bold", {
            "text-default": !inactive,
          })}
        >
          {inactive ? "Invited" : user.name}
          <StaffOnly>(#{user.accountId})</StaffOnly>
        </p>
        <p className="text-sm">{user.email}</p>
      </div>
    </div>
  );
};

export const UsersList = ({
  loading = false,
  users,
  defaultSort = "ACCOUNT__EMAIL_ASC",
  onSortChange,
}) => {
  const [selectedUser, setSelectedUser] = useState();
  const [role, setRole] = useState();

  const rolesModal = useModalState();
  const updateRoleModal = useModalState({
    onOpen: (user, role) => {
      setSelectedUser(user);
      setRole(role);
    },
    onClose: () => {
      setSelectedUser();
      setRole();
    },
  });
  const removeUserModal = useModalState({
    onOpen: (user) => setSelectedUser(user),
    onClose: () => setSelectedUser(),
  });

  const TABLE_HEADERS = [
    { label: "Name", sort: "ACCOUNT__EMAIL", width: 300 },
    {
      label: "Role",
      sort: "ROLE",
      Icon: InformationCircleIcon,
      onIconClick: rolesModal.open,
    },
    { label: "Last Activity", sort: "ACCOUNT__LAST_ACTIVE" },
    {
      label: " ",
      accessor: "menu",
      width: 40,
    },
  ];

  const rows = users.map((user) => ({
    name: <UserInfo user={user} />,
    role: <RolePill user={user} />,
    lastactivity: user.lastActive ? formatFullDate(user.lastActive) : "",
    menu: (
      <div className="w-full pr-2 text-right">
        <UserActionsKabob
          openRemoveUserModal={removeUserModal.open}
          openUpdateRoleModal={updateRoleModal.open}
          user={user}
        />
      </div>
    ),
  }));

  return (
    <>
      <Table
        ariaLabel="Users List"
        defaultSort={defaultSort}
        onSortChange={onSortChange}
        rows={rows}
        loading={loading}
        headers={TABLE_HEADERS}
        emptyMessage={MESSAGES.USER_LIST_EMPTY}
      />

      {rolesModal.isOpen && (
        <RolesOverviewModal
          isOpen={rolesModal.isOpen}
          onClose={rolesModal.close}
        />
      )}

      {updateRoleModal.isOpen && (
        <UpdateRoleModal
          open={updateRoleModal.isOpen}
          onClose={updateRoleModal.close}
          user={selectedUser}
          role={role}
        />
      )}

      {removeUserModal.isOpen && (
        <RemoveUserModal
          open={removeUserModal.isOpen}
          onClose={removeUserModal.close}
          user={selectedUser}
        />
      )}
    </>
  );
};

UsersList.propTypes = {
  loading: PT.bool,
  users: PT.array,
  defaultSort: PT.string,
  onSortChange: PT.func,
};
