import { gql } from "@m/api/v4";

export const SET_TEAM_MEMBERS = gql(`
  mutation SetCompanyTeamMembers($input: SetCompanyTeamMembersInput!) {
    setCompanyTeamMembers(input: $input) {
      ok
      message
    }
  }
`);
