import { dt, getMilliseconds } from "@m/utils";

import { getPillar } from "./getPillar";

export const getDailyTrendActivities = (dailyScoreResults, pillarId) => {
  return dailyScoreResults
    .filter(({ pillar }) => getPillar(pillar)?.id === pillarId)
    .reduce((acc, { created, pillar, resolvedRecommendationsCount }) => {
      if (resolvedRecommendationsCount === 0) return acc;

      const activity = {
        timestamp: created,
        pillar,
        resolvedRecommendationsCount,
      };

      if (pillar === null) {
        activity.allScoreResults = dailyScoreResults.filter(
          (result) => result.created === created
        );
      }

      return [...acc, activity];
    }, []);
};

export const getMonthlyTrendActivities = (
  averageMonthlyScoreResults,
  pillarId
) => {
  return averageMonthlyScoreResults
    .filter(({ pillar }) => getPillar(pillar)?.id === pillarId)
    .reduce((acc, { month, year, pillar, resolvedRecommendationsCount }) => {
      if (resolvedRecommendationsCount === 0) return acc;

      const monthDatetime = dt.fromObject({ month, year });
      const firstDayOfMonth = monthDatetime.startOf("month").toISODate();
      const lastDayOfMonth = monthDatetime.endOf("month").toISODate();

      const activity = {
        timestamp: firstDayOfMonth,
        pillar,
        resolvedRecommendationsCount,
      };

      if (pillar === null) {
        activity.allScoreResults = averageMonthlyScoreResults.filter(
          ({ month, year }) => {
            const monthMs = dt.fromObject({ month, year }).toMillis();
            return (
              monthMs >= getMilliseconds(firstDayOfMonth) &&
              monthMs <= getMilliseconds(lastDayOfMonth)
            );
          }
        );
      }

      return [...acc, activity];
    }, []);
};

export const getWeeklyTrendActivities = (
  averageWeeklyScoreResults,
  pillarId
) => {
  return averageWeeklyScoreResults
    .filter(({ pillar }) => getPillar(pillar)?.id === pillarId)
    .reduce((acc, { week, year, pillar, resolvedRecommendationsCount }) => {
      if (resolvedRecommendationsCount === 0) return acc;

      const weekDatetime = dt.fromObject({ weekNumber: week, weekYear: year });
      const firstDayOfWeek = weekDatetime.startOf("week").toISODate();
      const lastDayOfWeek = weekDatetime.endOf("week").toISODate();

      const activity = {
        timestamp: firstDayOfWeek,
        pillar,
        resolvedRecommendationsCount,
      };

      if (pillar === null) {
        activity.allScoreResults = averageWeeklyScoreResults.filter(
          ({ week, year }) => {
            const weekMs = dt
              .fromObject({ weekNumber: week, weekYear: year })
              .toMillis();
            return (
              weekMs >= getMilliseconds(firstDayOfWeek) &&
              weekMs <= getMilliseconds(lastDayOfWeek)
            );
          }
        );
      }

      return [...acc, activity];
    }, []);
};
