import { useAuth } from "@m/login";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_USER_PROFILE } from "./useUserProfile";

export const UPDATE_LAST_NAME = gql`
  mutation UpdateLastName($input: UpdateViewerInput!) {
    updateViewer(input: $input) {
      ok
      viewer {
        lastName
      }
    }
  }
`;

export const useUpdateLastName = (userProfile) => {
  const { user, updateUserSession } = useAuth();

  const onCompleted = (data) => {
    const { lastName } = data.updateViewer.viewer;
    updateUserSession({ ...user, lastName });
  };

  const [mutate, { data, loading }] = useMutation(UPDATE_LAST_NAME, {
    onCompleted,
    refetchQueries: [GET_USER_PROFILE],
  });

  const updateLastName = (lastName) => {
    if (lastName === userProfile.lastName) return;

    mutate({
      variables: {
        input: { lastName },
      },
    });
  };

  const ok = data?.updateViewer?.ok;

  return [updateLastName, { ok, loading }] as const;
};
