import { gql } from "@mc/utils/graphql";

export const LIST_USERS = gql`
  query activeUsers {
    companies(active: true, sort: NAME_ASC) @csv {
      edges {
        node {
          company: name
          members {
            edges {
              node {
                role
                invited: registered
                account {
                  name
                  email
                  lastActive
                  jobRole
                  jobLevel
                }
              }
            }
          }
        }
      }
    }
  }
`;
