import { SCORECARD_PILLARS } from "./pillars";

export enum CloudScorePillarIds {
  "AllChecks" = "all-checks",
  "CostOptimization" = "cost-optimization",
  "Operations" = "operations",
  "Security" = "security",
  "Reliability" = "reliability",
  "Performance" = "performance",
}

export const SCORECARD_DETAILS = {
  all: {
    id: CloudScorePillarIds.AllChecks,
    name: "All Checks",
    pillar: null,
    order: 0,
  },
  cost: {
    ...SCORECARD_PILLARS.COST_OPTIMIZATION,
    id: CloudScorePillarIds.CostOptimization,
    order: 1,
  },
  operations: {
    ...SCORECARD_PILLARS.OPERATIONAL_EXCELLENCE,
    id: CloudScorePillarIds.Operations,
    order: 2,
  },
  security: {
    ...SCORECARD_PILLARS.SECURITY,
    id: CloudScorePillarIds.Security,
    order: 3,
  },
  reliability: {
    ...SCORECARD_PILLARS.RELIABILITY,
    id: CloudScorePillarIds.Reliability,
    order: 4,
  },
  performance: {
    ...SCORECARD_PILLARS.PERFORMANCE,
    id: CloudScorePillarIds.Performance,
    order: 5,
  },
};

const details = Object.values(SCORECARD_DETAILS);

export const PILLAR_DETAILS = details.filter(({ pillar }) => pillar !== null);
export const PILLAR_IDS = details.map(({ id }) => id);
