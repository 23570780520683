import { MutableRefObject, useEffect, useRef } from "react";

type Callback = (...args: any) => void | Promise<any>;

interface DebounceOptions {
  delay?: number;
}

type TimeoutRefObject = MutableRefObject<NodeJS.Timeout | undefined>;

export const useDebouncedCallback = (
  callback: Callback,
  options: DebounceOptions
): Callback => {
  const { delay = 0 } = options || {};
  const timeout: TimeoutRefObject = useRef(undefined);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const debouncedCallback = (...args: any) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => callback(...args), delay);
  };

  return debouncedCallback;
};
