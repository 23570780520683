import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_SCORECARD_TREND_WEEKLY = gql`
  query useScorecardTrendWeekly(
    $intervalCount: Int!
    $interval: ScorecardHistoryType!
  ) {
    scorecardsAverageScoreResults(
      intervalCount: $intervalCount
      interval: $interval
    ) {
      edges {
        node {
          averageScore
          week
          year
          pillar
          name
          resolvedRecommendationsCount
        }
      }
    }
  }
`;

export const useScorecardTrendWeekly = ({ weeks }) => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_WEEKLY_ERROR);

  const { data, loading, error } = useQuery(GET_SCORECARD_TREND_WEEKLY, {
    variables: { intervalCount: weeks, interval: "WEEKLY" },
    onError,
  });

  const averageWeeklyScoreResults =
    data?.scorecardsAverageScoreResults?.edges?.map((edge) => edge.node) || [];

  return {
    data: { averageWeeklyScoreResults },
    loading,
    error,
  };
};
