const AVATAR_COLORS = [
  "bg-[#E99696] text-[#972F2F]", // red
  "bg-[#F7921E] text-[#7B490F]", // orange
  "bg-[#FFC30D] text-[#725706]", // yellow
  "bg-[#98C974] text-[#406325]", // green
  "bg-[#53C1BE] text-[#1A5957]", // teal
  "bg-[#00A1E2] text-[#00495E]", // blue
  "bg-[#DD96E9] text-[#793599]", // purple
  "bg-[#96ADE9] text-[#2B458A]", // indigo
];

export function getAvatarColorByName(name) {
  if (!name) return;
  const seed = name.split("").reduce((total, c) => total + c.charCodeAt(0), 0);
  return AVATAR_COLORS[seed % AVATAR_COLORS.length];
}
