export const DEFAULT_JOB_INDUSTRY_SELECTION = "Select job industry";

export const JOB_INDUSTRIES = {
  DEFAULT: DEFAULT_JOB_INDUSTRY_SELECTION,
  AEROSPACE: "Aerospace",
  AGRICULTURE: "Agriculture",
  AUTOMOTIVE: "Automotive",
  COMPUTERS_ELECTRONICS: "Computers & Electronics",
  CONSUMER_GOODS: "Consumer Goods",
  EDUCATION: "Education",
  FINANCIAL_SERVICES: "Financial Services",
  GAMING: "Gaming",
  GOVERNMENT: "Government",
  HEALTHCARE: "Healthcare",
  HOSPITALITY: "Hospitality",
  LIFE_SCIENCES: "Life Sciences",
  MANUFACTURING: "Manufacturing",
  MARKETING_ADVERTISING: "Marketing & Advertising",
  MEDIA_ENTERTAINMENT: "Media & Entertainment",
  MINING: "Mining",
  NON_PROFIT_ORGANIZATION: "Non-Profit Organization",
  OIL_GAS: "Oil & Gas",
  OTHER: "Other",
  POWER_UTILITIES: "Power & Utilities",
  PROFESSIONAL_SERVICES: "Professional Services",
  REAL_ESTATE_CONSTRUCTION: "Real Estate & Construction",
  RETAIL: "Retail",
  SOFTWARE_INTERNET: "Software & Internet",
  TELECOMMUNICATIONS: "Telecommunications",
  TRANSPORTATION_LOGISTICS: "Transportation & Logistics",
  TRAVEL: "Travel",
  WHOLESALE_DISTRIBUTION: "Wholesale & Distribution",
};

export const JOB_INDUSTRY_OPTIONS = [
  ...Object.entries(JOB_INDUSTRIES).map((o) => ({
    disabled: o[1] == DEFAULT_JOB_INDUSTRY_SELECTION,
    value: o[1],
  })),
];
