import PT from "prop-types";

import { Timestamp } from "@m/ui";

export const DatetimeField = ({ label, isoTime }) => (
  <div>
    <div className="text-xs font-semibold text-default">{label}</div>
    <div
      aria-label="Last Updated Date"
      className="mt-0.5 font-semibold text-default"
    >
      <Timestamp isoTime={isoTime} longFormat={true} titleCase={true} />
    </div>
  </div>
);

DatetimeField.propTypes = {
  label: PT.string.isRequired,
  isoTime: PT.string.isRequired,
};
