import { Outlet, RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import {
  ENGINEER_ASSIST_FEATURE_FLAGS,
  FEATURE_FLAGS,
  MUTATIONS,
  PATHS,
  SKUS,
} from "@mc/constants";
import {
  EngagementsConfigurationPage,
  EngagementsDetailsPage,
  EngagementsElevateProjectPage,
  EngagementsPage,
} from "@mc/features/Engagements";
import { NotFound } from "@mc/pages";
import { Navigate } from "@mc/router";

import { engagementModalRoutes } from "./engagementModalRoutes";
import { engineerAssistRoutes } from "./engineerAssistRoutes";

export const engagementsRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS}
        fallback={<NotFound />}
      >
        <Navigate replace to={PATHS.ENGAGEMENTS_PROJECTS} />
      </AccessRequired>
    ),
  },
  {
    path: "projects",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS}
        fallback={<NotFound />}
      >
        <EngagementsPage />
      </AccessRequired>
    ),
  },
  {
    path: "projects/configuration",
    element: (
      <AccessRequired
        mutation={MUTATIONS.CREATE_ENGAGEMENT_WITH_TEMPLATE}
        fallback={<Navigate replace to={PATHS.ENGAGEMENTS_PROJECTS} />}
      >
        <EngagementsConfigurationPage />
      </AccessRequired>
    ),
  },
  {
    path: "projects/:engagementId",
    element: (
      <AccessRequired
        entitlement={SKUS.ENGAGEMENTS_DEVOPS}
        fallback={
          <AccessRequired
            feature={FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS}
            fallback={<NotFound />}
          >
            <EngagementsDetailsPage />
            <Outlet />
          </AccessRequired>
        }
      >
        <EngagementsElevateProjectPage />
        <Outlet />
      </AccessRequired>
    ),
    children: engagementModalRoutes,
  },
  {
    path: "engineer-assist",
    element: (
      <AccessRequired
        feature={ENGINEER_ASSIST_FEATURE_FLAGS}
        matchFeatures="any"
        fallback={<NotFound />}
      >
        <Outlet />
      </AccessRequired>
    ),
    children: engineerAssistRoutes,
  },
];
