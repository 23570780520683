import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import {
  DEFAULT_SORT_RISK_LOG,
  MESSAGES_ENGAGEMENTS_RISK_LOG,
} from "../constants";
import { IRiskLogItem } from "../types";

import { GET_RISK_LOG_ITEMS } from "./useRiskLogItems";

export const UPDATE_RISK_LOG_ITEM = gql`
  mutation UpdateRiskLogItem($input: UpdateRiskLogItemInput!) {
    updateRiskLogItem(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateRiskLogItem = (engagementId: string) => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_RISK_LOG.SUCCESS_UPDATING_RISK_LOG);

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_RISK_LOG.ERROR_UPDATING_RISK_LOG);

  const [mutate, { loading }] = useMutation(UPDATE_RISK_LOG_ITEM, {
    onCompleted,
    onError,
  });

  const updateRiskLogItem = ({
    id,
    status,
    title,
    description,
    noteList,
    probability,
    mitigationStrategy,
    impact,
    itemType,
    owner,
    dateNeeded,
  }: Partial<IRiskLogItem> = {}) => {
    return mutate({
      variables: {
        input: {
          id,
          status,
          title,
          description,
          noteList,
          probability,
          mitigationStrategy,
          impact,
          itemType,
          owner,
          dateNeeded,
        },
      },
      refetchQueries: [
        {
          query: GET_RISK_LOG_ITEMS,
          variables: { id: engagementId, sort: DEFAULT_SORT_RISK_LOG },
        },
      ],
    });
  };

  return [updateRiskLogItem, { loading }] as const;
};
