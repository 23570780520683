import { useQuery } from "@m/api";
import {
  EngagementChecklistItemQuery,
  EngagementDecisionItemsQuery,
  EngagementMilestoneItemQuery,
  RiskLogItemQuery,
} from "@m/api/public/types";
import { toast } from "@m/ui";

import {
  GET_ENGAGEMENTS_CHECKLIST_ITEM,
  GET_ENGAGEMENTS_DECISION_ITEM,
  GET_ENGAGEMENTS_MILESTONE_ITEM,
  GET_RISK_LOG_ITEM,
} from "../api";
import {
  DETAIL_ITEM_TYPE,
  MESSAGES_ENGAGEMENTS_CHECKLIST,
  MESSAGES_ENGAGEMENTS_DECISIONS,
  MESSAGES_ENGAGEMENTS_MILESTONES,
  MESSAGES_ENGAGEMENTS_RISK_LOG,
} from "../constants";
import { EngagementDetailItemType } from "../types";

export interface EngagementDetailItemParams {
  sort?: string;
  detailType: DETAIL_ITEM_TYPE;
  itemId: string;
}

export const useEngagementDetailItem = (
  engagementId: string,
  params?: EngagementDetailItemParams
): {
  data: { detailItem: EngagementDetailItemType };
  loading: boolean;
} => {
  const { detailType, itemId } = params || {};
  const where = {} as { id: string };

  let query;
  let errorMessage;
  switch (detailType) {
    case DETAIL_ITEM_TYPE.MILESTONE:
      query = GET_ENGAGEMENTS_MILESTONE_ITEM;
      errorMessage = MESSAGES_ENGAGEMENTS_MILESTONES.GET_ITEMS_ERROR;
      break;
    case DETAIL_ITEM_TYPE.ACTION_ITEM:
      query = GET_ENGAGEMENTS_CHECKLIST_ITEM;
      errorMessage = MESSAGES_ENGAGEMENTS_CHECKLIST.GET_ITEMS_ERROR;
      break;
    case DETAIL_ITEM_TYPE.RISK:
      query = GET_RISK_LOG_ITEM;
      errorMessage = MESSAGES_ENGAGEMENTS_RISK_LOG.GET_ITEMS_ERROR;
      break;
    case DETAIL_ITEM_TYPE.DECISION:
      query = GET_ENGAGEMENTS_DECISION_ITEM;
      errorMessage = MESSAGES_ENGAGEMENTS_DECISIONS.GET_ITEMS_ERROR;
      break;
    default:
      break;
  }

  if (itemId) where.id = itemId;

  const onError = () => toast.error(errorMessage);

  const { data, loading } = useQuery(query, {
    variables: {
      id: engagementId,
      where,
    },
    onError,
  });

  return { data: formatData(data), loading };
};

const formatData = (
  data:
    | EngagementMilestoneItemQuery
    | EngagementChecklistItemQuery
    | EngagementDecisionItemsQuery
    | RiskLogItemQuery
) => {
  let items;
  switch (true) {
    case data?.engagement && "milestones" in data.engagement:
      items = (data as EngagementMilestoneItemQuery)?.engagement?.milestones;
      break;
    case data?.engagement && "checklist" in data.engagement:
      items = (data as EngagementChecklistItemQuery)?.engagement?.checklist;
      break;
    case data?.engagement && "riskLogItems" in data.engagement:
      items = (data as RiskLogItemQuery)?.engagement?.riskLogItems;
      break;
    case data?.engagement && "decisions" in data.engagement:
      items = (data as EngagementDecisionItemsQuery)?.engagement?.decisions;
      break;
    default:
      return { detailItem: null };
  }

  const detailItems =
    items?.edges?.map((edge) => {
      const item = {
        ...edge.node,
        ...("tasks" in edge.node && {
          tasks: edge?.node?.tasks?.edges.map((edge) => edge.node),
        }),
        ...("noteList" in edge.node && {
          noteList: edge?.node?.noteList?.edges.map((edge) => edge.node),
        }),
      };

      return item;
    }) || [];

  return { detailItem: detailItems[0] };
};
