const AWS_SERVICE_QUOTA_MESSAGE = (
  <>
    AWS has introduced <span className="font-bold">Service Quotas</span>. With
    Service Quotas, you can manage the process of requesting quota increases,
    also known as service limit increases, directly in your AWS console for
    faster access to additional resources.
  </>
);

export const ServiceLimitInfoBox = () => {
  return (
    <p className="rounded-xl bg-gray-500 p-4 text-white">
      <img
        src="/icons/tips_w.svg"
        alt="Notice"
        width="50"
        className="float-left mr-2"
      />
      {AWS_SERVICE_QUOTA_MESSAGE}
      <br />
      <br />
      For more information, see{" "}
      <a
        href="https://docs.aws.amazon.com/general/latest/gr/aws_service_limits.html"
        target="_blank"
        rel="noreferrer"
        className="text-yellow-300 underline"
      >
        AWS service quotas
      </a>
      , or open the{" "}
      <a
        href="https://console.aws.amazon.com/servicequotas/home"
        target="_blank"
        rel="noreferrer"
        className="text-yellow-300 underline"
      >
        Service Quotas console
      </a>{" "}
      to get started.
    </p>
  );
};
