import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  FC,
  Fragment,
  ReactNode,
} from "react";

import { Backdrop } from "../Backdrop";

export interface ModalPropsStrict {
  /* Max width of Modal */
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl";

  /* Callback */
  onClose?: (value: boolean) => void;

  /* Visible or hidden */
  open: boolean;

  /* Items to display in the header */
  header?: string | ReactNode;

  /* Optional menu element */
  menuActions?: ReactNode;
}

export interface ModalProps extends ModalPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

// static sub-component types
type DialogTitleProps = ComponentProps<typeof Dialog.Title>;
type DialogDescriptionProps = ComponentProps<typeof Dialog.Description>;
type ModalActionsProps = ComponentProps<typeof ModalActions>;

interface ModalComponent extends FC<ModalProps> {
  Title: FC<DialogTitleProps>;
  Description: FC<DialogDescriptionProps>;
  Actions: FC<ModalActionsProps>;
}

export const Modal: ModalComponent = ({
  children,
  className,
  onClose, // Headless UI's Dialog requires an onClose fn but there are cases where we don't actually need it
  open,
  size = "lg",
  header,
  menuActions,

  ...props
}) => {
  const empty = () => null;
  const dialogOnClose = onClose || empty;

  const xIconClickHandler = () => onClose?.(open);

  const xIconButtonClsx = clsx({
    "hover:opacity-[.85]": onClose,
    "opacity-0 pointer-events-none": !onClose,
  });

  const modalClsx = clsx(
    "overflow-hidden rounded-lg bg-white p-3 text-left align-middle shadow-lg",
    className
  );

  return (
    <Transition appear={true} show={open} as={Fragment}>
      <Dialog as="div" className="relative" onClose={dialogOnClose} {...props}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Backdrop />
        </Transition.Child>

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto text-sm">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              className={clsx("w-full transform", ModalSizes[size])}
              enter="transition-all duration-75"
              enterFrom="opacity-0 translate-y-[-10%]"
              enterTo="opacity-100 translate-y-0"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {/* The actual dialog panel  */}
              <Dialog.Panel className={modalClsx}>
                <div className="mb-1 flex items-center">
                  {/* An optional dialog header  */}
                  {header && (
                    <header className="flex items-center text-xl font-semibold text-default">
                      {header}
                    </header>
                  )}
                  <div className="ml-auto flex h-fit self-start">
                    {menuActions && (
                      <button className={" hover:opacity-[.85]"}>
                        {menuActions}
                      </button>
                    )}
                    <button
                      data-testid="modal-close"
                      onClick={xIconClickHandler}
                      className={xIconButtonClsx}
                    >
                      <XMarkIcon className="h-3 w-3" />
                    </button>
                  </div>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const ModalActions = ({
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) => (
  <div className="flex justify-end" data-component="ModalActions" {...props}>
    {children}
  </div>
);

Modal.Title = Dialog.Title;
Modal.Description = Dialog.Description;
Modal.Actions = ModalActions;

enum ModalSizes {
  "xs" = "max-w-xs",
  "sm" = "max-w-sm",
  "md" = "max-w-md",
  "lg" = "max-w-lg",
  "xl" = "max-w-xl",
  "2xl" = "max-w-2xl",
  "3xl" = "max-w-3xl",
  "4xl" = "max-w-4xl",
  "5xl" = "max-w-5xl",
  "6xl" = "max-w-6xl",
  "7xl" = "max-w-7xl",
}
