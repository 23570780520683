export enum CONFIGURATION_STAGES {
  TEMPLATE = "TEMPLATE",
  DETAILS = "DETAILS",
  REVIEW = "REVIEW",
}

export enum CONFIGURATION_STAGE_LABELS {
  TEMPLATE = "Template",
  DETAILS = "Details",
  REVIEW = "Review",
}
