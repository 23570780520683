import { useNavigate, useParams } from "react-router-dom";

import {
  EngagementBudgetStatus,
  EngagementWorkStatus,
} from "@m/api/public/types";
import { Drawer, EmptyMessage, Spinner } from "@m/ui";

import { useEngagementStatus, useUpdateEngagementStatus } from "../api";
import { MESSAGES_ENGAGEMENTS_STATUS } from "../constants";

import { EngagementsStatusForm } from "./EngagementsStatusForm";

export const EditEngagementsStatusDrawer = () => {
  const navigate = useNavigate();
  const { engagementId } = useParams<{
    engagementId: string;
  }>();

  const {
    data: { workStatus, budgetStatus },
    loading,
  } = useEngagementStatus(engagementId);

  const [updateStatus, { loading: updateLoading }] =
    useUpdateEngagementStatus(engagementId);

  const handleCloseDrawer = () => navigate("..");

  const handleSubmit = (updates: {
    budgetStatus: EngagementBudgetStatus;
    workStatus: EngagementWorkStatus;
  }) => {
    updateStatus(updates).then(() => {
      handleCloseDrawer();
    });
  };

  const statusAvailable = workStatus && budgetStatus;

  return (
    <Drawer
      backdrop={true}
      onClose={handleCloseDrawer}
      open={true}
      title="Project Status"
    >
      <div className="p-2">
        {loading && (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}
        {!loading && !statusAvailable && (
          <EmptyMessage message={MESSAGES_ENGAGEMENTS_STATUS.EMPTY} />
        )}
        {!loading && statusAvailable && (
          <EngagementsStatusForm
            loading={updateLoading}
            workStatus={workStatus}
            budgetStatus={budgetStatus}
            onClose={handleCloseDrawer}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </Drawer>
  );
};
