import { useScorecardTrendDaily } from "../api";
import {
  CLOUD_SCORE_MESSAGES,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { useLoadScoreChange } from "../hooks";
import { getDailyTrendActivities, getDailyTrendLines } from "../utils";

import { CloudScoreTrendGraphBase } from "./CloudScoreTrendGraphBase";

const { fullText, intervalCount } = SCORECARD_TREND_TIME_RANGES.ONE_MONTH;

interface CloudScoreTrendGraph1MonthProps {
  pillarId: string;
}

export const CloudScoreTrendGraph1Month = ({
  pillarId,
}: CloudScoreTrendGraph1MonthProps) => {
  const {
    data: { dailyScoreResults },
    loading,
  } = useScorecardTrendDaily({ days: intervalCount });

  useLoadScoreChange({
    scoreResults: dailyScoreResults,
    pillarId,
  });

  const { activeTrendLine, allTrendLines } = getDailyTrendLines(
    dailyScoreResults,
    pillarId
  );

  const activities = getDailyTrendActivities(dailyScoreResults, pillarId);

  return (
    <CloudScoreTrendGraphBase
      activeTrendLine={activeTrendLine}
      activities={activities}
      allTrendLines={allTrendLines}
      ariaLabel={`${fullText} Cloud Score Trend Graph`}
      axisBottom={{
        format: "%b %d",
        tickValues: "every 2 days",
      }}
      emptyMessage={CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_DAILY_EMPTY}
      loading={loading}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
      }}
    />
  );
};
