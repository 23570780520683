import { useMemo } from "react";

import {
  RioReportAccount,
  RioReportInstanceType,
  RioReportOperatingSystem,
  RioReportRegion,
  RioReportServices,
} from "@m/api/public/types";
import { Table, TableHeader } from "@m/ui";
import { toDollars } from "@m/utils";

import { RioDetailsTab } from "../types";
import { getOnDemandEquivalent, getSavingsRate } from "../utils";

interface RioDetailsTableProps {
  selectedRioTab: RioDetailsTab;
  accounts: RioReportAccount[];
  regions: RioReportRegion[];
  instanceTypes: RioReportInstanceType[];
  operatingSystems: RioReportOperatingSystem[];
  services: RioReportServices[];
}

export const RioDetailsTable = ({
  selectedRioTab,
  accounts,
  regions,
  instanceTypes,
  operatingSystems,
  services,
}: RioDetailsTableProps) => {
  const additionalTableHeaders = getRioDetailsTableHeaders(selectedRioTab);

  const rows = useMemo(() => {
    switch (selectedRioTab) {
      case RioDetailsTab.ACCOUNTS:
        return accounts
          .map((account) => ({
            accountId: account.accountId,
            // accountName: account.accountName, // todo(djbowers): get accountName from backend
            savingsRate: getSavingsRate(account.savings, account.cost),
            savings: toDollars(account.savings),
            onDemandEquivalent: getOnDemandEquivalent(
              account.cost,
              account.savings
            ),
          }))
          .sort((a, b) => a.accountId.localeCompare(b.accountId));
      case RioDetailsTab.AWS_SERVICE:
        return services
          .map((service) => ({
            service: service.serviceName,
            savingsRate: getSavingsRate(service.savings, service.cost),
            savings: toDollars(service.savings),
            onDemandEquivalent: getOnDemandEquivalent(
              service.cost,
              service.savings
            ),
          }))
          .sort((a, b) => {
            if (a.service.toLowerCase() === "other") return 1;
            if (b.service.toLowerCase() === "other") return -1;
            return a.service.localeCompare(b.service);
          });
      case RioDetailsTab.INSTANCE_TYPES:
        return instanceTypes
          .map((instanceType) => ({
            instanceType: instanceType.instanceType,
            os: instanceType.operatingSystem,
            service: instanceType.service,
            savingsRate: getSavingsRate(
              instanceType.savings,
              instanceType.cost
            ),
            savings: toDollars(instanceType.savings),
            onDemandEquivalent: getOnDemandEquivalent(
              instanceType.cost,
              instanceType.savings
            ),
          }))
          .sort((a, b) => a.os.localeCompare(b.os))
          .sort((a, b) => a.instanceType.localeCompare(b.instanceType))
          .sort((a, b) => a.service.localeCompare(b.service));
      case RioDetailsTab.REGIONS:
        return regions
          .map((region) => ({
            region: region.region,
            savingsRate: getSavingsRate(region.savings, region.cost),
            savings: toDollars(region.savings),
            onDemandEquivalent: getOnDemandEquivalent(
              region.cost,
              region.savings
            ),
          }))
          .sort((a, b) => a.region.localeCompare(b.region));
      case RioDetailsTab.OPERATING_SYSTEMS:
        return operatingSystems
          .map((operatingSystem) => ({
            os: operatingSystem.operatingSystem,
            savingsRate: getSavingsRate(
              operatingSystem.savings,
              operatingSystem.cost
            ),
            savings: toDollars(operatingSystem.savings),
            onDemandEquivalent: getOnDemandEquivalent(
              operatingSystem.cost,
              operatingSystem.savings
            ),
          }))
          .sort((a, b) => a.os.localeCompare(b.os));
    }
  }, [
    selectedRioTab,
    accounts,
    regions,
    instanceTypes,
    operatingSystems,
    services,
  ]);

  return (
    <Table
      ariaLabel="Rio Details Table"
      headers={[
        ...additionalTableHeaders,
        {
          label: "Gross Savings",
          accessor: "savings",
          align: "right",
        },
        {
          label: "On-Demand Equivalent",
          accessor: "onDemandEquivalent",
          align: "right",
        },
        {
          label: "Effective Savings Rate",
          accessor: "savingsRate",
          align: "right",
        },
      ]}
      rows={rows}
    />
  );
};

const ACCOUNTS_TABLE_HEADERS = [
  { label: "Account ID", accessor: "accountId" },
  // { label: "Account Name", accessor: "accountName" },  // todo(djbowers): get accountName from backend
];

const AWS_SERVICE_TABLE_HEADERS = [{ label: "Service", accessor: "service" }];

const INSTANCE_TYPE_TABLE_HEADERS = [
  { label: "Instance Type", accessor: "instanceType" },
  { label: "OS", accessor: "os" },
  { label: "Service", accessor: "service" },
];

const REGION_TABLE_HEADERS = [{ label: "Region", accessor: "region" }];

const OPERATING_SYSTEM_TABLE_HEADERS = [{ label: "OS", accessor: "os" }];

const getRioDetailsTableHeaders = (
  selectedRioTab: RioDetailsTab
): TableHeader[] => {
  switch (selectedRioTab) {
    case RioDetailsTab.ACCOUNTS:
      return ACCOUNTS_TABLE_HEADERS;
    case RioDetailsTab.AWS_SERVICE:
      return AWS_SERVICE_TABLE_HEADERS;
    case RioDetailsTab.INSTANCE_TYPES:
      return INSTANCE_TYPE_TABLE_HEADERS;
    case RioDetailsTab.REGIONS:
      return REGION_TABLE_HEADERS;
    case RioDetailsTab.OPERATING_SYSTEMS:
      return OPERATING_SYSTEM_TABLE_HEADERS;
  }
};
