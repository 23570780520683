import { useEffect } from "react";

import { SpinnerScreen } from "@m/ui";

import { useAuth } from "../contexts";

export const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <SpinnerScreen data-testid="logout" />;
};
