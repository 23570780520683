import PT from "prop-types";
import { useCallback, useMemo } from "react";

import { Avatar } from "@m/ui";

const InviteUserItem = ({ user, checked, onClick }) => {
  const handleClick = () => {
    onClick(user, checked);
  };

  return (
    <div
      onClick={handleClick}
      className="grid cursor-pointer grid-cols-[1fr_1fr_8fr] place-items-center justify-items-center py-1 hover:bg-slate-50"
    >
      <input
        readOnly
        type="checkbox"
        className="h-2 w-2 cursor-pointer"
        checked={checked}
      />
      <Avatar account={user} />
      <div className="ml-1 justify-self-start text-left leading-none">
        {/**
         * todo: refactor this to use isPendingInvite
         */}
        <p className="font-bold">
          {user.name === "None None" ? "Invited" : user.name}
        </p>
        <p className="text-sm">{user.email}</p>
      </div>
    </div>
  );
};

export const InviteUsersList = ({
  addSelection,
  removeSelection,
  selectedUsers,
  users = [],
}) => {
  const handleUserItemClick = useCallback(
    (user, checked) => {
      if (checked) return removeSelection(user);

      addSelection(user);
    },
    [addSelection, removeSelection]
  );

  const userListItems = useMemo(
    () =>
      users.map((user) => {
        const checked = selectedUsers.has(user);

        return (
          <InviteUserItem
            checked={checked}
            key={user.accountId}
            onClick={handleUserItemClick}
            user={user}
          />
        );
      }),
    [users, selectedUsers, handleUserItemClick]
  );

  return (
    <div className="mt-3">
      <div className="flex h-[200px] flex-col overflow-y-auto">
        {userListItems}
      </div>
    </div>
  );
};

InviteUsersList.propTypes = {
  addSelection: PT.func.isRequired,
  removeSelection: PT.func.isRequired,
  selectedUsers: PT.instanceOf(Set),
  users: PT.array.isRequired,
};
