import { CalendarIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useState } from "react";
import DatePickerComponent, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Input } from "@m/ui";
import { dt } from "@m/utils";

export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";

export type DatePickerProps = Omit<
  ReactDatePickerProps,
  "onChange" | "onSelect"
> & {
  onChange?: (date: Date, event: ChangeEvent<HTMLInputElement>) => void;
  min?: string;
  dateFormat?: string;
};

export const DatePicker = ({
  className,
  dateFormat = DEFAULT_DATE_FORMAT,
  placeholderText = DEFAULT_DATE_FORMAT.toLowerCase(),
  name,
  id,
  value,
  onChange,
  required = false,
  ...props
}: DatePickerProps) => {
  const initialDate = value
    ? dt.fromISO(value.toString()).startOf("day").toJSDate()
    : null;

  const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);

  const handlePickerChange: DatePickerProps["onChange"] = (date, event) => {
    setSelectedDate(date);
    onChange?.(date, event);
  };

  return (
    <DatePickerComponent
      className={className}
      name={name}
      id={id}
      required={required}
      portalId="date-picker-portal"
      dateFormat={dateFormat}
      selected={selectedDate}
      onChange={handlePickerChange}
      placeholderText={placeholderText}
      customInput={<Input rightIcon={CalendarIcon} {...props} />}
    />
  );
};
