export function convertToPercentage(convertValue: number | string) {
  // The function accepts a percentage value (convertValue) which could be a number or a string.

  // Math.max(0, Number(convertValue)) ensures that the input value is not less than 0.
  // If it is, it defaults to 0.

  // Math.min(100, ...) ensures that the percentage does not exceed 100. If the convertValue is more than 100, it defaults to 100.

  // The toFixed() method is used to convert the number to a string and to ensure there are no decimal places.
  // The result is a string that represents a percentage between 0 and 100, inclusive.
  if (convertValue) {
    const percentage = Math.min(
      100,
      Math.max(0, Number(convertValue))
    ).toFixed();
    return percentage;
  } else {
    return "0";
  }
}
