import { ReactNode } from "react";

import { formatFullDate } from "@m/utils";

interface Props {
  timestamp: string;
  children: ReactNode;
}
export const EngagementActivityFeedItem = ({ timestamp, children }: Props) => {
  const timestampFormatted = formatFullDate(timestamp, "LLL d");
  return (
    <div className="flex justify-between">
      <div className="flex-grow text-sm text-gray-800">{children}</div>
      <div className="flex-none text-sm text-subdued">{timestampFormatted}</div>
    </div>
  );
};
