import { WizardStage } from "@mc/components/Wizard";

import { CONFIGURATION_STAGES, CONFIGURATION_STAGE_LABELS } from "../constants";

type Props = {
  stage: CONFIGURATION_STAGES;
};

const { TEMPLATE, DETAILS, REVIEW } = CONFIGURATION_STAGES;

export const WizardStagesCreateProject = ({ stage }: Props) => {
  const stageStatusOne = getStageStatusOne(stage);
  const stageStatusTwo = getStageStatusTwo(stage);
  const stageStatusThree = getStageStatusThree(stage);

  return (
    <div className="flex flex-col gap-4">
      <WizardStage
        label={CONFIGURATION_STAGE_LABELS[TEMPLATE]}
        status={stageStatusOne}
      />
      <WizardStage
        label={CONFIGURATION_STAGE_LABELS[DETAILS]}
        status={stageStatusTwo}
      />
      <WizardStage
        label={CONFIGURATION_STAGE_LABELS[REVIEW]}
        status={stageStatusThree}
      />
    </div>
  );
};

const getStageStatusOne = (stage: CONFIGURATION_STAGES) =>
  stage === TEMPLATE ? "ACTIVE" : "OK";

const getStageStatusTwo = (stage: CONFIGURATION_STAGES) => {
  if (stage === TEMPLATE) return "INACTIVE";
  if (stage === DETAILS) return "ACTIVE";
  if (stage === REVIEW) return "OK";
};

const getStageStatusThree = (stage: CONFIGURATION_STAGES) =>
  stage === REVIEW ? "ACTIVE" : "INACTIVE";
