import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLB_LABEL, GET_SEARCH_TERMS_ERROR } from "../constants";

export const GET_CLASSIC_LOAD_BALANCER_SEARCH_TERMS = gql(/* GraphQL */ `
  query ClassicLoadBalancerSearchTerms($searchTerm: String) {
    classicLoadBalancerSearchTerms(searchTerm: $searchTerm) {
      searchTerms
    }
  }
`);

export const useClassicLoadBalancerSearchTerms = (searchTerm: string) => {
  const onError = () => toast.error(GET_SEARCH_TERMS_ERROR(CLB_LABEL));

  const { data, loading, error, refetch } = useQuery(
    GET_CLASSIC_LOAD_BALANCER_SEARCH_TERMS,
    {
      variables: { searchTerm },
      onError,
    }
  );

  const searchTerms = data?.classicLoadBalancerSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    error,
    loading,
    refetch,
  };
};
