import { useAuth } from "@m/login";
import { Modal } from "@m/ui";

import { PATHS } from "@mc/constants";

import { useSession } from "../api";

export const CompanySessionDialog = () => {
  const { user } = useAuth();
  const { currentSession } = useSession();

  const reloadWindow = () => (window.location = PATHS.DASHBOARD);
  const title = currentSession
    ? "Your session has changed."
    : "Your session has ended.";
  return (
    <Modal
      open={currentSession?.value !== user.company.id}
      header={
        <Modal.Title className="pb-1 text-center font-semibold">
          {title}
        </Modal.Title>
      }
    >
      <>
        {currentSession && (
          <Modal.Description className="mb-2 text-left ">
            <div className="mb-1 block">
              You are no longer viewing <strong>{user.company.name}</strong>
            </div>
            <div className="block">
              Your new session is viewing{" "}
              <strong>{currentSession?.label}</strong>
            </div>
          </Modal.Description>
        )}
        <button
          onClick={reloadWindow}
          className="text-sm text-default underline hover:text-blue-400"
        >
          Refresh
        </button>
      </>
    </Modal>
  );
};
