export const getFullName = (firstName?: string, lastName?: string): string => {
  if (!firstName) return "";
  if (!lastName) return firstName;

  return `${firstName} ${lastName}`;
};

export const abbreviateLastName = (
  firstName?: string,
  lastName?: string
): string => {
  if (!firstName) return "";

  const names = firstName.split(" ");

  if (names.length > 1) {
    lastName = names[1];
  }

  return lastName ? `${names[0]} ${lastName.charAt(0)}.` : names[0];
};

export const getInitials = (firstName?: string): string => {
  if (!firstName) return "";

  let lastName = "";

  const names = firstName.split(" ");

  if (names.length > 1) {
    lastName = names[1];
  }

  return lastName
    ? firstName.charAt(0) + lastName?.charAt(0)
    : firstName.charAt(0);
};
