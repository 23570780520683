import { useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";

import { SpinnerScreen } from "@m/ui";

import { gql } from "../public";
import { CompanyEntitlementsQuery } from "../public/types";
import { Entitlements } from "../types";

export const EntitlementsContext = createContext<Entitlements>(undefined);

export const useEntitlements = () => useContext(EntitlementsContext);

export const ENTITLEMENTS_QUERY = gql(/* GraphQL */ `
  query CompanyEntitlements {
    userProfile {
      currentCompany {
        entitlements
        companyEntitlements {
          products {
            edges {
              node {
                sku
              }
            }
          }
          bundles {
            edges {
              node {
                sku
                products {
                  edges {
                    node {
                      sku
                    }
                  }
                }
              }
            }
          }
          addons {
            edges {
              node {
                sku
              }
            }
          }
          discounts {
            edges {
              node {
                sku
              }
            }
          }
        }
      }
    }
  }
`);

/**
 * Get entitlements for the user's current company.
 */
export const EntitlementsProvider = ({ children }: PropsWithChildren) => {
  const { data, loading } = useQuery(ENTITLEMENTS_QUERY);

  const entitlements = useMemo(() => getEntitlements(data), [data]);

  return (
    <EntitlementsContext.Provider value={entitlements}>
      {loading ? <SpinnerScreen /> : children}
    </EntitlementsContext.Provider>
  );
};

const getEntitlements = (data?: CompanyEntitlementsQuery): Entitlements => {
  // company.entitlements contains the names of entitlements enrolled through
  // the old connectwise system.
  const entitlements =
    data?.userProfile?.currentCompany?.entitlements?.filter(
      (entitlement): entitlement is NonNullable<typeof entitlement> =>
        entitlement !== null
    ) ?? [];

  const addEntitlement = (sku: string | null = null) => {
    if (sku) entitlements.push(sku);
  };

  // company.companyEntitlements has the entitlements enrolled through the new
  // service catalog system.
  const companyEntitlements =
    data?.userProfile?.currentCompany?.companyEntitlements ?? {};

  const products =
    companyEntitlements.products?.edges.map((edge) => edge?.node) ?? [];
  const bundles =
    companyEntitlements.bundles?.edges.map((edge) => edge?.node) ?? [];
  const addons =
    companyEntitlements.addons?.edges.map((edge) => edge?.node) ?? [];
  const discounts =
    companyEntitlements.discounts?.edges.map((edge) => edge?.node) ?? [];

  // Get companyEntitlement skus and combine with names from the old system
  for (const product of products) addEntitlement(product?.sku);
  for (const bundle of bundles) {
    addEntitlement(bundle?.sku);
    const products = bundle?.products?.edges.map((edge) => edge?.node) ?? [];
    for (const product of products) addEntitlement(product?.sku);
  }
  for (const addon of addons) addEntitlement(addon?.sku);
  for (const discount of discounts) addEntitlement(discount?.sku);

  // Remove duplicates
  return [...new Set(entitlements)];
};
