import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_FILTERS_ERROR, RDS_LABEL } from "../constants";

export const RDS_INSTANCES_FILTERS = gql(/* GraphQL */ `
  query RdsInstanceFilters {
    rdsInstanceFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      statuses
      instanceClasses
      availabilityZones
      engines
    }
  }
`);

export const useRdsInstancesFilters = () => {
  const onError = () => toast.error(GET_FILTERS_ERROR(RDS_LABEL));

  const { data, loading, error } = useQuery(RDS_INSTANCES_FILTERS, {
    onError,
  });

  const filters = data?.rdsInstanceFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const zones = filters.availabilityZones || [];
  const statuses = filters.statuses || [];
  const classes = filters.instanceClasses || [];
  const engines = filters.engines || [];

  return {
    data: { filters: { accounts, zones, statuses, classes, engines } },
    error,
    loading,
  };
};
