import { MutableRefObject, useRef, useState } from "react";

import { generateUniqueId } from "@m/api";

export type FileInputType = {
  files: Map<string, File>;
  commentAttachments: { name: string; size: number; type: string }[];
  fileInputRef: MutableRefObject<HTMLInputElement>;
  openFileMenu: () => void;
  removeAttachment: (id: string) => void;
  removeAllAttachments: () => void;
  attachFile: () => void;
};

export const useFileInput = (): FileInputType => {
  const [files, setFiles] = useState(new Map());
  const [commentAttachments, setCommentAttachments] = useState([]);

  const fileInputRef = useRef<HTMLInputElement>();

  const attachFile = () => {
    const { files } = fileInputRef.current;

    for (const file of files) {
      const attachmentData = {
        attachmentName: file.name,
        attachmentSize: file.size,
        attachmentType: file.type,
      };
      setFiles((prevFiles) => new Map(prevFiles).set(generateUniqueId(), file));
      setCommentAttachments((prevAttachmentData) => [
        ...prevAttachmentData,
        attachmentData,
      ]);
    }
  };

  const openFileMenu = () => {
    fileInputRef.current.click();
  };

  const removeAttachment = (id: string) => {
    setFiles((prevFiles) => {
      const newFiles = new Map(prevFiles);
      newFiles.delete(id);
      return newFiles;
    });
  };

  const removeAllAttachments = () => {
    fileInputRef.current.value = "";
    setFiles(() => new Map());
  };

  return {
    files,
    commentAttachments,
    fileInputRef,
    openFileMenu,
    removeAttachment,
    removeAllAttachments,
    attachFile,
  };
};
