import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_FILTERS_ERROR, LAMBDA_LABEL } from "../constants";

export const GET_LAMBDA_FILTERS = gql(/* GraphQL */ `
  query LambdaFilters {
    lambdaFunctionFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      runtimes
      handlers
    }
  }
`);

export const useLambdaFilters = () => {
  const onError = () => toast.error(GET_FILTERS_ERROR(LAMBDA_LABEL));

  const { data, loading, error } = useQuery(GET_LAMBDA_FILTERS, {
    onError,
  });

  const filters = data?.lambdaFunctionFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const runtimes = filters.runtimes || [];
  const handlers = filters.handlers || [];

  return {
    data: { filters: { accounts, runtimes, handlers } },
    error,
    loading,
  };
};
