import { ReactNode } from "react";

import { AccessRequirements, useAccessRequired } from "./useAccessRequired";

interface Props extends AccessRequirements {
  /** Children to be rendered if the user has access */
  children: ReactNode;

  /** Fallback to be rendered if the user does not have access */
  fallback?: ReactNode;
}

export const AccessRequired = ({
  children,
  fallback = null,
  ...props
}: Props) => {
  const isAllowed = useAccessRequired({ ...props });

  if (isAllowed === true) return <>{children}</>;
  if (isAllowed === false) return <>{fallback}</>;
  return null;
};
