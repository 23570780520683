import { fromCamelCaseToProperCase } from "@m/utils";

import { CaseType } from "../types";

interface CaseDescriptionArgs {
  accountIds: string;
  caseType: CaseType;
  category: string;
  description: string;
  priority: string;
  regions: string;
  resourcesText: string;
  subcategory: string;
}

export const createCaseDescription = ({
  accountIds,
  caseType,
  category,
  description,
  priority,
  regions,
  resourcesText,
  subcategory,
}: CaseDescriptionArgs) => {
  let header = `Case Type: ${fromCamelCaseToProperCase(caseType)}\n`;
  if (category) header += `Case Category: ${category}\n`;
  if (subcategory) header += `Case Subcategory: ${subcategory}\n`;
  header += `Case Priority: ${priority}\n`;
  header += `Account IDs: ${accountIds || "not reported"}\n`;
  header += `Regions: ${regions || "not reported"}\n`;
  header += `Resources: ${resourcesText || "not reported"}\n`;
  return `${header}\n${description}`;
};
