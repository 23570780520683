export const getCurrentLocation = () => {
  return [window.location.pathname, window.location.search].join("");
};

export const getReturnToUrl = (): string => {
  return localStorage?.getItem("returnTo") as string;
};

export const setReturnToUrl = (url?: string) => {
  const destination = getCurrentLocation();
  localStorage?.setItem("returnTo", url ?? destination);
};

export const removeReturnToUrl = () => {
  localStorage?.removeItem("returnTo");
};
