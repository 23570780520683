export const MESSAGES = {
  CASE_CLOSE_FAILURE: "Unable to close your support case",
  CASE_CREATION_FAILURE: "Unable to create your support case",
  CASE_DETAIL_FAILURE: "Unable to load your support case",
  CASE_DETAIL_NOT_FOUND: "Support case not found",
  CASE_LIST_FAILURE: "Unable to access your ticket data",
  INTERCOM_MESSAGE_FAILURE: "Unable to load Intercom messages",
  LIVE_CHAT_DISABLED:
    "Live chat is available from 8 AM - 8 PM EST Monday - Friday",
  INVALID_SEARCH_QUERY_LENGTH:
    "Search must be at least 3 characters in length.",
};
