export const EmptyCircleIcon = ({ className = "w-3 h-3", ...props }) => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth={1}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <circle className="st0" cx="11.61" cy="11.61" r="9.61" />
    </svg>
  );
};
