import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";

import {
  getCurrentLocation,
  getReturnToUrl,
  removeReturnToUrl,
} from "@m/utils";

import { LOGIN_ERROR, MAINTENANCE_ERROR } from "../constants";
import { useAuth } from "../contexts";
import { establishLoginSession } from "../utils";

export const useAuth0Login = () => {
  const { updateUserSession } = useAuth();

  const match = useMatch("/sso/:domain");

  const [error, setError] = useState<ReactNode>("");

  const {
    getAccessTokenSilently,
    handleRedirectCallback,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  /**
   * If we're not handling an active authorization flow,
   * automatically redirect the user to the Auth0 login screen.
   */
  const redirectToAuth0Login = useCallback(() => {
    const returnTo = getReturnToUrl();
    const destination = returnTo || getCurrentLocation();
    if (returnTo) removeReturnToUrl();

    const auth0Params: RedirectLoginOptions = {
      appState: { returnTo: destination },
    };
    if (match?.pattern?.path === "/sso/:domain" && match?.params?.domain) {
      auth0Params["connection"] = match.params.domain;
      delete auth0Params["appState"]["returnTo"];
    }

    loginWithRedirect(auth0Params);
  }, [loginWithRedirect, match]);

  /**
   * After we've successfully completed the OAuth PKCE authentication
   * flow with Auth0, pass the valid access token to the Services API
   * so that we can attempt to identify the user and map it to a known
   * account record in the Services API (and establish a session).
   */
  const loginServicesApi = useCallback(() => {
    getAccessTokenSilently()
      .then((token) => establishLoginSession(token))
      .then((user) => updateUserSession(user))
      .catch((error) =>
        setError(error === "X-Mission-Maintenance" ? MAINTENANCE_ERROR : error)
      );
  }, [getAccessTokenSilently, updateUserSession]);

  /**
   * After the component mounts, watch for ?code= in the URL,
   * which represents the OAuth2.0 authorization callback from
   * Auth0.
   */
  const loginAuth0 = useCallback(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      handleRedirectCallback()
        .then(loginServicesApi)
        .catch((e) => {
          if (
            e?.response?.status === 503 &&
            e.response.headers.has("X-Mission-Maintenance")
          ) {
            setError(MAINTENANCE_ERROR);
          } else {
            setError(LOGIN_ERROR);
          }
          Sentry.withScope(function (scope) {
            scope.setLevel("warning");
            Sentry.captureException(e);
          });
        });
    } else {
      redirectToAuth0Login();
    }
  }, [handleRedirectCallback, loginServicesApi, redirectToAuth0Login]);

  useEffect(() => {
    if (isLoading || error) return;

    if (!isAuthenticated) {
      loginAuth0();
    } else {
      loginServicesApi();
    }
  }, [isAuthenticated, isLoading, error, loginAuth0, loginServicesApi]);

  return { error, redirectToAuth0Login };
};
