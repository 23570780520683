import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { generatePath, useNavigate } from "react-router-dom";

import { Card } from "@m/ui/components/Card";

import { ChangeIndicator } from "@mc/components/ChangeIndicator";
import { PATHS } from "@mc/constants";

import { getPillar } from "../utils";

import { MissionCloudScore } from "./MissionCloudScore";
import { ScoreGaugeLinear } from "./ScoreGaugeLinear";

interface Props {
  criticalChecks: number;
  name: string;
  okChecks: number;
  pillar: string;
  score: number;
  scoreChange: number;
  totalChecks: number;
  warningChecks: number;
}

export const PillarSummaryCard = ({
  criticalChecks,
  name,
  okChecks,
  pillar,
  score,
  scoreChange = 0,
  totalChecks,
  warningChecks,
}: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const detail = getPillar(pillar);
    const path = generatePath(PATHS.CLOUD_SCORE_PILLAR_DETAILS, {
      pillarId: detail.id,
    });
    if (detail) navigate(path);
  };

  return (
    <Card
      animateOnHover={true}
      aria-label={`${name} Summary Card`}
      className="cursor-pointer flex-col overflow-hidden border"
      onClick={handleClick}
    >
      <ScoreGaugeLinear
        criticalChecks={criticalChecks}
        okChecks={okChecks}
        totalChecks={totalChecks}
        warningChecks={warningChecks}
      />
      <div className="flex-col p-1 text-center">
        <div
          aria-label="Pillar Name"
          className="text-md p-1 font-semibold text-black"
        >
          {name}
        </div>
        <div className="mb-1 flex items-center justify-center">
          <MissionCloudScore score={score} />
          <ChangeIndicator change={scoreChange} />
        </div>
        <div className="flex flex-row items-center justify-center p-1 font-medium text-action">
          See Checks
          <ChevronRightIcon className="h-3 w-3" />
        </div>
      </div>
    </Card>
  );
};
