import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import {
  ALB_LABEL,
  GET_SEARCH_TERMS_ERROR,
  LOAD_BALANCER_TYPES,
} from "../constants";

export const GET_APPLICATION_LOAD_BALANCER_SEARCH_TERMS = gql(/* GraphQL */ `
  query ApplicationLoadBalancerSearchTerms(
    $searchTerm: String
    $loadBalancerType: String
  ) {
    loadBalancerSearchTerms(
      searchTerm: $searchTerm
      loadBalancerType: $loadBalancerType
    ) {
      searchTerms
    }
  }
`);

export const useApplicationLoadBalancerSearchTerms = (searchTerm: string) => {
  const onError = () => toast.error(GET_SEARCH_TERMS_ERROR(ALB_LABEL));

  const { data, loading, error, refetch } = useQuery(
    GET_APPLICATION_LOAD_BALANCER_SEARCH_TERMS,
    {
      variables: {
        searchTerm,
        loadBalancerType: LOAD_BALANCER_TYPES.APPLICATION,
      },
      onError,
    }
  );

  const searchTerms = data?.loadBalancerSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    error,
    loading,
    refetch,
  };
};
