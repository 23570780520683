import { ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME } from "@mc/features/Engagements/constants";

import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemBudgetStatusChange: EngagementActivityFeedComponent =
  ({ after, timestamp }) => {
    if (!after) return null;

    const budgetStatusReadable = ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME[after];
    return (
      <EngagementActivityFeedItem timestamp={timestamp}>
        The budget is forecasted as {budgetStatusReadable}
      </EngagementActivityFeedItem>
    );
  };
