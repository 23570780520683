import { ScorecardSummary } from "@m/api/public/types";

import { CloudScorePillarIds } from "../constants";
import { getPillar } from "../utils";

import { PillarDetailsCard } from "./PillarDetailsCard";

interface Props {
  pillarId: CloudScorePillarIds;
  summaries: Partial<ScorecardSummary>[];
}

export const PillarDetailsCards = ({ pillarId, summaries }: Props) => {
  const pillar = getPillar(pillarId);

  if (!pillar) return null;

  const summary = summaries.find((summary) => summary.pillar === pillar.pillar);

  if (!summary) return null;

  return (
    <div className="grid grid-cols-3 gap-3">
      <PillarDetailsCard
        title={summary.pillar ? "Pillar Score" : "Total Score"}
        value={summary.score}
        change={summary.scoreChange}
      />
      <PillarDetailsCard
        title="Monitored Checks"
        value={summary.totalChecks}
        // todo: wire up to api once scorecards track changes in monitored checks
        // change={}
        showTrend={false}
      />
      <PillarDetailsCard
        title="Unoptimized Checks"
        value={summary.warning + summary.error}
        // todo: wire up to api once scorecards track changes in unoptimized checks
        // change={}
        flipTrend={true}
      />
    </div>
  );
};
