import { useAuth } from "@m/login";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_USER_PROFILE } from "./useUserProfile";

export const UPDATE_JOB_ROLE = gql`
  mutation UpdateJobRole($input: UpdateViewerInput!) {
    updateViewer(input: $input) {
      ok
      viewer {
        jobRole
      }
    }
  }
`;

export const useUpdateJobRole = (userProfile) => {
  const { user, updateUserSession } = useAuth();

  const onCompleted = (data) => {
    const { jobRole } = data.updateViewer.viewer;
    updateUserSession({ ...user, jobRole });
  };

  const [mutate, { data, loading }] = useMutation(UPDATE_JOB_ROLE, {
    onCompleted,
    refetchQueries: [GET_USER_PROFILE],
  });

  const updateJobRole = (jobRole) => {
    if (jobRole === userProfile.jobRole) return;

    mutate({
      variables: {
        input: { jobRole },
      },
    });
  };

  const ok = data?.updateViewer?.ok;

  return [updateJobRole, { ok, loading }] as const;
};
