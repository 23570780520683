import clsx from "clsx";
import { ReactNode } from "react";

export const DetailsLayout = ({
  actions,
  children,
  className,
}: {
  actions: ReactNode;
  children: ReactNode;
  className?: string;
}) => (
  <div className={clsx("flex min-h-full w-full", className)}>
    <div className="shrink grow overflow-x-hidden p-4">{children}</div>

    <div className="flex w-[290px] shrink-0 flex-col space-y-4 border-l bg-gray-50 p-4">
      {actions}
    </div>
  </div>
);
