export const CloudScoreArtboard = () => {
  return (
    <svg
      width="203"
      height="172"
      viewBox="0 0 203 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="203" height="172" fill="none" />
      <g>
        <rect
          width="2211"
          height="1471"
          transform="translate(-1139 -371)"
          fill="none"
        />
        <g>
          <g clipPath="url(#clip0_1701_16)">
            <rect x="-619" y="-303" width="1440" height="1024" fill="none" />
            <rect x="-619" y="-303" width="1440" height="1024" fill="none" />
            <g>
              <path
                d="M138.575 21.6715L18.1189 34.1408C16.4263 34.316 15.1962 35.8301 15.3715 37.5228L20.2599 84.7465C20.4351 86.4392 21.9492 87.6692 23.6418 87.494L144.098 75.0248C145.791 74.8495 147.021 73.3354 146.846 71.6427L141.957 24.419C141.782 22.7264 140.268 21.4963 138.575 21.6715Z"
                fill="#FCFCFC"
                stroke="#EFF0F1"
                strokeWidth="2.43"
                strokeMiterlimit="10"
              />
              <g>
                <path
                  d="M68.1955 37.029L49.1211 39.0035C48.2487 39.0938 47.6147 39.8742 47.705 40.7466L48.2157 45.6804C48.306 46.5528 49.0865 47.1868 49.9588 47.0965L69.0332 45.1219C69.9056 45.0316 70.5396 44.2512 70.4493 43.3788L69.9386 38.445C69.8483 37.5726 69.0679 36.9386 68.1955 37.029Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M108.845 32.8228L78.3985 35.9745C77.3141 36.0867 76.5261 37.0568 76.6383 38.1412L77.0696 42.3073C77.1818 43.3916 78.1519 44.1797 79.2363 44.0675L109.682 40.9158C110.767 40.8035 111.555 39.8335 111.443 38.7491L111.011 34.583C110.899 33.4986 109.929 32.7105 108.845 32.8228Z"
                  fill="#F2F2F2"
                />
              </g>
              <path
                d="M65.9082 50.5614L55.4616 51.6428C54.5719 51.7349 53.9253 52.5309 54.0174 53.4206L55.1 63.8798C55.1922 64.7696 55.9881 65.4162 56.8778 65.3241L67.3244 64.2427C68.2142 64.1506 68.8608 63.3546 68.7687 62.4649L67.686 52.0057C67.5939 51.1159 66.7979 50.4693 65.9082 50.5614Z"
                fill="#E4E5E5"
              />
              <path
                d="M104.366 47.7384L87.1416 49.5214C83.9997 49.8467 81.7163 52.6574 82.0415 55.7994L82.0422 55.8056C82.3674 58.9476 85.1781 61.231 88.3201 60.9057L105.544 59.1227C108.686 58.7975 110.97 55.9868 110.644 52.8448L110.644 52.8385C110.318 49.6966 107.508 47.4132 104.366 47.7384Z"
                fill="#E1F8F0"
              />
              <g>
                <path
                  d="M86.7461 57.5153L89.0237 54.5607L90.9091 56.0348L93.9459 52.3779L94.3002 55.8323"
                  stroke="#07C180"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M90.6182 52.7259L93.946 52.3779"
                  stroke="#07C180"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g>
                <path
                  d="M98.7354 55.3708L98.6278 54.3079L97.5965 54.4155L97.5522 53.9852L98.5835 53.8777L98.476 52.8148L98.9062 52.7705L99.0137 53.8334L100.045 53.7258L100.089 54.1561L99.058 54.2636L99.1656 55.3265L98.7354 55.3708Z"
                  fill="#07C180"
                />
                <path
                  d="M102.474 55.5226L101.918 55.5795L101.671 53.1817C101.658 53.0488 101.646 52.9286 101.639 52.821C101.633 52.7135 101.627 52.6059 101.62 52.5047C101.576 52.5553 101.538 52.6059 101.494 52.6565C101.449 52.7072 101.399 52.7578 101.342 52.821L100.962 53.1943L100.64 52.8653L101.627 51.91L102.088 51.8594L102.468 55.5226H102.474Z"
                  fill="#07C180"
                />
                <path
                  d="M105.865 52.3339C105.891 52.5806 105.84 52.7894 105.714 52.9539C105.587 53.1184 105.404 53.2323 105.176 53.3019V53.3208C105.486 53.3208 105.72 53.3968 105.884 53.5296C106.049 53.6625 106.144 53.8523 106.169 54.0927C106.194 54.3078 106.157 54.5039 106.074 54.6811C105.986 54.8582 105.84 55.0038 105.638 55.124C105.429 55.2442 105.157 55.3201 104.822 55.3517C104.619 55.3707 104.436 55.377 104.259 55.3644C104.081 55.3517 103.917 55.3138 103.752 55.2568L103.702 54.7507C103.866 54.814 104.043 54.8582 104.227 54.8835C104.41 54.9089 104.588 54.9089 104.752 54.8899C105.075 54.8582 105.296 54.776 105.423 54.6431C105.549 54.5103 105.606 54.3458 105.581 54.137C105.555 53.9282 105.461 53.7953 105.283 53.7131C105.106 53.6372 104.847 53.6119 104.512 53.6498L104.17 53.6878L104.119 53.2259L104.467 53.188C104.79 53.1563 105.011 53.0741 105.144 52.9476C105.277 52.821 105.334 52.6628 105.315 52.4794C105.296 52.3149 105.233 52.1947 105.113 52.1187C104.992 52.0428 104.828 52.0112 104.619 52.0365C104.423 52.0555 104.259 52.1061 104.126 52.1757C103.993 52.2453 103.866 52.3339 103.752 52.4351L103.436 52.0808C103.569 51.9606 103.727 51.853 103.917 51.7581C104.107 51.6632 104.328 51.5999 104.588 51.5746C104.98 51.5367 105.283 51.5873 105.505 51.7328C105.726 51.8783 105.847 52.0808 105.878 52.3528L105.865 52.3339Z"
                  fill="#07C180"
                />
              </g>
              <path
                opacity="0.75"
                d="M99.6281 66.0101L65.3118 69.5624C64.3629 69.6606 63.6734 70.5094 63.7716 71.4583L64.0016 73.6797C64.0998 74.6286 64.9486 75.3181 65.8974 75.2199L100.214 71.6676C101.163 71.5694 101.852 70.7206 101.754 69.7717L101.524 67.5503C101.426 66.6014 100.577 65.9118 99.6281 66.0101Z"
                fill="#EBECED"
              />
            </g>
            <g>
              <path
                d="M187.905 85.4612L66.9228 80.1155C65.2228 80.0404 63.7838 81.3576 63.7087 83.0576L61.613 130.487C61.5379 132.187 62.8551 133.626 64.5551 133.702L185.537 139.047C187.237 139.122 188.676 137.805 188.751 136.105L190.847 88.6753C190.922 86.9753 189.605 85.5363 187.905 85.4612Z"
                fill="#FCFCFC"
                stroke="#EFF0F1"
                strokeWidth="2.43"
                strokeMiterlimit="10"
              />
              <g>
                <path
                  d="M116.04 90.3277L96.8829 89.4812C96.0067 89.4425 95.265 90.1214 95.2263 90.9976L95.0073 95.953C94.9686 96.8291 95.6475 97.5708 96.5237 97.6095L115.681 98.456C116.558 98.4947 117.299 97.8158 117.338 96.9397L117.557 91.9843C117.596 91.1081 116.917 90.3664 116.04 90.3277Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M156.86 92.1277L126.281 90.7766C125.191 90.7285 124.27 91.5724 124.221 92.6615L124.037 96.8457C123.988 97.9348 124.832 98.8568 125.921 98.9049L156.5 100.256C157.589 100.304 158.511 99.4603 158.56 98.3711L158.744 94.1869C158.793 93.0978 157.949 92.1759 156.86 92.1277Z"
                  fill="#F2F2F2"
                />
              </g>
              <path
                d="M111.785 103.369L101.292 102.905C100.399 102.866 99.6423 103.558 99.6028 104.452L99.1387 114.957C99.0992 115.851 99.7916 116.607 100.685 116.646L111.177 117.11C112.071 117.15 112.827 116.457 112.867 115.564L113.331 105.059C113.371 104.165 112.678 103.409 111.785 103.369Z"
                fill="#E4E5E5"
              />
              <g>
                <path
                  d="M131.375 113.311L134.058 110.723L135.709 112.45L139.252 109.287L139.1 112.754"
                  stroke="#07C180"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M135.905 109.136L139.252 109.288"
                  stroke="#07C180"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g>
                <path
                  d="M143.548 112.951L143.592 111.888L142.554 111.843L142.573 111.407L143.611 111.451L143.655 110.382L144.092 110.401L144.047 111.47L145.085 111.514L145.066 111.951L144.028 111.907L143.984 112.969L143.548 112.951Z"
                  fill="#07C180"
                />
                <path
                  d="M147.23 113.646L146.673 113.621L146.78 111.211C146.78 111.078 146.793 110.958 146.799 110.85C146.806 110.742 146.818 110.635 146.831 110.534C146.78 110.578 146.736 110.622 146.679 110.66C146.629 110.705 146.572 110.749 146.502 110.799L146.072 111.116L145.8 110.742L146.92 109.945L147.388 109.964L147.223 113.646H147.23Z"
                  fill="#07C180"
                />
                <path
                  d="M151.051 110.995C151.039 111.248 150.956 111.444 150.811 111.584C150.659 111.723 150.463 111.811 150.229 111.849V111.868C150.532 111.919 150.754 112.02 150.899 112.178C151.045 112.336 151.108 112.533 151.096 112.779C151.083 112.994 151.026 113.184 150.912 113.342C150.798 113.507 150.634 113.627 150.412 113.716C150.191 113.804 149.912 113.836 149.571 113.823C149.368 113.817 149.185 113.792 149.014 113.754C148.843 113.716 148.679 113.652 148.527 113.57L148.552 113.064C148.71 113.153 148.875 113.222 149.058 113.273C149.235 113.323 149.413 113.349 149.577 113.361C149.9 113.374 150.134 113.33 150.279 113.216C150.425 113.108 150.501 112.944 150.513 112.735C150.526 112.526 150.444 112.374 150.279 112.273C150.115 112.172 149.862 112.109 149.52 112.096L149.179 112.083L149.197 111.621L149.545 111.634C149.868 111.647 150.102 111.602 150.254 111.495C150.406 111.387 150.482 111.242 150.488 111.052C150.494 110.888 150.444 110.761 150.336 110.666C150.229 110.571 150.071 110.521 149.862 110.508C149.666 110.502 149.495 110.521 149.349 110.578C149.204 110.634 149.065 110.698 148.938 110.78L148.679 110.381C148.831 110.28 149.001 110.198 149.204 110.128C149.406 110.059 149.634 110.033 149.893 110.046C150.286 110.065 150.577 110.16 150.773 110.337C150.969 110.514 151.058 110.736 151.051 111.001V110.995Z"
                  fill="#07C180"
                />
              </g>
              <path
                opacity="0.75"
                d="M142.879 123.597L108.413 122.074C107.46 122.032 106.653 122.771 106.611 123.724L106.512 125.955C106.47 126.908 107.208 127.714 108.161 127.757L142.628 129.279C143.58 129.322 144.387 128.583 144.429 127.63L144.528 125.399C144.57 124.446 143.832 123.639 142.879 123.597Z"
                fill="#EBECED"
              />
              <g>
                <path
                  d="M147.972 106.156L132.758 105.484C129.749 105.351 127.202 107.682 127.069 110.692L127.045 111.229C126.912 114.238 129.244 116.785 132.253 116.918L147.466 117.59C150.475 117.723 153.022 115.392 153.155 112.382L153.179 111.845C153.312 108.836 150.981 106.289 147.972 106.156Z"
                  fill="#F4F4F4"
                />
                <g>
                  <path
                    d="M131.647 108.984L134.095 111.8L135.892 110.218L139.138 113.685L139.29 110.218"
                    stroke="#D1D4D4"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M135.797 113.54L139.138 113.686"
                    stroke="#D1D4D4"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <g>
                  <path
                    d="M144.129 111.51L145.167 111.554L145.148 111.99L144.11 111.946L143.674 111.927L142.636 111.883L142.655 111.446L143.693 111.491L144.129 111.51Z"
                    fill="#D1D4D4"
                  />
                  <path
                    d="M147.312 113.686L146.755 113.66L146.862 111.25C146.862 111.117 146.875 110.997 146.881 110.889C146.888 110.782 146.9 110.674 146.913 110.573C146.862 110.617 146.818 110.661 146.761 110.699C146.711 110.744 146.654 110.788 146.584 110.838L146.154 111.155L145.882 110.782L147.002 109.984L147.47 110.003L147.305 113.686H147.312Z"
                    fill="#D1D4D4"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                d="M155.217 150.882L155.484 153.888C155.637 155.609 157.154 156.878 158.874 156.725L174.452 155.339C176.173 155.186 177.441 153.67 177.288 151.949L176.43 142.301C176.277 140.581 174.761 139.312 173.04 139.465L169.65 139.766"
                fill="white"
              />
              <path
                d="M155.217 150.882L155.484 153.888C155.637 155.609 157.154 156.878 158.874 156.725L174.452 155.339C176.173 155.186 177.441 153.67 177.288 151.949L176.43 142.301C176.277 140.581 174.761 139.312 173.04 139.465L169.65 139.766"
                stroke="#07C180"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </g>
            <path
              d="M157.462 140.85L169.656 139.766L169.389 136.76C169.236 135.04 167.719 133.771 165.999 133.924L150.421 135.309C148.7 135.462 147.431 136.979 147.584 138.699L148.442 148.347C148.595 150.068 150.112 151.337 151.832 151.184L155.223 150.882L154.632 144.234C154.479 142.513 155.747 140.997 157.468 140.844L157.462 140.85Z"
              fill="white"
              stroke="#07C180"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M166.222 151.079C167.764 150.942 168.903 149.581 168.766 148.039C168.629 146.498 167.268 145.359 165.726 145.496C164.184 145.633 163.045 146.994 163.182 148.536C163.319 150.078 164.681 151.216 166.222 151.079Z"
              fill="white"
              stroke="#07C180"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </g>
          <g>
            <path
              d="M141.675 36.201C142.712 36.1504 158.206 35.1254 159.174 16.4361C159.174 16.3222 159.098 16.221 158.991 16.2147C157.833 16.1388 151.747 15.4428 146.85 9.13503C146.787 9.05279 146.673 9.02748 146.591 9.08442C145.572 9.72975 139.467 13.3613 133.089 11.457C132.994 11.4253 132.887 11.4696 132.849 11.5645C132.121 13.2285 126.332 27.7421 141.573 36.182C141.605 36.201 141.643 36.2136 141.681 36.2073L141.675 36.201Z"
              fill="white"
              stroke="#07C180"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <g>
              <path
                d="M139.739 21.9598L142.206 25.4332L149.381 20.4287"
                fill="white"
              />
              <path
                d="M139.739 21.9598L142.206 25.4332L149.381 20.4287"
                stroke="#07C180"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <g>
            <path
              d="M57.7314 85.132C57.5226 82.1205 54.7515 79.8745 51.7653 80.2857C51.2592 80.3553 50.753 80.2035 50.3671 79.8681C48.0895 77.8879 44.5402 78.2548 42.7244 80.659C42.4144 81.0639 41.9525 81.317 41.4401 81.3549C38.4286 81.5637 36.1826 84.3348 36.5938 87.3211C36.6634 87.8272 36.5116 88.3334 36.1762 88.7193C34.196 90.9969 34.5629 94.5463 36.9671 96.362C37.372 96.6721 37.6251 97.1339 37.663 97.6464C37.8718 100.658 40.6429 102.904 43.6291 102.493C44.1353 102.423 44.6414 102.575 45.0273 102.91C47.305 104.891 50.8543 104.524 52.67 102.119C52.98 101.714 53.4419 101.461 53.9544 101.423C56.9659 101.215 59.2119 98.4436 58.8006 95.4573C58.7311 94.9512 58.8829 94.445 59.2182 94.0591C61.1985 91.7815 60.8315 88.2321 58.4274 86.4163C58.0225 86.1063 57.7694 85.6445 57.7314 85.132Z"
              fill="white"
              stroke="#B5B8BF"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <g>
              <path
                d="M43.4204 91.3953L46.7799 94.1222L51.6199 87.9346"
                fill="white"
              />
              <path
                d="M43.4204 91.3953L46.7799 94.1222L51.6199 87.9346"
                stroke="#B5B8BF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <rect
            x="-618.5"
            y="-302.5"
            width="1439"
            height="1023"
            stroke="#004C97"
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect x="-619" y="-303" width="1440" height="1024" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
