import { useEffect, useRef, useState } from "react";

import { useAuth } from "@m/login";
import { Button, toast } from "@m/ui";

import { useMutation } from "@mc/utils/graphql";

import { SET_TEAM_MEMBERS } from "../api";

const TEAM_MEMBER_SET_FAILURE =
  "Sorry, we encountered a problem updating the team members for this account.";

export const CompanyTeamSaveButton = (team) => {
  const initialTeam = useRef();
  const [dirty, setDirty] = useState(false);
  const { user } = useAuth();
  const company = user.company.id;

  useEffect(() => {
    // on subsequent calls (when the team state changes, e.g,. team is
    // added/removed from), this dirty state will be set to true so that we can
    // enable the "Save Changes" button
    if (initialTeam.current) setDirty(true);
    // this is called on the first render to store the "initial" team value
    initialTeam.current = team;
  }, [team]);

  const onError = () => {
    toast.dismiss();
    toast.error(TEAM_MEMBER_SET_FAILURE);
  };
  const onCompleted = () => {
    setDirty(false);
  };
  const [setTeamMembers, { loading }] = useMutation(SET_TEAM_MEMBERS, {
    onCompleted: onCompleted,
    onError: onError,
    context: { clientName: "v4" },
  });
  const onSave = () => {
    const newMembers = team.team.map((member) => ({
      accountId: member.account.databaseId,
      roleName: member.jobRole.name,
    }));
    setTeamMembers({
      variables: {
        input: {
          companyId: company,
          teamMembers: newMembers,
        },
      },
    });
  };

  const disabled = loading || !dirty;

  return (
    <div className="py-4">
      <Button onClick={onSave} disabled={disabled} loading={loading}>
        Save Changes
      </Button>
    </div>
  );
};
