import { gql } from "@m/api/v4";

import { useMutation } from "@mc/utils/graphql";

import { GET_PHOTO_OPT_OUT } from "./usePhotoOptOut";

export const UPDATE_PHOTO_OPT_OUT = gql(/* GraphQL */ `
  mutation UpdatePhotoOptOut($input: UpdateViewerInput!) {
    updateViewer(input: $input) {
      ok
      viewer {
        isCharthopOptOut
      }
    }
  }
`);

export const useUpdatePhotoOptOut = () => {
  const [mutate, { loading }] = useMutation(UPDATE_PHOTO_OPT_OUT, {
    context: { clientName: "v4" },
    refetchQueries: [GET_PHOTO_OPT_OUT],
  });

  function togglePhotoOptOut(useInitials: boolean) {
    mutate({
      variables: {
        input: {
          isCharthopOptOut: !useInitials,
        },
      },
    });
  }

  return [togglePhotoOptOut, { loading }];
};
