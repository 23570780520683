import { FormEventHandler, useState } from "react";

import { useAuth } from "@m/login";
import { Button, Field, Fields, Input, MissionLogo } from "@m/ui";

import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

import { useRegisterUser } from "../api";

export const RegistrationPage = () => {
  const { user } = useAuth();

  const [errorMessage, setErrorMessage] = useState("");

  const [registerUser, { loading }] = useRegisterUser(setErrorMessage);

  const handleSubmit: FormEventHandler<RegistrationForm> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.currentTarget;
    const firstName = elements.firstName.value;
    const lastName = elements.lastName.value;
    registerUser(firstName, lastName);
  };

  if (!user?.isPendingInvite) return <Navigate replace to={PATHS.ROOT} />;

  return (
    <div className="flex h-screen items-center justify-center bg-layout-inverse">
      <div className="w-full max-w-md rounded-lg bg-white p-5">
        <form onSubmit={handleSubmit} className="space-y-3">
          <MissionLogo logo="mark" className="m-auto h-[52px]" />

          <div className="space-y-2 text-center">
            <h1 className="p-0 text-lg font-semibold">Mission Control</h1>

            {errorMessage ? (
              <p className="m-0 text-sm text-red-500">{errorMessage}</p>
            ) : (
              <p className="m-0 text-sm text-gray-500">
                What name do you prefer for your profile?
              </p>
            )}
          </div>

          <Fields>
            <Field label="First Name" htmlFor="firstName">
              <Input id="firstName" required />
            </Field>
            <Field label="Last Name" htmlFor="lastName">
              <Input id="lastName" required />
            </Field>

            <Button
              type="submit"
              kind="primary"
              size="large"
              className="w-full"
              loading={loading}
            >
              Continue
            </Button>
          </Fields>
        </form>
      </div>
    </div>
  );
};

export interface RegistrationFormElements extends HTMLFormControlsCollection {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
}

export interface RegistrationForm extends HTMLFormElement {
  readonly elements: RegistrationFormElements;
}
