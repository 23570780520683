import { DatePicker } from "@m/ui";

const formGroupStyle = {
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
};

export const DateRangePicker = ({ after, before }) => (
  <div className="flex gap-3">
    <div style={formGroupStyle}>
      <label className="font-bold">From</label>
      <DatePicker onChange={after} />
    </div>
    <div style={formGroupStyle}>
      <label className="font-bold">To</label>
      <DatePicker onChange={before} />
    </div>
  </div>
);
