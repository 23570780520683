import clsx from "clsx";
import { FC, ReactElement, ReactNode } from "react";

export interface FieldPropsStrict {
  /** Add a related secondary action for the field */
  action?: ReactNode;

  /** Additional classes */
  className?: string;

  /** Optional additional information about this field */
  description?: ReactNode;

  /** Show an error */
  error?: string | ReactElement;

  /** Show that the field is required or optional visually */
  flag?: "required" | "optional";

  /** ID of the form control being labeled */
  htmlFor?: string;

  /** A descriptive phrase titling the form field */
  label: ReactNode;

  /** ID of the label, for use with aria-labelledby */
  labelId?: string;

  /** Set row layout for the label and children fields */
  row?: boolean | "1/3" | "1/2" | "2/3";

  /** Item alignment for row layout */
  rowAlign?: "center" | "top";

  /** Size of the label */
  size?: "default" | "large";
}

interface FieldProps extends FieldPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

const flags = {
  required: (
    <span className="text-negative" aria-hidden="true">
      *
    </span>
  ),
  optional: (
    <span className="text-xs font-medium text-subdued" aria-hidden="true">
      (optional)
    </span>
  ),
};

export const Field: FC<FieldProps> = ({
  action,
  children,
  className,
  description,
  error,
  flag,
  htmlFor,
  label,
  labelId,
  row,
  rowAlign = "center",
  size = "default",
}) => {
  const fieldClasses = clsx(
    "flex gap-1",
    {
      // Field layout
      "flex-row justify-between": row,
      "items-center": row && rowAlign === "center",
      "items-start": row && rowAlign === "top",
      "flex-col": !row,
    },
    className
  );

  const labelClasses = clsx(
    "flex items-baseline gap-1 font-semibold text-default",
    {
      // Label size
      "text-sm": size === "default",
      "text-xl": size === "large",

      // Label margin
      "mb-1": size === "large",
      "mt-1": row && rowAlign === "top",
    }
  );

  return (
    <div className={fieldClasses} data-component="Field">
      <div
        className={clsx("flex flex-col gap-0.5", {
          "basis-1/2": row === "1/2" || row === true,
          "basis-1/3": row === "2/3",
          "basis-2/3": row === "1/3",
        })}
      >
        <div className="flex items-baseline justify-between gap-2">
          <span className={labelClasses}>
            <label className="m-0" htmlFor={htmlFor} id={labelId}>
              {label}
            </label>
            {flag && flags[flag]}
          </span>
          {action}
        </div>
        {description && (
          <div className="font-regular mb-0.5 text-xs text-subdued">
            {description}
          </div>
        )}
      </div>
      <div
        className={clsx("flex flex-col gap-0.5", {
          "basis-1/2": row === "1/2" || row === true,
          "basis-1/3": row === "1/3",
          "basis-2/3": row === "2/3",
        })}
      >
        {children}
        {error && (
          <div className="text-xs font-medium text-negative">{error}</div>
        )}
      </div>
    </div>
  );
};
