import { generatePath, useParams } from "react-router-dom";

import { useAuth } from "@m/login";
import { SpinnerScreen } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

import {
  MARKETPLACE_SIGNUP_ERRORS,
  useMarketplaceSignup,
  useValidateRegistrationToken,
} from "../api";
import {
  AccountCreationError,
  AccountSignIn,
  CompanyAlreadyExistsError,
  CreateAccountForm,
  CreateAccountFormElements,
  PreparingAccount,
  SubscriptionDoesNotExistError,
} from "../components";

export const MarketplaceSignupPage = () => {
  const { token } = useParams<{ token: string }>();

  const { user } = useAuth();

  const {
    data: tokenValidationResponse,
    loading: tokenValidationLoading,
    error: tokenValidationError,
  } = useValidateRegistrationToken(token);

  const [
    signup,
    {
      data: signupResponse,
      loading: signupLoading,
      error: signupError,
      called: signupCalled,
    },
  ] = useMarketplaceSignup(token);

  const {
    success: isTokenValid,
    skipUserAccountSignup,
    companyName,
    companyId,
  } = tokenValidationResponse || {};

  const handleSubmit = (elements: CreateAccountFormElements) => {
    const emailAddress = elements.workEmailInput.value;
    const firstName = elements.firstNameInput.value;
    const lastName = elements.lastNameInput.value;

    if (companyId) {
      signup({ companyId, emailAddress, firstName, lastName });
    } else {
      const companyName = elements.companyNameInput.value;
      signup({ companyName, emailAddress, firstName, lastName });
    }
  };

  const redirectToAuth0 = () => {
    const auth0Url = signupResponse?.auth0Url;
    if (auth0Url) window.open(auth0Url);
  };

  const domainInUseError =
    signupError === MARKETPLACE_SIGNUP_ERRORS.DOMAIN_IN_USE;
  const accountCreationError =
    signupError === MARKETPLACE_SIGNUP_ERRORS.SIGNUP_ERROR;
  const error =
    domainInUseError || accountCreationError || tokenValidationError;

  const isAwaitingUserInput = isTokenValid && !signupCalled;

  const showCreateAccountForm = isAwaitingUserInput && !skipUserAccountSignup;
  const showAccountSignIn = isAwaitingUserInput && skipUserAccountSignup;
  const showPreparingAccount = signupCalled && !error;

  if (user) {
    const signupPath = generatePath(PATHS.SIGNUP_TOKEN, { token });
    return <Navigate replace to={signupPath} />;
  }

  return (
    <Page data-testid="signup-page" padding={false}>
      {tokenValidationLoading && <SpinnerScreen fitToParent />}

      {tokenValidationError && <SubscriptionDoesNotExistError />}
      {domainInUseError && <CompanyAlreadyExistsError />}
      {accountCreationError && <AccountCreationError />}

      {showAccountSignIn && (
        <AccountSignIn
          companyName={companyName}
          companyId={companyId}
          token={token}
        />
      )}
      {showCreateAccountForm && (
        <CreateAccountForm companyName={companyName} onSubmit={handleSubmit} />
      )}
      {showPreparingAccount && (
        <PreparingAccount
          loading={signupLoading}
          onCompleted={redirectToAuth0}
        />
      )}
    </Page>
  );
};
