import { gql } from "@m/api/public";

import { useQuery } from "@mc/utils/graphql";

export const POLL_CLOUD_SCORE = gql(/* GraphQL */ `
  query PollCloudScore {
    scorecards(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`);

export const usePollCloudScore = (
  pollInterval: number,
  shouldPoll: boolean
) => {
  const { data, ...result } = useQuery(POLL_CLOUD_SCORE, {
    pollInterval,
    skip: !shouldPoll,
  });

  const scorecards = data?.scorecards?.edges.map((edge) => edge.node);

  const hasCloudScore = scorecards?.length > 0;

  return {
    data: { hasCloudScore },
    ...result,
  };
};
