import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_ENGAGEMENTS_CHECKLIST_ITEMS } from "../api";
import {
  DEFAULT_SORT_ENGAGEMENTS_CHECKLIST,
  MESSAGES_ENGAGEMENTS_CHECKLIST,
} from "../constants";
import { IEngagementsChecklistItem } from "../types";

export const UPDATE_ENGAGEMENT_CHECKLIST_ITEM = gql`
  mutation UpdateEngagementChecklistItem($input: UpdateChecklistItemInput!) {
    updateChecklistItem(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateEngagementChecklistItem = (engagementId: string) => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_CHECKLIST.UPDATE_SUCCESS);

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_CHECKLIST.UPDATE_ERROR);

  const [mutate, { loading }] = useMutation(UPDATE_ENGAGEMENT_CHECKLIST_ITEM, {
    onCompleted,
    onError,
  });

  const updateChecklistItem = (
    updatedChecklistItem: Partial<IEngagementsChecklistItem>
  ) => {
    const { id, open, title, dueDate, noteList, owner } =
      updatedChecklistItem || {};

    return mutate({
      variables: {
        input: {
          engagementId,
          id,
          open,
          title,
          dueDate,
          noteList,
          owner,
        },
      },
      refetchQueries: [
        {
          query: GET_ENGAGEMENTS_CHECKLIST_ITEMS,
          variables: {
            id: engagementId,
            sort: DEFAULT_SORT_ENGAGEMENTS_CHECKLIST,
          },
        },
      ],
    });
  };

  return [updateChecklistItem, { loading }] as const;
};
