import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { Button } from "@m/ui";

import { useCompleteOnboarding } from "../api";

export const CompleteOnboardingWelcomeDetails = ({ sku }: { sku: string }) => {
  const [completeOnboarding, { loading }] = useCompleteOnboarding(sku);

  return (
    <Button
      className="w-full py-2"
      kind="primary"
      loading={loading}
      onClick={completeOnboarding}
    >
      Let's get started
      <ArrowRightIcon className="h-2 w-2" />
    </Button>
  );
};
