import { CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";

import { useRecursiveTimeout } from "@mc/hooks";

import styles from "./PreparingAccount.module.css";

interface Props {
  /** Loading state of the API call to create the account */
  loading: boolean;

  /** Called when the preparing account process is complete */
  onCompleted: () => void;
}

export const PreparingAccount = ({ loading, onCompleted }: Props) => {
  const [remaining, setRemaining] = useState<number>(3);
  const calledReady = remaining === 0;

  useRecursiveTimeout(() => {
    if (loading || calledReady) return;

    if (remaining > 1) {
      setRemaining((prev) => (prev -= 1));
    } else {
      onCompleted();
      setRemaining(0);
    }
  }, 1000);

  return (
    <div role="status" className="flex flex-col items-center gap-5">
      <div className="relative w-fit">
        <div
          className={clsx(
            "h-[240px] w-[240px] rounded-full border-8 border-solid",
            {
              [styles.animateSpin]: loading,
              "border-green-100 border-t-status-good": loading,
              "border-status-good": !loading,
            }
          )}
        />

        {loading ? <LoadingBars /> : <ReadyCheckmark />}
      </div>

      <div className="text-4xl font-bold text-default">
        {loading ? "Preparing your account" : "Your account is ready!"}
      </div>

      {!loading && !calledReady && (
        <div className="text-xl font-normal text-subdued">
          Set your password in {remaining}...
        </div>
      )}
    </div>
  );
};

const LoadingBars = () => {
  const barStyles = "h-full w-2 rounded-md bg-white";

  return (
    <div
      className="absolute left-5 top-5 h-[144px] w-[144px] rounded-full border-2 border-white bg-status-good p-4"
      data-testid="loading-bars"
    >
      <div className="flex h-full items-end justify-center gap-1">
        <div className={clsx(barStyles, styles.animateLeft)} />
        <div className={clsx(barStyles, styles.animateMiddle)} />
        <div className={clsx(barStyles, styles.animateRight)} />
      </div>
    </div>
  );
};

const ReadyCheckmark = () => {
  return (
    <CheckCircleIcon
      className="absolute left-4 top-4 h-[176px] w-[176px] fill-status-good"
      data-testid="ready-checkmark"
    />
  );
};
