import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { Bookmark } from "@m/api/public/types";
import { Button, Link, Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";

import { AccessRequired } from "@mc/components/AccessRequired";
import { MUTATIONS, PATHS } from "@mc/constants";
import { useBookmarks } from "@mc/features/BookmarkSettings/api";

export const BookmarksWidget = () => {
  const {
    data: { bookmarks },
    loading,
  } = useBookmarks();

  return (
    <div>
      <div className="flex items-center justify-between p-1">
        <p className="font-semibold">Bookmarks</p>
        <AccessRequired mutation={MUTATIONS.ADD_BOOKMARK}>
          <Link
            to={PATHS.SETTINGS_BOOKMARKS}
            fontSize="sm"
            leftIcon={PencilIcon}
          >
            Edit
          </Link>
        </AccessRequired>
      </div>
      <Card
        data-testid="bookmarks-widget"
        className={clsx({ "bg-zinc-50": bookmarks.length === 0 })}
      >
        {loading && (
          <div className="flex justify-center">
            <Spinner className="my-4" />
          </div>
        )}
        {!loading &&
          (bookmarks.length === 0 ? (
            <EmptyState />
          ) : (
            <BookmarksList bookmarks={bookmarks} />
          ))}
      </Card>
    </div>
  );
};

const BookmarksList = ({ bookmarks }: { bookmarks: Bookmark[] }) => (
  <div className="flex flex-col p-2">
    {bookmarks.map((bookmark) => (
      <div key={bookmark.id} className="flex p-1">
        <Link
          key={bookmark.id}
          href={bookmark.url}
          target="_blank"
          className="hover:cursor-pointer hover:underline"
        >
          {bookmark.linkDisplayName}
        </Link>
        <div className="ml-auto text-subdued">
          {bookmark.linkShortDescription}
        </div>
      </div>
    ))}
  </div>
);

const EmptyState = () => (
  <div className="flex flex-col gap-2 p-4">
    <div className="text-center">There are no bookmarks recorded.</div>
    <Button
      className="m-auto w-fit"
      leftIcon={PlusIcon}
      to={PATHS.SETTINGS_BOOKMARKS}
    >
      Add bookmark
    </Button>
  </div>
);
