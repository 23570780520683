import clsx from "clsx";
import { FC, ReactNode } from "react";

export type PageWidths = "small" | "default" | "large" | "full";
export type PageAlignments = "center" | "left";

interface Props {
  align?: PageAlignments;

  /** Content to be rendered in the main area of the page */
  children: ReactNode;

  /** Additional classes to be applied to the page content */
  className?: string;

  /** Padding of the page content */
  padding?: boolean;

  /** Title to be rendered in the header of the page */
  title?: ReactNode;

  /** Width of the page */
  width?: PageWidths;
}

export const Page: FC<Props> = ({
  align = "center",
  children,
  className,
  padding = true,
  title = null,
  width = "default",
  ...props
}) => {
  const classes = clsx(className, "container grow", {
    // Page alignment
    "mx-auto": align === "center",
    "mr-auto": align === "left",

    // Page width
    "max-w-screen-md": width === "small", // 768px
    "max-w-screen-xl": width === "default", // 1280px
    "max-w-screen-2xl": width === "large", // 1536px
    "max-w-full": width === "full", // 100%

    // Page padding
    "p-4": padding,
    "p-0": !padding,
  });

  return (
    <div
      className="flex grow flex-col"
      data-component="Page"
      data-testid="page"
    >
      {title}
      <main className={classes} {...props}>
        {children}
      </main>
    </div>
  );
};
