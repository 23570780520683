import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_CLOUD_SCORE_RECOMMENDATIONS = gql(/* GraphQL */ `
  query CloudScoreRecommendations {
    scorecardRecommendations {
      edges {
        node {
          id
          title
          scorecardRecStatus
          updatedTime
          createdBy {
            id
            firstName
            lastName
            email
          }
          scorecardResult {
            id
            name
            pillar
            status
          }
          flaggedResources {
            totalCount
          }
        }
      }
    }
  }
`);

export const useCloudScoreRecommendations = () => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.SCORECARD_RECOMMENDATIONS_FAILURE);

  const { data, ...result } = useQuery(GET_CLOUD_SCORE_RECOMMENDATIONS, {
    onError,
  });

  const recommendations =
    data?.scorecardRecommendations?.edges.map((edge) => edge.node) || [];

  return {
    data: { recommendations },
    ...result,
  };
};
