import { useCallback, useState } from "react";

import { saveToLocalStorage } from "@m/login";
import { UserSession } from "@m/types";

export const useUserSession = (defaultUser = null) => {
  const localUser = localStorage.getItem("user") || "";

  const [user, setUser] = useState<UserSession | null>(
    defaultUser || (localUser && JSON.parse(localUser)) || null
  );

  const updateUserSession = useCallback((session: UserSession) => {
    const user = saveToLocalStorage(session);
    setUser(user);
  }, []);

  return { user, updateUserSession };
};
