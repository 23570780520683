import {
  AppState,
  Auth0ProviderOptions,
  Auth0Provider as BaseAuth0Provider,
} from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0Provider = (opts: Partial<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const handleRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <BaseAuth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={handleRedirectCallback}
      audience={
        process.env.REACT_APP_AUTH0_AUDIENCE ||
        process.env.REACT_APP_SERVICES_API
      }
      {...opts}
    />
  );
};
