import {
  ArrowUturnLeftIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { Dropdown, DropdownItem } from "@m/ui";

import { PATHS } from "@mc/constants";

import { useUpdateEngagementChecklistItem } from "../api";
import { IEngagementsChecklistItem } from "../types";

export const ChecklistAction = ({
  actionItem,
  engagementId,
}: {
  actionItem: IEngagementsChecklistItem;
  engagementId: string;
}) => {
  const navigate = useNavigate();

  const { open } = actionItem;

  const [updateActionItem] = useUpdateEngagementChecklistItem(engagementId);

  const handleEdit = () => {
    const path = generatePath(PATHS._ENGAGEMENT_CHECKLIST_EDIT, {
      engagementId,
      actionItemId: actionItem.id,
    });
    navigate("../" + path);
  };

  const handleReopen = (e: ChangeEvent<HTMLButtonElement>) => {
    const isOpen = Boolean(e.target.value);
    const { ...actionItemCopy } = actionItem;
    delete actionItemCopy.noteList;

    updateActionItem({
      ...actionItemCopy,
      open: isOpen,
    });
  };

  return (
    <>
      <Dropdown
        className="w-[165px] p-1"
        direction="bottom-end"
        trigger={
          <EllipsisVerticalIcon
            aria-label="Action Item Actions"
            className="h-2.5 w-2.5"
          />
        }
      >
        <DropdownItem className="p-1" onClick={handleEdit}>
          <PencilIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Edit
        </DropdownItem>

        {!open && (
          <DropdownItem className="p-1" value={true} onClick={handleReopen}>
            <ArrowUturnLeftIcon className="mr-1 h-2.5 w-2.5 text-accent" />
            Reopen
          </DropdownItem>
        )}
      </Dropdown>
    </>
  );
};
