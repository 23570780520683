import {
  Provider as TooltipProvider,
  TooltipProviderProps,
} from "@radix-ui/react-tooltip";
import { ReactNode } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./tailwind.css";

interface ProviderProps {
  children: ReactNode;

  /* The duration from when the pointer enters the trigger until the tooltip gets opened. */
  delayDuration?: TooltipProviderProps["delayDuration"];

  /* How much time a user has to enter another trigger without incurring a delay again. */
  skipDelayDuration?: TooltipProviderProps["skipDelayDuration"];
}

export const UIProvider = ({
  children,
  delayDuration = 300,
  skipDelayDuration = 500,
}: ProviderProps) => (
  <HelmetProvider>
    <TooltipProvider
      delayDuration={delayDuration}
      skipDelayDuration={skipDelayDuration}
    >
      <ToastContainer autoClose={3000} hideProgressBar />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </TooltipProvider>
  </HelmetProvider>
);
