import { useApolloClient, useLazyQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const IDENTIFY_AWS_PAYER_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query IdentifyAwsPayerAccount($awsAccountId: String!, $externalId: String) {
    identifyAwsPayerAccount(
      awsAccountId: $awsAccountId
      externalId: $externalId
    ) {
      callingAccountId
      callingAccountIsPayer
      payerAccountId
    }
  }
`);

export const useIdentifyAwsPayerAccount = (
  awsAccountId: string,
  externalId: string
) => {
  const client = useApolloClient();

  const [identifyAwsPayerAccount, result] = useLazyQuery(
    IDENTIFY_AWS_PAYER_ACCOUNT_QUERY,
    {
      variables: { awsAccountId, externalId },
      onCompleted: ({ identifyAwsPayerAccount: { payerAccountId } }) => {
        toast.success(
          `Successfully identified AWS payer account ${payerAccountId}`
        );
        client.refetchQueries({ include: ["GetMarketplaceRegistrations"] });
      },
    }
  );

  return [identifyAwsPayerAccount, result] as const;
};
