import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { Ec2InstanceFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { EC2_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export const GET_EC2_INSTANCES = gql(/* GraphQL */ `
  query Ec2Instances(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [Ec2InstanceSortEnum]
    $where: Ec2InstanceFilter
  ) {
    ec2Instances(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          account
          id
          instanceId
          instanceType
          availabilityZone
          publicIp
          privateIp
          platform
          accountName
          vpcId
          state
          createTime
          tagTotal
          tags
          name
          newrelicPermalink
          monitoringPolicy
          backupPolicy
          patchingPolicy
        }
      }
    }
  }
`);

export const useEc2Instances = (query) => {
  const where: Ec2InstanceFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;
  if (query.types) where.instanceTypeIn = query.types;
  if (query.states) where.stateIn = query.states;
  if (query.zones) where.availabilityZoneIn = query.zones;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(EC2_LABEL));

  const { data, loading, error, fetchMore } = useQuery(GET_EC2_INSTANCES, {
    variables: {
      sort: query.sort,
      ...INITIAL_PAGINATION,
      searchTerm: query.search,
      where,
    },
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const { edges, pageInfo, totalCount } = data?.ec2Instances || {};
  const ec2Instances = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { ec2Instances },
    error,
    loading,
    pagination,
  };
};
