import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import {
  DEFAULT_SORT_ENGAGEMENTS_MILESTONES,
  MESSAGES_ENGAGEMENTS_MILESTONES,
} from "../constants";
import { IEngagementMilestoneItem } from "../types";

import { IEngagementMilestoneParams } from "./useEngagementMilestoneItem";
import { GET_ENGAGEMENTS_MILESTONE_ITEMS } from "./useEngagementMilestoneItems";

export const UPDATE_ENGAGEMENTS_MILESTONE = gql`
  mutation UpdateEngagementsMilestone($input: UpdateMilestoneInput!) {
    updateMilestone(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateEngagementsMilestone = (
  engagementId: string,
  params: Partial<IEngagementMilestoneParams> = {}
) => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_MILESTONES.UPDATE_ERROR);
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_MILESTONES.UPDATE_SUCCESS);

  const [mutate, { loading }] = useMutation(UPDATE_ENGAGEMENTS_MILESTONE, {
    onCompleted,
    onError,
  });

  const updateEngagementsMilestone = ({
    id,
    title,
    percentageComplete,
    dueDate,
    owner,
    noteList,
    status,
    tasks,
  }: Partial<IEngagementMilestoneItem> = {}) => {
    return mutate({
      variables: {
        input: {
          engagementId,
          id,
          title,
          percentageComplete,
          dueDate,
          owner,
          noteList,
          status,
          tasks,
        },
      },
      refetchQueries: [
        {
          query: GET_ENGAGEMENTS_MILESTONE_ITEMS,
          variables: {
            id: engagementId,
            sort: params.sort || DEFAULT_SORT_ENGAGEMENTS_MILESTONES,
          },
        },
      ],
    });
  };

  return [updateEngagementsMilestone, { loading }] as const;
};
