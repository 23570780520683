import { useMemo } from "react";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";
import { dt } from "@m/utils";

import { ACTIVITY_FEED_MESSAGES } from "@mc/features/ActivityFeed/constants";
import { ENGAGEMENT_ACTIVITY_FEED_MAP } from "@mc/features/EngagementsActivityFeed/constants";
import { EngagementActivityStreamNode } from "@mc/features/EngagementsActivityFeed/types";
import { useQuery } from "@mc/utils/graphql";

// TODO(jamesmoody): Update this to just use totalCount when the activity filter supports filtering by engagementId
export const GET_ENGAGEMENT_ACTIVITY_FEED_CHANGES_COUNT = gql(`
query EngagementActivityFeedChangesCount($activityStreamFilter: ActivityStreamFilter, $first: Int, $after: String) {
  activityStream(where: $activityStreamFilter, first: $first, after: $after) {
    edges {
      node {
        __typename
        ... on EngagementStartActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementBudgetStatusChangeActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementBudgetSpentActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementDecisionActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementActionItemCreatedActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementActionItemCompletedActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementActionItemNoteAddedActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementMilestoneActivityStream {
          engagement {
            id
          }
        }
        ... on EngagementRiskLogItemActivityStream {
          engagement {
            id
          }
        }
      }
    }
  }
}
`);

export const useEngagementActivityFeedChangesCount = ({
  engagementId,
}: {
  engagementId: string;
}) => {
  const onError = () =>
    toast.error(ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_FAILURE);

  const cutoff = useMemo(() => dt.utc().minus({ days: 30 }).toISO(), []);

  const streamTypes = [
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementStartActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementBudgetSpentActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementBudgetStatusChangeActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementDecisionActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemCreatedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemCompletedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemNoteAddedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementMilestoneActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementRiskLogItemActivityStream,
  ].map((stream) => stream.type);

  const { data, loading, error } = useQuery(
    GET_ENGAGEMENT_ACTIVITY_FEED_CHANGES_COUNT,
    {
      variables: {
        activityStreamFilter: {
          timestampGt: cutoff,
          typeIn: streamTypes,
          // TODO(jamesmoody): We'll need some type of filter to get the count of changes for a specific engagement
        },
      },
      onError,
    }
  );

  const activitiesForEngagement =
    data?.activityStream?.edges.filter((edge) => {
      const node = edge?.node as EngagementActivityStreamNode;
      return node?.engagement?.id === engagementId;
    }) || [];

  return {
    data: { activityCount: activitiesForEngagement.length },
    loading,
    error: error !== undefined,
  };
};
