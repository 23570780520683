interface Props {
  name: string;
  id: string;
}

export const AccountOption = ({ name, id }: Props) => {
  return (
    <div className="flex flex-col items-start">
      <div>{name}</div>
      <div className="font-light text-subdued">{id}</div>
    </div>
  );
};
