import { useNavigate } from "react-router-dom";

import { Modal } from "@m/ui";
import { toProperCase } from "@m/utils";

import { EngagementsDetailItemForm } from "../components";
import {
  DEFAULT_INITIAL_ENGAGEMENTS_CHECKLIST_ITEM,
  DEFAULT_INITIAL_ENGAGEMENTS_MILESTONE,
  DEFAULT_INITIAL_ENGAGEMENT_DECISION,
  DEFAULT_INITIAL_RISK_LOG_ITEM,
  DETAIL_ITEM_TYPE,
} from "../constants";

export const EngagementsDetailItemAddModal = ({
  detailType,
}: {
  detailType: DETAIL_ITEM_TYPE;
}) => {
  const navigate = useNavigate();
  const handleClose = () => navigate("..");

  let initialItem;
  switch (detailType) {
    case DETAIL_ITEM_TYPE.MILESTONE:
      initialItem = DEFAULT_INITIAL_ENGAGEMENTS_MILESTONE;
      break;
    case DETAIL_ITEM_TYPE.ACTION_ITEM:
      initialItem = DEFAULT_INITIAL_ENGAGEMENTS_CHECKLIST_ITEM;
      break;
    case DETAIL_ITEM_TYPE.RISK:
      initialItem = DEFAULT_INITIAL_RISK_LOG_ITEM;
      break;
    case DETAIL_ITEM_TYPE.DECISION:
      initialItem = DEFAULT_INITIAL_ENGAGEMENT_DECISION;
      break;
    default:
      break;
  }

  return (
    <Modal
      open={true}
      size="lg"
      header={
        <Modal.Title className="pb-1 text-center font-semibold">
          Add {toProperCase(detailType)}
        </Modal.Title>
      }
      onClose={handleClose}
      aria-label="Engagement Detail Item Add Modal"
    >
      <EngagementsDetailItemForm
        detailType={detailType}
        detailItem={initialItem}
      />
    </Modal>
  );
};
