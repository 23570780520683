import { useMemo } from "react";

import { Button, Card, Link, Spinner } from "@m/ui";
import { formatDate } from "@m/utils";

import { SimpleTable } from "@mc/components/SimpleTable";

import { RemoveButton } from "./RemoveButton";

export const GET_UNCLAIMED_CONNECTIONS_EMPTY =
  "There are no unclaimed git repository connections. Install the Mission Engineering Access GitHub app to create a new connection.";

const EmptyMessage = () => {
  return (
    <div className="pt-2">
      <p className="mx-6 text-center text-gray-500">
        {GET_UNCLAIMED_CONNECTIONS_EMPTY}
      </p>
    </div>
  );
};

const TableHeader = () => {
  return (
    <div className="mb-2 flex justify-between">
      <label className="font-semibold" htmlFor="unclaimed-connections">
        All Unclaimed Connections
      </label>
      <Link
        href="https://github.com/apps/mission-engineering-access"
        rel="noreferrer"
        target="_blank"
      >
        Create Connection
      </Link>
    </div>
  );
};

const ClaimButton = ({
  connection,
  company,
  claimConnection,
  claimConnectionLoading,
}) => {
  const onClaim = () => claimConnection(connection, company);
  return (
    <Button
      kind="primary"
      aria-label="Claim"
      onClick={onClaim}
      loading={claimConnectionLoading}
    >
      Claim to {company.name}
    </Button>
  );
};

export const UnclaimedConnectionsTable = ({
  connections,
  loading,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Repositories",
        accessor: "repositories",
      },
      {
        Header: "Added",
        accessor: "added",
      },
      {
        Header: "",
        accessor: "claim",
      },
      {
        Header: "",
        accessor: "remove",
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      connections.map((connection) => {
        return {
          repositories: connection.repositories.edges.map((edge) => {
            const repo = edge.node;
            return <p key={repo.id}>{repo.fullName}</p>;
          }),
          added: connection.createdAt && formatDate(connection.createdAt),
          claim: <ClaimButton connection={connection} {...props} />,
          remove: <RemoveButton connection={connection} {...props} />,
        };
      }),
    [connections, props]
  );

  return (
    <Card className="mt-2 p-2">
      <TableHeader />

      <SimpleTable
        columns={columns}
        data={data}
        label="Unclaimed Connections"
      />

      {loading ? (
        <div className="flex justify-center">
          <Spinner className="m-4" />
        </div>
      ) : (
        connections.length === 0 && <EmptyMessage />
      )}
    </Card>
  );
};
