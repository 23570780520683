import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  BaseFilter,
  FilterBar,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";

import { Page } from "@mc/components/Page";

import {
  useLambdaFilters,
  useLambdaFunctions,
  useLambdaSearchTerms,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  GET_RESOURCES_EMPTY,
  LAMBDA_LABEL,
  TABLE_HEADERS_LAMBDA_FUNCTIONS,
} from "../constants";
import { createAccountOptions } from "../utils";

export const LambdaFunctionsPage = () => {
  const [query, setQuery] = useQueryParams({
    accounts: ArrayParam,
    runtimes: ArrayParam,
    handlers: ArrayParam,
    search: StringParam,
    sort: withDefault(StringParam, "NAME_ASC"),
  });

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useLambdaSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ query, setQuery, refetchSearchTerms });

  const {
    data: {
      filters: { accounts, runtimes, handlers },
    },
  } = useLambdaFilters();

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { lambdaFunctions },
    loading,
    pagination,
  } = useLambdaFunctions(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );

  const handleSortChange = (sort: string) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const rows = useMemo(
    () =>
      lambdaFunctions.map((lambdaFunction) => {
        const { codeSize, memorySize } = lambdaFunction;
        const tags = JSON.parse(lambdaFunction?.tags || "{}");
        const tagCount = Object.keys(tags).length;
        const name = lambdaFunction?.functionName;

        return {
          ...lambdaFunction,
          tags: (
            <ResourceTagsBadge
              onClick={setDrawerDetails}
              details={{
                name,
                tags,
              }}
              count={tagCount}
            />
          ),
          memorySize: memorySize && `${memorySize} MB`,
          codeSize: codeSize && `${codeSize.toLocaleString()} bytes`,
        };
      }),
    [lambdaFunctions]
  );

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      accounts: undefined,
      runtimes: undefined,
      handlers: undefined,
      search: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleDeselectAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const handleSelectedRuntimes = (runtimes: string[]) => {
    setQuery({
      runtimes,
    });
  };

  const handleDeselectRuntimesFilter = () => {
    setQuery({
      runtimes: undefined,
    });
  };

  const handleSelectedHandlers = (handlers: string[]) => {
    setQuery({
      handlers,
    });
  };

  const handleDeselectHandlersFilter = () => {
    setQuery({
      handlers: undefined,
    });
  };

  const hasActiveFilters =
    query.accounts?.length > 0 ||
    query.runtimes?.length > 0 ||
    query.handlers?.length > 0 ||
    !!searchTerm;

  return (
    <Page width="full" title={<ResourcesPageHeader title="Lambda Functions" />}>
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2"
      >
        <Search
          ariaLabel="Lambda Function Search Input"
          disabled={loading}
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <BaseFilter
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleDeselectAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
        <BaseFilter
          ariaLabel="Runtime Filter"
          disabled={runtimes.length === 0 || loading}
          initialValue="Runtime"
          multiple={true}
          onChange={handleSelectedRuntimes}
          onClear={handleDeselectRuntimesFilter}
          options={createFilterOptions(runtimes)}
          selection={query.runtimes || []}
        />
        <BaseFilter
          ariaLabel="Handler Filter"
          disabled={handlers.length === 0 || loading}
          initialValue="Handler"
          multiple={true}
          onChange={handleSelectedHandlers}
          onClear={handleDeselectHandlersFilter}
          options={createFilterOptions(handlers)}
          selection={query.handlers || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(LAMBDA_LABEL)}
        headers={TABLE_HEADERS_LAMBDA_FUNCTIONS}
        label="Lambda Functions"
        loading={loading}
        onCloseDrawer={() => setDrawerDetails(null)}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
