export const WidgetDetailSection = ({
  label,
  detail,
}: {
  label: string;
  detail: string;
}) => {
  return (
    <div className="space-y-0.5">
      <div className="text-xs font-semibold uppercase tracking-wide text-subdued">
        {label}
      </div>
      <div className="font-bold">{detail}</div>
    </div>
  );
};
