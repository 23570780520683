import * as Sentry from "@sentry/react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import { GlobalProviders } from "@mc/providers";
import { routes } from "@mc/routes";

import { SentryReportDialog } from "./components";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const MissionControlApp = () => {
  const router = sentryCreateBrowserRouter([
    {
      element: (
        <Sentry.ErrorBoundary fallback={<SentryReportDialog />}>
          <GlobalProviders>
            <Outlet />
          </GlobalProviders>
        </Sentry.ErrorBoundary>
      ),
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
};
