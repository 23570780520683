import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_SCORECARD_TREND_MONTHLY = gql`
  query useScorecardTrendMonthly(
    $intervalCount: Int!
    $interval: ScorecardHistoryType!
  ) {
    scorecardsAverageScoreResults(
      intervalCount: $intervalCount
      interval: $interval
    ) {
      edges {
        node {
          averageScore
          month
          year
          pillar
          name
          resolvedRecommendationsCount
        }
      }
    }
  }
`;

export const useScorecardTrendMonthly = ({ months }) => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_MONTHLY_ERROR);

  const { data, loading, error } = useQuery(GET_SCORECARD_TREND_MONTHLY, {
    variables: { intervalCount: months, interval: "MONTHLY" },
    onError,
  });

  const averageMonthlyScoreResults =
    data?.scorecardsAverageScoreResults?.edges?.map((edge) => edge.node) || [];

  return {
    data: { averageMonthlyScoreResults },
    loading,
    error,
  };
};
