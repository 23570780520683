import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_FILTERS_ERROR, NLB_LABEL } from "../constants";

export const GET_NETWORK_LOAD_BALANCER_FILTERS = gql(/* GraphQL */ `
  query NetworkLoadBalancerFilters {
    networkLoadBalancerFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      regions
      states
    }
  }
`);

export const useNetworkLoadBalancerFilters = () => {
  const onError = () => toast.error(GET_FILTERS_ERROR(NLB_LABEL));

  const { data, loading, error } = useQuery(GET_NETWORK_LOAD_BALANCER_FILTERS, {
    onError,
  });

  const filters = data?.networkLoadBalancerFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const regions = filters.regions || [];
  const states = filters.states || [];

  return {
    data: { filters: { accounts, regions, states } },
    error,
    loading,
  };
};
