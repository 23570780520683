import PT from "prop-types";

import { Button, Confirm } from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

export const IgnoreCheckDialog = ({
  checkName,
  ignore,
  onClose,
  onConfirm,
  open = false,
}) => {
  const handleConfirm = () => {
    onConfirm(ignore);
  };

  const action = ignore ? "ignore" : "monitor";

  return (
    <Confirm
      onClose={onClose}
      aria-label="Ignore Check Dialog"
      open={open}
      actions={[
        <Button
          key={`${action}-button`}
          kind={ignore ? "destructive" : "primary"}
          onClick={handleConfirm}
        >
          {fromSnakeCaseToProperCase(action)}
        </Button>,
        <Button key="cancel-button" fill="none" onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to{` ${action} `}
        <span className="font-semibold text-default">{checkName}</span>? Your
        Mission Cloud Score will be affected by this change
      </p>
    </Confirm>
  );
};

IgnoreCheckDialog.propTypes = {
  checkName: PT.string.isRequired,
  ignore: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
  open: PT.bool,
};
