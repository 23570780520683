import PT from "prop-types";

import { CHECK_SOURCES } from "../constants";

export const CheckDetailsSource = ({ label, title }) => {
  const displayTitle = CHECK_SOURCES[title]?.name;

  return (
    <div>
      <div className="text-xs font-semibold text-default">{label}</div>
      <div
        aria-label="Check Source"
        className="mt-0.5 font-semibold text-default"
      >
        {displayTitle}
      </div>
    </div>
  );
};

CheckDetailsSource.propTypes = {
  label: PT.string.isRequired,
  title: PT.oneOf(Object.keys(CHECK_SOURCES)).isRequired,
};
