import { useMutation } from "@m/api";
import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_BOOKMARKS } from "./useBookmarks";

export const DELETE_BOOKMARK = gql(/* GraphQL */ `
  mutation DeleteBookmark($input: DeleteBookmarkInput!) {
    deleteBookmark(input: $input) {
      ok
      message
    }
  }
`);

export const useDeleteBookmark = () => {
  const [mutate, { data, ...result }] = useMutation(DELETE_BOOKMARK, {
    onCompleted: () => toast.success("Successfully deleted Bookmark"),
    onError: () => toast.error("Unable to delete Bookmark"),
    refetchQueries: [GET_BOOKMARKS],
  });

  const deleteBookmark = (bookmarkId: string) =>
    mutate({
      variables: {
        input: {
          bookmarkId,
        },
      },
    });

  const bookmarkDeleted = data?.deleteBookmark?.ok ?? false;

  return [deleteBookmark, { data: bookmarkDeleted, ...result }] as const;
};
