import { useAuth } from "@m/login";
import { Button, useModalState } from "@m/ui";

import { Page } from "@mc/components/Page";

import { useEligibleProductsForInvitation } from "../api";
import { MarketplaceInviteDialog } from "../components";

export const MarketplaceInvitationsPage = () => {
  const {
    user: { company },
  } = useAuth();

  const { data: eligibleProductsForInvitation, loading } =
    useEligibleProductsForInvitation();

  const inviteDialog = useModalState();

  const invitationsDisabled = eligibleProductsForInvitation.length === 0;

  return (
    <>
      <Page>
        <Button
          kind="primary"
          onClick={inviteDialog.open}
          loading={loading}
          disabled={invitationsDisabled}
        >
          Invite to Marketplace Offer
        </Button>
      </Page>

      {!invitationsDisabled && (
        <MarketplaceInviteDialog
          companyName={company.name}
          onClose={inviteDialog.close}
          open={inviteDialog.isOpen}
          productsForInvitation={eligibleProductsForInvitation}
        />
      )}
    </>
  );
};
