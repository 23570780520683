import { useNavigate } from "react-router-dom";

import { Button } from "@m/ui";

import { PATHS } from "@mc/constants";
import { ErrorPage } from "@mc/pages";

export const CompanyAlreadyExistsError = () => {
  const navigate = useNavigate();

  return (
    <ErrorPage
      title="Company already exists"
      content={
        <div>
          <div>Your account cannot be created.</div>
          <div>This account is already managed by Mission.</div>
        </div>
      }
      action={<Button onClick={() => navigate(PATHS.ROOT)}>Back</Button>}
    />
  );
};
