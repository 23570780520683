import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { ComponentType, ReactNode, useState } from "react";

import {
  AccessRequired,
  AccessRequirements,
} from "@mc/components/AccessRequired";
import { useMatch } from "@mc/router";

interface DropdownLinksProps {
  children: ReactNode;
  defaultOpen?: boolean;
  feature?: AccessRequirements["feature"];
  icon?: ComponentType<any>;
  matchFeatures?: AccessRequirements["matchFeatures"];
  path?: string;
  title: string;
}

export const DropdownLinks = ({
  children,
  defaultOpen = true,
  feature,
  icon: Icon,
  matchFeatures,
  path = "",
  title,
}: DropdownLinksProps) => {
  const match = useMatch(path);
  const [open, setOpen] = useState(defaultOpen || match !== null);

  const toggleMenu = () => setOpen((prev) => !prev);
  const ChevronIcon = open ? ChevronDownIcon : ChevronRightIcon;

  return (
    <AccessRequired feature={feature} matchFeatures={matchFeatures}>
      <div aria-label={`${title} Links`} className="flex flex-col">
        <button
          className="flex grow items-center rounded-lg hover:bg-neutral-200 hover:bg-opacity-80"
          onClick={toggleMenu}
        >
          <span className="inline-flex grow items-center gap-1 space-x-1 px-1 py-0.5 text-sm font-medium transition">
            {Icon && <Icon className="h-2 w-2 stroke-2" />}
            {title}
          </span>
          <ChevronIcon className="mr-1 inline h-2.5 w-2.5 text-accent" />
        </button>
        <div
          data-testid="dropdown-child-links"
          className={clsx("ml-3 flex flex-col space-y-[1px]", {
            hidden: !open,
          })}
        >
          {children}
        </div>
      </div>
    </AccessRequired>
  );
};
