import { FocusEventHandler } from "react";

import { Field, Input } from "@m/ui";
import { toHyphenLowercase } from "@m/utils";

import { InputStatusIcon } from "./InputStatusIcon";

interface Props {
  defaultValue?: string;
  disabled?: boolean;
  fieldLabel: string;
  loading?: boolean;
  ok?: boolean;
  onInputChange?: (input: string) => void;
}

export function TextInput({
  defaultValue,
  disabled,
  fieldLabel,
  loading,
  ok,
  onInputChange,
}: Props) {
  const fieldId = toHyphenLowercase(fieldLabel);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onInputChange(e.target.value);
  };

  return (
    <Field label={fieldLabel} htmlFor={fieldId}>
      <div className="relative">
        <Input
          defaultValue={defaultValue}
          id={fieldId}
          maxLength={99}
          onBlur={handleBlur}
          disabled={disabled}
        />
        <div className="absolute right-2 top-1/4">
          <InputStatusIcon loading={loading} ok={ok} />
        </div>
      </div>
    </Field>
  );
}
