import { useEffect, useRef } from "react";

/*
 * This hook calls a provided function repeatedly on a delay.
 *
 * When the function returns, it will immediately be scheduled again in `delay`
 * milliseconds.
 *
 * If the function returns a promise, the function will be scheduled again after
 * the promise is resolved.
 */
export const useRecursiveTimeout = (callback, delay) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let id;
    function tick() {
      const ret = callbackRef.current();

      if (ret instanceof Promise) {
        ret.then(() => {
          id = setTimeout(tick, delay);
        });
      } else {
        id = setTimeout(tick, delay);
      }
    }
    id = setTimeout(tick, delay);
    return () => id && clearTimeout(id);
  }, [delay]);
};
