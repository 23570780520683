export const DEFAULT_COMPANY_SIZE_SELECTION = "Select company size";

export const COMPANY_SIZES = {
  DEFAULT: DEFAULT_COMPANY_SIZE_SELECTION,
  MAX_1: "1-50 employees",
  MAX_200: "50-200 employees",
  MAX_500: "200-500 employees",
  MAX_1000: "501 - 1000 employees",
  MAX_2000: "1001 - 2000 employees",
  MAX_5000: "2000 - 5000 employees",
  MAX: "5000 + employees",
};

export const COMPANY_SIZE_OPTIONS = [
  ...Object.entries(COMPANY_SIZES).map((o) => ({
    disabled: o[1] == DEFAULT_COMPANY_SIZE_SELECTION,
    value: o[1],
  })),
];
