import { useContext, useMemo } from "react";
import { Link, generatePath } from "react-router-dom";

import { Badge } from "@m/ui";

import { HorizontalLine } from "@mc/components/HorizontalLine";
import { PATHS } from "@mc/constants";

import { PILLAR_DETAILS, SCORECARD_TREND_TIME_RANGES } from "../constants";
import { CloudScoreTrendContext } from "../contexts";
import { getDateRangeLabel, getPillar } from "../utils";

interface CloudScoreTrendActivityPanelProps {
  activity: {
    timestamp: string;
    pillar: string | null;
    resolvedRecommendationsCount: number;
    allScoreResults?: {
      pillar: string;
      resolvedRecommendationsCount: number;
    }[];
  };
  onClick: () => void;
}

export const CloudScoreTrendActivityPanel = ({
  activity: {
    timestamp,
    pillar,
    resolvedRecommendationsCount,
    allScoreResults,
  },
  onClick,
}: CloudScoreTrendActivityPanelProps) => {
  const { selectedRange } = useContext(CloudScoreTrendContext);
  const timeRange = SCORECARD_TREND_TIME_RANGES[selectedRange];
  const interval = timeRange?.interval;

  const label = getDateRangeLabel(interval, timestamp);
  const isOverall = pillar === null;

  return (
    <button
      className="flex w-fit flex-col space-y-1 p-2 text-left"
      onClick={onClick}
    >
      {isOverall ? (
        <OverallSummaryContent
          allScoreResults={allScoreResults}
          resolvedRecommendationsCount={resolvedRecommendationsCount}
        />
      ) : (
        <IndividualPillarContent
          pillar={pillar}
          resolvedRecommendationsCount={resolvedRecommendationsCount}
        />
      )}

      <div className="text-2xs font-semibold text-subdued">
        {isOverall && <div>Recommendations Resolved</div>}
        <div>{label}</div>
      </div>
    </button>
  );
};

const OverallSummaryContent = ({
  allScoreResults,
  resolvedRecommendationsCount,
}) => {
  const pillarCounts = useMemo(() => {
    return Object.values(PILLAR_DETAILS).map(({ id, name, pillar }) => {
      const scoreResult = allScoreResults.find(
        (result) => result.pillar === pillar
      );
      if (!scoreResult) return null;

      const { resolvedRecommendationsCount } = scoreResult;

      return (
        <Link
          key={id}
          className="flex justify-between gap-x-4 text-xs text-action hover:text-accent"
          to={generatePath(PATHS.CLOUD_SCORE_PILLAR_DETAILS, { pillarId: id })}
        >
          <div id={pillar} className="font-semibold">
            {name}:
          </div>
          <div aria-labelledby={pillar} className="text-default">
            {resolvedRecommendationsCount}
          </div>
        </Link>
      );
    });
  }, [allScoreResults]);

  return (
    <>
      <div className="flex items-center justify-between text-xs font-semibold text-default">
        <div id="total-count">Total:</div>
        <div aria-labelledby="total-count">{resolvedRecommendationsCount}</div>
      </div>
      <div className="-mx-2">
        <HorizontalLine className="bg-white opacity-80" />
      </div>
      <div className="flex flex-col space-y-0.5 pb-1 pt-0.5">
        {pillarCounts}
      </div>
    </>
  );
};

const IndividualPillarContent = ({ pillar, resolvedRecommendationsCount }) => {
  const pillarName = getPillar(pillar)?.name;

  return (
    <>
      {pillarName && (
        <Badge label={pillarName} className="w-fit" size="small" />
      )}
      <div className="text-xs font-semibold text-default">
        {resolvedRecommendationsCount} Recommendation
        {resolvedRecommendationsCount > 1 && "s"} Resolved
      </div>
    </>
  );
};
