import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_CHECK_RESULTS = gql(/* GraphQL */ `
  query CloudScoreCheckResults {
    scorecards(first: 1) {
      edges {
        node {
          id
          results {
            edges {
              node {
                id
                ignored
                name
                status
                source
                pillar
                potentialMonthlySavings
                totalResources
              }
            }
          }
        }
      }
    }
  }
`);

export const useCheckResults = () => {
  const onError = () => toast.error(CLOUD_SCORE_MESSAGES.CHECK_RESULTS_FAILURE);

  const { data, ...result } = useQuery(GET_CHECK_RESULTS, {
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const scorecard = data?.scorecards?.edges?.length
    ? data.scorecards.edges[0].node
    : null;
  const checks = scorecard?.results?.edges.map((edge) => edge.node) || [];

  return {
    data: { checks },
    ...result,
  };
};
