import { AccountOption } from "../components";

export const createAccountOptions = (accounts) => {
  if (!accounts) return [];

  return accounts.map(({ databaseId, name }) => {
    return {
      label: <AccountOption name={name} id={databaseId} />,
      id: databaseId,
    };
  });
};
