import { useScorecardTrendMonthly } from "../api";
import {
  CLOUD_SCORE_MESSAGES,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { useLoadScoreChange } from "../hooks";
import { getMonthlyTrendActivities, getMonthlyTrendLines } from "../utils";

import { CloudScoreTrendGraphBase } from "./CloudScoreTrendGraphBase";

const { fullText, intervalCount } = SCORECARD_TREND_TIME_RANGES.ONE_YEAR;

interface CloudScoreTrendGraph1YearProps {
  pillarId: string;
}

export const CloudScoreTrendGraph1Year = ({
  pillarId,
}: CloudScoreTrendGraph1YearProps) => {
  const {
    data: { averageMonthlyScoreResults },
    loading,
  } = useScorecardTrendMonthly({ months: intervalCount });

  useLoadScoreChange({
    scoreResults: averageMonthlyScoreResults,
    pillarId,
    scoreKey: "averageScore",
  });

  const { activeTrendLine, allTrendLines } = getMonthlyTrendLines(
    averageMonthlyScoreResults,
    pillarId
  );

  const activities = getMonthlyTrendActivities(
    averageMonthlyScoreResults,
    pillarId
  );

  return (
    <CloudScoreTrendGraphBase
      activeTrendLine={activeTrendLine}
      activities={activities}
      allTrendLines={allTrendLines}
      ariaLabel={`${fullText} Cloud Score Trend Graph`}
      axisBottom={{
        format: "%b",
        tickValues: "every 2 months",
      }}
      emptyMessage={CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_MONTHLY_EMPTY}
      loading={loading}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
      }}
    />
  );
};
