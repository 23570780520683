import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { useQuery } from "@mc/utils/graphql";

import { MESSAGES_EMAIL_NOTIFICATION, TEMPLATE_ALIASES } from "../constants";

export const GET_EMAIL_NOTIFICATION_SETTINGS = gql(`
  query EmailNotificationSettings {
    emailNotificationPreferences {
      edges {
        node {
          cadence
          templateAlias
        }
      }
    }
    emailTemplates {
      edges {
        node {
          alias
          defaultCadence
          id
        }
      }
    }
  }
`);

export const useEmailNotificationSettings = () => {
  const onError = () =>
    toast.error(
      MESSAGES_EMAIL_NOTIFICATION.GET_EMAIL_NOTIFICATIONS_PREFS_FAILURE
    );

  const { loading, error, data } = useQuery(GET_EMAIL_NOTIFICATION_SETTINGS, {
    onError,
  });

  const preferences =
    data?.emailNotificationPreferences?.edges.map((edge) => edge.node) || [];
  const templates = data?.emailTemplates?.edges?.map((edge) => edge.node) || [];

  const findPreferredCadence = (templateAlias: string) => {
    return preferences.find((p) => p.templateAlias === templateAlias)?.cadence;
  };
  const findDefaultCadence = (alias: string) => {
    return templates.find((t) => t.alias === alias)?.defaultCadence;
  };
  const getNotificationSetting = (alias: string) => {
    return findPreferredCadence(alias) || findDefaultCadence(alias);
  };

  // prettier-ignore
  const settings = {
    project_summary: getNotificationSetting(TEMPLATE_ALIASES.PROJECT_WEEKLY_SUMMARY),
    project_invites: getNotificationSetting(TEMPLATE_ALIASES.PROJECT_INVITES),
    recommendations: getNotificationSetting(TEMPLATE_ALIASES.RECOMMENDATION),
    monthly_summary: getNotificationSetting(TEMPLATE_ALIASES.MONTHLY_SUMMARY),
  };

  return {
    data: settings,
    loading,
    error: error !== undefined,
  };
};
