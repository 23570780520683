import * as Sentry from "@sentry/react";
import { useEffect } from "react";

const SOURCE_FILE_REGEX = /plugin|extension/i;
const BLOCKED_URI_REGEX = /grammarly/i;

export const useReportCSPViolation = () => {
  useEffect(() => {
    const ReportCSPViolation = ({
      blockedURI,
      sourceFile,
      lineNumber,
      columnNumber,
      violatedDirective,
    }: SecurityPolicyViolationEvent) => {
      const sendMessage =
        !SOURCE_FILE_REGEX.test(sourceFile || "") &&
        !BLOCKED_URI_REGEX.test(blockedURI || "");

      if (sendMessage) {
        let message = `CSP Violation - Blocked URI: ${blockedURI || "NA"}`;

        if (violatedDirective) {
          message += `, Violated Directive: ${violatedDirective}`;
        }
        if (sourceFile) message += `, File: ${sourceFile}`;
        if (lineNumber) message += `, Line: ${lineNumber}`;
        if (columnNumber) message += `, Column: ${columnNumber}`;

        Sentry.captureMessage(message, { level: "error" });
      }
    };

    window.addEventListener("securitypolicyviolation", ReportCSPViolation);

    return () => {
      window.removeEventListener("securitypolicyviolation", ReportCSPViolation);
    };
  }, []);
};
