import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { useQuery } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

export const GET_TEAM_MEMBERS = gql(`
  query TeamMembers($sort: [CompanyMembershipSortEnum]) {
    viewer {
      currentCompany {
        members(sort: $sort) {
          edges {
            node {
              accountId
              name
              firstName
              lastName
              email
              lastActive
              registered
              enforceMfa
              role
            }
          }
        }
      }
    }
  }
`);

export const useTeamMembers = () => {
  const onError = () => toast.error(MESSAGES.USER_LIST_ERROR);

  const { data, loading, refetch } = useQuery(GET_TEAM_MEMBERS, {
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const users =
    data?.viewer?.currentCompany?.members?.edges?.map((edge) => edge.node) ||
    [];

  const registeredUsers = users.filter((user) => user.registered);
  const unregisteredUsers = users.filter((user) => !user.registered);

  return {
    data: { registeredUsers, unregisteredUsers },
    loading,
    refetch,
  };
};
