import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const SENTRY_DSN =
  "https://5e29e0402b8245d7ae46bbab7e729d2f@o93328.ingest.sentry.io/6058830";

/* istanbul ignore next */
export const initializeSentry = () => {
  const release = process.env.REACT_APP_SENTRY_RELEASE;
  if (release) {
    let environment;
    switch (process.env.REACT_APP_SENTRY_ENV) {
      case "deploy/dev":
      case "main":
        environment = "development";
        break;
      case "deploy/prod":
        environment = "production";
        break;
      default:
        environment = "local";
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: environment,
      release: release,

      // Performance Setup
      integrations: [
        Sentry.browserTracingIntegration({
          tracingOrigins: [
            "localhost",
            "dev-control.missioncloud.com",
            "control.missioncloud.com",
            "dev-api.missioncloud.com",
            "api.missioncloud.com",
          ],
          routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      tracesSampleRate:
        process.env.REACT_APP_SENTRY_ENV === "deploy/prod" ? 0.2 : 1.0,
    });

    console.log(`Sentry: enabled, env: ${environment}, release: ${release}`);
  } else {
    console.log("Sentry: disabled");
  }
};

export const setSentryUser = (user) => {
  if (user) {
    Sentry.setUser({
      id: user.id,
      name: user.name,
      email: user.email,
      isStaff: user.isStaff,
      current_company: user.company.name,
      current_company_id: user.company.id,
    });
    console.log("Set Sentry user: ", user.name);
  } else {
    Sentry.setUser(null);
    console.log("Unset Sentry user");
  }
};
