export const logoutSession = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const servicesApiUrl = process.env.REACT_APP_SERVICES_API;
    if (!servicesApiUrl) return reject();

    const csrfToken = localStorage.getItem("csrf");
    if (!csrfToken) return reject();

    fetch(servicesApiUrl.concat("/api/public/v1/graphql"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      credentials: "include",
      body: JSON.stringify({ query: "mutation { logout { ok } }" }),
    })
      .then(async (response) => {
        const data = await response.json();
        return data?.logout?.ok ? resolve() : reject();
      })
      .catch((e) => {
        console.error(e);
        reject();
      })
      .finally(() => {
        localStorage.removeItem("csrf");
      });
  });
};
