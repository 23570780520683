import { useMemo } from "react";

import { Spinner } from "@m/ui";
import { formatDate } from "@m/utils";

import { SimpleTable } from "@mc/components/SimpleTable";

import { RemoveButton } from "./RemoveButton";

export const GET_CUSTOMER_CONNECTIONS_EMPTY =
  "There are no git repository connections for this company. Claim a new connection from the list below.";

const EmptyMessage = () => {
  return (
    <div className="m-2 rounded-md border border-dashed p-2">
      <p className="mx-6 text-center text-gray-500">
        {GET_CUSTOMER_CONNECTIONS_EMPTY}
      </p>
    </div>
  );
};

export const CustomerConnectionsTable = ({
  connections,
  loading,
  ...props
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Repositories",
        accessor: "repositories",
      },
      {
        Header: "Added",
        accessor: "added",
      },
      {
        Header: "Claimed",
        accessor: "claimed",
      },
      {
        Header: "",
        accessor: "remove",
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      connections.map((connection) => {
        return {
          repositories: connection.repositories.edges.map((edge) => {
            const repo = edge.node;
            return <p key={repo.id}>{repo.fullName}</p>;
          }),
          added: connection.createdAt && formatDate(connection.createdAt),
          claimed: formatDate(connection.verifiedAt),
          remove: <RemoveButton connection={connection} {...props} />,
        };
      }),
    [connections, props]
  );

  return (
    <>
      <SimpleTable columns={columns} data={data} label="Customer Connections" />

      {loading ? (
        <div className="flex justify-center">
          <Spinner className="m-4" />
        </div>
      ) : (
        connections.length === 0 && <EmptyMessage />
      )}
    </>
  );
};
