import { generatePath } from "react-router-dom";

import { ScorecardChangeActivityStream } from "@m/api/public/types";

import { PATHS } from "@mc/constants";
import { CHECK_STATUS_MESSAGES } from "@mc/features/CloudScore/constants";
import { CheckStatusIcon } from "@mc/features/CloudScore/icons";

import { ActivityFeedItem } from "./ActivityFeedItem";

interface Props {
  activity: Pick<
    ScorecardChangeActivityStream,
    "changes" | "latestResult" | "pillar" | "name" | "id" | "timestamp"
  >;
}

export const ActivityFeedItemScorecardChange = ({ activity }: Props) => {
  const {
    changes: { status },
    latestResult,
    pillar: category,
    timestamp,
    name,
  } = activity;

  let link = null;
  if (latestResult) {
    const { id: checkId } = latestResult;
    link = generatePath(PATHS._CLOUD_SCORE_CHECK_DETAILS_TABNAME, {
      checkId,
      tabName: "optimization-history",
    });
  }

  const icon = <CheckStatusIcon status={status[1]} />;
  const message = (
    <>
      <strong>{name}</strong> status changed to{" "}
      <strong>{CHECK_STATUS_MESSAGES[status[1]]}</strong>
    </>
  );

  return (
    <ActivityFeedItem
      icon={icon}
      link={link}
      message={message}
      timestamp={timestamp}
      category={category}
    />
  );
};
