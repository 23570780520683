import { Toggle } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { FEATURE_FLAGS } from "@mc/constants";

import {
  useEmailNotificationSettings,
  useUpdateEmailNotificationPreference,
} from "../api";
import { NotificationOption, NotificationsGroup } from "../components";
import { NOTIFICATIONS_SETTINGS_OPTIONS, TEMPLATE_ALIASES } from "../constants";

export const NotificationSettingsPage = () => {
  const { data: settings, loading, error } = useEmailNotificationSettings();

  const [updatePreference, { loading: updateLoading }] =
    useUpdateEmailNotificationPreference();

  const {
    RECOMMENDATIONS_TOGGLE,
    MONTHLY_SUMMARY_TOGGLE,
    PROJECT_INVITES_TOGGLE,
    PROJECT_SUMMARY_TOGGLE,
  } = NOTIFICATIONS_SETTINGS_OPTIONS;

  const handleChangeRecommendationsOption = (checked: boolean) => {
    const cadence = checked
      ? RECOMMENDATIONS_TOGGLE.CHECKED
      : RECOMMENDATIONS_TOGGLE.UNCHECKED;
    updatePreference(TEMPLATE_ALIASES.RECOMMENDATION, cadence);
  };

  const handleChangeMonthlySummaryOption = (checked: boolean) => {
    const cadence = checked
      ? MONTHLY_SUMMARY_TOGGLE.CHECKED
      : MONTHLY_SUMMARY_TOGGLE.UNCHECKED;
    updatePreference(TEMPLATE_ALIASES.MONTHLY_SUMMARY, cadence);
  };

  const handleChangeProjectInvitesOption = (checked: boolean) => {
    const cadence = checked
      ? PROJECT_INVITES_TOGGLE.CHECKED
      : PROJECT_INVITES_TOGGLE.UNCHECKED;
    updatePreference(TEMPLATE_ALIASES.PROJECT_INVITES, cadence);
  };

  const handleChangeProjectSummaryOption = (checked: boolean) => {
    const cadence = checked
      ? PROJECT_SUMMARY_TOGGLE.CHECKED
      : PROJECT_SUMMARY_TOGGLE.UNCHECKED;
    updatePreference(TEMPLATE_ALIASES.PROJECT_WEEKLY_SUMMARY, cadence);
  };

  const recommendationsChecked =
    settings.recommendations === RECOMMENDATIONS_TOGGLE.CHECKED;
  const monthlySummaryChecked =
    settings.monthly_summary === MONTHLY_SUMMARY_TOGGLE.CHECKED;
  const projectSummaryChecked =
    settings.project_summary === PROJECT_SUMMARY_TOGGLE.CHECKED;
  const projectInvitesChecked =
    settings.project_invites === PROJECT_INVITES_TOGGLE.CHECKED;

  const actionsDisabled = loading || error || updateLoading;

  return (
    <Page data-testid="notification-settings-page">
      <PageHeading heading="Notifications" />

      <div className="flex flex-col gap-y-7">
        <AccessRequired feature={FEATURE_FLAGS.CLOUD_SCORE_ALLOW_ACCESS}>
          <NotificationsGroup
            feature="Cloud Score"
            description="Receive emails with updates to your Mission Cloud Score"
          >
            <AccessRequired
              feature={FEATURE_FLAGS.CLOUD_SCORE_RECOMMENDATIONS_ALLOW_ACCESS}
            >
              <NotificationOption
                category="Recommendations"
                description="Receive notifications when a new recommendation is added to your Cloud Score"
                htmlFor="recommendations"
                action={
                  <Toggle
                    checked={recommendationsChecked}
                    disabled={actionsDisabled}
                    id="recommendations"
                    onChange={handleChangeRecommendationsOption}
                  />
                }
              />
            </AccessRequired>
            <NotificationOption
              category="Monthly Summary"
              description="Receive a monthly notification that summarizes your Cloud Score changes in the previous 30 days"
              htmlFor="monthly-summary"
              action={
                <Toggle
                  checked={monthlySummaryChecked}
                  disabled={actionsDisabled}
                  id="monthly-summary"
                  onChange={handleChangeMonthlySummaryOption}
                />
              }
            />
          </NotificationsGroup>
        </AccessRequired>
        <AccessRequired feature={FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS}>
          <NotificationsGroup
            feature="Engagements"
            description="Receive emails with updates to your projects"
          >
            <AccessRequired feature={FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS}>
              <NotificationOption
                category="Project Invites"
                description="Receive notifications when a new project is shared with you."
                htmlFor="engagements-project-invites"
                action={
                  <Toggle
                    checked={projectInvitesChecked}
                    disabled={actionsDisabled}
                    id="engagements-project-invites"
                    onChange={handleChangeProjectInvitesOption}
                  />
                }
              />
              <NotificationOption
                category="Weekly Summary"
                description="Receive a weekly notification that summarizes your project changes in the previous 7 days"
                htmlFor="engagements-weekly-summary"
                action={
                  <Toggle
                    checked={projectSummaryChecked}
                    disabled={actionsDisabled}
                    id="engagements-weekly-sumamry"
                    onChange={handleChangeProjectSummaryOption}
                  />
                }
              />
            </AccessRequired>

            {/* todo: uncomment when project-based notifications are supported */}
            {/* <NotificationOptionMultiple
              category="Weekly Summary"
              description="Receive a weekly notification that summarizes your project changes in the previous 7 days"
              htmlFor="engagments-weekly-summary"
              handleSubscribeAll={handleSubscribeAllProjectSummary}
              handleUnsubscribeAll={handleUnsubscribeAllProjectSummary}
            >
              {engagements.map((engagement) => (
                <NotificationOption
                  key={engagement.id}
                  category={engagement.title}
                  htmlFor={`engagement-${engagement.id}`}
                  action={
                    <Toggle
                      checked={projectSummaryChecked}
                      disabled={actionsDisabled}
                      id={`engagement-${engagement.id}`}
                      onChange={handleChangeProjectSummaryOption}
                    />
                  }
                />
              ))}
            </NotificationOptionMultiple> */}
          </NotificationsGroup>
        </AccessRequired>
      </div>
    </Page>
  );
};
