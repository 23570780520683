import PT from "prop-types";

import { Button, Confirm } from "@m/ui";

import { useUpdateRole } from "../api";
import { MESSAGES } from "../constants";

export const UpdateRoleModal = ({ onClose, open, role, user }) => {
  const [updateRole] = useUpdateRole(user, role);

  const handleConfirm = () => {
    updateRole();
    onClose();
  };

  return (
    <Confirm
      open={open}
      onClose={onClose}
      title={MESSAGES.UPDATE_USER_CONFIRMATION(role, user.name)}
      actions={[
        <Button key="confirm-button" kind="primary" onClick={handleConfirm}>
          Confirm
        </Button>,
        <Button
          key="cancel-button"
          fill="none"
          kind="primary"
          onClick={onClose}
        >
          Cancel
        </Button>,
      ]}
    />
  );
};

UpdateRoleModal.propTypes = {
  onClose: PT.func.isRequired,
  open: PT.bool.isRequired,
  role: PT.string,
  user: PT.object.isRequired,
};
