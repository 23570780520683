import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_CUSTOMER_CONNECTIONS } from "./useCustomerConnections";
import { GET_UNCLAIMED_CONNECTIONS } from "./useUnclaimedConnections";

export const REMOVE_CONNECTION_FAILURE =
  "We encountered a problem removing this git connection.";

export const REMOVE_CONNECTION_CONFIRMATION =
  "Are you sure you want to remove this git connection?";

export const REMOVE_CONNECTION = gql`
  mutation useRemoveConnection($input: ReviewGitHubAppInstallationInput!) {
    reviewGitHubAppInstallation(input: $input) {
      ok
      message
    }
  }
`;

export const useRemoveConnection = () => {
  const onError = () => {
    toast.error(REMOVE_CONNECTION_FAILURE);
  };

  const [mutate, { loading }] = useMutation(REMOVE_CONNECTION, {
    context: { clientName: "v4" },
    onError,
    refetchQueries: [
      { query: GET_CUSTOMER_CONNECTIONS },
      { query: GET_UNCLAIMED_CONNECTIONS },
    ],
  });

  const removeConnection = (connection) => {
    if (window.confirm(REMOVE_CONNECTION_CONFIRMATION)) {
      mutate({
        variables: {
          input: {
            installationId: connection.id,
            isValid: false,
          },
        },
      });
    }
  };

  return [removeConnection, { loading }];
};
