import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

import { Banner } from "@m/ui";

interface ResolutionNotesActivityProps {
  notes: string;
}

export function ResolutionNotesActivity({
  notes,
}: ResolutionNotesActivityProps) {
  return (
    <div className="mb-4">
      <Banner
        title="Resolution Notes"
        label={notes}
        icon={ClipboardDocumentListIcon}
      />
    </div>
  );
}
