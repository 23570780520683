import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const LAMBDA_LABEL = AWS_RESOURCES_LABELS.lambdaFunctions;

export const TABLE_HEADERS_LAMBDA_FUNCTIONS = [
  { label: "Function Name", accessor: "functionName", sort: "NAME" },
  { label: "Account Name", accessor: "accountName", sort: "AWS_ACCOUNT__NAME" },
  { label: "Account ID", accessor: "accountId", sort: "AWS_ACCOUNT__ID" },
  { label: "Runtime", accessor: "runtime", sort: "RUNTIME" },
  { label: "Handler", accessor: "handler", sort: "HANDLER" },
  { label: "Memory Size", accessor: "memorySize", sort: "MEMORY" },
  { label: "Region", accessor: "region", sort: "REGION" },
  { label: "Code Size", accessor: "codeSize", sort: "CODE_SIZE" },
  { label: "Tags", accessor: "tags", sort: "TAGS" },
];
