import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { LambdaFunctionFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { GET_RESOURCES_FAILURE, LAMBDA_LABEL } from "../constants";

export const GET_LAMBDA_FUNCTIONS = gql(/* GraphQL */ `
  query LambdaFunctions(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $searchTerm: String
    $monthToDate: Boolean
    $sort: [LambdaFunctionSortEnum]
    $where: LambdaFunctionFilter
  ) {
    lambdaFunctions(
      first: $first
      after: $after
      last: $last
      before: $before
      searchTerm: $searchTerm
      monthToDate: $monthToDate
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          codeSize
          handler
          memorySize: memory
          functionName: name
          region
          runtime
          tags
        }
      }
    }
  }
`);

export const useLambdaFunctions = (query) => {
  const where: LambdaFunctionFilter = {};

  if (query.accounts) where.accountIn = query.accounts;
  if (query.runtimes) where.runtimeIn = query.runtimes;
  if (query.handlers) where.handlerIn = query.handlers;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(LAMBDA_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_LAMBDA_FUNCTIONS,
    {
      variables: {
        sort: query.sort,
        ...INITIAL_PAGINATION,
        searchTerm: query.search,
        where,
      },
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.lambdaFunctions || {};
  const lambdaFunctions = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { lambdaFunctions },
    error,
    loading,
    pagination,
    refetch,
  };
};
