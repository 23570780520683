import PT from "prop-types";

import { Button, Confirm } from "@m/ui";

import { useDeleteScorecardRecommendation } from "../api";

export const DeleteRecommendationDialog = ({
  id,
  onClose,
  onCompleted,
  open,
  title,
}) => {
  const [deleteScorecardRecommendation] = useDeleteScorecardRecommendation();

  const handleDelete = () => {
    deleteScorecardRecommendation(id);
    onCompleted();
  };

  return (
    <Confirm
      onClose={onClose}
      title="Delete Recommendation?"
      aria-label="Delete Recommendation Dialog"
      open={open}
      actions={[
        <Button key="delete-button" kind="destructive" onClick={handleDelete}>
          Delete
        </Button>,
        <Button key="cancel-button" fill="none" onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <p>
        <span
          className="font-semibold text-default"
          aria-label="Recommendation Title"
        >
          {`${title} `}
        </span>
        and its content will be removed.
      </p>
    </Confirm>
  );
};

DeleteRecommendationDialog.propTypes = {
  id: PT.string.isRequired,
  onClose: PT.func.isRequired,
  onCompleted: PT.func.isRequired,
  open: PT.bool.isRequired,
  title: PT.string.isRequired,
};
