import { AuthContextRef } from "@m/login";

import { showDialog } from "@mc/app/components/GlobalDialog";
import { cacheConfig } from "@mc/constants";
import { MaintenanceDialog } from "@mc/features/MaintenanceDialog";

const logout = () => {
  if (AuthContextRef?.current) AuthContextRef.current.logout(true);
};

const onMaintenanceResponse = () => {
  showDialog(MaintenanceDialog);
};

export const apiProps = {
  cacheConfig,
  errorOptions: { logout, onMaintenanceResponse },
};

export * from "@m/api";
