import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES_ENGAGEMENTS_WORK_STATUS } from "../constants";

import { GET_ENGAGEMENT_STATUS } from "./useEngagementStatus";

export const UPDATE_ENGAGEMENT_WORK_STATUS = gql`
  mutation UpdateEngagementWorkStatus($input: UpdateWorkStatusInput!) {
    updateWorkStatus(input: $input) {
      ok
      message
    }
  }
`;

export interface IUpdateWorkStatusInput {
  dueDate?: string;
  engagementId: string;
  id: string;
  percentageComplete: number;
  startDate?: string;
  status?: string;
  tracked: number;
}

export const useUpdateEngagementWorkStatus = () => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_WORK_STATUS.UPDATE_WORK_STATUS_SUCCESS);
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_WORK_STATUS.UPDATE_WORK_STATUS_ERROR);

  const [mutate, mutationResult] = useMutation(UPDATE_ENGAGEMENT_WORK_STATUS, {
    onCompleted,
    onError,
  });

  const updateEngagementWorkStatus = (input: IUpdateWorkStatusInput) => {
    return mutate({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: GET_ENGAGEMENT_STATUS,
          variables: { id: input.engagementId },
        },
      ],
    });
  };

  return [updateEngagementWorkStatus, mutationResult] as const;
};
