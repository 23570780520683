import { useMatch as useBaseMatch } from "react-router-dom";

import { createCompanyPath } from "@mc/utils/companyPath";

/**
 * Custom useMatch hook that prefixes strings passed into the `pattern`
 * arg with `/:companyId`.
 */
export const useMatch = (pattern: string) => {
  return useBaseMatch(createCompanyPath(pattern));
};
