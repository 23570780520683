import { ChangeEvent } from "react";

import {
  EngagementWorkStatus,
  EngagementWorkStatusType,
} from "@m/api/public/types";
import { Field, Fields, Input, Select, VALIDATION_MESSAGES } from "@m/ui";
import { formatFullDate, fromSnakeCaseToProperCase } from "@m/utils";

import { REGEX_PATTERNS } from "../constants";
import { getTrackedTime } from "../utils";

export interface WorkStatusFormProps {
  workStatus: EngagementWorkStatus | undefined;
  onUpdate: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const WorkStatusForm = ({
  workStatus,
  onUpdate,
}: WorkStatusFormProps) => {
  const { startDate, dueDate, status, percentageComplete } = workStatus;

  const formattedStartDate = formatFullDate(startDate, "yyyy-MM-dd");
  const formattedDueDate = formatFullDate(dueDate, "yyyy-MM-dd");
  const formattedTrackedTime = getTrackedTime(startDate);

  return (
    <Fields>
      <Field label="Overall Status" htmlFor="status" row="1/3">
        <Select
          id="status"
          name="status"
          value={status}
          onChange={onUpdate}
          options={[
            EngagementWorkStatusType.AheadOfSchedule,
            EngagementWorkStatusType.BehindSchedule,
            EngagementWorkStatusType.OnSchedule,
          ].map((option) => (
            <option key={option} value={option}>
              {fromSnakeCaseToProperCase(option)}
            </option>
          ))}
        />
      </Field>
      <Field label="Percentage Complete" htmlFor="percentageComplete" row="1/3">
        <Input
          className="text-right"
          rightAddon={<>&#37;</>}
          id="percentageComplete"
          name="percentageComplete"
          onChange={onUpdate}
          pattern={REGEX_PATTERNS.PERCENT}
          required={true}
          title={VALIDATION_MESSAGES.INVALID_PERCENTAGE_FORMAT}
          type="number"
          value={percentageComplete}
        />
      </Field>
      <Field label="Start Date" htmlFor="startDate" row="1/3">
        <Input
          id="startDate"
          name="startDate"
          onChange={onUpdate}
          required={true}
          type="date"
          value={formattedStartDate}
        />
      </Field>
      <Field label="Due Date" htmlFor="dueDate" row="1/3">
        <Input
          id="dueDate"
          name="dueDate"
          onChange={onUpdate}
          required={true}
          type="date"
          value={formattedDueDate}
        />
      </Field>
      <div className="flex flex-row justify-between">
        <div className="text-sm font-semibold">Tracked Time</div>
        <div className="p-1">{formattedTrackedTime}</div>
      </div>
    </Fields>
  );
};
