import { gql, useQuery } from "@mc/utils/graphql";

export const FLAGGED_RECOMMENDATION_RESOURCE_IDS_QUERY = gql`
  query useFlaggedRecommendationResourceIds($id: ID!) {
    scorecardRecommendationDetails(id: $id) {
      id
      flaggedResources {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const useRecommendationResourceIds = (id) => {
  const { data, loading, error } = useQuery(
    FLAGGED_RECOMMENDATION_RESOURCE_IDS_QUERY,
    { variables: { id } }
  );

  const recommendation = data?.scorecardRecommendationDetails;
  const resourceIds =
    recommendation?.flaggedResources?.edges?.map((edge) => edge.node.id) || [];

  return {
    data: { resourceIds },
    loading,
    error,
  };
};
