import { Button } from "@m/ui";

import { EmptyState } from "@mc/components/EmptyState";
import { AWS_MARKETPLACE_URLS } from "@mc/constants";

export const MarketplaceSubscriptionsEmpty = () => (
  <EmptyState
    title="There are no subscriptions on record."
    description="You don't have any active subscriptions, you can subscribe to Mission products through the AWS Marketplace."
    actions={
      <Button
        kind="primary"
        target="_blank"
        to={AWS_MARKETPLACE_URLS.MISSION_SELLER_PROFILE}
      >
        Go to Marketplace
      </Button>
    }
  />
);
