import { toast } from "@m/ui";
import { dt } from "@m/utils";

import { gql, useQuery } from "@mc/utils/graphql";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_SCORECARD_TREND_DAILY = gql`
  query useScorecardTrendDaily($where: ScorecardFilter) {
    scorecards(where: $where) {
      edges {
        node {
          id
          created
          score
          summaries {
            edges {
              node {
                id
                name
                score
                pillar
                resolvedRecommendationsCount
              }
            }
          }
        }
      }
    }
  }
`;

export const useScorecardTrendDaily = ({ days }) => {
  const date = dt.now().minus({ days }).endOf("day").toISO();

  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.GET_SCORECARD_TREND_DAILY_ERROR);

  const { data, loading, error } = useQuery(GET_SCORECARD_TREND_DAILY, {
    variables: {
      where: { createdGt: date },
    },
    onError,
  });

  const scorecards = data?.scorecards?.edges?.map((edge) => edge.node) || [];
  const dailyScoreResults = [];

  for (const scorecard of scorecards) {
    const summaries = scorecard.summaries.edges.map((edge) => edge.node);
    for (const summary of summaries) {
      dailyScoreResults.push({ created: scorecard.created, ...summary });
    }
  }

  return {
    data: { dailyScoreResults },
    loading,
    error,
  };
};
