import { SidebarGroup, SidebarLink } from "@mc/components/Sidebar/components";
import { PATHS } from "@mc/constants";

export const MissionAdminNavigation = () => {
  return (
    <nav className="flex flex-col gap-3">
      <SidebarGroup>
        <SidebarLink title="Invitations" to={PATHS.STAFF_INVITATIONS} />
        <SidebarLink title="Reports" to={PATHS.STAFF_REPORTS} />
        <SidebarLink title="My Team" to={PATHS.STAFF_TEAM} />
        <SidebarLink title="Git Repositories" to={PATHS.STAFF_GIT} />
      </SidebarGroup>
    </nav>
  );
};
