import clsx from "clsx";

import { Tooltip } from "@m/ui";

import { getSegmentsWithCommandsAndOffsets } from "../utils";

import { MissionCloudScoreText } from "./MissionCloudScoreText";
import { ScoreGaugeLinear } from "./ScoreGaugeLinear";

interface Props {
  criticalChecks: number;
  okChecks: number;
  score: number;
  totalChecks: number;
  warningChecks: number;
}

export const RadialCloudScoreGauge = ({
  criticalChecks = 0,
  okChecks = 0,
  score = 0,
  totalChecks = 0,
  warningChecks = 0,
}: Props) => {
  if (score === 0) return null;

  const gap = totalChecks * 0.03;
  const denominator = totalChecks + gap * 2;

  const segments: RadialCloudScoreGaugeSegment[] = [
    {
      percent: (okChecks / denominator) * 100,
      className: "stroke-status-good",
    },
    {
      percent: (gap / denominator) * 100,
      className: "stroke-transparent",
    },
    {
      percent: (warningChecks / denominator) * 100,
      className: "stroke-status-warning",
    },
    {
      percent: (gap / denominator) * 100,
      className: "stroke-transparent",
    },
    {
      percent: (criticalChecks / denominator) * 100,
      className: "stroke-status-error",
    },
  ];

  const radius = 15;
  const strokeWidth = 1;

  const svgHeight = radius + strokeWidth;
  const svgWidth = radius * 2 + strokeWidth;

  return (
    <div data-testid="radial-score-gauge" className="grid">
      <svg
        viewBox={`0 ${-radius / 2} ${svgWidth} ${svgHeight}`}
        className={clsx(
          "hidden lg:block",
          "mx-auto h-auto w-[425px]",
          "col-start-1 col-end-1 row-start-1 row-end-1"
        )}
      >
        {getSegmentsWithCommandsAndOffsets(
          segments,
          radius,
          svgWidth,
          svgHeight
        ).map((segment: RadialCloudScoreGaugeSegment, i) => (
          <path
            key={i}
            className={clsx("origin-center", segment.className)}
            d={segment.commands}
            fill="none"
            strokeLinecap="round"
            strokeWidth={strokeWidth}
            transform={`rotate(${segment.offset})`}
          />
        ))}
      </svg>

      <div
        className={clsx(
          "text-center",
          "col-start-1 col-end-1 row-start-1 row-end-1",
          "flex flex-col justify-center"
        )}
      >
        <div className="mb-3 text-6xl font-medium text-default">
          {score.toString()}
        </div>

        <div className="mb-1 text-default">
          <MissionCloudScoreText score={score} text="Your AWS environment" />
        </div>

        <Tooltip
          content="Your score is calculated on a scale of 0-10 and is based on check results from each category."
          side="bottom"
          asChild
        >
          <button className="text-xs font-thin text-subdued underline">
            How is this score calculated?
          </button>
        </Tooltip>

        <div className="mt-3 lg:hidden">
          <ScoreGaugeLinear
            criticalChecks={criticalChecks}
            okChecks={okChecks}
            warningChecks={warningChecks}
            totalChecks={totalChecks}
            height="large"
          />
        </div>
      </div>
    </div>
  );
};

export type RadialCloudScoreGaugeSegment = {
  percent: number;
  className: string;
  commands?: string;
  offset?: number;
};
