import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

export const GET_CUSTOMER_CONNECTIONS_FAILURE =
  "We encountered a problem fetching git connections for this company.";

export const GET_CUSTOMER_CONNECTIONS = gql`
  query useCustomerConnections($companyId: ID) {
    githubInstallations(companyId: $companyId) {
      edges {
        node {
          id
          company {
            id
            name
          }
          repositories {
            edges {
              node {
                id
                fullName
                private
              }
            }
          }
          createdAt
          installedByLogin
          verifiedAt
          verifiedBy {
            id
            name
          }
        }
      }
    }
  }
`;

export const useCustomerConnections = (companyId) => {
  const onError = () => toast.error(GET_CUSTOMER_CONNECTIONS_FAILURE);

  const { loading, data } = useQuery(GET_CUSTOMER_CONNECTIONS, {
    context: { clientName: "v4" },
    variables: { companyId },
    onError,
  });

  const customerConnections =
    data?.githubInstallations?.edges.map((edge) => edge.node) || [];

  return {
    data: { customerConnections },
    loading,
  };
};
