import { useMemo } from "react";

import { useAuth } from "@m/login";
import { Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";

import { sortNameAsc } from "@mc/utils/sort";

import { useTeamMembers } from "../api";
import { MESSAGES_MY_TEAM } from "../constants";

import { MyTeamListItem } from "./MyTeamListItem";

export const MyTeamWidget = () => {
  const {
    data: { teamMembers },
    loading,
    error,
  } = useTeamMembers();

  const {
    user: { company },
  } = useAuth();

  const empty = !loading && (teamMembers.length < 1 || error);
  const ready = !loading && !empty;

  const listItems = useMemo(() => {
    return Array.from(teamMembers)
      .sort((a, b) => sortNameAsc(a.account, b.account))
      .map((teamMember) => (
        <MyTeamListItem key={teamMember.id} teamMember={teamMember} />
      ));
  }, [teamMembers]);

  return (
    <Card
      title="My Team"
      data-testid="my-team-widget"
      className="flex !h-full flex-col"
    >
      <div className="my-2 flex h-[8rem] grow flex-col gap-1 overflow-auto px-3">
        {ready && listItems}

        {empty && (
          <div className="m-auto flex flex-col space-y-2 px-5 text-center text-sm text-default">
            <div className="font-semibold">
              {MESSAGES_MY_TEAM.GET_TEAM_MEMBERS_EMPTY(company.name)}
            </div>
            <div>{MESSAGES_MY_TEAM.GET_TEAM_MEMBERS_EMPTY_EXTRA}</div>
          </div>
        )}

        {loading && <Spinner className="m-auto" />}
      </div>
    </Card>
  );
};
