import {
  ChevronDoubleUpIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

import {
  EngagementDecisionImpact,
  RiskLogItemImpact,
  RiskLogItemProbability,
} from "@m/api/public/types";
import { Tooltip } from "@m/ui";
import { toProperCase } from "@m/utils";

import { DETAIL_ITEM_IMPACT } from "../constants";

export const EngagementDetailLevelIndicator = ({
  itemLevelType,
  level,
}: {
  itemLevelType: "Probability" | "Impact";
  level:
    | RiskLogItemProbability
    | RiskLogItemImpact
    | EngagementDecisionImpact
    | DETAIL_ITEM_IMPACT;
}) => {
  const levelIconMap = {
    LOW: (
      <ChevronUpIcon className={"h-2.5 w-2.5 cursor-help text-status-good"} />
    ),
    MEDIUM: (
      <ChevronDoubleUpIcon className="h-2.5 w-2.5 cursor-help text-status-warning" />
    ),
    HIGH: (
      // Heroicons doesn't have a triple chevron icon so we'll need to fake it
      <div className="relative mr-2.5 cursor-help pt-3">
        <div className="absolute bottom-0">
          <ChevronUpIcon className="h-2.5 w-2.5 text-status-error" />
        </div>
        <div className="absolute bottom-0.5">
          <ChevronUpIcon className="h-2.5 w-2.5 text-status-error" />
        </div>
        <div className="absolute bottom-1">
          <ChevronUpIcon className="h-2.5 w-2.5 text-status-error" />
        </div>
      </div>
    ),
  };

  return (
    <Tooltip content={`${itemLevelType}: ${toProperCase(level)}`}>
      <div>{levelIconMap[level]}</div>
    </Tooltip>
  );
};
