import { CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import { EmptyCircleIcon } from "@mc/icons";

interface Props {
  checked: boolean;
  readOnly?: boolean;
  className?: string;
  size?: "small" | "default";
  [key: string]: any;
}

export const CircleCheckbox = ({
  checked,
  readOnly,
  className,
  size = "default",
  ...props
}: Props) => {
  const classes = clsx(className, {
    "text-action": checked && !readOnly,
    "text-accent": !checked || readOnly,
  });

  const Icon = checked ? CheckCircleIcon : EmptyCircleIcon;

  return (
    <span
      aria-checked={checked}
      className={classes}
      data-component="CircleCheckbox"
      role="checkbox"
      {...props}
    >
      <Icon
        className={clsx("stroke-1", {
          "h-2 w-2": size === "small",
          "h-3 w-3": size === "default",
        })}
      />
    </span>
  );
};
