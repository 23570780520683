import { gql, useLazyQuery } from "@m/api";

export const VALIDATE_TRUSTED_ADVISOR_ACCESS = gql`
  query validateTrustedAdvisorAccess(
    $awsAccountId: String!
    $externalId: String!
  ) {
    validateTrustedAdvisorAccess(
      awsAccountId: $awsAccountId
      externalId: $externalId
    )
  }
`;

export const useValidateTrustedAdvisorAccess = (
  awsAccountId: string,
  iamExternalId: string
) => {
  const [validate, { data, ...result }] = useLazyQuery(
    VALIDATE_TRUSTED_ADVISOR_ACCESS,
    {
      variables: { awsAccountId, externalId: iamExternalId },
      fetchPolicy: "no-cache",
    }
  );

  const trustedAdvisorAccessValidated =
    data?.validateTrustedAdvisorAccess || false;

  return [
    validate,
    { data: trustedAdvisorAccessValidated, ...result },
  ] as const;
};
