import clsx from "clsx";
import { ReactNode } from "react";

import { Backdrop } from "@m/ui";
import { useEscapeListener } from "@m/utils/hooks";

import { OverlayFooter, OverlayHeader } from "./components";

interface Props {
  actions?: ReactNode[];
  buttons?: ReactNode[];
  children?: ReactNode;
  className?: string;
  onClose: () => void;
  open: boolean;
  title: ReactNode;
  [key: string]: any;
}

export const Overlay = ({
  actions = [],
  buttons = [],
  children,
  className,
  onClose,
  open,
  title,
  ...props
}: Props) => {
  useEscapeListener(onClose);

  if (!open) return null;

  return (
    <>
      <Backdrop onMouseDown={onClose} />
      <div
        data-testid="overlay"
        className={clsx(
          "rounded-lg border bg-white",
          "fixed bottom-2 left-2 right-2 top-2 z-20",
          "flex flex-col justify-between",
          className
        )}
        {...props}
      >
        <OverlayHeader title={title} actions={actions} onClose={onClose} />

        <div className="grow overflow-y-auto">{children}</div>

        {buttons.length > 0 && <OverlayFooter buttons={buttons} />}
      </div>
    </>
  );
};
