import { dt, toHyphenLowercase } from "@m/utils";

import { SCORECARD_DETAILS } from "../constants";

const getTrendLines = ({ scoreResults, pillarId, getISODate, getScore }) => {
  const allTrendLines = Object.values(SCORECARD_DETAILS).map(
    ({ name, pillar }) => ({
      id: name,
      pillar,
      data: [],
    })
  );

  scoreResults.forEach((scoreResult) => {
    const { pillar } = scoreResult;
    const isoDate = getISODate(scoreResult);
    const score = getScore(scoreResult);

    allTrendLines
      .find((line) => line.pillar === pillar)
      .data.push({
        x: isoDate,
        y: score,
      });
  });

  const activeTrendLine = allTrendLines.find(
    (series) => toHyphenLowercase(series.id) === pillarId
  );

  return { activeTrendLine, allTrendLines };
};

export const getDailyTrendLines = (dailyScoreResults, pillarId) => {
  return getTrendLines({
    scoreResults: dailyScoreResults,
    pillarId,
    getISODate: ({ created }) => dt.fromISO(created).toISODate(),
    getScore: ({ score }) => score,
  });
};

export const getWeeklyTrendLines = (averageWeeklyScoreResults, pillarId) => {
  return getTrendLines({
    scoreResults: averageWeeklyScoreResults,
    pillarId,
    getISODate: ({ week, year }) =>
      dt.fromObject({ weekNumber: week, weekYear: year }).toISOWeekDate(),
    getScore: ({ averageScore }) => averageScore,
  });
};

export const getMonthlyTrendLines = (averageMonthlyScoreResults, pillarId) => {
  return getTrendLines({
    scoreResults: averageMonthlyScoreResults,
    pillarId,
    getISODate: ({ month, year }) => dt.fromObject({ month, year }).toISODate(),
    getScore: ({ averageScore }) => averageScore,
  });
};
