import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

interface Props extends ComponentPropsWithoutRef<"div"> {
  align?: "left" | "right";
}

export const Buttons = ({ align = "left", className, ...props }: Props) => {
  const classes = clsx(className, "flex items-center gap-2", {
    "flex-row": align === "left",
    "flex-row-reverse": align === "right",
  });
  return <div data-component="Buttons" className={classes} {...props} />;
};
