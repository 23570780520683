import PT from "prop-types";

export const TABLE_PROPTYPES = {
  // Base Props
  ariaLabel: PT.string,
  emptyMessage: PT.string,
  headers: PT.array.isRequired,
  headerFontSize: PT.oneOf(["default", "small"]),
  id: PT.string,
  loading: PT.bool,
  rows: PT.array.isRequired,
  showIcons: PT.bool,
  showHeader: PT.bool,
  wrapRowText: PT.bool,

  // Sorting Props
  defaultSort: PT.string,
  onSortChange: PT.func,

  // Filter Props
  clearFilters: PT.func,
  clearFiltersButtonText: PT.string,
  hasActiveFilters: PT.bool,

  // Pagination Props
  currentPage: PT.number,
  fetchMore: PT.func,
  pageInfo: PT.object,
  pageSize: PT.number,
  setCurrentPage: PT.func,
  showPagination: PT.bool,
  totalCount: PT.number,

  // Row Select Props
  isAllRowsSelected: PT.bool,
  manualRowIdKey: PT.string,
  selectedRowIds: PT.array,
  showSelectAll: PT.bool,
  toggleAllRowsSelected: PT.func,
  toggleAllRowsSelectedTitle: PT.string,
  toggleRowSelected: PT.func,
  toggleRowSelectedTitle: PT.string,
};
