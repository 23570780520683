export const removeZeroes = (obj: object) => {
  // Create a new object
  let newObj = {};

  // Loop over the object
  for (let key in obj) {
    // If the value of the key is not 0, copy it to the new object
    if (obj[key] !== 0) {
      newObj[key] = obj[key];
    }
  }
  // Return the new object
  return newObj;
};
