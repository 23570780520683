import { gql, useQuery } from "@apollo/client";

import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS_STATUS } from "../constants";

export const GET_ENGAGEMENT_STATUS = gql`
  query EngagementStatus($id: ID!) {
    engagement(id: $id) {
      id
      __typename
      workStatus {
        updateTime
        dueDate
        engagementId
        id
        percentageComplete
        startDate
        status
      }
      budgetStatus {
        budgetSpent
        changeOrders
        engagementId
        estimateAtCompletion
        id
        originalSof
        remainingBudget
        status
        totalBudget
        totalCreditsApplied
      }
    }
  }
`;

export const useEngagementStatus = (engagementId: string) => {
  const onError = () => toast.error(MESSAGES_ENGAGEMENTS_STATUS.GET_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENT_STATUS, {
    variables: {
      id: engagementId,
    },
    onError,
  });

  const { budgetStatus, workStatus } = data?.engagement || {};

  return { data: { budgetStatus, workStatus }, loading };
};
