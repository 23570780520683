const JCB = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="42"
      viewBox="0 0 65 42"
      fill="none"
    >
      <path
        d="M52.6867 30.3467C52.6867 33.8424 49.8759 36.6769 46.4069 36.6769H12.3127V11.655C12.3127 8.15843 15.1236 5.32385 18.5931 5.32385H52.6864L52.6863 30.3466L52.6867 30.3467Z"
        fill="white"
      />
      <path
        d="M41.5718 21.5494C42.5456 21.5707 43.525 21.506 44.4949 21.583C45.4771 21.7677 45.7139 23.2666 44.8412 23.7575C44.2458 24.0809 43.5384 23.8779 42.8929 23.935H41.5718V21.5494ZM45.0579 18.8492C45.2743 19.619 44.5381 20.3101 43.8026 20.2041H41.5718C41.5874 19.4782 41.5412 18.6903 41.5944 18.0026C42.4881 18.0279 43.39 17.9508 44.2785 18.0429C44.6603 18.1396 44.9798 18.4559 45.0579 18.8492ZM50.4267 7.43359C50.4682 8.90367 50.4327 10.4515 50.4446 11.9514C50.4419 18.0494 50.4504 24.1473 50.4399 30.2457C50.4007 32.5311 48.3914 34.517 46.1398 34.5626C43.886 34.5718 41.6319 34.564 39.3779 34.5665V25.3475C41.8338 25.3347 44.2909 25.3735 46.7459 25.3282C47.8847 25.2561 49.1321 24.4987 49.1851 23.2354C49.3192 21.9667 48.1325 21.0891 47.0059 20.9505C46.5728 20.9392 46.5854 20.8233 47.0059 20.7727C48.0804 20.5386 48.9243 19.4175 48.6081 18.2948C48.3387 17.1139 47.0439 16.6569 45.9669 16.6591C43.7709 16.6441 41.5744 16.657 39.3784 16.6527C39.3925 14.9316 39.3488 13.2087 39.4019 11.4889C39.5759 9.24471 41.6359 7.39402 43.8558 7.43417C46.0464 7.43384 48.2366 7.43384 50.4267 7.43375V7.43359Z"
        fill="url(#paint0_linear_49_1375)"
      />
      <path
        d="M14.5616 11.7213C14.6177 9.43956 16.6356 7.47001 18.8843 7.43666C21.1297 7.42969 23.3754 7.43565 25.6208 7.43364C25.6147 15.068 25.633 22.7032 25.6116 30.3371C25.5251 32.5911 23.5292 34.5231 21.305 34.563C19.0553 34.5713 16.8054 34.5642 14.5557 34.5665V25.0367C16.7409 25.5571 19.0325 25.7786 21.2618 25.4334C22.5944 25.2173 24.0524 24.5576 24.5036 23.1641C24.8356 21.972 24.6487 20.7175 24.6981 19.494V16.6527H20.84C20.8227 18.5319 20.8755 20.4143 20.8121 22.2912C20.7081 23.4449 19.5747 24.1779 18.4953 24.1387C17.1566 24.153 14.5038 23.1608 14.5038 23.1608C14.4972 19.64 14.5427 15.2308 14.5616 11.7217V11.7213Z"
        fill="url(#paint1_linear_49_1375)"
      />
      <path
        d="M27.0601 17.7988C26.857 17.8422 27.0193 17.1015 26.9673 16.8205C26.9812 15.0439 26.9384 13.2654 26.9909 11.49C27.1644 9.23639 29.2402 7.38109 31.4691 7.43384H38.0331C38.0269 15.0682 38.0452 22.7034 38.0238 30.3373C37.9373 32.5913 35.9412 34.5233 33.7169 34.5632C31.4672 34.5718 29.2172 34.5646 26.9673 34.5668V24.1256C28.5038 25.3964 30.5923 25.5942 32.5066 25.5977C33.9498 25.5972 35.3845 25.3729 36.786 25.0374V23.1245C35.2066 23.918 33.3499 24.422 31.5991 23.9661C30.3778 23.6597 29.4912 22.4699 29.5109 21.1994C29.3694 19.8782 30.1378 18.4833 31.4258 18.0905C33.0251 17.5858 34.7681 17.9718 36.2672 18.6279C36.5883 18.7974 36.9143 19.0077 36.7859 18.4666V16.963C34.2788 16.3618 31.6106 16.1405 29.0918 16.7946C28.3627 17.0019 27.6523 17.3164 27.0601 17.7989V17.7988Z"
        fill="url(#paint2_linear_49_1375)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_49_1375"
          x1="39.3023"
          y1="20.4199"
          x2="50.3748"
          y2="20.4199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007B40" />
          <stop offset="1" stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_49_1375"
          x1="14.3202"
          y1="21.2599"
          x2="25.4394"
          y2="21.2599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D2970" />
          <stop offset="1" stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_49_1375"
          x1="26.951"
          y1="20.683"
          x2="38.031"
          y2="20.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E2B2F" />
          <stop offset="1" stopColor="#E30138" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JCB;
