import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const EC2_LABEL = AWS_RESOURCES_LABELS.ec2Instances;

export const TABLE_HEADERS_EC2_INSTANCES = [
  { label: " ", accessor: "newrelic" },
  { label: "Name", sort: "NAME" },
  { label: "ID", accessor: "instanceId", sort: "ID" },
  { label: "Account Name", accessor: "accountName", sort: "AWS_ACCOUNT__NAME" },
  { label: "Account ID", accessor: "account", sort: "ACCOUNT" },
  { label: "Type", accessor: "instanceType", sort: "INSTANCE_TYPE" },
  { label: "Zone", accessor: "availabilityZone", sort: "AVAILABILITY_ZONE" },
  { label: "Public IP", accessor: "publicIp", sort: "PUBLIC_IP" },
  { label: "Private IP", accessor: "privateIp", sort: "PRIVATE_IP" },
  { label: "Platform", sort: "PLATFORM" },
  { label: "VPC", accessor: "vpcId", sort: "VPC_ID" },
  { label: "State", sort: "STATE" },
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  {
    label: "Monitoring",
    accessor: "monitoringPolicy",
    sort: "MONITORING_POLICY",
  },
  { label: "Backup", accessor: "backupPolicy", sort: "BACKUP_POLICY" },
  { label: "Patching", accessor: "patchingPolicy", sort: "PATCHING_POLICY" },
  { label: "Tags", sort: "TAGS" },
];
