import * as Sentry from "@sentry/react";

export const SentryReportDialog = () => {
  try {
    const cachedUser = localStorage.getItem("user");
    const user = JSON.parse(cachedUser);
    const params = {
      user: {
        name: user?.name,
        email: user?.email,
      },
    };
    Sentry.showReportDialog(params);
  } catch (err) {
    Sentry.showReportDialog({});
  }

  return null;
};
