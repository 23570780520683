import { useQuery } from "@m/api";
import { gql } from "@m/api/public";

export const GET_COMPANY_PRODUCTS_DIMENSIONS = gql(/* GraphQL */ `
  query GetCompanyProductsDimensions {
    userProfile {
      currentCompany {
        entitlements
        companyEntitlements {
          products {
            edges {
              node {
                displayName
              }
            }
          }
        }
        marketplaceRegistrations {
          edges {
            node {
              catalogItem {
                id
                displayName
                dimensions {
                  edges {
                    node {
                      id
                      displayName
                      dimensionTypeId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const useCompanyProductsDimensions = () => {
  const { data, loading, error } = useQuery(GET_COMPANY_PRODUCTS_DIMENSIONS);

  const products =
    data?.userProfile?.currentCompany?.companyEntitlements?.products?.edges.map(
      (edge) => edge?.node?.displayName
    ) || [];
  const dimensions =
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges?.flatMap(
      (edge) =>
        edge?.node?.catalogItem?.dimensions.edges.map((edge) => edge.node)
    ) || [];

  return {
    data: { products, dimensions },
    loading,
    error,
  };
};
