import { CheckIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { CaseCategory, CasePriority, CaseState } from "@m/api/public/types";
import { useAuth } from "@m/login";
import {
  AccountAvatar,
  Avatar,
  Badge,
  Button,
  Spinner,
  TBadgeStatus,
  toast,
} from "@m/ui";
import { fromSnakeCaseToProperCase, toTimeLocaleLong } from "@m/utils";
import { hideIntercom } from "@m/utils/intercom";

import {
  AccessRequired,
  useAccessRequired,
} from "@mc/components/AccessRequired";
import { HTMLContent } from "@mc/components/HTMLContent";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { ReplyTimeline } from "@mc/components/ReplyTimeline";
import { PATHS } from "@mc/constants";
import { DetailsLayout } from "@mc/layouts";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { useCaseDetails, useCloseCase } from "../api";
import { CasePriorityBadge, CaseWaitingOnBanner } from "../components";
import { LiveChatButton } from "../components/LiveChatButton";
import { MESSAGES } from "../constants";
import { getCaseType, isLiveChatEnabled } from "../utils";

export function CaseDetailsPage() {
  const { sysId } = useParams<{ sysId: string }>();
  const navigate = useNavigate();

  const {
    data: { caseDetails, allActivities },
    loading,
    refetch,
  } = useCaseDetails(sysId);
  const { user } = useAuth();
  const location = useLocation();

  const [activities, setActivities] = useState([]);
  const textBoxRef = useRef(null);

  const {
    number,
    shortDescription: title,
    description: caseDescription,
    state,
    priority,
    category,
    waitingOn,
    openedAt,
    openedBy = {},
    assignedTo,
  } = caseDetails || {};

  const isDevOpsDesk = category === CaseCategory.DevopsDesk;

  const crumbs = [
    {
      path: isDevOpsDesk
        ? PATHS.ENGAGEMENTS_ENGINEER_ASSIST
        : PATHS.SUPPORT_CASES,
      name: isDevOpsDesk ? "Engineer Assist" : "Cases",
    },
    {
      path: isDevOpsDesk
        ? PATHS.ENGAGEMENTS_ENGINEER_ASSIST_DETAILS
        : PATHS.SUPPORT_CASE_DETAILS,
      name: number,
    },
  ];

  useEffect(() => {
    return () => {
      hideIntercom();
    };
  }, []);

  useEffect(() => {
    if (activities.length !== allActivities.length) {
      setActivities(allActivities);
    }
  }, [activities, allActivities]);

  useEffect(() => {
    if (!loading && caseDetails === null) {
      toast.error(MESSAGES.CASE_DETAIL_NOT_FOUND);
      navigate(generatePath(PATHS.SUPPORT_CASES));
    }
  }, [caseDetails, navigate, loading]);

  useEffect(() => {
    if (
      !loading &&
      isDevOpsDesk &&
      !location.pathname.includes(PATHS.ENGAGEMENTS_ENGINEER_ASSIST)
    ) {
      navigate(
        generateCompanyPath(
          generatePath(PATHS.ENGAGEMENTS_ENGINEER_ASSIST_DETAILS, {
            sysId,
          }),
          user.company.slug
        )
      );
    }
  }, [loading, isDevOpsDesk, location, sysId, navigate, user]);

  const closeable = state !== CaseState.Closed;

  const liveChatEnabled = !loading && isLiveChatEnabled(category);

  const allowReplies =
    useAccessRequired({ mutation: "createCaseComment" }) && closeable === true;

  return (
    <Page
      data-testid="case-details-page"
      width="full"
      padding={false}
      title={
        <PageTitle
          padding="small"
          breadcrumbs={crumbs}
          actions={
            <div className="flex gap-2">
              {liveChatEnabled && <LiveChatButton />}
              {textBoxRef.current && (
                <Button
                  kind="primary"
                  onClick={() => {
                    textBoxRef.current.focus();
                  }}
                >
                  Reply
                </Button>
              )}
            </div>
          }
        />
      }
    >
      <DetailsLayout
        actions={
          caseDetails && (
            <Sidebar
              assignedTo={assignedTo}
              closeable={closeable}
              priority={priority}
              state={state}
              sysId={sysId}
              category={category}
            />
          )
        }
      >
        {loading && <Spinner className="mx-auto" />}
        {!loading && (
          <div className="flex flex-col gap-3">
            <CaseWaitingOnBanner
              waitingOn={waitingOn}
              textBoxRef={textBoxRef}
            />

            <HTMLContent className="text-2xl font-semibold">
              {title || ""}
            </HTMLContent>

            <HTMLContent className="space-y-2 text-sm">
              {caseDescription || ""}
            </HTMLContent>

            <div className="flex flex-col gap-2">
              {openedBy && openedAt && (
                <div className="mb-1.5 flex justify-between">
                  <div className="flex">
                    <Avatar
                      account={openedBy}
                      className="text-sm font-semibold"
                      includeName
                    />
                  </div>
                  <div className="flex self-end text-right text-xs text-subdued">
                    {toTimeLocaleLong(openedAt)}
                  </div>
                </div>
              )}
              <hr />
            </div>
            {caseDetails && (
              <ReplyTimeline
                activities={activities}
                allowReplies={allowReplies}
                detail={caseDetails}
                textBoxRef={textBoxRef}
                setActivities={setActivities}
                refetch={refetch}
              />
            )}
          </div>
        )}
      </DetailsLayout>
    </Page>
  );
}

interface SidebarProps {
  assignedTo: AccountAvatar;
  closeable: boolean;
  priority: CasePriority;
  state: CaseState;
  sysId: string;
  category: CaseCategory;
}

const Sidebar = ({
  assignedTo,
  closeable,
  priority,
  state,
  sysId,
  category,
}: SidebarProps) => {
  const [closeCase, { loading: closeLoading }] = useCloseCase(sysId);
  const isDevOpsDesk = category === CaseCategory.DevopsDesk;

  const stateStatuses = {
    [CaseState.New]: "positive",
    [CaseState.AwaitingInfo]: "warning",
    [CaseState.Canceled]: "default",
    [CaseState.Cancelled]: "default",
    [CaseState.Closed]: "default",
    [CaseState.Resolved]: "positive",
    [CaseState.OpenAssigned]: "active",
    [CaseState.OpenUnassigned]: "active",
    [CaseState.WorkInProgress]: "active",
  };

  return (
    <>
      {assignedTo && !isDevOpsDesk && (
        <>
          <div className="space-y-1 font-semibold" data-testid="case-assignee">
            <h4 className="text-xs">Assignee</h4>
            <div>
              <Avatar account={assignedTo} className="text-sm" includeName />
            </div>
          </div>

          <hr />
        </>
      )}

      <div className="flex justify-between text-xs">
        <span className="font-semibold">Status</span>
        {state === CaseState.UnknownOrUndefined ? (
          <span className="font-medium text-subdued">&ndash;</span>
        ) : (
          <Badge
            data-testid="case-status-badge"
            label={fromSnakeCaseToProperCase(state)}
            status={stateStatuses[state] as TBadgeStatus}
            size="small"
          />
        )}
      </div>

      {!isDevOpsDesk && (
        <>
          <div className="flex justify-between text-xs">
            <span className="font-semibold">Priority</span>
            <CasePriorityBadge priority={priority} size="small" />
          </div>
          <div
            data-testid="case-category"
            className="flex justify-between text-xs"
          >
            <span className="font-semibold">Type</span>
            <p className="font-medium text-subdued">{getCaseType(category)}</p>
          </div>
        </>
      )}

      {closeable && (
        <AccessRequired mutation="updateCase">
          <hr />
          <Button
            loading={closeLoading}
            onClick={closeCase}
            leftIcon={CheckIcon}
            iconClassName="text-green-500"
          >
            Close Case
          </Button>
        </AccessRequired>
      )}
    </>
  );
};
