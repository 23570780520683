export const SaveIcon = ({
  ariaLabel,
  className = "h-3 w-3",
}: {
  ariaLabel?: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={ariaLabel}
      className={className}
    >
      <path
        d="M18.3125 4.09375L15.2813 1.3125L15.2188 1.28125C15 1.125 14.75 1.03125 14.5313 1.03125H3.71875C2.40625 1.03125 1.34375 2.09375 1.34375 3.40625V17.5312C1.34375 18.8438 2.40625 19.9062 3.71875 19.9062H16.2813C17.5938 19.9062 18.6563 18.8438 18.6563 17.5312V4.90625C18.6563 4.625 18.5625 4.34375 18.3125 4.09375ZM12.4688 2.46875V4.25H5.9375V2.46875H12.4688ZM6.71875 18.5625V12.7812H13.2813V18.5312H6.71875V18.5625ZM17.25 17.5625C17.25 18.0938 16.8125 18.5312 16.2813 18.5312H14.6875V12.5C14.6875 11.9062 14.1875 11.4062 13.5938 11.4062H6.40625C5.8125 11.4062 5.3125 11.9062 5.3125 12.5V18.5625H3.71875C3.1875 18.5625 2.75 18.125 2.75 17.5938V3.4375C2.75 2.90625 3.1875 2.46875 3.71875 2.46875H4.53125V4.5625C4.53125 5.15625 5.03125 5.65625 5.625 5.65625H12.7813C13.375 5.65625 13.875 5.15625 13.875 4.5625V2.46875H14.4375L17.25 5.0625V17.5625Z"
        fill="inherit"
      />
      <path
        d="M11.2812 13.7812H8.71875C8.34375 13.7812 8 14.0937 8 14.5C8 14.9062 8.3125 15.2187 8.71875 15.2187H11.2812C11.6562 15.2187 12 14.9062 12 14.5C12 14.0937 11.6562 13.7812 11.2812 13.7812Z"
        fill="inherit"
      />
      <path
        d="M11.2812 16.1562H8.71875C8.34375 16.1562 8 16.4688 8 16.875C8 17.2813 8.3125 17.5938 8.71875 17.5938H11.2812C11.6562 17.5938 12 17.2813 12 16.875C12 16.4688 11.6562 16.1562 11.2812 16.1562Z"
        fill="inherit"
      />
    </svg>
  );
};
