import { gql, useMutation } from "@m/api";
import { toast } from "@m/ui";

import {
  DEFAULT_SORT_RISK_LOG,
  MESSAGES_ENGAGEMENTS_RISK_LOG,
} from "../constants";

import { GET_RISK_LOG_ITEMS } from "./useRiskLogItems";

export const UPDATE_RISK_LOG_ITEM_STATUS = gql`
  mutation UpdateRiskLogItemStatus($input: UpdateRiskLogItemInput!) {
    updateRiskLogItem(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateRiskLogItemStatus = (engagementId: string) => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_RISK_LOG.ERROR_UPDATING_RISK_LOG);

  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_RISK_LOG.SUCCESS_UPDATING_RISK_LOG);

  const [mutate, { loading }] = useMutation(UPDATE_RISK_LOG_ITEM_STATUS);

  const updateRiskItemStatus = ({ id, status }) => {
    return mutate({
      variables: {
        input: {
          id,
          status,
        },
      },

      refetchQueries: [
        {
          query: GET_RISK_LOG_ITEMS,
          variables: { id: engagementId, sort: DEFAULT_SORT_RISK_LOG },
        },
      ],
      onCompleted,
      onError,
    });
  };

  return [updateRiskItemStatus, { loading }] as const;
};
