import ReactDatePicker from "react-datepicker";

import { AggregateBillingPeriod } from "@m/api/public/types";

export const MeteredChargesDatePicker = ({
  label,
  billingPeriodType,
  startDate,
  endDate,
  onChange,
}: {
  label;
  billingPeriodType;
  startDate;
  endDate;
  onChange: (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
  ) => void;
}) => {
  const shouldShowMonthPicker =
    billingPeriodType === AggregateBillingPeriod.Monthly;

  return (
    <ReactDatePicker
      placeholderText={label}
      selected={startDate}
      clearButtonClassName=""
      onChange={onChange}
      clearButtonTitle="Clear"
      className="h-4 w-[160px] rounded-md border border-gray-300 p-1 "
      monthClassName={() => "p-1"}
      isClearable
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="M/d/yyyy"
      showMonthYearPicker={shouldShowMonthPicker}
    />
  );
};
