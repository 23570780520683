import { gql } from "@mc/utils/graphql";

export const LIST_TEAMS = gql`
  query {
    companies(active: true, sort: NAME_ASC) @csv {
      edges {
        node {
          databaseId
          name
          teams {
            role
            account {
              name
            }
          }
        }
      }
    }
  }
`;
