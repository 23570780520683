import { ChangeEvent, useState } from "react";
import { DecodedValueMap, QueryParamConfig, SetQuery } from "use-query-params";

import { ApolloQueryResult, OperationVariables } from "@m/api";
import { useDebouncedCallback } from "@m/utils";

interface QuerySearchTerm {
  /** query from useQuery hook */
  query: DecodedValueMap<{ search: QueryParamConfig<string, string> }>;

  /** setQuery from use-query-params */
  setQuery: SetQuery<any>;

  /** refetch from Apollo Query */
  refetchSearchTerms?: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<any>>;
}

export const useSearchTerm = ({
  query,
  setQuery,
  refetchSearchTerms,
}: QuerySearchTerm) => {
  const [searchTerm, setSearchTerm] = useState(query.search);

  const handleGetSearchTerms = useDebouncedCallback(
    async (value: string) => {
      await refetchSearchTerms?.({ searchTerm: value });
    },
    { delay: 500 }
  );

  const handleSearchInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === "") setQuery({ search: undefined });

    if (value.length > 2) await handleGetSearchTerms(value);

    setSearchTerm(value);
  };

  const handleSubmitSearch = (selectedSearch: string) => {
    setQuery({
      search: selectedSearch ? selectedSearch : undefined,
    });

    setSearchTerm(selectedSearch);
  };

  const clearSearchTerm = () => setSearchTerm("");

  return {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  };
};
