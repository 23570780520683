import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const GET_COMPANIES = gql(/* GraphQL */ `
  query GetCompanies {
    userProfile {
      companies {
        id: databaseId
        name
      }
    }
  }
`);

export const useGetCompanies = () => {
  const { data, ...result } = useQuery(GET_COMPANIES);

  const companies = data?.userProfile?.companies || [];

  return { data: { companies }, ...result };
};
