import { CasePriority } from "@m/api/public/types";

import { CaseType } from "../types";

interface CasePriorityDefinition {
  name: string;
  value: CasePriority;
}

export const CASE_PRIORITY_DEFINITIONS: {
  [key in CaseType]: CasePriorityDefinition[];
} = {
  [CaseType.Account]: [
    {
      name: "General Request",
      value: CasePriority.Low,
    },
    {
      name: "Urgent Request",
      value: CasePriority.Moderate,
    },
  ],
  [CaseType.Billing]: [
    {
      name: "General Request",
      value: CasePriority.Low,
    },
    {
      name: "Urgent Request",
      value: CasePriority.Moderate,
    },
  ],
  [CaseType.Operations]: [
    {
      name: "Low",
      value: CasePriority.Low,
    },
    {
      name: "Medium",
      value: CasePriority.Moderate,
    },
    {
      name: "High",
      value: CasePriority.High,
    },
    {
      name: "Critical",
      value: CasePriority.Critical,
    },
  ],
  [CaseType.Support]: [
    {
      name: "Low",
      value: CasePriority.Low,
    },
    {
      name: "Medium",
      value: CasePriority.Moderate,
    },
    {
      name: "High",
      value: CasePriority.High,
    },
    {
      name: "Critical",
      value: CasePriority.Critical,
    },
  ],
  [CaseType.AwsGuidance]: [
    {
      name: "Medium",
      value: CasePriority.Moderate,
    },
  ],
};
