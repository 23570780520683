import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import PT from "prop-types";

import { Button, Dropdown, DropdownItem } from "@m/ui";

import { RECOMMENDATION_STATUS } from "../constants";

export const UpdateRecommendationStatusButton = ({
  loading = false,
  onUpdateStatus,
  recommendationId,
  scorecardRecStatus,
}) => {
  const { ARCHIVED, RESOLVED, ACTIVE } = RECOMMENDATION_STATUS;
  const handleResolve = () => onUpdateStatus(recommendationId, RESOLVED);
  const handleArchive = () => onUpdateStatus(recommendationId, ARCHIVED);
  const handleReopen = () => onUpdateStatus(recommendationId, ACTIVE);

  return (
    <Button as="div" role="generic" className="w-full !p-0" loading={loading}>
      <div className="flex grow justify-between">
        {scorecardRecStatus === RECOMMENDATION_STATUS.ACTIVE && (
          <button
            className="flex w-full items-center justify-center py-1"
            onClick={handleResolve}
            disabled={loading}
          >
            <CheckCircleIcon className="h-3 w-3" />
            <div className="ml-0.5">Resolve</div>
          </button>
        )}

        {scorecardRecStatus !== RECOMMENDATION_STATUS.ACTIVE && (
          <button
            className="flex w-full items-center justify-center py-1"
            onClick={handleReopen}
            disabled={loading}
          >
            <ArrowPathIcon className="h-3 w-3" />
            <div className="ml-0.5">Reopen</div>
          </button>
        )}

        {scorecardRecStatus !== RECOMMENDATION_STATUS.ARCHIVED && (
          <Dropdown
            direction="bottom-end"
            trigger={
              <button
                aria-label="Show Recommendation Status Options"
                className="flex items-center border-l p-1"
              >
                <ChevronDownIcon className="h-3 w-3" />
              </button>
            }
          >
            <DropdownItem as="div">
              <button
                className="flex items-center"
                onClick={handleArchive}
                disabled={loading}
              >
                <ArchiveBoxIcon className="h-2.5 w-2.5" />
                <div className="ml-0.5">Archive</div>
              </button>
            </DropdownItem>
          </Dropdown>
        )}
      </div>
    </Button>
  );
};

UpdateRecommendationStatusButton.propTypes = {
  loading: PT.bool.isRequired,
  onUpdateStatus: PT.func.isRequired,
  recommendationId: PT.string.isRequired,
  scorecardRecStatus: PT.string.isRequired,
};
