import { ACTIVITY_NAMESPACES } from "../constants";

import { ActivityFeedItem } from "./ActivityFeedItem";

export const ActivityFeedItemCompanyTeam = ({ activity }) => {
  const {
    account: { name },
    timestamp,
    roleName,
    roleDescription,
  } = activity;

  const category = ACTIVITY_NAMESPACES.mission.title;

  const message = (
    <>
      <strong>{name}</strong> was assigned as your <strong>{roleName}</strong>
    </>
  );

  return (
    <ActivityFeedItem
      message={message}
      timestamp={timestamp}
      tooltip={roleDescription}
      category={category}
    />
  );
};
