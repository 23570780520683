import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

export const GET_UNCLAIMED_CONNECTIONS_FAILURE =
  "We encountered a problem fetching unclaimed git connections.";

export const GET_UNCLAIMED_CONNECTIONS = gql`
  query useUnclaimedConnections {
    githubInstallations(includeClaimed: false) {
      edges {
        node {
          id
          company {
            id
            name
          }
          repositories {
            edges {
              node {
                id
                fullName
              }
            }
          }
          createdAt
          installedByLogin
          verifiedAt
          verifiedBy {
            id
            name
          }
        }
      }
    }
  }
`;

export const useUnclaimedConnections = () => {
  const onError = () => toast.error(GET_UNCLAIMED_CONNECTIONS_FAILURE);

  const { loading, data } = useQuery(GET_UNCLAIMED_CONNECTIONS, {
    context: { clientName: "v4" },
    onError,
  });

  const unclaimedConnections =
    data?.githubInstallations?.edges.map((edge) => edge.node) || [];

  return {
    data: { unclaimedConnections },
    loading,
  };
};
