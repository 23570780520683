import { useAuth0 } from "@auth0/auth0-react";

import { setReturnToUrl } from "@m/utils";
import { shutdownIntercom } from "@m/utils/intercom";

import { logoutSession } from "../utils";

export const useAuth0Logout = () => {
  const { logout: logoutAuth0 } = useAuth0();

  /**
   * Deactivate the Services API session, and then redirect the user
   * to the Auth0 logout flow.
   */
  const logout = async (expired = false) => {
    if (!localStorage.getItem("user")) return;

    // Auth0 does not allow redirection to arbitrary URLs (they must be
    // whitelisted per-Auth0 app/tenant)
    // If the session is expired, record the current URL so we can send the
    // user back to the same page when they log in again
    if (expired === true) setReturnToUrl();

    //Removes session values from company switcher functionality
    localStorage.removeItem("currentSession");

    localStorage.removeItem("user");
    try {
      await logoutSession();
    } catch {
      // If the GraphQL logout request is unsuccessful (with e.g., a 401, or bad CSRF)
      // that likely means the user's session is invalid, and API requests in general
      // are not working, so silently continue with the process of logging the
      // user out of Auth0 and unsetting localStorage so they're presented with
      // the login screen again.
    }

    shutdownIntercom();

    if (process.env.REACT_APP_SKIP_AUTH0) {
      window.location.pathname = "/";
    } else {
      logoutAuth0({
        returnTo: window.location.origin,
      });
    }
  };

  return logout;
};
