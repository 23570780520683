import { useMatch } from "react-router-dom";

import { BreadcrumbType } from "../Breadcrumbs";

export const useBreadcrumbs = (
  path = "",
  routes: BreadcrumbType[] = []
): BreadcrumbType[] => {
  const match = useMatch(path);

  if (!match?.pattern.path) return [];

  const crumbs = routes
    // Get all routes that contain the current one
    .filter(({ path }) => path && match.pattern.path.includes(path))
    // Swap out dynamic routes with their param values
    // eg. /cloud-score/:detail will become /cloud-score/operations
    .map(({ path, ...rest }) => ({
      path: path
        ? Object.entries(match.params).reduce(
            (acc, [param, value]) => acc.replace(`:${param}`, value as string),
            path
          )
        : null,
      ...rest,
    }));

  return crumbs;
};
