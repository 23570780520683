import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { DashboardPage } from "@mc/features/Dashboard";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

import { CaseDetailsRedirect } from "./redirects";

export const dashboardRoutes: RouteObject[] = [
  {
    index: true,
    element: <DashboardPage />,
  },
  {
    path: "cases",
    element: <Navigate replace to={PATHS.SUPPORT_CASES} />,
  },
  {
    path: "cases/create",
    element: <Navigate replace to={PATHS.SUPPORT_CASES_CREATE} />,
  },
  {
    path: "cases/:sysId",
    element: <CaseDetailsRedirect />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
