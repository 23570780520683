import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const RDS_LABEL = AWS_RESOURCES_LABELS.rdsInstances;

export const TABLE_HEADERS_RDS_INSTANCES = [
  { label: "Name", accessor: "databaseName", sort: "NAME" },
  { label: "Account Name", accessor: "accountName", sort: "AWS_ACCOUNT__NAME" },
  { label: "Account ID", accessor: "accountId", sort: "ACCOUNT" },
  { label: "Status", sort: "STATUS" },
  { label: "Class", accessor: "instanceClass", sort: "INSTANCE_CLASS" },
  { label: "Zone", accessor: "availabilityZone", sort: "AVAILABILITY_ZONE" },
  { label: "Multi AZ", accessor: "isMultiAz", sort: "MULTI_AZ" },
  { label: "Encrypted", accessor: "isEncrypted", sort: "STORAGE_ENCRYPTED" },
  { label: "Engine", accessor: "engine", sort: "ENGINE" },
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  {
    label: "Monitoring",
    accessor: "monitoringPolicy",
    sort: "MONITORING_POLICY",
  },
  { label: "Backup", accessor: "backupPolicy", sort: "BACKUP_POLICY" },
  { label: "Tags", sort: "TAGS" },
];
