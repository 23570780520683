import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_FILTERS_ERROR, REDSHIFT_LABEL } from "../constants";

export const GET_REDSHIFT_CLUSTER_FILTERS = gql(/* GraphQL */ `
  query RedshiftClusterFilters {
    redshiftClusterFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      statuses
      nodeTypes
    }
  }
`);

export const useRedshiftClusterFilters = () => {
  const onError = () => toast.error(GET_FILTERS_ERROR(REDSHIFT_LABEL));

  const { data, loading, error } = useQuery(GET_REDSHIFT_CLUSTER_FILTERS, {
    onError,
  });

  const filters = data?.redshiftClusterFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const statuses = filters.statuses || [];
  const nodeTypes = filters.nodeTypes || [];

  return {
    data: {
      filters: { accounts, statuses, nodeTypes },
    },
    error,
    loading,
  };
};
