import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { AWS_ACCOUNT_RESOURCES_MESSAGES } from "../constants";

export const AWS_ACCOUNT_RESOURCES = gql`
  query ResourceCounts {
    applicationLoadBalancers: loadBalancers(loadBalancerType: "application") {
      totalCount
    }
    autoScalingGroups {
      totalCount
    }
    classicLoadBalancers {
      totalCount
    }
    ec2Instances {
      totalCount
    }
    ecsClusters {
      totalCount
    }
    eksClusters {
      totalCount
    }
    elasticacheClusters {
      totalCount
    }
    lambdaFunctions {
      totalCount
    }
    networkLoadBalancers: loadBalancers(loadBalancerType: "network") {
      totalCount
    }
    rdsInstances {
      totalCount
    }
    redshiftClusters {
      totalCount
    }
  }
`;

export const useAwsAccountResources = () => {
  const onError = () =>
    toast.error(AWS_ACCOUNT_RESOURCES_MESSAGES.GET_RESOURCES_FAILURE);

  const {
    loading,
    data = {},
    refetch,
  } = useQuery(AWS_ACCOUNT_RESOURCES, {
    onError,
  });

  return {
    data,
    loading,
    refetch,
  };
};
