import { useLazyQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const VALIDATE_ONBOARDING_ROLE_DEPLOYED = gql(/* GraphQL */ `
  query ValidateOnboardingRoleDeployedToAwsAccount(
    $awsAccountId: String!
    $externalId: String
    $roleName: String
  ) {
    validateBootstrapRoleDeployedToAwsAccount(
      awsAccountId: $awsAccountId
      externalId: $externalId
      roleName: $roleName
    )
  }
`);

export const useValidateOnboardingRoleDeployed = (
  awsAccountId: string,
  iamExternalId: string,
  roleName: string,
  pollInterval: number
) => {
  const [validate, { data, networkStatus, ...result }] = useLazyQuery(
    VALIDATE_ONBOARDING_ROLE_DEPLOYED,
    {
      variables: {
        awsAccountId,
        externalId: iamExternalId,
        roleName,
      },
      notifyOnNetworkStatusChange: true, // update network status when polling
      pollInterval,
    }
  );

  const onboardingRoleValidated =
    data?.validateBootstrapRoleDeployedToAwsAccount || false;

  const isPolling = networkStatus === 6; // indicates that a polling query is currently in flight

  return [
    validate,
    {
      data: onboardingRoleValidated,
      isPolling,
      ...result,
    },
  ] as const;
};
