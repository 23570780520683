import { useMutation } from "@apollo/client";
import { generatePath } from "react-router-dom";

import { gql } from "@m/api/public";
import { CaseCategory, CaseCreationInput } from "@m/api/public/types";
import { toast } from "@m/ui";

import { PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";

import { MESSAGES } from "../constants";

export const CREATE_CASE = gql(/* GraphQL */ `
  mutation CreateCase($input: CaseCreationInput!) {
    createCase(input: $input) {
      ok
      message
      case {
        sysId
        number
        category
      }
    }
  }
`);

export const useCreateCase = () => {
  const navigate = useNavigate();

  const [mutate, result] = useMutation(CREATE_CASE, {
    onCompleted: (data) => {
      if (!data?.createCase?.case) return;

      const { sysId, category } = data.createCase.case;
      const redirectPath =
        category === CaseCategory.DevopsDesk
          ? PATHS.ENGAGEMENTS_ENGINEER_ASSIST_DETAILS
          : PATHS.SUPPORT_CASE_DETAILS;

      navigate(generatePath(redirectPath, { sysId }));
    },
    onError: () => toast.error(MESSAGES.CASE_CREATION_FAILURE),
  });

  const createCase = (input: CaseCreationInput) => {
    return mutate({ variables: { input } });
  };

  return [createCase, result] as const;
};
