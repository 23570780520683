import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";

export const CommentCount = ({ commentCount }: { commentCount: number }) => {
  return (
    <div className="flex" data-testid="comment-count">
      <ChatBubbleOvalLeftEllipsisIcon className="mr-0.5 inline h-2.5 w-2.5" />
      <p className="text-sm font-bold">{commentCount}</p>
    </div>
  );
};
