import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

// eslint-disable-next-line
import { Button } from "../../Button";
import { ExampleHeader, ExampleRow } from "../models";

export const EXAMPLE_HEADERS = [
  new ExampleHeader({}),
  new ExampleHeader({
    label: "Header 2",
  }),
  new ExampleHeader({
    label: "Header 3",
  }),
];

export const EXAMPLE_ICON_ROWS = [
  {
    header1: "row data 1",
    header2: "row data 2",
    header3: "row data 3",
    icon: (
      <ExclamationTriangleIcon
        aria-label="Exclamation Icon"
        className="h-3 w-3"
      />
    ),
  },
];

export const EXAMPLE_COLUMNS = [
  {
    label: "Column 1",
    sort: "FIRST",
  },
  {
    label: "Column 2",
    sort: "SECOND",
  },
  "Button", // columns can also just be strings
];

export const EXAMPLE_ROWS = [
  new ExampleRow({
    button: <Button size="small">Click Me</Button>,
  }),
  new ExampleRow({
    column1: "react-table",
    column2: "rocks",
    button: (
      <Button size="small" kind="destructive">
        Delete
      </Button>
    ),
  }),
  new ExampleRow({
    column1: "whatever",
    column2: "you want",
  }),
];

export const EXAMPLE_COMPLEX_COLUMNS = Array.from(
  { length: 20 },
  (v, i) => `column${i}`
);

export const EXAMPLE_COMPLEX_ROWS = Array.from({ length: 7 }, () => {
  return EXAMPLE_COMPLEX_COLUMNS.reduce(
    (obj, item) => ({ ...obj, [item]: item }),
    {}
  );
});
