import { useQuery } from "@m/api";
import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS_RISK_LOG } from "../constants";
import { IRiskLogItem } from "../types";

export const GET_RISK_LOG_ITEM = gql(/* GraphQL */ `
  query RiskLogItem(
    $id: ID!
    $sort: [RiskLogItemSortEnum]
    $where: EngagementRiskLogItemFilter
  ) {
    engagement(id: $id) {
      id
      riskLogItems(sort: $sort, where: $where) {
        __typename
        edges {
          node {
            databaseId
            engagementId
            updateTime
            createTime
            dateNeeded
            description
            id
            impact
            itemType
            mitigationStrategy
            noteList {
              edges {
                node {
                  id
                  content
                  createdAt
                  updatedAt
                  createdBy {
                    name
                    firstName
                    lastName
                    email
                  }
                }
              }
            }
            owner
            probability
            status
            title
            createdBy {
              name
              email
              firstName
              lastName
            }
            createTime
          }
        }
      }
    }
  }
`);

interface RiskLogParams {
  riskId: string;
}

export const useRiskLogItem = (engagementId: string, params: RiskLogParams) => {
  const where = {} as { id: string };

  if (params.riskId) where.id = params.riskId;

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_RISK_LOG.GET_ITEMS_ERROR);

  const { data, loading, error } = useQuery(GET_RISK_LOG_ITEM, {
    variables: { id: engagementId, where },
    onError,
  });

  const riskLogItems =
    data?.engagement?.riskLogItems?.edges?.map((edge) => {
      return {
        ...edge.node,
        noteList: (edge.node.noteList.edges || []).map((edge) => edge.node),
      };
    }) || [];

  return {
    data: { riskLogItem: riskLogItems[0] as IRiskLogItem },
    loading,
    error,
  };
};
