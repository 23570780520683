import { Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import PT from "prop-types";
import { useCallback, useEffect, useState } from "react";

import { Spinner } from "@m/ui";

import { DEFAULT_HIDE_DELAY, INPUT_STATUS_TEST_ID } from "../constants";

export function InputStatusIcon({
  hideDelay = DEFAULT_HIDE_DELAY,
  loading,
  ok,
}) {
  const [isShowing, setIsShowing] = useState(true);

  const hide = () => setIsShowing(false);

  const setHideTimeout = useCallback(() => {
    if (hideDelay) setTimeout(hide, hideDelay);
  }, [hideDelay]);

  const success = ok === true;
  const error = ok === false;

  useEffect(() => {
    if (success) setHideTimeout();
  }, [success, setHideTimeout]);

  if (loading) return <Spinner className="m-0" />;

  let icon = null;
  if (error) {
    icon = (
      <XMarkIcon
        className="h-2.5 w-2.5 text-status-error"
        data-testid={INPUT_STATUS_TEST_ID}
      />
    );
  } else if (success) {
    icon = (
      <CheckIcon
        className="h-2.5 w-2.5 text-status-good"
        data-testid={INPUT_STATUS_TEST_ID}
      />
    );
  }
  return <Transition show={isShowing}>{icon}</Transition>;
}

InputStatusIcon.propTypes = {
  hideDelay: PT.number,
  loading: PT.bool,
  ok: PT.bool,
};
