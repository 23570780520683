import {
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { RiskLogItemStatus } from "@m/api/public/types";
import { Dropdown, DropdownItem } from "@m/ui";

import { PATHS } from "@mc/constants";

import { useUpdateRiskLogItemStatus } from "../api";
import { IRiskLogItem } from "../types";

export const RiskLogAction = ({
  riskLogItem,
  engagementId,
}: {
  riskLogItem: IRiskLogItem;
  engagementId: string;
}) => {
  const navigate = useNavigate();

  const { status } = riskLogItem;

  const [updateRiskItemStatus] = useUpdateRiskLogItemStatus(engagementId);

  const handleEdit = () => {
    const editPath = generatePath(PATHS._ENGAGEMENT_RISK_LOG_EDIT, {
      engagementId,
      riskId: riskLogItem.id,
    });
    navigate("../" + editPath);
  };

  const handleUpdate = (e: ChangeEvent<HTMLButtonElement>) => {
    const newStatus = e.target.value as RiskLogItemStatus;
    const { ...riskLogItemCopy } = riskLogItem;
    delete riskLogItemCopy.noteList;

    updateRiskItemStatus({
      ...riskLogItemCopy,
      status: newStatus,
    });
  };

  return (
    <Dropdown
      className="w-[165px] p-1"
      trigger={
        <EllipsisVerticalIcon
          aria-label="Risk Log Actions"
          className="h-3 w-3"
        />
      }
      direction="bottom-end"
    >
      <DropdownItem className="p-1" onClick={handleEdit}>
        <PencilIcon className="mr-1 h-2.5 w-2.5 text-accent" />
        Edit
      </DropdownItem>

      {status !== RiskLogItemStatus.Archived && (
        <DropdownItem
          className="p-1"
          value={RiskLogItemStatus.Archived}
          onClick={handleUpdate}
        >
          <ArchiveBoxIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Archive
        </DropdownItem>
      )}

      {status === RiskLogItemStatus.Archived && (
        <DropdownItem
          className="p-1"
          value={RiskLogItemStatus.Open}
          onClick={handleUpdate}
        >
          <ArchiveBoxIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Unarchive
        </DropdownItem>
      )}

      {status === RiskLogItemStatus.Resolved && (
        <DropdownItem
          className="p-1"
          value={RiskLogItemStatus.Open}
          onClick={handleUpdate}
        >
          <ArrowUturnLeftIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Reopen
        </DropdownItem>
      )}
    </Dropdown>
  );
};
