import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES_ENGAGEMENTS_BUDGET } from "../constants";

import { GET_ENGAGEMENT_STATUS } from "./useEngagementStatus";

export const UPDATE_ENGAGEMENT_BUDGET = gql`
  mutation UpdateEngagementBudgetStatus($input: UpdateBudgetStatusInput!) {
    updateBudgetStatus(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateEngagementBudget = () => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_BUDGET.UPDATE_BUDGET_SUCCESS);
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_BUDGET.UPDATE_BUDGET_ERROR);

  const [mutate, mutationResult] = useMutation(UPDATE_ENGAGEMENT_BUDGET, {
    onCompleted,
    onError,
  });

  const updateEngagementBudget = (mutationPayload: IUpdateBudgetInput) => {
    return mutate({
      variables: {
        input: mutationPayload,
      },
      refetchQueries: [
        {
          query: GET_ENGAGEMENT_STATUS,
          variables: { id: mutationPayload.engagementId },
        },
      ],
    });
  };

  return [updateEngagementBudget, mutationResult] as const;
};

export interface IUpdateBudgetInput {
  engagementId: string;
  id: string;
  status: string;
  originalSof: number;
  changeOrders: number;
  totalCreditsApplied: number;
  budgetSpent: number;
  estimateAtCompletion: number;
}
