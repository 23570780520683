import { getMilliseconds } from "@m/utils";

import {
  CHECK_STATUS,
  PILLAR_DETAILS,
  RECOMMENDATION_STATUS,
} from "../constants";

const STATUS_ORDER = {
  [RECOMMENDATION_STATUS.ACTIVE]: 1,
  [RECOMMENDATION_STATUS.RESOLVED]: 2,
  [RECOMMENDATION_STATUS.ARCHIVED]: 3,
};

export const sortPillars = (a, b) => {
  const i = PILLAR_DETAILS.indexOf(
    PILLAR_DETAILS.find((d) => a.pillar === d.pillar)
  );
  const j = PILLAR_DETAILS.indexOf(
    PILLAR_DETAILS.find((d) => b.pillar === d.pillar)
  );
  return i - j;
};

export const sortStatusAsc = (a, b) => {
  const statusA = a.ignored ? CHECK_STATUS.IGNORED : a.status;
  const statusB = b.ignored ? CHECK_STATUS.IGNORED : b.status;

  const order = {
    [CHECK_STATUS.ERROR]: 2,
    [CHECK_STATUS.WARNING]: 3,
    [CHECK_STATUS.OK]: 4,
    [CHECK_STATUS.IGNORED]: 5,
  };
  return order[statusA] - order[statusB];
};

export const sortRecommendations = (a, b) => {
  let position;

  const i = STATUS_ORDER[a.scorecardRecStatus];
  const j = STATUS_ORDER[b.scorecardRecStatus];

  // adjust position by status and increase the weight
  position = (i - j) * 10;

  // adjust the position by updatedTime
  if (getMilliseconds(a.updatedTime) > getMilliseconds(b.updatedTime)) {
    position--;
  } else {
    position++;
  }

  return position;
};
