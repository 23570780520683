import { Popover } from "@headlessui/react";
import { useContext, useState } from "react";
import { usePopper } from "react-popper";

import { ActivityContext } from "../contexts";
import { Activity } from "../types";

interface ActivityPopoverProps {
  activity: Activity;
  activityLineElement: Element;
  ActivityPanelComponent: React.ElementType;
  popoverButtonRef: React.RefObject<HTMLButtonElement>;
}

export const ActivityPopover = ({
  activity,
  activityLineElement,
  ActivityPanelComponent,
  popoverButtonRef,
}: ActivityPopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(activityLineElement, popperElement, {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 32],
        },
      },
    ],
  });

  const { setActivity, setPinnedActivityIndex } = useContext(ActivityContext);

  const handleClose = () => {
    setActivity(null);
    setPinnedActivityIndex(null);
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className="hidden" ref={popoverButtonRef} />

          {(activity || open) && (
            <Popover.Panel
              aria-label="Activity Panel"
              className="z-10 max-w-sm rounded-xl bg-white shadow-lg shadow-gray-300"
              ref={setPopperElement}
              style={styles.popper}
              static={true}
              {...attributes.popper}
            >
              <Popover.Button>
                <ActivityPanelComponent
                  activity={activity}
                  onClick={handleClose}
                />
              </Popover.Button>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};
