interface Props {
  className?: string;
  height: string | number;
  width: string | number;
}

export const ActivityFeedEmptyIcon = ({ className, height, width }: Props) => (
  <svg
    viewBox="0 0 183 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{ height, width }}
  >
    <g clipPath="url(#clip0_2095_2884)">
      <path
        d="M120.475 49.4229H90.6779C90.0255 49.4249 89.3814 49.2742 88.7943 48.9823C88.2073 48.6904 87.6929 48.2649 87.2903 47.7383C86.8803 47.2067 86.594 46.5861 86.453 45.9238C86.3121 45.2615 86.3203 44.575 86.477 43.9164C89.2232 32.1135 89.2232 19.8129 86.4768 8.00997C86.3202 7.35142 86.3121 6.66491 86.453 6.00266C86.594 5.34041 86.8803 4.71988 87.2902 4.18827C87.6928 3.66161 88.2072 3.23613 88.7943 2.94421C89.3813 2.65229 90.0255 2.50162 90.6779 2.50368H120.475C121.504 2.50672 122.498 2.88436 123.281 3.56935C124.064 4.25435 124.584 5.20221 124.75 6.24412C126.711 19.3132 126.711 32.6133 124.75 45.6824C124.584 46.7244 124.064 47.6722 123.281 48.3572C122.498 49.0422 121.504 49.4199 120.475 49.4229Z"
        fill="#E6E6E6"
      />
      <path
        d="M90.6787 4.80397C90.3654 4.80289 90.056 4.87514 89.774 5.01523C89.4921 5.15531 89.245 5.35954 89.0515 5.61238C88.8523 5.87143 88.7133 6.17369 88.6451 6.49616C88.5768 6.81864 88.5812 7.15284 88.6578 7.47333C91.4862 19.629 91.4863 32.2971 88.658 44.4527C88.5813 44.7731 88.5769 45.1073 88.6451 45.4297C88.7133 45.7521 88.8522 46.0543 89.0514 46.3133L89.0516 46.3135C89.245 46.5664 89.4921 46.7707 89.774 46.9108C90.056 47.0509 90.3654 47.1232 90.6787 47.1221H120.476C120.97 47.1223 121.448 46.9425 121.825 46.6148C122.202 46.2871 122.453 45.8327 122.534 45.3327C124.461 32.4952 124.461 19.4309 122.534 6.5934C122.453 6.0933 122.202 5.63896 121.825 5.31126C121.448 4.98356 120.97 4.80379 120.476 4.80396L90.6787 4.80397Z"
        fill="white"
      />
      <path
        d="M113.973 15.8663H98.3364C97.742 15.8657 97.1722 15.6231 96.7519 15.1918C96.3315 14.7606 96.0951 14.1758 96.0945 13.5659V12.6669C96.0951 12.057 96.3315 11.4723 96.7519 11.041C97.1722 10.6097 97.742 10.3671 98.3364 10.3665H113.973C114.567 10.3671 115.137 10.6097 115.557 11.041C115.978 11.4723 116.214 12.057 116.215 12.6669V13.5659C116.214 14.1758 115.978 14.7606 115.557 15.1918C115.137 15.6231 114.567 15.8657 113.973 15.8663Z"
        fill="#E6E6E6"
      />
      <path
        d="M113.973 27.2255H98.3364C97.742 27.2248 97.1722 26.9822 96.7519 26.5509C96.3315 26.1197 96.0951 25.5349 96.0945 24.925V24.026C96.0951 23.4161 96.3315 22.8314 96.7519 22.4001C97.1722 21.9688 97.742 21.7263 98.3364 21.7256H113.973C114.567 21.7263 115.137 21.9688 115.557 22.4001C115.978 22.8314 116.214 23.4161 116.215 24.026V24.925C116.214 25.5349 115.978 26.1197 115.557 26.5509C115.137 26.9822 114.567 27.2248 113.973 27.2255Z"
        fill="#E6E6E6"
      />
      <path
        d="M113.973 38.5846H98.3364C97.742 38.5839 97.1722 38.3413 96.7519 37.9101C96.3315 37.4788 96.0951 36.8941 96.0945 36.2841V35.3852C96.0951 34.7753 96.3315 34.1905 96.7519 33.7592C97.1722 33.328 97.742 33.0854 98.3364 33.0847H113.973C114.567 33.0854 115.137 33.328 115.557 33.7592C115.978 34.1905 116.214 34.7753 116.215 35.3852V36.2841C116.214 36.8941 115.978 37.4788 115.557 37.9101C115.137 38.3413 114.567 38.5839 113.973 38.5846Z"
        fill="#E6E6E6"
      />
      <path
        d="M176.526 78.563H146.729C146.077 78.5651 145.433 78.4144 144.846 78.1225C144.259 77.8305 143.744 77.4051 143.342 76.8784C142.932 76.3468 142.645 75.7263 142.504 75.064C142.363 74.4017 142.372 73.7151 142.528 73.0566C145.274 61.2536 145.274 48.953 142.528 37.1501C142.371 36.4916 142.363 35.8051 142.504 35.1428C142.645 34.4806 142.932 33.86 143.341 33.3284C143.744 32.8017 144.258 32.3763 144.846 32.0843C145.433 31.7924 146.077 31.6418 146.729 31.6438H176.526C177.555 31.6469 178.55 32.0245 179.332 32.7095C180.115 33.3945 180.635 34.3423 180.801 35.3843C182.762 48.4534 182.762 61.7535 180.801 74.8226C180.635 75.8645 180.115 76.8124 179.332 77.4974C178.55 78.1824 177.555 78.56 176.526 78.563Z"
        fill="#E6E6E6"
      />
      <path
        d="M146.729 33.9443C146.415 33.9433 146.106 34.0155 145.824 34.1556C145.542 34.2957 145.295 34.4999 145.102 34.7528C144.902 35.0118 144.763 35.3141 144.695 35.6365C144.627 35.959 144.631 36.2932 144.708 36.6137C147.536 48.7693 147.536 61.4375 144.708 73.5931C144.631 73.9135 144.627 74.2477 144.695 74.5701C144.763 74.8925 144.902 75.1947 145.101 75.4537L145.102 75.4539C145.295 75.7068 145.542 75.911 145.824 76.0512C146.106 76.1913 146.415 76.2636 146.729 76.2625H176.526C177.02 76.2626 177.498 76.0829 177.875 75.7552C178.252 75.4275 178.503 74.9731 178.584 74.473C180.511 61.6356 180.511 48.5712 178.584 35.7338C178.503 35.2337 178.252 34.7793 177.875 34.4516C177.498 34.1239 177.02 33.9442 176.526 33.9443L146.729 33.9443Z"
        fill="white"
      />
      <path
        d="M117.112 111.729H87.3152C86.6346 111.731 85.9626 111.574 85.3501 111.269C84.7377 110.965 84.201 110.521 83.7809 109.972C83.3534 109.417 83.0548 108.77 82.9078 108.08C82.7608 107.389 82.7693 106.673 82.9327 105.986C85.672 94.2127 85.672 81.9428 82.9325 70.1693C82.7692 69.4826 82.7608 68.7667 82.9078 68.0761C83.0548 67.3855 83.3534 66.7385 83.7808 66.1842C84.2009 65.6347 84.7376 65.1908 85.3501 64.8862C85.9625 64.5816 86.6346 64.4244 87.3152 64.4265H117.112C118.186 64.4298 119.223 64.8239 120.04 65.5387C120.856 66.2534 121.399 67.2424 121.572 68.3295C123.536 81.4179 123.536 94.7378 121.572 107.826C121.399 108.913 120.856 109.902 120.04 110.617C119.223 111.332 118.186 111.726 117.112 111.729Z"
        fill="#F2F2F2"
      />
      <path
        d="M170.024 50.7577H154.388C153.793 50.757 153.223 50.5145 152.803 50.0832C152.383 49.6519 152.146 49.0672 152.146 48.4572V47.5583C152.146 46.9483 152.383 46.3636 152.803 45.9323C153.223 45.5011 153.793 45.2585 154.388 45.2578H170.024C170.618 45.2585 171.188 45.5011 171.609 45.9323C172.029 46.3636 172.265 46.9483 172.266 47.5583V48.4573C172.265 49.0672 172.029 49.6519 171.609 50.0832C171.188 50.5145 170.618 50.757 170.024 50.7577Z"
        fill="#E6E6E6"
      />
      <path
        d="M170.024 62.1168H154.388C153.793 62.1162 153.223 61.8736 152.803 61.4423C152.383 61.011 152.146 60.4263 152.146 59.8164V58.9174C152.146 58.3075 152.383 57.7227 152.803 57.2915C153.223 56.8602 153.793 56.6176 154.388 56.6169H170.024C170.618 56.6176 171.188 56.8602 171.609 57.2915C172.029 57.7227 172.265 58.3075 172.266 58.9174V59.8164C172.265 60.4263 172.029 61.011 171.609 61.4423C171.188 61.8736 170.618 62.1162 170.024 62.1168Z"
        fill="#E6E6E6"
      />
      <path
        d="M102.297 96.7383C107.044 96.7383 110.892 92.79 110.892 87.9196C110.892 83.0491 107.044 79.1008 102.297 79.1008C97.5505 79.1008 93.7026 83.0491 93.7026 87.9196C93.7026 92.79 97.5505 96.7383 102.297 96.7383Z"
        fill="white"
      />
      <path
        d="M106.407 86.7694H103.418V83.702C103.418 83.397 103.3 83.1044 103.09 82.8887C102.879 82.673 102.594 82.5518 102.297 82.5518C102 82.5518 101.714 82.673 101.504 82.8887C101.294 83.1044 101.176 83.397 101.176 83.702V86.7694H98.1864C97.8891 86.7694 97.604 86.8906 97.3938 87.1063C97.1835 87.322 97.0654 87.6146 97.0654 87.9197C97.0654 88.2248 97.1835 88.5173 97.3938 88.733C97.604 88.9488 97.8891 89.07 98.1864 89.07H101.176V92.1373C101.176 92.4424 101.294 92.735 101.504 92.9507C101.714 93.1664 102 93.2876 102.297 93.2876C102.594 93.2876 102.879 93.1664 103.09 92.9507C103.3 92.735 103.418 92.4424 103.418 92.1373V89.07H106.407C106.705 89.07 106.99 88.9488 107.2 88.733C107.41 88.5173 107.528 88.2248 107.528 87.9197C107.528 87.6146 107.41 87.322 107.2 87.1063C106.99 86.8906 106.705 86.7694 106.407 86.7694Z"
        fill="#E6E6E6"
      />
      <path
        d="M113.134 6.82557H97.4393C96.9935 6.82506 96.5661 6.64311 96.2509 6.31964C95.9356 5.99618 95.7583 5.55761 95.7578 5.10017V2.03278C95.7583 1.57533 95.9356 1.13677 96.2509 0.813302C96.5661 0.489837 96.9935 0.307889 97.4393 0.307373H113.134C113.579 0.307889 114.007 0.489837 114.322 0.813302C114.637 1.13677 114.815 1.57533 114.815 2.03278V5.10017C114.815 5.55761 114.637 5.99618 114.322 6.31964C114.007 6.64311 113.579 6.82506 113.134 6.82557Z"
        fill="#CCCCCC"
      />
      <path
        d="M169.932 36.3493H154.238C153.792 36.3487 153.365 36.1668 153.05 35.8433C152.734 35.5199 152.557 35.0813 152.557 34.6238V31.5565C152.557 31.099 152.734 30.6605 153.05 30.337C153.365 30.0135 153.792 29.8316 154.238 29.8311H169.932C170.378 29.8316 170.806 30.0135 171.121 30.337C171.436 30.6605 171.613 31.099 171.614 31.5565V34.6238C171.613 35.0813 171.436 35.5199 171.121 35.8433C170.806 36.1668 170.378 36.3487 169.932 36.3493Z"
        fill="#CCCCCC"
      />
      <path
        d="M33.8342 180.923H38.4155L39.8478 162.792H33.8335L33.8342 180.923Z"
        fill="#FFB8B8"
      />
      <path
        d="M32.6658 179.389L41.6877 179.388H41.688C42.4431 179.388 43.1907 179.541 43.8883 179.837C44.5859 180.134 45.2197 180.568 45.7536 181.116C46.2875 181.664 46.711 182.314 46.9999 183.03C47.2888 183.746 47.4376 184.513 47.4376 185.288V185.479L32.6661 185.48L32.6658 179.389Z"
        fill="#2F2E41"
      />
      <path
        d="M9.17165 180.923H13.7529L15.9325 162.792H9.1709L9.17165 180.923Z"
        fill="#FFB8B8"
      />
      <path
        d="M8.00391 179.389L17.0258 179.388H17.0262C17.7812 179.388 18.5289 179.541 19.2264 179.837C19.924 180.134 20.5578 180.568 21.0917 181.116C21.6256 181.664 22.0491 182.314 22.338 183.03C22.627 183.746 22.7757 184.513 22.7757 185.288V185.479L8.00419 185.48L8.00391 179.389Z"
        fill="#2F2E41"
      />
      <path
        d="M9.98484 175.196C9.51752 175.195 9.06733 175.015 8.72369 174.69C8.53923 174.517 8.39066 174.308 8.28691 174.075C8.18316 173.842 8.12637 173.59 8.11994 173.334L6.28271 105.77L32.3693 112.461L40.418 137.237C41.456 140.424 41.9053 143.782 41.7429 147.139L40.7434 171.155C40.7207 171.647 40.514 172.111 40.1665 172.451C39.819 172.791 39.3572 172.98 38.8772 172.98H34.5018C34.0502 172.978 33.6144 172.809 33.2743 172.504C32.9343 172.199 32.7127 171.779 32.6504 171.32L29.5231 144.809C29.2499 142.741 28.5448 140.758 27.4561 138.995L21.3019 128.997C21.2607 128.93 21.2004 128.878 21.1293 128.847C21.0582 128.817 20.9797 128.81 20.9046 128.827C20.8294 128.845 20.7613 128.885 20.7095 128.944C20.6577 129.002 20.6247 129.075 20.6152 129.154L15.7856 173.281C15.7337 173.729 15.5297 174.143 15.2093 174.452C14.8889 174.76 14.4728 174.943 14.0342 174.967L10.0912 175.192C10.0557 175.194 10.0203 175.196 9.98484 175.196Z"
        fill="#2F2E41"
      />
      <path
        d="M28.0281 62.026C33.0969 62.026 37.2059 57.8097 37.2059 52.6087C37.2059 47.4077 33.0969 43.1914 28.0281 43.1914C22.9594 43.1914 18.8503 47.4077 18.8503 52.6087C18.8503 57.8097 22.9594 62.026 28.0281 62.026Z"
        fill="#FFB8B8"
      />
      <path
        d="M32.2442 115.209C32.0862 115.209 31.929 115.188 31.776 115.148H31.7757L5.89348 108.338C5.43073 108.217 5.03097 107.918 4.77639 107.503C4.52181 107.088 4.43176 106.589 4.52476 106.109L10.4688 73.2526C10.8392 71.3534 11.6532 69.5748 12.841 68.0695C14.0288 66.5642 15.5549 65.3772 17.2883 64.6104C18.9772 63.8434 20.8242 63.5148 22.6663 63.6536C24.5083 63.7923 26.2887 64.3942 27.8504 65.4061C28.2127 65.6381 28.5778 65.891 28.935 66.158C30.3732 67.2466 31.5461 68.6623 32.3616 70.2941C33.1771 71.9259 33.6131 73.7294 33.6354 75.5631L34.108 113.265C34.1112 113.518 34.0654 113.77 33.9732 114.005C33.8811 114.24 33.7444 114.455 33.5711 114.635C33.3978 114.816 33.1913 114.96 32.9635 115.058C32.7358 115.157 32.4913 115.208 32.2442 115.209Z"
        fill="#F37920"
      />
      <path
        d="M8.55689 115.595C8.24814 115.129 8.04276 114.599 7.95523 114.043C7.8677 113.486 7.90015 112.917 8.05028 112.374C8.20041 111.832 8.46457 111.33 8.82416 110.904C9.18376 110.478 9.63003 110.138 10.1316 109.908L8.98034 96.2576L15.0809 99.6503L15.2388 112.093C15.5596 112.983 15.5509 113.963 15.2146 114.847C14.8782 115.731 14.2375 116.458 13.4139 116.889C12.5903 117.321 11.6409 117.428 10.7457 117.189C9.85056 116.951 9.07175 116.384 8.5569 115.595H8.55689Z"
        fill="#FFB8B8"
      />
      <path
        d="M10.7359 108.103C10.5638 108.103 10.3923 108.08 10.2256 108.037C9.86689 107.943 9.53961 107.751 9.27834 107.482C9.01708 107.213 8.83154 106.876 8.74137 106.508L5.74332 94.2831C4.69558 90.0134 5.19504 85.4985 7.14879 81.5784L13.4298 68.9708C13.8268 67.4613 14.7862 66.1724 16.1009 65.382C17.4157 64.5917 18.9805 64.3633 20.4579 64.746C21.1943 64.9368 21.8864 65.2757 22.4941 65.743C23.1019 66.2104 23.6132 66.7969 23.9984 67.4685C24.3915 68.1498 24.6486 68.9045 24.7547 69.6887C24.8608 70.4729 24.8138 71.2709 24.6165 72.0363L15.6754 92.5517L15.7816 104.835C15.7843 105.268 15.6574 105.692 15.4178 106.049C15.1783 106.406 14.8377 106.679 14.4422 106.83L11.45 107.971C11.2216 108.058 10.9797 108.103 10.7359 108.103Z"
        fill="#F37920"
      />
      <path
        d="M53.535 110.574C52.9892 110.51 52.4638 110.324 51.9956 110.029C51.5275 109.734 51.1281 109.338 50.8255 108.867C50.5229 108.397 50.3246 107.864 50.2444 107.306C50.1642 106.749 50.2041 106.18 50.3614 105.64L39.5273 97.6302L46.013 95.0989L55.3069 103.093C56.1736 103.424 56.8918 104.07 57.3254 104.908C57.759 105.747 57.8778 106.719 57.6594 107.641C57.441 108.563 56.9005 109.371 56.1403 109.912C55.3802 110.452 54.4532 110.688 53.535 110.574H53.535Z"
        fill="#FFB8B8"
      />
      <path
        d="M47.8633 104.726C47.5439 104.725 47.2289 104.649 46.9431 104.503L35.9574 98.8596C32.1203 96.8889 29.1112 93.5554 27.4898 89.479L22.2743 76.3696C21.4184 75.076 21.0922 73.4891 21.3661 71.951C21.6399 70.4129 22.4919 69.0468 23.7382 68.1473C24.361 67.698 25.0646 67.3804 25.8082 67.213C26.5518 67.0455 27.3204 67.0317 28.0692 67.1721C28.8269 67.3119 29.55 67.6046 30.1966 68.0332C30.8432 68.4618 31.4005 69.0178 31.8362 69.669L41.1575 90.0056L50.2997 97.9358C50.6216 98.2163 50.8518 98.5915 50.9592 99.0106C51.0666 99.4297 51.0459 99.8726 50.9001 100.279L49.7903 103.35C49.6455 103.753 49.3837 104.1 49.0404 104.345C48.6953 104.593 48.2844 104.726 47.8633 104.726Z"
        fill="#F37920"
      />
      <path
        d="M26.1015 62.1701C26.5891 62.389 27.5877 58.4021 27.1404 57.6047C26.4751 56.4188 26.5145 56.4273 26.0729 55.6427C25.6312 54.8581 25.5324 53.7842 26.1043 53.0938C26.6762 52.4035 27.9996 52.4951 28.2606 53.3606C28.0927 51.7166 29.6775 50.395 31.2473 50.0236C32.8172 49.6523 34.473 49.8828 36.0515 49.5523C37.8834 49.1688 39.7894 47.5932 39.0585 45.5261C38.9331 45.182 38.7453 44.8653 38.5051 44.5928C37.6606 43.6186 36.4794 43.2026 35.3244 42.8003C32.9217 41.9635 30.4532 41.1162 27.9174 41.2086C25.8281 41.3207 23.8159 42.0553 22.1271 43.3226C20.4383 44.5898 19.1461 46.3348 18.4088 48.3438C18.234 48.8464 18.0987 49.3625 18.0039 49.8872C17.1336 54.6893 19.8513 59.426 24.2653 61.2546L26.1015 62.1701Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.2"
        d="M15.2642 105.114L15.4723 92.4878L21.9903 79.0408L16.7589 92.844L15.2642 105.114Z"
        fill="black"
      />
      <path
        d="M74.3711 136.799L46.8545 125.068C46.2513 124.813 45.7128 124.421 45.2798 123.92C44.8469 123.419 44.5309 122.824 44.3561 122.179C44.1763 121.527 44.1439 120.841 44.2614 120.174C44.3789 119.507 44.6432 118.876 45.0341 118.33C51.9836 108.511 56.5831 97.152 58.4603 85.1711C58.562 84.5014 58.8112 83.8642 59.189 83.3081C59.5668 82.752 60.0632 82.2917 60.6405 81.9622C61.2093 81.6343 61.8434 81.4439 62.4947 81.4055C63.146 81.367 63.7972 81.4815 64.3988 81.7402L91.9155 93.4713C92.8644 93.8791 93.6415 94.6193 94.1082 95.56C94.5749 96.5008 94.7011 97.581 94.4642 98.6083C91.3883 111.449 86.415 123.731 79.7171 135.028C79.1748 135.925 78.3398 136.596 77.3608 136.92C76.3818 137.244 75.3223 137.202 74.3711 136.799Z"
        fill="#E6E6E6"
      />
      <path
        d="M63.5383 83.8647C63.2494 83.7404 62.9366 83.6853 62.6239 83.7036C62.3111 83.722 62.0065 83.8133 61.7333 83.9706C61.4525 84.1314 61.2111 84.3558 61.0275 84.6268C60.8439 84.8977 60.723 85.208 60.6739 85.5342C58.7405 97.873 54.0036 109.572 46.8464 119.683C46.6558 119.949 46.5268 120.256 46.4692 120.58C46.4117 120.905 46.427 121.239 46.5141 121.556L46.5142 121.557C46.5982 121.866 46.75 122.152 46.958 122.393C47.166 122.633 47.4247 122.822 47.7144 122.944L75.2311 134.675C75.6872 134.87 76.196 134.892 76.6666 134.738C77.1373 134.583 77.5393 134.263 77.8011 133.833C84.3802 122.736 89.2653 110.672 92.2868 98.0586C92.3989 97.5649 92.3367 97.0464 92.1111 96.5954C91.8855 96.1443 91.5113 95.7901 91.055 95.5958L63.5383 83.8647Z"
        fill="white"
      />
      <path
        d="M78.19 109.979L63.7504 103.823C63.2017 103.588 62.7662 103.14 62.5393 102.576C62.3124 102.012 62.3128 101.379 62.5402 100.816L62.8764 99.9854C63.105 99.4224 63.542 98.9755 64.0914 98.7427C64.6408 98.5099 65.2578 98.5103 65.8069 98.7437L80.2466 104.9C80.7952 105.134 81.2308 105.583 81.4577 106.146C81.6845 106.71 81.6842 107.343 81.4567 107.907L81.1206 108.737C80.8919 109.3 80.4549 109.747 79.9055 109.98C79.3561 110.212 78.7392 110.212 78.19 109.979Z"
        fill="#004C97"
      />
      <path
        d="M73.9427 120.468L59.5031 114.312C58.9544 114.078 58.5189 113.629 58.292 113.066C58.0651 112.502 58.0654 111.869 58.2929 111.305L58.6291 110.475C58.8577 109.912 59.2947 109.465 59.8441 109.232C60.3935 109 61.0105 109 61.5596 109.233L75.9993 115.389C76.5479 115.624 76.9835 116.072 77.2103 116.636C77.4372 117.2 77.4369 117.833 77.2094 118.396L76.8733 119.227C76.6446 119.79 76.2077 120.236 75.6582 120.469C75.1088 120.702 74.4919 120.702 73.9427 120.468Z"
        fill="#004C97"
      />
      <path
        d="M83.9756 95.236C83.7595 95.2359 83.5454 95.1928 83.3451 95.1093L68.7951 89.0732C68.382 88.9012 68.0522 88.5682 67.8781 88.1471C67.704 87.726 67.6998 87.2512 67.8664 86.8269L68.9869 83.9831C69.1541 83.5589 69.4787 83.2202 69.8892 83.0415C70.2998 82.8628 70.7627 82.8588 71.1761 83.0303L85.7262 89.0664C86.1393 89.2384 86.4691 89.5714 86.6432 89.9925C86.8173 90.4136 86.8215 90.8884 86.6549 91.3127L85.5344 94.1565C85.4085 94.475 85.193 94.7478 84.9154 94.9401C84.6377 95.1323 84.3106 95.2354 83.9756 95.236Z"
        fill="#CCCCCC"
      />
      <path
        d="M53.4164 185.693H1.10219C1.00308 185.693 0.908045 185.652 0.837967 185.58C0.76789 185.508 0.728516 185.411 0.728516 185.309C0.728516 185.208 0.76789 185.11 0.837967 185.038C0.908045 184.966 1.00308 184.926 1.10219 184.926H53.4164C53.5155 184.926 53.6106 184.966 53.6807 185.038C53.7508 185.11 53.7901 185.208 53.7901 185.309C53.7901 185.411 53.7508 185.508 53.6807 185.58C53.6106 185.652 53.5155 185.693 53.4164 185.693Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_2095_2884">
        <rect
          width="181.543"
          height="185.385"
          fill="white"
          transform="translate(0.728516 0.307373)"
        />
      </clipPath>
    </defs>
  </svg>
);
