import { FormEventHandler, useMemo, useState } from "react";
import { generatePath, useParams } from "react-router-dom";

import {
  CaseCategory,
  CasePriority,
  CaseSubcategory,
} from "@m/api/public/types";
import { Button, Buttons, DynamicSelect, Field, Fields, Textarea } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { useAwsAccounts } from "@mc/features/AccountSettings/api";
import { useCreateCase } from "@mc/features/SupportCases/api";
import { useNavigate } from "@mc/router";

import {
  EngineerAssistRequestForm,
  EngineerAssistSupportBanner,
  RequestTypeRadioGroup,
} from "../components";
import {
  MaintenanceWindowNeeded,
  PreferredContactMethods,
  RequestType,
} from "../types";
import { createRequestDescription } from "../utils";

export const CreateRequestPage = () => {
  const navigate = useNavigate();

  const {
    loading: isAccountsLoading,
    data: { accounts },
  } = useAwsAccounts();

  const [createCase, { loading }] = useCreateCase();

  const accountOptions = useMemo(() => {
    return accounts.map((account) => {
      const { databaseId, name } = account;
      return { value: databaseId, label: name, subLabel: databaseId };
    });
  }, [accounts]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const { requestType = RequestType.DevOpsDesk } = useParams<{
    requestType?: RequestType;
  }>();

  const handleClickCancel = () => {
    navigate(PATHS.ENGAGEMENTS_ENGINEER_ASSIST);
  };

  const handleSubmit: FormEventHandler<CreateRequestForm> = (e) => {
    e.preventDefault();
    const { elements } = e.currentTarget;

    submitRequest(elements);
  };

  const handleChangeRequestType = (value: RequestType) =>
    navigate(
      generatePath(PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE_REQUESTTYPE, {
        requestType: value,
      })
    );

  const submitRequest = (elements: CreateRequestFormElements) => {
    const {
      titleInput,
      descriptionInput,
      repositoryUrlInput,
      maintenanceWindowNeededRadio,
      desiredCompletionDateInput,
      preferredContactMethodRadio,
      schedulingLinkInput,
    } = elements;

    const title = titleInput.value;

    const repositoryUrl = repositoryUrlInput.value;
    const maintenanceWindowNeeded =
      maintenanceWindowNeededRadio.value as MaintenanceWindowNeeded;
    const desiredCompletionDate = desiredCompletionDateInput.value;
    const preferredContactMethod =
      preferredContactMethodRadio.value as PreferredContactMethods;
    const schedulingLink = schedulingLinkInput.value;

    const category =
      requestType === RequestType.DevOpsDesk ? "DevOps Desk" : "AI Desk";
    const subcategory =
      requestType === RequestType.DevOpsDesk ? "DevOps Desk" : "AI Desk";
    const priority = "Medium";

    const accountIds = selectedAccounts
      .map((account) => account.value)
      .join(", ");

    const description = createRequestDescription({
      accountIds,
      category,
      description: descriptionInput.value,
      desiredCompletionDate,
      maintenanceWindowNeeded,
      preferredContactMethod,
      priority,
      repositoryUrl,
      requestType,
      schedulingLink,
      subcategory,
    });

    createCase({
      shortDescription: title,
      description,
      // TODO(jamesmoody): Update this to the actual category and subcategory for AI Desk
      category:
        requestType === RequestType.DevOpsDesk
          ? CaseCategory.DevopsDesk
          : CaseCategory.Request,
      subcategory:
        requestType === RequestType.DevOpsDesk
          ? CaseSubcategory.DevopsDesk
          : CaseSubcategory.UnknownOrUndefined,
      priority: CasePriority.Moderate,
    });
  };

  return (
    <Page
      title={
        <PageTitle
          breadcrumbs={[
            {
              path: PATHS.ENGAGEMENTS_ENGINEER_ASSIST,
              name: "Engineer Assist",
            },
            {
              path: PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE,
              name: "New Request",
            },
          ]}
          padding="small"
        />
      }
      width="small"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-[40px]">
        <EngineerAssistSupportBanner className="w-full" />
        <Fields>
          <Textarea
            aria-label="Title"
            autoFocus={true}
            autogrow
            containerClasses="text-2xl"
            id="title"
            name="titleInput"
            noStyles
            nextFieldOnReturn={true}
            placeholder="Request title"
            required={true}
            rows={1}
          />
          <Textarea
            aria-label="Description"
            autogrow
            id="description"
            name="descriptionInput"
            noStyles
            placeholder="Add case description including any technologies and AWS services that will be used"
            required={true}
            rows={3}
          />
        </Fields>

        <hr />

        <RequestTypeRadioGroup
          requestType={requestType}
          onChange={handleChangeRequestType}
        />

        <Field
          htmlFor="accounts"
          label="Accounts"
          row="2/3"
          rowAlign="top"
          className="w-full"
        >
          <DynamicSelect
            id="accounts"
            name="accountsSelect"
            aria-label="Accounts Select"
            placeholder="Add accounts"
            initialOptions={accountOptions}
            selectedOptions={selectedAccounts}
            isMulti={true}
            isLoading={isAccountsLoading}
            onSelectedOptionsChange={setSelectedAccounts}
          />
        </Field>

        <EngineerAssistRequestForm />

        <Buttons>
          <Button kind="primary" loading={loading} type="submit">
            Create request
          </Button>
          <Button kind="primary" fill="none" onClick={handleClickCancel}>
            Cancel
          </Button>
        </Buttons>
      </form>
    </Page>
  );
};

interface CreateRequestFormElements extends HTMLFormControlsCollection {
  titleInput: HTMLInputElement;
  descriptionInput: HTMLInputElement;
  accountsInput: HTMLInputElement;
  repositoryUrlInput: HTMLInputElement;
  maintenanceWindowNeededRadio: HTMLInputElement;
  desiredCompletionDateInput: HTMLInputElement;
  preferredContactMethodRadio: HTMLInputElement;
  schedulingLinkInput: HTMLInputElement;
}

export interface CreateRequestForm extends HTMLFormElement {
  readonly elements: CreateRequestFormElements;
}
