const DinersClub = () => {
  return (
    <svg
      width="65"
      height="42"
      viewBox="0 0 65 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.9941 21.1219C49.9941 12.7709 43.0792 6.99895 35.5025 7.00147H28.9824C21.3155 6.99869 15.0049 12.7727 15.0049 21.1219C15.0049 28.7601 21.3156 35.0356 28.9824 34.9987H35.5025C43.0785 35.0354 49.9941 28.7581 49.9941 21.1219Z"
        fill="#0079BE"
      />
      <path
        d="M29.0234 8.18433C22.0176 8.18651 16.3409 13.9222 16.3384 21.001C16.3401 28.0787 22.0171 33.8136 29.0234 33.8161C36.0307 33.814 41.7092 28.0788 41.71 21.001C41.7091 13.922 36.0309 8.18685 29.0234 8.18433Z"
        fill="white"
      />
      <path
        d="M21.0059 20.9665C21.0125 17.5074 23.1514 14.5576 26.1675 13.3855V28.5458C23.1514 27.3744 21.0124 24.426 21.0059 20.9665ZM31.9225 28.5496V13.3859C34.9399 14.5553 37.0821 17.5067 37.0875 20.9674C37.082 24.4292 34.9399 27.3785 31.9225 28.55V28.5496Z"
        fill="#0079BE"
      />
    </svg>
  );
};

export default DinersClub;
