import {
  EngagementDecisionImpact,
  EngagementDecisionOwner,
  EngagementDecisionStatus,
} from "@m/api/public/types";

export const TABLE_HEADERS_ENGAGEMENT_DECISIONS = [
  { accessor: "status", width: 24, cellClasses: "px-1" },
  { accessor: "title", cellClasses: "px-1" },
  { accessor: "impact", width: 24 },
  { accessor: "dueDate", width: 140, cellClasses: "text-right" },
  { accessor: "owner", width: 24 },
  { accessor: "details", width: 10 },
];

export const MESSAGES_ENGAGEMENTS_DECISIONS = {
  EMPTY_DECISIONS: "Unable to find Decision data",
  GET_ITEMS_ERROR: "Unable to access engagement Decisions data",
  NO_DECISION_DETAILS: "No Decision details found",
  UPDATE_ERROR: "Unable to update Decision data",
  UPDATE_SUCCESS: "Successfully updated Decision data",
};
export const DEFAULT_SORT_ENGAGEMENTS_DECISIONS = "DUE_DATE_ASC";

export const DEFAULT_INITIAL_ENGAGEMENT_DECISION = {
  owner: EngagementDecisionOwner.Mission,
  impact: EngagementDecisionImpact.Low,
  status: EngagementDecisionStatus.Open,
};
