export const GreenCheckIcon = () => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12.0004" cy="12.0004" r="9.6" className="fill-emerald-100" />
      <path
        d="M15.8969 8.95996C13.8793 10.8882 12.1385 13.1038 10.7404 15.541L8.09961 12.9003"
        className="stroke-emerald-900"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
