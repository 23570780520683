import {
  Arrow,
  Content,
  TooltipContentProps as ContentProps,
  Portal,
  Root,
  TooltipProps as RootProps,
  Trigger,
  TooltipTriggerProps as TriggerProps,
} from "@radix-ui/react-tooltip";
import clsx from "clsx";

export interface TooltipProps {
  /* The axis the tooltip should display */
  align?: ContentProps["align"];

  /* Use child as trigger */
  asChild?: TriggerProps["asChild"];

  /* Element to wrap in a Tooltip */
  children: TriggerProps["children"];

  /** Additional classes */
  className?: string;

  /** Tooltip Color */
  color?: "dark" | "light";

  /* The content of the Tooltip */
  content: ContentProps["children"];

  /* Initial show/hide state */
  defaultOpen?: RootProps["defaultOpen"];

  /* The duration from when the pointer enters the trigger until the tooltip gets opened. This will override the prop with the same name passed to Provider. */
  delayDuration?: RootProps["delayDuration"];

  /* Disallow the Tooltip from displaying */
  disabled?: boolean;

  /* Force Tooltip to hide or show */
  open?: RootProps["open"];

  /* Callback for when open state changes */
  onOpenChange?: RootProps["onOpenChange"];

  /* Which side of the child Tooltip should display */
  side?: ContentProps["side"];

  /* Additional props for the Tooltip */
  [key: string]: any;
}

export const Tooltip = ({
  align,
  asChild,
  children,
  className,
  color = "dark",
  content,
  defaultOpen,
  delayDuration = 300,
  disabled,
  onOpenChange,
  open,
  side,
  ...props
}: TooltipProps) => {
  if (disabled) return <>{children}</>;

  const contentClasses = clsx(
    "z-50 rounded p-1 text-xs font-semibold",
    className,
    {
      "bg-[#0C1D28] text-inverse antialiased": color === "dark",
      "bg-white text-default shadow border": color === "light",
    }
  );

  return (
    <Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <Trigger asChild={asChild} className={"cursor-default"}>
        {children}
      </Trigger>
      <Portal>
        <Content
          side={side}
          align={align}
          sideOffset={4}
          className={contentClasses}
          {...props}
        >
          {content}
          {color === "dark" && (
            <Arrow
              width={14}
              height={5}
              style={{
                fill: "#0C1D28",
              }}
            />
          )}
        </Content>
      </Portal>
    </Root>
  );
};
