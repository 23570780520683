export const getFirstSentence = (description = "") => {
  // ^      asserts position at start of a line
  // .      matches any character (except for line terminators)
  // *?     matches the previous token between zero and unlimited times, as few times as possible, expanding as needed (lazy)
  // [.]    followed by a single character [.]
  // (?!\d) negative lookahead, it will skip a period followed by a digit eg. (0.0.0.0)

  const firstSentence = description.match(/^.*?[.](?!\d)/)[0];

  return firstSentence || "";
};
