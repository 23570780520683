import { CHECK_SOURCES, CHECK_STATUS } from "./checks";
import { CloudScorePillarIds } from "./scorecardDetails";

export interface CheckListFilter {
  id: string;
  key: string;
  label: string;
  multiple: boolean;
  selected: string | string[];
  options: CheckListFilterOption[];
}

export interface CheckListFilterOption {
  id: string;
  label: string;
}

export const CHECK_LIST_FILTERS: Record<string, CheckListFilter> = {
  STATUS: {
    id: "Check Status",
    key: "status",
    label: "Status",
    multiple: true,
    selected: [],
    options: [
      { label: "Critical", id: CHECK_STATUS.ERROR },
      { label: "Warning", id: CHECK_STATUS.WARNING },
      { label: "Optimized", id: CHECK_STATUS.OK },
      { label: "Ignored", id: CHECK_STATUS.IGNORED },
    ],
  },
  SOURCE: {
    id: "Check Source",
    key: "source",
    label: "Source",
    multiple: true,
    selected: [],
    options: [
      { label: "Trusted Advisor", id: CHECK_SOURCES.AWS_TRUSTED_ADVISOR.key },
      {
        label: "Compute Optimizer",
        id: CHECK_SOURCES.AWS_COMPUTE_OPTIMIZER.key,
      },
    ],
  },
  SECURITY_SOURCE: {
    id: "Check Source",
    key: "source",
    label: "Source",
    multiple: true,
    selected: [],
    options: [
      { label: "Trusted Advisor", id: CHECK_SOURCES.AWS_TRUSTED_ADVISOR.key },
      { label: "Security Hub", id: CHECK_SOURCES.AWS_SECURITY_HUB.key },
    ],
  },
};

export const CHECK_LIST_CATEGORY_FILTERS = {
  [CloudScorePillarIds.AllChecks]: [
    CHECK_LIST_FILTERS.SOURCE,
    CHECK_LIST_FILTERS.STATUS,
  ],
  [CloudScorePillarIds.CostOptimization]: [
    CHECK_LIST_FILTERS.SOURCE,
    CHECK_LIST_FILTERS.STATUS,
  ],
  [CloudScorePillarIds.Operations]: [CHECK_LIST_FILTERS.STATUS],
  [CloudScorePillarIds.Security]: [
    CHECK_LIST_FILTERS.SECURITY_SOURCE,
    CHECK_LIST_FILTERS.STATUS,
  ],
  [CloudScorePillarIds.Reliability]: [CHECK_LIST_FILTERS.STATUS],
  [CloudScorePillarIds.Performance]: [
    CHECK_LIST_FILTERS.SOURCE,
    CHECK_LIST_FILTERS.STATUS,
  ],
};
