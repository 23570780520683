import { FormEventHandler, useState } from "react";

import { Button, Field, Fields, Input } from "@m/ui";

import { Auth0Login } from "@mc/app/components";
import { WORK_EMAIL_PATTERN } from "@mc/utils/emails";

interface CreateAccountFormProps {
  companyName?: string;
  onSubmit: (elements: CreateAccountFormElements) => void;
}

export const CreateAccountForm = ({
  companyName,
  onSubmit,
}: CreateAccountFormProps) => {
  const [showLogin, setShowLogin] = useState(false);

  const handleSubmit: FormEventHandler<CreateAccountFormElement> = (e) => {
    e.preventDefault();
    onSubmit(e.currentTarget.elements);
  };

  const handleClickSignIn = () => {
    setShowLogin(true);
  };

  if (showLogin) return <Auth0Login fitToParent />;

  return (
    <div className="flex flex-col items-center gap-y-5">
      <form
        className="flex flex-col items-center gap-y-5"
        data-testid="create-account-form"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-4xl font-bold">
            Create your Mission Control account
          </h1>

          <p className="text-xl text-subdued">
            {companyName ? (
              <>
                Create your account to join{" "}
                <span className="font-semibold text-default">
                  {companyName}
                </span>
              </>
            ) : (
              <>Please provide the details below to get started</>
            )}
          </p>
        </div>

        <Fields className="w-full max-w-md">
          <Field label="First Name" htmlFor="first-name">
            <Input
              autoComplete="given-name"
              autoFocus={true}
              id="first-name"
              name="firstNameInput"
              pattern="[a-z A-Z]+"
              required={true}
              title="First Name can not contain numbers or symbols."
            />
          </Field>
          <Field label="Last Name" htmlFor="last-name">
            <Input
              autoComplete="family-name"
              id="last-name"
              name="lastNameInput"
              pattern="[a-z A-Z]+"
              required={true}
              title="Last Name can not contain numbers or symbols."
            />
          </Field>
          {!companyName && (
            <Field label="Company Name" htmlFor="company-name">
              <Input
                autoComplete="organization"
                id="company-name"
                name="companyNameInput"
                required={true}
                type="text"
              />
            </Field>
          )}
          <Field label="Work Email" htmlFor="work-email">
            <Input
              autoComplete="email"
              id="work-email"
              name="workEmailInput"
              pattern={WORK_EMAIL_PATTERN}
              required={true}
              title="Please enter a valid company email address."
              type="email"
            />
          </Field>
        </Fields>

        <Button
          className="w-full max-w-md"
          kind="primary"
          size="large"
          type="submit"
        >
          Create your account
        </Button>
      </form>

      {!companyName && (
        <div className="flex w-full flex-col items-center gap-y-2">
          <div className="text-sm font-medium text-subdued">
            Already have an account?
          </div>
          <Button className="w-full max-w-md" onClick={handleClickSignIn}>
            Sign in
          </Button>
        </div>
      )}
    </div>
  );
};

export interface CreateAccountFormElements extends HTMLFormControlsCollection {
  companyNameInput?: HTMLInputElement;
  firstNameInput: HTMLInputElement;
  lastNameInput: HTMLInputElement;
  workEmailInput: HTMLInputElement;
}

interface CreateAccountFormElement extends HTMLFormElement {
  readonly elements: CreateAccountFormElements;
}
