import { RadioGroup } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

import { Badge, Tooltip } from "@m/ui";

import { CircleCheckbox } from "@mc/components/CircleCheckbox";
import { RequestType } from "@mc/features/EngineerAssist/types";

import { CaseType } from "../types";

interface RadioGroupOptionProps {
  label: string;
  popupContent?: ReactNode;
  showNew?: boolean;
  value: CaseType | RequestType;
  disabled?: boolean;
}

export const RadioGroupOption = ({
  value,
  label,
  showNew,
  popupContent,
  disabled = false,
}: RadioGroupOptionProps) => {
  return (
    <RadioGroup.Option
      value={value}
      disabled={disabled}
      className={clsx("w-full rounded-md", {
        "form-control__focus-within": !disabled,
        "opacity-50": disabled,
      })}
    >
      {({ checked }) => (
        <button
          type="button"
          disabled={disabled}
          className={clsx(
            "flex h-full w-full items-center gap-1.5 rounded-md border p-2 text-left",
            {
              "text-default": !checked,
              "border-blue-500 bg-blue-50 text-action": checked,
            }
          )}
        >
          <CircleCheckbox checked={checked} size="small" />
          <div className="flex grow flex-wrap gap-0.5">
            <div className="text-[13px] font-semibold">{label}</div>
            {showNew && (
              <div className="flex items-center">
                <Badge label="New" status="positive" strong size="small" />
              </div>
            )}
          </div>
          {popupContent && (
            <Tooltip
              asChild
              className="p-2"
              color="light"
              content={popupContent}
              side="right"
            >
              <InformationCircleIcon className="h-2 w-2 shrink-0 text-accent hover:cursor-help" />
            </Tooltip>
          )}
        </button>
      )}
    </RadioGroup.Option>
  );
};
