import { ACTIVITY_NAMESPACES } from "../constants";

import { ActivityFeedItem } from "./ActivityFeedItem";

export const ActivityFeedItemUserInvitation = ({ activity }) => {
  const {
    timestamp,
    account: { email, name },
  } = activity;

  const category = ACTIVITY_NAMESPACES.team.title;

  const message = (
    <>
      <strong>{name.trim() || email}</strong> was invited
    </>
  );

  return (
    <ActivityFeedItem
      timestamp={timestamp}
      category={category}
      message={message}
    />
  );
};
