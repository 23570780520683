import clsx from "clsx";

export const Placeholder = ({ opacity, loading }) => (
  <div
    data-testid={loading ? "placeholder-loading" : "placeholder"}
    style={{ opacity: loading ? 1 : opacity }}
    className={clsx("h-2 w-full rounded-full bg-gray-100", {
      "animate-pulse": loading,
    })}
  />
);
