import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";

import { StoredPaymentMethod } from "../components";

export const BillingSettingsPage = () => (
  <Page data-testid="billing-settings-page">
    <PageHeading heading="Billing" />

    <div className="flex flex-col gap-y-5">
      <Section title="Payment Methods">
        <StoredPaymentMethod />
      </Section>
    </div>
  </Page>
);

const Section = ({ children, title }) => (
  <div className="flex flex-col gap-y-1">
    <div className="text-sm font-semibold text-subdued">{title}</div>
    {children}
  </div>
);
