import {
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { EngagementDecisionStatus } from "@m/api/public/types";
import { Dropdown, DropdownItem } from "@m/ui";

import { PATHS } from "@mc/constants";

import { useUpdateEngagementsDecision } from "../api";
import { EngagementDecisionType } from "../types";

export const DecisionAction = ({
  decision,
  engagementId,
}: {
  decision: EngagementDecisionType;
  engagementId: string;
}) => {
  const navigate = useNavigate();

  const { id: decisionId, status } = decision;

  const [updateDecision, { loading }] =
    useUpdateEngagementsDecision(engagementId);

  const handleEdit = () => {
    const editPath = generatePath(PATHS._ENGAGEMENT_DECISION_EDIT, {
      engagementId,
      decisionId,
    });
    navigate("../" + editPath);
  };

  const handleUpdate = (e: ChangeEvent<HTMLButtonElement>) => {
    const newStatus = e.target.value as EngagementDecisionStatus;
    const { ...decisionCopy } = decision;

    updateDecision({
      ...decisionCopy,
      status: newStatus,
    });
  };

  return (
    <Dropdown
      className="w-[165px] p-1"
      trigger={
        <EllipsisVerticalIcon
          aria-label="Decision Actions"
          className="h-3 w-3"
        />
      }
      direction="bottom-end"
    >
      <DropdownItem className="p-1" onClick={handleEdit}>
        <PencilIcon className="mr-1 h-2.5 w-2.5 text-accent" />
        Edit
      </DropdownItem>

      {status !== EngagementDecisionStatus.Archived && (
        <DropdownItem
          className="p-1"
          value={EngagementDecisionStatus.Archived}
          onClick={handleUpdate}
          disabled={loading}
        >
          <ArchiveBoxIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Archive
        </DropdownItem>
      )}

      {status === EngagementDecisionStatus.Archived && (
        <DropdownItem
          className="p-1"
          value={EngagementDecisionStatus.Open}
          onClick={handleUpdate}
          disabled={loading}
        >
          <ArchiveBoxIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Unarchive
        </DropdownItem>
      )}

      {status === EngagementDecisionStatus.Completed && (
        <DropdownItem
          className="p-1"
          value={EngagementDecisionStatus.Open}
          onClick={handleUpdate}
          disabled={loading}
        >
          <ArrowUturnLeftIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Reopen
        </DropdownItem>
      )}
    </Dropdown>
  );
};
