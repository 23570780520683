import { EmptyState } from "@mc/components/EmptyState";

import { CloudScoreNoAccessIcon } from "../icons";

export const CloudScoreConfigurationError = () => {
  return (
    <EmptyState
      image={<CloudScoreNoAccessIcon width={300} height={220} />}
      title="We need additional roles and permissions."
      description="Please reach out to your CSM for help configuring your account."
    />
  );
};
