import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

import { GET_TEAM_MEMBERS } from "./useTeamMembers";

export const REMOVE_USER = gql`
  mutation useRemoveUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      ok
      message
      account {
        email
      }
    }
  }
`;

export const useRemoveUser = () => {
  const onError = () => toast.error(MESSAGES.REMOVE_USER_ACCESS_ERROR);

  const onCompleted = (data) =>
    toast.success(
      `${
        data.removeUser?.account?.email || "User"
      } was removed from Mission Control.`
    );

  const [mutate] = useMutation(REMOVE_USER, {
    refetchQueries: [{ query: GET_TEAM_MEMBERS }],
    onError,
    onCompleted,
  });

  function removeUser(accountId) {
    mutate({
      variables: {
        input: { accountId },
      },
    });
  }

  return [removeUser];
};
