import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";

import { Button } from "@m/ui";

import { SimpleTable } from "@mc/components/SimpleTable";

export const GET_CUSTOMER_REPOS_EMPTY =
  "There are no connected repositories for the current company. " +
  "Go to the Connections tab to claim a new git connection for this company.";

const EmptyMessage = () => {
  return (
    <div className="col-span-4 m-2 rounded-md border border-dashed p-2">
      <p className="mx-6 text-center text-gray-500">
        {GET_CUSTOMER_REPOS_EMPTY}
      </p>
    </div>
  );
};

const RevokeButton = ({
  collaborator,
  repository,
  revokeAccess,
  revokeAccessLoading,
}) => {
  const onRevoke = () => revokeAccess(collaborator.account, repository);
  return (
    <Button kind="destructive" onClick={onRevoke} loading={revokeAccessLoading}>
      Remove
    </Button>
  );
};

const CollaboratorsCell = ({ collaborators, ...props }) => {
  return (
    <div className="flex flex-col space-y-0.5">
      {collaborators &&
        collaborators.map((collaborator) => {
          return (
            <div key={collaborator.id} className="flex space-x-4">
              <p>{collaborator.account.name}</p>
              <RevokeButton collaborator={collaborator} {...props} />
            </div>
          );
        })}
    </div>
  );
};

export const CustomerRepositoriesTable = ({ repositories, ...props }) => {
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "access",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Collaborators",
        accessor: "collaborators",
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      repositories.map((repository) => {
        return {
          access: repository.private && (
            <LockClosedIcon className="h-2.5 w-2.5" />
          ),
          name: repository.fullName,
          collaborators: (
            <CollaboratorsCell
              repository={repository}
              collaborators={
                repository.collaborators?.edges.map((edge) => edge.node) || []
              }
              {...props}
            />
          ),
        };
      }),
    [repositories, props]
  );

  return (
    <>
      <SimpleTable
        columns={columns}
        data={data}
        label="Customer Repositories"
      />
      {repositories.length === 0 && <EmptyMessage />}
    </>
  );
};
