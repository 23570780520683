import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const GET_CLOUD_SCORE_DETAILS = gql(`
  query CloudScoreDetails {
    scorecards(first: 1) {
      edges {
        node {
          id
          summaries {
            edges {
              node {
                id
                name
                pillar
                score
                scoreChange
                totalChecks
                ok
                warning
                error
              }
            }
          }
        }
      }
    }
  }
`);

export const useCloudScoreDetails = () => {
  const { data, ...result } = useQuery(GET_CLOUD_SCORE_DETAILS);

  const scorecard = data?.scorecards?.edges.map((edge) => edge.node)[0];
  const summaries = scorecard?.summaries?.edges.map((edge) => edge.node) || [];

  return {
    data: { summaries },
    ...result,
  };
};
