import { LoginError } from "@m/login";
import { useAuth0Login } from "@m/login/hooks";
import { SpinnerScreen } from "@m/ui";

import { NoActiveSubscriptionFoundError } from "@mc/pages";

import { LocalLogin } from "./LocalLogin";

export const MissionControlLogin = () => {
  return process.env.REACT_APP_SKIP_AUTH0 ? <LocalLogin /> : <Auth0Login />;
};

export const Auth0Login = ({ fitToParent }: { fitToParent?: boolean }) => {
  const { error, redirectToAuth0Login } = useAuth0Login();

  if (error) {
    return error === 402 ? (
      <NoActiveSubscriptionFoundError />
    ) : (
      <LoginError error={error} onRetry={redirectToAuth0Login} />
    );
  }

  return (
    <SpinnerScreen
      data-testid="mission-control-login"
      fitToParent={fitToParent}
    />
  );
};
