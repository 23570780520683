import { useTable } from "react-table";

export function SimpleTable({ columns = [], data = [], label = "" }) {
  const table = useTable({ columns, data });

  return (
    <table
      {...table.getTableProps([
        {
          className: "w-full text-left",
          "aria-label": label,
        },
      ])}
    >
      <thead>
        {table.headerGroups.map((headerGroup, headerGroupIndex) => (
          <tr
            key={`header-group-${headerGroupIndex}`}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, columnIndex) => (
              <th
                key={`header-group-${headerGroupIndex}-column-${columnIndex}`}
                {...column.getHeaderProps([
                  {
                    className: "border-b-2 pb-2",
                  },
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...table.getTableBodyProps()}>
        {table.rows.map((row, rowIndex) => {
          table.prepareRow(row);
          return (
            <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => (
                <td
                  key={`row-${rowIndex}-cell-${cellIndex}`}
                  {...cell.getCellProps([
                    {
                      className: "pt-2",
                    },
                  ])}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
