import { ChangeEvent, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Drawer, Input, SpinnerScreen } from "@m/ui";
import { dt, formatFullDate } from "@m/utils";

import { useEngagementsActivityStream } from "../api";
import { ENGAGEMENT_ACTIVITY_FEED_GROUPS } from "../constants";
import { EngagementActivityStreamNode } from "../types";

import { EngagementActivityFeedGroup } from "./EngagementActivityFeedGroup";

export const EngagementActivityFeedDrawer = () => {
  const navigate = useNavigate();
  const { engagementId } = useParams();

  const [cutoff, setCutoff] = useState(dt.utc().minus({ days: 30 }).toISO());

  const {
    data: { activities },
    loading,
  } = useEngagementsActivityStream({ cutoff });

  const filteredActivities = useMemo(() => {
    return activities.filter(
      (activity: any) => activity?.engagement?.id === engagementId
    );
  }, [activities, engagementId]);

  const onClose = () => navigate("..");

  const handleDateChange = (date: ChangeEvent<HTMLInputElement>) => {
    const formattedDate = dt.fromISO(date.target.value).toISO();
    setCutoff(formattedDate);
  };

  return (
    <Drawer onClose={onClose} open={true} title="Activity">
      <div className="mx-3 mt-2 flex flex-col">
        <div className="flex-col space-y-1">
          <span className="font-semibold">From</span>
          {/* TODO(jamesmoody): Update this to use DateRangePicker when the query has support for it */}
          <div className="w-1/3">
            <Input
              onChange={(date) => {
                handleDateChange(date);
              }}
              placeholder={cutoff}
              type="date"
              value={formatFullDate(cutoff, "yyyy-MM-dd")}
            />
          </div>
        </div>
        {loading && <SpinnerScreen fitToParent className="m-auto my-5" />}
        {!loading && filteredActivities.length === 0 && (
          <EngagementActivityFeedEmpty />
        )}
        {!loading && (
          <EngagementsActivityFeed activities={filteredActivities} />
        )}
      </div>
    </Drawer>
  );
};

const EngagementsActivityFeed = ({
  activities,
}: {
  activities: Array<EngagementActivityStreamNode>;
}) => {
  return (
    <>
      {Object.values(ENGAGEMENT_ACTIVITY_FEED_GROUPS).map(({ id, title }) => {
        return (
          <EngagementActivityFeedGroup
            key={id}
            group={id}
            title={title}
            activities={activities}
          />
        );
      })}
    </>
  );
};

const EngagementActivityFeedEmpty = () => {
  return (
    <div className="mt-4 rounded-md border border-dashed bg-gray-50 px-3 py-2">
      <div className="font-semibold text-default">No recent activity found</div>
      <div className="text-sm text-subdued">
        Activities will appear here when found within the selected date range
      </div>
    </div>
  );
};
