import { gql } from "@m/api/public";

import { useQuery } from "@mc/utils/graphql";

export const GET_STORED_PAYMENT_METHOD = gql(/* GraphQL */ `
  query StoredPaymentMethod {
    customerPaymentProfile(first: 1) {
      edges {
        node {
          expirationDate
          maskedCreditCardNumber
          cardType
        }
      }
    }
  }
`);

export const useStoredPaymentMethod = () => {
  const { data, ...result } = useQuery(GET_STORED_PAYMENT_METHOD);

  const storedPaymentMethod = data?.customerPaymentProfile?.edges?.map(
    (edge) => edge.node
  )?.[0];

  return { data: { storedPaymentMethod }, ...result };
};
