import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS_DECISIONS } from "../constants";
import { EngagementDecisionType } from "../types";

export const GET_ENGAGEMENTS_DECISION_ITEM = gql(/* GraphQL */ `
  query EngagementDecisionItem($id: ID!, $where: EngagementDecisionFilter) {
    engagement(id: $id) {
      id
      decisions(where: $where) {
        edges {
          node {
            id
            dueDate
            owner
            title
            description
            status
            completionNotes {
              edges {
                node {
                  id
                  content
                }
              }
            }
            latestCompletionNote {
              id
              content
            }
            noteList {
              edges {
                node {
                  id
                  content
                  createdAt
                  updatedAt
                }
              }
            }
            createTime
            createdBy {
              id
            }
            impact
            completedTime
          }
        }
      }
    }
  }
`);

export interface EngagementsDecisionParams {
  sort?: string;
  decisionId: string;
}

export const useEngagementsDecisionItem = (
  engagementId: string,
  params?: EngagementsDecisionParams
): {
  data: { decisionItem: EngagementDecisionType };
  loading: boolean;
} => {
  const where = {} as { id: string };

  if (params?.decisionId) where.id = params.decisionId;

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_DECISIONS.GET_ITEMS_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENTS_DECISION_ITEM, {
    variables: {
      id: engagementId,
      where,
    },
    onError,
  });

  const decisionItems =
    data?.engagement?.decisions?.edges?.map((edge) => {
      const item = {
        ...edge.node,
        noteList: (edge?.node?.noteList?.edges || []).map((edge) => edge.node),
        completionNotes: (edge?.node?.completionNotes?.edges || []).map(
          (edge) => edge?.node
        ),
      };
      return item;
    }) || [];

  return { data: { decisionItem: decisionItems[0] }, loading };
};
