export const checkForAll = (
  option: string[] | string = [],
  list: string[]
): boolean => {
  if (option.length === 0) return true;

  if (typeof option === "string") return list.includes(option);

  if (Array.isArray(option))
    return option.reduce<boolean>(
      (prev, curr) => prev && list.includes(curr),
      true
    );
};

export const checkForAny = (
  option: string[] | string = [],
  list: string[]
): boolean => {
  if (option.length === 0) return false;

  if (typeof option === "string") return list.includes(option);

  if (Array.isArray(option))
    return option.reduce<boolean>(
      (prev, curr) => prev || list.includes(curr),
      false
    );
};
