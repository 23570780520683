import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_CUSTOMER_CONNECTIONS } from "./useCustomerConnections";
import { GET_UNCLAIMED_CONNECTIONS } from "./useUnclaimedConnections";

export const CLAIM_CONNECTION_FAILURE =
  "We encountered a problem claiming this git connection.";

export const CLAIM_CONNECTION = gql`
  mutation useClaimConnection($input: ReviewGitHubAppInstallationInput!) {
    reviewGitHubAppInstallation(input: $input) {
      ok
      message
    }
  }
`;

export const useClaimConnection = () => {
  const onError = () => {
    toast.error(CLAIM_CONNECTION_FAILURE);
  };

  const [mutate, { loading }] = useMutation(CLAIM_CONNECTION, {
    context: { clientName: "v4" },
    onError,
    refetchQueries: [
      { query: GET_CUSTOMER_CONNECTIONS },
      { query: GET_UNCLAIMED_CONNECTIONS },
    ],
  });

  const claimConnection = (connection, company) => {
    if (window.confirm(`Claim git connection to ${company.name}?`)) {
      mutate({
        variables: {
          input: {
            installationId: connection.id,
            companyId: company.id,
            isValid: true,
          },
        },
      });
    }
  };

  return [claimConnection, { loading }];
};
