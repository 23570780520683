export const GET_RESOURCES_EMPTY = (label) => `No ${label} Found.`;

export const GET_RESOURCES_WITH_FILTERS_EMPTY = (label) =>
  `There are no ${label} that match the current filters.`;

export const GET_RESOURCES_WITH_SEARCH_TERM_EMPTY = (label) =>
  `There are no ${label} that match the search term.`;

export const GET_RESOURCES_WITH_FILTERS_AND_SEARCH_TERM_EMPTY = (label) =>
  `There are no ${label} that match the search term and current filters.`;

export const GET_RESOURCES_FAILURE = (label) =>
  `Unable to access your ${label} data.`;

export const GET_FILTERS_ERROR = (label) =>
  `Unable to access your ${label} filters data.`;

export const GET_SEARCH_TERMS_ERROR = (label) =>
  `Unable to access your ${label} search terms data.`;

export const ALL_ACCOUNTS_DISABLED =
  "We are currently unable to sync resources due to account configurations, please contact Mission for further details.";
export const SOME_ACCOUNTS_DISABLED =
  "Some account resources need additional account configuration to sync, please contact Mission for further details.";
export const ACCOUNTS_NOT_SYNCED =
  "We're synchronizing data from your AWS accounts soon. Hang tight!";
export const GET_AWS_ACCOUNTS_FAILURE =
  "We encountered an issue accessing your AWS account data.";

export const AWS_ACCOUNT_RESOURCES_MESSAGES = {
  GET_RESOURCES_FAILURE:
    "Unable to fetch AWS account resources for this company.",
};
