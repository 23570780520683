import { InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

interface BannerProps extends ComponentPropsWithoutRef<"div"> {
  /* Optional slot to pass in Buttons */
  actions?: ReactNode;

  align?: "center" | "top";

  /** The icon on the left of the Banner  */
  icon?: ElementType;

  /** The content of the Banner */
  label: ReactNode;

  /** Change color to indicate the type of Banner */
  status?: "info" | "warning" | "empty";

  /** An optional title for the Banner */
  title?: string;
}

export const Banner = ({
  actions = null,
  align = "center",
  className,
  icon: Icon = InformationCircleIcon,
  label,
  status = "info",
  title,
  ...props
}: BannerProps) => {
  const bannerClasses = clsx(
    "flex gap-2 p-2 border rounded-lg text-sm font-medium",
    className,
    {
      /* alignment */
      "items-center": align === "center",
      "items-top": align === "top",

      /* status */
      "bg-blue-50 border-blue-300 text-default": status === "info",
      "bg-yellow-50 border-yellow-300 text-yellow-900": status === "warning",
      "bg-zinc-50 border-dashed text-default": status === "empty",
    }
  );

  const iconClasses = clsx("h-2.5 w-2.5 shrink-0", {
    "text-action": status === "info",
    "text-yellow-600": status === "warning",
    "text-subdued": status === "empty",
  });

  const labelClasses = clsx({
    "text-subdued": status === "empty",
  });

  return (
    <div
      data-component="Banner"
      data-testid="banner"
      className={bannerClasses}
      {...props}
    >
      {Icon && <Icon className={iconClasses} />}
      <div className="flex w-full flex-col">
        {title && <div className="font-bold">{title}</div>}
        {label && <div className={labelClasses}>{label}</div>}
      </div>
      {actions}
    </div>
  );
};
