import { generatePath } from "react-router-dom";

import { ScorecardIgnoredCheckActivityStream } from "@m/api/public/types";

import { PATHS } from "@mc/constants";
import { SCORECARD_PILLARS } from "@mc/features/CloudScore/constants";
import { CheckMonitorIcon } from "@mc/features/CloudScore/icons";

import { CHECK_IGNORE_STATUS_MAP } from "../utils";

import { ActivityFeedItem } from "./ActivityFeedItem";

interface Props {
  activity: Pick<
    ScorecardIgnoredCheckActivityStream,
    "timestamp" | "operation" | "actor" | "checkDefinitionName" | "latestResult"
  >;
}

export const ActivityFeedItemScorecardIgnoredCheck = ({ activity }: Props) => {
  const { timestamp, operation, actor, checkDefinitionName, latestResult } =
    activity;

  const category = SCORECARD_PILLARS.COST_OPTIMIZATION.name;

  let link = null;
  if (latestResult) {
    const { id: checkId } = latestResult;
    link = generatePath(PATHS._CLOUD_SCORE_CHECK_DETAILS_TABNAME, {
      checkId,
      tabName: "optimization-history",
    });
  }

  const icon = <CheckMonitorIcon operation={operation} />;
  const message = (
    <>
      <strong>{checkDefinitionName}</strong> was set to{" "}
      <strong>{CHECK_IGNORE_STATUS_MAP[operation]}</strong> by{" "}
      <strong>{actor?.name || actor.email}</strong>
    </>
  );

  return (
    <ActivityFeedItem
      icon={icon}
      link={link}
      message={message}
      timestamp={timestamp}
      category={category}
    />
  );
};
