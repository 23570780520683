import React from "react";

import DateRangePicker from "@mc/components/DateRangePicker";

import { LIST_CASES } from "../api";

import { DownloadableReport } from "./DownloadableReport";

export function CasesReport() {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  return (
    <DownloadableReport
      query={LIST_CASES}
      variables={{ after: startDate, before: endDate }}
      context={{ clientName: "v4" }}
      title="Cases"
      description="CSV data which includes a list of users who create tickets through email
      channel."
      options={<DateRangePicker after={setStartDate} before={setEndDate} />}
    />
  );
}
