import parseReply from "parse-reply";
import PT from "prop-types";

import { HTMLContent } from "@mc/components/HTMLContent";
import { ACTIVITY_TYPES } from "@mc/components/ReplyTimeline/constants/activities";

const SIGNATURE_RE = /^[\s]*855-MISSION x2 \(24\/7 Support\)/m;

export function EmailTrimmer({ type, sysId, children: rawComment }) {
  let prettyComment = rawComment;

  // if the comment is an email we received, strip out quoted replies
  if (type === ACTIVITY_TYPES.EMAIL_RECEIVED) {
    prettyComment = parseReply(rawComment);
  }

  // if the comment is an email we sent, attempt to remove our ServiceNow
  // signature
  if (type === ACTIVITY_TYPES.EMAIL_SENT) {
    const match = SIGNATURE_RE.exec(rawComment);
    if (match?.index) {
      prettyComment = rawComment.slice(0, match.index);
    }
  }

  return <HTMLContent groupKey={sysId}>{prettyComment}</HTMLContent>;
}
EmailTrimmer.propTypes = {
  type: PT.string.isRequired,
  sysId: PT.string.isRequired,
  children: PT.string.isRequired,
};
