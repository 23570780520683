import { useAuth } from "@m/login";

import { PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";
import { gql, useMutation } from "@mc/utils/graphql";

export const REGISTER_USER = gql`
  mutation createCustomerContact($input: CustomerContactCreationInput!) {
    createCustomerContact(input: $input) {
      ok
      sysId
    }
  }
`;

export const useRegisterUser = (setErrorMessage: (msg: string) => void) => {
  const { user, updateUserSession } = useAuth();

  const navigate = useNavigate();

  const onError = () => {
    setErrorMessage("Something went wrong.  Please try again.");
  };

  const [mutate, result] = useMutation(REGISTER_USER, {
    onError,
  });

  const registerUser = (firstName: string, lastName: string) => {
    mutate({
      variables: { input: { firstName, lastName } },
      onCompleted: () => {
        updateUserSession({
          ...user,
          firstName,
          lastName,
          isPendingInvite: false,
        });
        navigate(PATHS.ROOT);
      },
    });
  };

  return [registerUser, result] as const;
};
