export const WelcomePageArtboard = () => {
  return (
    <svg
      width="138"
      height="122"
      viewBox="0 0 138 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.531 20.0312H129.375C128.231 20.0312 127.134 20.4856 126.326 21.2944C125.517 22.1031 125.062 23.2 125.062 24.3438V54.5312L121.505 59.1888C120.904 58.4156 120.136 57.7888 119.259 57.3556C118.381 56.9223 117.416 56.6939 116.438 56.6875L97.0312 76.0938C94.361 79.0626 92.8326 82.8836 92.7188 86.875V101.969H114.281V99.8125C114.594 96.5005 116.132 93.4246 118.594 91.1875L131.531 78.25C134.201 75.2812 135.73 71.4601 135.844 67.4688V24.3438C135.844 23.2 135.389 22.1031 134.581 21.2944C133.772 20.4856 132.675 20.0312 131.531 20.0312Z"
        fill="#F8F7F7"
      />
      <path d="M120.75 104.125H86.25V121.375H120.75V104.125Z" fill="#75B8FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.544 54.5312H116.438C118.725 54.5312 120.919 55.44 122.536 57.0575C124.154 58.675 125.062 60.8688 125.062 63.1562V63.8359L109.579 85.9553L106.046 83.4822L120.706 62.5398C120.573 61.624 120.149 60.7689 119.487 60.1069C118.873 59.493 118.093 59.0833 117.253 58.9215L98.5977 77.5767C96.2987 80.1522 94.9804 83.4556 94.875 86.9068V101.969H90.5625V86.8442L90.5634 86.8135C90.6918 82.3111 92.416 78.0008 95.4281 74.6518L95.4662 74.6094L115.544 54.5312Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.438 112.75H112.125V108.438H116.438V112.75Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.375 22.1875C128.803 22.1875 128.255 22.4147 127.85 22.8191C127.446 23.2234 127.219 23.7719 127.219 24.3438V54.5312H122.906V24.3438C122.906 22.6281 123.588 20.9828 124.801 19.7697C126.014 18.5565 127.659 17.875 129.375 17.875H131.531C133.247 17.875 134.892 18.5565 136.105 19.7697C137.318 20.9828 138 22.6281 138 24.3438V67.4995L137.999 67.5302C137.871 72.0327 136.147 76.343 133.134 79.6919L133.096 79.7344L120.082 92.7487L120.044 92.7834C118.003 94.6373 116.72 97.1784 116.438 99.9184V104.125H86.25V99.8125H112.125V99.711L112.135 99.61C112.496 95.7808 114.267 92.2232 117.104 89.6277L129.965 76.767C132.264 74.1916 133.582 70.8881 133.688 67.4369V24.3438C133.688 23.7719 133.46 23.2234 133.056 22.8191C132.652 22.4147 132.103 22.1875 131.531 22.1875H129.375Z"
        fill="#524A4D"
      />
      <path
        d="M6.46875 20.0312H8.625C9.76875 20.0312 10.8656 20.4856 11.6744 21.2944C12.4831 22.1031 12.9375 23.2 12.9375 24.3438V54.5312L16.4953 59.1888C17.0956 58.4156 17.8636 57.7888 18.7413 57.3556C19.6191 56.9223 20.5837 56.6939 21.5625 56.6875L40.9688 76.0938C43.639 79.0626 45.1674 82.8836 45.2812 86.875V101.969H23.7188V99.8125C23.4063 96.5005 21.8684 93.4246 19.4062 91.1875L6.46875 78.25C3.79854 75.2812 2.27012 71.4601 2.15625 67.4688V24.3438C2.15625 23.2 2.6106 22.1031 3.41935 21.2944C4.2281 20.4856 5.325 20.0312 6.46875 20.0312Z"
        fill="#F8F7F7"
      />
      <path d="M51.75 104.125H17.25V121.375H51.75V104.125Z" fill="#75B8FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7471 58.9215C19.9068 59.0833 19.1269 59.493 18.5131 60.1069C17.8511 60.7689 17.4265 61.624 17.2943 62.5398L31.954 83.4822L28.421 85.9553L12.9375 63.8359V63.1562C12.9375 60.8688 13.8462 58.675 15.4637 57.0575C17.0812 55.44 19.275 54.5312 21.5625 54.5312H22.4556L42.5338 74.6094L42.5719 74.6518C45.584 78.0008 47.3082 82.3111 47.4366 86.8135L47.4375 86.8442V101.969H43.125V86.9068C43.0196 83.4556 41.7013 80.1522 39.4023 77.5767L20.7471 58.9215Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5625 108.438H25.875V112.75H21.5625V108.438Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.89465 19.7697C3.10778 18.5565 4.75313 17.875 6.46875 17.875H8.625C10.3406 17.875 11.986 18.5565 13.1991 19.7697C14.4122 20.9828 15.0938 22.6281 15.0938 24.3438V54.5312H10.7812V24.3438C10.7812 23.7719 10.5541 23.2234 10.1497 22.8191C9.74532 22.4147 9.19687 22.1875 8.625 22.1875H6.46875C5.89688 22.1875 5.34843 22.4147 4.94405 22.8191C4.53968 23.2234 4.3125 23.7719 4.3125 24.3438V67.4369C4.41794 70.8882 5.7362 74.1916 8.03517 76.767L20.8959 89.6277C23.7326 92.2232 25.5043 95.7808 25.8655 99.61L25.875 99.711V99.8125H51.75V104.125H21.5625V99.9184C21.2801 97.1784 19.9966 94.6373 17.9562 92.7834L17.918 92.7487L4.90371 79.7344L4.86555 79.6919C1.85345 76.343 0.129332 72.0327 0.000877038 67.5302L0 67.4995V24.3438C0 22.6281 0.681527 20.9828 1.89465 19.7697Z"
        fill="#524A4D"
      />
      <path
        d="M97.0312 2.78125H40.9688L28.0312 24.3438L69 76.0938L109.969 24.3438L97.0312 2.78125Z"
        fill="white"
      />
      <path
        d="M92.7188 9.25H45.2812L36.6562 24.3438L69 65.3125L101.344 24.3438L92.7188 9.25Z"
        fill="#FACF6C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7478 0.625H98.252L112.585 24.513L68.9999 79.5676L25.415 24.513L39.7478 0.625ZM42.1895 4.9375L30.6474 24.1745L68.9999 72.6199L107.353 24.1745L95.8103 4.9375H42.1895Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.969 26.5H28.0312V22.1875H109.969V26.5Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.1638 24.9475L71.0701 76.6975L66.9301 75.49L82.0238 23.74L86.1638 24.9475Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9763 23.74L71.0701 75.49L66.9301 76.6975L51.8363 24.9475L55.9763 23.74Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8177 1.67188L55.7552 23.2344L52.0573 25.4531L39.1198 3.89064L42.8177 1.67188Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.8802 3.89064L85.9427 25.4531L82.2448 23.2344L95.1823 1.67188L98.8802 3.89064Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7665 4.01778L55.6727 25.5803L52.1398 23.1072L67.2335 1.54472L70.7665 4.01778Z"
        fill="#524A4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7665 1.54472L85.8602 23.1072L82.3273 25.5803L67.2335 4.01778L70.7665 1.54472Z"
        fill="#524A4D"
      />
    </svg>
  );
};
