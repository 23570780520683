import { generatePath, useParams } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

export const CaseDetailsRedirect = () => {
  const params = useParams<{ sysId: string }>();

  return (
    <Navigate
      replace
      to={generatePath(PATHS.SUPPORT_CASE_DETAILS, { sysId: params.sysId })}
    />
  );
};
