import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

export const GET_CUSTOMER_REPOS_FAILURE =
  "We encountered a problem fetching repositories for this company.";

export const GET_CUSTOMER_REPOS = gql`
  query useCustomerRepositories($companyId: ID) {
    githubRepositories(companyId: $companyId) {
      edges {
        node {
          id
          name
          fullName
          private
          collaborators {
            edges {
              node {
                id
                account {
                  id
                  name
                }
                githubLogin
              }
            }
          }
        }
      }
    }
  }
`;

export function useCustomerRepositories(companyId) {
  const onError = () => toast.error(GET_CUSTOMER_REPOS_FAILURE);

  const { loading, data } = useQuery(GET_CUSTOMER_REPOS, {
    variables: { companyId },
    context: { clientName: "v4" },
    onError,
  });

  const customerRepositories =
    data?.githubRepositories?.edges.map((edge) => edge.node) || [];

  return {
    data: { customerRepositories },
    loading,
  };
}
