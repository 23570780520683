import { useAuth } from "@m/login";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";

import {
  useClaimConnection,
  useCustomerConnections,
  useRemoveConnection,
  useUnclaimedConnections,
} from "../api";
import {
  CustomerConnectionsTable,
  GitReposTabs,
  UnclaimedConnectionsTable,
} from "../components";

export function GitRepoConnectionsPage() {
  const { user } = useAuth();

  const {
    data: { customerConnections },
    loading: customerConnectionsLoading,
  } = useCustomerConnections(user.company.id);
  const {
    data: { unclaimedConnections },
    loading: unclaimedConnectionsLoading,
  } = useUnclaimedConnections();

  const [claimConnection, { loading: claimConnectionLoading }] =
    useClaimConnection();
  const [removeConnection, { loading: removeConnectionLoading }] =
    useRemoveConnection();

  return (
    <Page data-testid="git-repo-connections-page">
      <PageHeading heading="Git Repo Connections" />
      <GitReposTabs />
      <div className="mb-4">
        <CustomerConnectionsTable
          connections={customerConnections}
          loading={customerConnectionsLoading}
          removeConnection={removeConnection}
          removeConnectionLoading={removeConnectionLoading}
        />
      </div>
      <UnclaimedConnectionsTable
        connections={unclaimedConnections}
        loading={unclaimedConnectionsLoading}
        company={user.company}
        claimConnection={claimConnection}
        claimConnectionLoading={claimConnectionLoading}
        removeConnection={removeConnection}
        removeConnectionLoading={removeConnectionLoading}
      />
    </Page>
  );
}
