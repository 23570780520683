import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  ConnectAwsAccountPage,
  ConnectAwsPayerAccountPage,
  IdentifyAwsPayerAccountPage,
  OnboardingWelcomePage,
} from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

export const onboardingRoutes: RouteObject[] = [
  {
    path: "welcome",
    element: <OnboardingWelcomePage />,
  },
  {
    path: "account",
    element: <ConnectAwsAccountPage />,
  },
  {
    path: "identify-payer",
    element: <IdentifyAwsPayerAccountPage />,
  },
  {
    path: "connect-payer",
    element: <ConnectAwsPayerAccountPage />,
  },
  {
    path: "*",
    element: <Navigate replace to={PATHS.ONBOARDING_WELCOME} />,
  },
];
