import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { useModalState } from "@m/ui";
import { toHyphenLowercase } from "@m/utils";

import { EmptyState } from "@mc/components/EmptyState";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { SettingsButton } from "@mc/components/SettingsButton";
import { PATHS } from "@mc/constants";

import { ScorecardSettingsModal } from "../components";
import { CloudScoreArtboard } from "../icons";

export const PreparingCloudScorePage = () => {
  const navigate = useNavigate();

  const settingsModal = useModalState();

  const handleClickAddAccounts = () => navigate(PATHS.SETTINGS_ACCOUNTS_ADD);
  const handleClickInviteTeam = () => navigate(PATHS.SETTINGS_USERS);

  return (
    <>
      <Page
        data-testid="preparing-cloud-score-page"
        title={
          <PageTitle
            actions={<SettingsButton onClick={settingsModal.open} />}
            padding="small"
            title="Cloud Score"
          />
        }
      >
        <div className="space-y-5">
          <EmptyState
            image={<CloudScoreArtboard />}
            loading={true}
            title="Preparing your Mission Cloud Score"
            description="It will be ready in a couple of minutes."
          />

          <div className="space-y-2">
            <div className="text-xs font-semibold text-subdued">
              While you wait, continue configuring your account:
            </div>
            <Action
              title="Add more AWS Accounts"
              description="Assess your entire environment by adding any additional AWS accounts you have"
              onClick={handleClickAddAccounts}
            />
            <Action
              title="Invite your team to Mission Control"
              description="Keep your team up-to-date on your daily score changes"
              onClick={handleClickInviteTeam}
            />
          </div>
        </div>
      </Page>

      <ScorecardSettingsModal
        isOpen={settingsModal.isOpen}
        onClose={settingsModal.close}
      />
    </>
  );
};

interface ActionProps {
  description: string;
  onClick?: () => void;
  title: string;
}

const Action = ({ description, onClick, title }: ActionProps) => {
  const id = toHyphenLowercase(title);
  const describedby = `action-description-${id}`;
  const labelledby = `action-title-${id}`;
  return (
    <button
      onClick={onClick}
      aria-describedby={describedby}
      aria-labelledby={labelledby}
      className={clsx(
        "flex cursor-pointer items-center justify-between space-x-2 px-3 py-2",
        "w-full rounded-lg border border-layout-border text-left shadow"
      )}
    >
      <div>
        <div className="text-base font-medium text-action" id={labelledby}>
          {title}
        </div>
        <div className="text-sm font-medium text-subdued" id={describedby}>
          {description}
        </div>
      </div>
      <div>
        <ChevronRightIcon className="mr-[11px] inline h-2.5 w-2.5" />
      </div>
    </button>
  );
};
