import md5 from "md5";

export const getAvatarUrlFromAccount = (
  account?: {
    email?: string;
    isCharthopOptOut?: boolean;
  } | null
): string => {
  if (!account) return "";

  const { email, isCharthopOptOut } = account || {};

  if (!email?.includes("@missioncloud.com")) return "";

  if (isCharthopOptOut) return "";

  const prefix = process.env.REACT_APP_STORYBOOK_SERVICES_API
    ? "./"
    : `${process.env.REACT_APP_SERVICES_API}/media/thumbs/`;

  return `${prefix}${md5(email || "unknown")}`;
};
