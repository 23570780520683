import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ALB_LABEL, GET_FILTERS_ERROR } from "../constants";

export const GET_APPLICATION_LOAD_BALANCER_FILTERS = gql(/* GraphQL */ `
  query ApplicationLoadBalancerFilters {
    applicationLoadBalancerFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      regions
      states
    }
  }
`);

export const useApplicationLoadBalancerFilters = () => {
  const onError = () => {
    toast.error(GET_FILTERS_ERROR(ALB_LABEL));
  };

  const { data, loading, error } = useQuery(
    GET_APPLICATION_LOAD_BALANCER_FILTERS,
    {
      onError,
    }
  );

  const filters = data?.applicationLoadBalancerFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const regions = filters.regions || [];
  const states = filters.states || [];

  return {
    data: { filters: { accounts, regions, states } },
    error,
    loading,
  };
};
