import { CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface Props {
  checked: boolean;
  className?: string;
  iconClassName?: string;
  [key: string]: any;
}

export const Checkbox = ({
  checked,
  className,
  iconClassName,
  ...props
}: Props) => {
  return (
    <div
      role="checkbox"
      aria-checked={checked}
      className={clsx(
        "box-border flex h-[16px] w-[16px] items-center rounded border-layout-border",
        {
          "border-0 bg-form-active": checked,
          "border-[1px] bg-white": !checked,
        },
        className
      )}
      {...props}
    >
      {checked ? (
        <CheckIcon className={clsx("h-3 w-3 stroke-white", iconClassName)} />
      ) : null}
    </div>
  );
};
