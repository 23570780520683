import PT from "prop-types";
import { useState } from "react";

import { Field, Select } from "@m/ui";

import {
  DEFAULT_JOB_INDUSTRY_SELECTION,
  JOB_INDUSTRIES,
  JOB_INDUSTRY_OPTIONS,
} from "../constants";

export function JobIndustryInput({ jobIndustry, onJobIndustryChange }) {
  const [jobIndustryDisplayValue, setJobIndustryDisplayValue] =
    useState(jobIndustry);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setJobIndustryDisplayValue(newValue);
    onJobIndustryChange && onJobIndustryChange(newValue);
  };

  return (
    <Field htmlFor="jobIndustry" label="Job Industry">
      <Select
        id="jobIndustry"
        className="w-full px-2 py-1"
        value={
          !jobIndustryDisplayValue
            ? DEFAULT_JOB_INDUSTRY_SELECTION
            : jobIndustryDisplayValue
        }
        onChange={handleChange}
        options={JOB_INDUSTRY_OPTIONS.map(({ disabled, value }) => (
          <option key={value} disabled={disabled}>
            {value}
          </option>
        ))}
      />
    </Field>
  );
}

JobIndustryInput.propTypes = {
  jobIndustry: PT.oneOf(Object.values(JOB_INDUSTRIES)),
};
