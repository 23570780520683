import { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface SidebarContextType {
  closeSidebar: () => void;
  open: boolean;
  openSidebar: () => void;
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<SidebarContextType>({
  closeSidebar: () => {},
  open: false,
  openSidebar: () => {},
  toggleSidebar: () => {},
});

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [navOpen, setNavOpen] = useState(false);

  const sidebarContext = useMemo(
    () => ({
      closeSidebar: () => setNavOpen(false),
      open: navOpen,
      openSidebar: () => setNavOpen(true),
      toggleSidebar: () => setNavOpen((prev) => !prev),
    }),
    [navOpen]
  );

  return (
    <SidebarContext.Provider value={sidebarContext}>
      {children}
    </SidebarContext.Provider>
  );
};
