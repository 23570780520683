import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, ReactNode } from "react";

import { Backdrop } from "../Backdrop";
import { Buttons } from "../Buttons";

export interface ConfirmProps {
  /* Required slot to pass in Buttons */
  actions: ReactNode;

  /* children components */
  children?: ReactNode;

  /* Callback */
  onClose: (value: boolean) => void;

  /* Visible or hidden */
  open: boolean;

  /* An optional title  */
  title?: string;
}

export const Confirm: FC<ConfirmProps> = ({
  actions,
  children,
  onClose,
  open,
  title,
  ...props
}) => (
  <Transition show={open} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={onClose} {...props}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <Backdrop />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto text-sm text-subdued">
        {/* Container to center the panel */}
        <div className="flex min-h-full items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-lg bg-white p-3 text-left align-middle shadow-lg transition-all">
            <div className="flex flex-col gap-1">
              {/* An optional dialog title  */}
              {title && (
                <Dialog.Title className="pb-0 text-base font-semibold text-default">
                  {title}
                </Dialog.Title>
              )}
              {children}
            </div>
            {actions && (
              <Buttons align="right" className="mt-3">
                {actions}
              </Buttons>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  </Transition>
);
