import {
  ArrowUpRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useState } from "react";

import { Banner, Button, Card, Field, Input, Spinner } from "@m/ui";

import {
  AWS_ACCOUNT_REQUIRED_SKUS,
  CLOUD_FORMATION_IAM_ROLES,
  CLOUD_FORMATION_MAX_ATTEMPTS,
  CLOUD_FORMATION_POLL_INTERVAL,
  PATHS,
} from "@mc/constants";
import { Navigate } from "@mc/router";
import { getCloudFormationTemplateURL } from "@mc/utils/cloudFormation";

import {
  useCreateMarketplaceOnboardingRequest,
  useValidateOnboardingRoleDeployed,
  useValidateTrustedAdvisorAccessOnboarding,
} from "../api";
import {
  AwsConsoleSigninLink,
  AwsManagementConsoleInfo,
  BusinessSupportError,
  ConnectCloudScoreAccountError,
} from "../components";
import { usePendingOnboarding } from "../hooks";
import { GreenCheckIcon } from "../icons";

const roleName = CLOUD_FORMATION_IAM_ROLES.MISSION_CLOUD_SCORE;

interface Props {
  /** Max number of validation attempts for cloud score onboarding IAM role before timing out */
  maxAttempts?: number;

  /** Poll interval for validating cloud score onboarding IAM role has been deployed  */
  pollInterval?: number;
}

export const ConnectAwsAccountPage = ({
  maxAttempts = CLOUD_FORMATION_MAX_ATTEMPTS,
  pollInterval = CLOUD_FORMATION_POLL_INTERVAL,
}: Props) => {
  const pendingOnboarding = usePendingOnboarding(AWS_ACCOUNT_REQUIRED_SKUS);

  const awsAccountId = pendingOnboarding?.awsAccountId;
  const iamExternalId = pendingOnboarding?.iamExternalId;
  const sku = pendingOnboarding?.sku;
  const [awsAccountName, setAwsAccountName] = useState(null);

  // template state
  const [isTemplateRunning, setIsTemplateRunning] = useState(false);
  const [
    onboardingRoleValidationComplete,
    setOnboardingRoleValidationComplete,
  ] = useState(false);
  const [
    trustedAdvisorValidationComplete,
    setTrustedAdvisorValidationComplete,
  ] = useState(false);
  const [validationAttempts, setValidationAttempts] = useState(0);

  const [
    validateOnboardingRoleDeployed,
    {
      data: onboardingRoleValidated,
      isPolling: isPollingOnboardingRole,
      loading: onboardingRoleLoading,
      stopPolling: stopPollingOnboardingRole,
    },
  ] = useValidateOnboardingRoleDeployed(
    awsAccountId,
    iamExternalId,
    roleName,
    pollInterval
  );

  const [
    validateTrustedAdvisorAccess,
    { data: trustedAdvisorAccessValidated, error: trustedAdvisorAccessError },
  ] = useValidateTrustedAdvisorAccessOnboarding(awsAccountId, iamExternalId);

  const [createOnboardingRequest, { error: onboardingRequestError }] =
    useCreateMarketplaceOnboardingRequest(
      awsAccountId,
      sku,
      [roleName],
      awsAccountName
    );

  const onboardingRolePollingTimedOut =
    validationAttempts === maxAttempts &&
    !onboardingRoleLoading &&
    !onboardingRoleValidated;

  const handleRunCloudFormationTemplate = () => {
    setValidationAttempts(0);
    setIsTemplateRunning(true);
    openTemplateUrl(sku, iamExternalId);
    validateOnboardingRoleDeployed();
  };

  const handleRerunCloudFormationTemplate = () => {
    setValidationAttempts(0);
    openTemplateUrl(sku, iamExternalId);
  };

  const handleAccountNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAwsAccountName(e.target.value);
  };

  useEffect(
    function handleIncrementValidationAttempts() {
      if (isPollingOnboardingRole) setValidationAttempts((prev) => prev + 1);
    },
    [isPollingOnboardingRole]
  );

  useEffect(
    function handleOnboardingRolePollingTimedOut() {
      if (onboardingRolePollingTimedOut) {
        stopPollingOnboardingRole();
        setIsTemplateRunning(false);
      }
    },
    [onboardingRolePollingTimedOut, stopPollingOnboardingRole]
  );

  useEffect(
    function handleOnboardingRoleValidated() {
      if (onboardingRoleValidated && !onboardingRoleValidationComplete) {
        stopPollingOnboardingRole();
        setOnboardingRoleValidationComplete(true);
        validateTrustedAdvisorAccess();
      }
    },
    [
      onboardingRoleValidated,
      onboardingRoleValidationComplete,
      stopPollingOnboardingRole,
      validateTrustedAdvisorAccess,
    ]
  );

  useEffect(
    function handleTrustedAdvisorAccessValidated() {
      if (trustedAdvisorAccessValidated && !trustedAdvisorValidationComplete) {
        setTrustedAdvisorValidationComplete(true);
        createOnboardingRequest();
        // after onboarding request is created, marketplace registration status will
        // change to 'Complete' and the user will be kicked out of onboarding.
      }
    },
    [
      trustedAdvisorAccessValidated,
      trustedAdvisorValidationComplete,
      stopPollingOnboardingRole,
      createOnboardingRequest,
    ]
  );

  if (!pendingOnboarding)
    return <Navigate replace to={PATHS.ONBOARDING_WELCOME} />;

  if (trustedAdvisorAccessError) return <BusinessSupportError />;
  if (onboardingRequestError) return <ConnectCloudScoreAccountError />;

  return (
    <div className="flex max-w-2xl flex-col items-center gap-5">
      <div className="text-4xl font-bold text-default">
        Connect your AWS Account
      </div>

      <div className="flex justify-center">
        <Banner
          label={
            <div className="text-action">
              AWS Business Support or higher is required to generate your Cloud
              Score
            </div>
          }
        />
      </div>

      <Card className="flex flex-col gap-4 p-2">
        <div className="flex flex-col gap-3">
          <Banner
            status="warning"
            label={<AwsConsoleSigninLink awsAccountId={awsAccountId} />}
          />
          <Field htmlFor="aws-account-id" label="AWS Account ID">
            <Input
              className="text-sm"
              disabled
              id="aws-account-id"
              value={awsAccountId}
            />
          </Field>

          <Field label="Account Name">
            <Input
              data-testid="aws-account-name"
              value={awsAccountName}
              onChange={handleAccountNameChange}
              className="text-sm"
              required
            />
          </Field>
          {iamExternalId && (
            <Field htmlFor="iam-external-id" label="IAM External ID">
              <Input
                className="text-sm"
                disabled
                id="iam-external-id"
                value={iamExternalId}
              />
            </Field>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-base font-semibold text-default">
            Run the CloudFormation Template below to deploy our necessary IAM
            role
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <GreenCheckIcon />
              <div className="text-sm font-medium text-default">
                We will sync data from Trusted Advisor and other AWS Support
                APIs
              </div>
            </div>
            <div className="flex items-center gap-1">
              <GreenCheckIcon />
              <div className="text-sm font-medium text-default">
                Use that data and our expertise to assess your environment daily
                across all five Well-Architected Framework pillars
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-2">
          <Button
            disabled={isTemplateRunning}
            kind="primary"
            onClick={handleRunCloudFormationTemplate}
            rightIcon={!isTemplateRunning && ArrowUpRightIcon}
            leftIcon={isTemplateRunning && Spinner}
            size="large"
          >
            {isTemplateRunning ? (
              <>Verifying Access</>
            ) : (
              <>Run CloudFormation Template</>
            )}
          </Button>
          {isTemplateRunning && (
            <Button
              fill="none"
              kind="primary"
              onClick={handleRerunCloudFormationTemplate}
            >
              Rerun CloudFormation Template
            </Button>
          )}
          {onboardingRolePollingTimedOut && (
            <div className="flex items-center gap-1">
              <ExclamationTriangleIcon className="h-2.5 w-2.5 text-status-warning" />
              <div className="text-sm font-bold text-default">
                Verification timed out. Try again
              </div>
            </div>
          )}
        </div>

        {!isTemplateRunning && <AwsManagementConsoleInfo />}
      </Card>
    </div>
  );
};

const openTemplateUrl = (sku: string, iamExternalId?: string) => {
  const url = getCloudFormationTemplateURL(sku, { iamExternalId });

  window.open(url, "_blank");
};
