import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";

import { toHyphenLowercase } from "@m/utils";

export interface TabData {
  id?: string;
  label: ReactNode;
  name?: string;
}

export interface TabsProps {
  activeTabName?: string;
  ariaLabel?: string;
  className?: string;
  disabledTabIds?: string[];
  onTabChange?: (tabData: TabData) => void;
  tabClassName?: string;
  tabsData: TabData[];
}

export const Tabs = ({
  activeTabName,
  ariaLabel = "Tabs",
  className,
  disabledTabIds = [],
  onTabChange,
  tabClassName,
  tabsData,
}: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (activeTabName) {
      const selectedTabIndex = tabsData.indexOf(
        tabsData.find(
          ({ name }) =>
            toHyphenLowercase(name) === toHyphenLowercase(activeTabName)
        )
      );
      setActiveTabIndex(selectedTabIndex);
    }
  }, [activeTabName, tabsData]);

  const handleChangeTab = (index: number) => {
    setActiveTabIndex(index);
    onTabChange?.(tabsData[index]);
  };

  return (
    <div className="relative" aria-label={ariaLabel} role="tablist">
      <div className={clsx("flex flex-wrap gap-x-3 bg-inherit", className)}>
        {tabsData.map((tab, index) => {
          const isDisabled = disabledTabIds.indexOf(tab.id) >= 0;
          if (isDisabled) return null;

          const handleClick = () => handleChangeTab(index);

          const isActive = activeTabIndex === index;

          return (
            <Tab
              key={index}
              isActive={isActive}
              tabClassName={tabClassName}
              onClick={handleClick}
              label={tab.label}
            />
          );
        })}
      </div>
    </div>
  );
};

interface TabProps {
  isActive: boolean;
  label: ReactNode;
  onClick: () => void;
  tabClassName: string;
}

const Tab = ({ isActive, tabClassName, onClick, label }: TabProps) => {
  return (
    <button
      role="tab"
      aria-selected={isActive}
      className={clsx(
        "border-b-2 border-transparent pb-1 pt-2 hover:border-gray-300 focus:border-gray-300",
        "inherit bg-none text-inherit outline-inherit",
        "whitespace-nowrap",
        {
          "!border-layout-active": isActive,
        },
        tabClassName
      )}
      onClick={onClick}
    >
      <div
        className={clsx("font-semibold text-gray-800", {
          "!text-default": isActive,
        })}
      >
        {label}
      </div>
    </button>
  );
};
