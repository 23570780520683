import { useAuth } from "@m/login";
import { Button, Link } from "@m/ui";

import { FullScreenLayout } from "@mc/layouts";

import { ErrorPage } from "./ErrorPage";

export const NoActiveSubscriptionFoundError = () => {
  const { logout } = useAuth();

  return (
    <FullScreenLayout>
      <ErrorPage
        title="No Active Subscription Found"
        content={
          <div>
            It looks like you have unsubscribed from your Mission product.
            <br />
            Please visit the{" "}
            <Link href="https://aws.amazon.com/marketplace/seller-profile?id=6bf0b17a-2fdc-4b10-85f3-30c1e2577492">
              AWS Marketplace
            </Link>{" "}
            to resubscribe.
          </div>
        }
        action={
          <Button onClick={logout} kind="primary">
            Log out
          </Button>
        }
      />
    </FullScreenLayout>
  );
};
