import { ApolloError } from "@apollo/client";

import { gql, useMutation } from "@mc/utils/graphql";

export const CREATE_COMMENT = gql`
  mutation useCreateCaseComment($input: CaseCommentCreationInput!) {
    createCaseComment(input: $input) {
      ok
      message
      comment {
        sysId
        type
        comment
        workNotes
        createdAt
        createdBy {
          name
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const useCreateCaseComment = (detail, replyMessage) => {
  const [runMutation, { loading }] = useMutation(CREATE_COMMENT, {
    variables: {
      input: {
        sysId: detail.sysId,
        comment: replyMessage,
      },
    },
  });

  const createCaseComment = async () => {
    const { data, errors } = await runMutation();
    if (errors && errors.length > 0) {
      throw new ApolloError({ graphQLErrors: errors });
    }
    return { data };
  };

  return [createCaseComment, { loading }] as const;
};
