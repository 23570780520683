import { Link } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";

export const AccessDenied = () => (
  <Page aria-label="403 Page" title={<PageTitle title="Access Denied" />}>
    <div className="flex-1">
      <p>Oops! It looks like you don't have access to view this page.</p>
      <Link to={PATHS.DASHBOARD} className="mt-2">
        Return to Dashboard
      </Link>
    </div>
  </Page>
);
