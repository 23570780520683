import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES_ENGAGEMENTS_DECISIONS } from "../constants";
import { EngagementDecisionType } from "../types";

export const UPDATE_ENGAGEMENTS_DECISION = gql`
  mutation UpdateEngagementsDecision($input: UpdateEngagementDecisionInput!) {
    updateEngagementDecision(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateEngagementsDecision = (engagementId: string) => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_DECISIONS.UPDATE_ERROR);
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_DECISIONS.UPDATE_SUCCESS);

  const [mutate, { loading }] = useMutation(UPDATE_ENGAGEMENTS_DECISION, {
    refetchQueries: ["EngagementDecisionItems", "EngagementDecisionItem"],
    onCompleted,
    onError,
  });

  const updateEngagementsDecision = ({
    id,
    title,
    description,
    dueDate,
    owner,
    impact,
    status,
    latestCompletionNote,
  }: Partial<EngagementDecisionType> = {}) => {
    return mutate({
      variables: {
        input: {
          id,
          engagementId,
          title,
          description,
          dueDate,
          owner,
          impact,
          status,
          note: latestCompletionNote?.content,
        },
      },
    });
  };

  return [updateEngagementsDecision, { loading }] as const;
};
