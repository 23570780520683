import { CheckCircleIcon } from "@heroicons/react/20/solid";
import PT from "prop-types";

export const FlexItem = ({ title, items = [] }) => {
  return (
    <div className="basis-[30%] rounded-lg border">
      <p className="border-b p-1 font-bold text-black">{title}</p>
      <ul className="p-1 text-left text-xs">
        {items.map((str, idx) => (
          <li className="pb-2" key={idx} style={{ color: "#212936" }}>
            <CheckCircleIcon className="inline-block h-2 w-2 pr-0.5 text-emerald-500" />
            {str}
          </li>
        ))}
      </ul>
    </div>
  );
};

FlexItem.propTypes = {
  title: PT.string,
  items: PT.array,
};
