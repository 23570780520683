import { useQuery } from "@m/api";
import { gql } from "@m/api/public";

export const GET_TEAM_MEMBERS = gql(/* GraphQL */ `
  query GetTeamMembers {
    teamMembers {
      jobRole {
        name
      }
      account {
        id
        databaseId
        name
        email
        firstName
        lastName
      }
    }
  }
`);

export const useTeamMembers = () => {
  const { data, loading, error } = useQuery(GET_TEAM_MEMBERS);

  const teamMembers = data?.teamMembers || [];

  return { data: { teamMembers }, loading, error };
};
