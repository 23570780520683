import { MESSAGES as USER_MANAGEMENT_MESSAGES } from "@mc/features/UserSettings/constants";

import { ACTIVITY_NAMESPACES } from "../constants";

import { ActivityFeedItem } from "./ActivityFeedItem";

export const ActivityFeedItemCompanyMembership = ({ activity }) => {
  const {
    account: { name, email },
    changes: { role },
  } = activity;
  const { title: category, link } = ACTIVITY_NAMESPACES.team;

  const message = (
    <>
      <strong>{name.trim() || email}</strong> was granted{" "}
      <strong>{USER_MANAGEMENT_MESSAGES.UPDATE_ROLE[role[1]]}</strong> level
      access
    </>
  );

  return (
    <ActivityFeedItem
      link={link}
      message={message}
      timestamp={activity.timestamp}
      category={category}
    />
  );
};
