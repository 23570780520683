import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

export const GET_VALID_MIME_TYPES = gql`
  query ValidCaseUploadMimeTypes {
    validCaseUploadMimeTypes
  }
`;

export const useValidCaseUploadMimeTypes = () => {
  const onError = () => {
    toast.error(MESSAGES.VALID_MIME_TYPES_FAILURE);
  };

  const { loading, data, refetch } = useQuery(GET_VALID_MIME_TYPES, {
    onError,
  });

  return {
    data: data?.validCaseUploadMimeTypes || [],
    loading,
    refetch,
  };
};
