import { DefaultOptions } from "@apollo/client";

export const DEFAULT_OPTIONS: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  },
  query: {
    fetchPolicy: "network-only",
  },
  mutate: {},
};
