export const MUTATIONS = {
  ADD_BOOKMARK: "addBookmarkToCompany",
  CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST: "createAwsAccountOnboardingRequest",
  CREATE_CASE: "createCase",
  CREATE_ENGAGEMENT_WITH_TEMPLATE: "createEngagementWithTemplate",
  CREATE_SCORECARD_RECOMMENDATION: "createScorecardRecommendation",
  UPDATE_CHECK_SOURCE_IGNORE: "updateCheckSourceIgnore",
  UPDATE_PAYMENT_PROFILE: "updatePaymentProfile",
  UPDATE_ENGAGEMENT: "updateEngagement",
};
