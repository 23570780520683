import { useCallback } from "react";

import { IndeterminateCheckbox } from "../components";
import { TABLE_TITLES } from "../constants";

export const useSelectAllCheckbox = ({
  isAllRowsSelected,
  selectedRowIds,
  toggleAllRowsSelected,
  toggleAllRowsSelectedTitle = TABLE_TITLES.TOGGLE_ALL_ROWS_SELECTED,
}) => {
  const renderSelectAllCheckbox = useCallback(
    (table) => (
      <IndeterminateCheckbox
        {...table.getToggleAllRowsSelectedProps({
          onChange: (e) => {
            const { checked } = e.target;
            table.toggleAllRowsSelected(checked);
            toggleAllRowsSelected(checked);
          },
          checked: isAllRowsSelected,
          indeterminate: Boolean(selectedRowIds?.length),
          title: toggleAllRowsSelectedTitle,
        })}
      />
    ),
    [
      isAllRowsSelected,
      selectedRowIds,
      toggleAllRowsSelected,
      toggleAllRowsSelectedTitle,
    ]
  );

  return {
    renderSelectAllCheckbox,
  };
};
