import {
  ApolloClient,
  ApolloProvider,
  Context,
  InMemoryCache,
  InMemoryCacheConfig,
  from,
} from "@apollo/client";
import { ReactNode } from "react";

import {
  customHeadersMiddleware,
  extensionsLink,
  httpLink,
  makeHttpErrorLink,
} from "@m/api/utils";

import { DEFAULT_OPTIONS } from "../constants";
import { TErrorOptions } from "../types";

export const ApiProvider = ({
  cacheConfig,
  children,
  context,
  errorOptions,
}: {
  cacheConfig: InMemoryCacheConfig;
  children: ReactNode;
  context?: Context;
  errorOptions?: TErrorOptions;
}) => {
  const cache = new InMemoryCache(cacheConfig);
  const apolloDevMode = process.env.NODE_ENV === "development";

  const client = new ApolloClient({
    cache,
    connectToDevTools: apolloDevMode,
    link: from([
      makeHttpErrorLink(errorOptions),
      customHeadersMiddleware,
      extensionsLink,
      httpLink,
    ]),
    defaultOptions: {
      watchQuery: {
        ...DEFAULT_OPTIONS.watchQuery,
        context,
      },
      query: {
        ...DEFAULT_OPTIONS.query,
        context,
      },
      mutate: {
        ...DEFAULT_OPTIONS.mutate,
        context,
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
