import clsx from "clsx";
import { ChangeEvent, ComponentPropsWithRef, forwardRef } from "react";

import css from "./Textarea.module.css";

export type TextareaRef = HTMLTextAreaElement;

// Convert to interface if we have custom props
export interface TextareaProps extends ComponentPropsWithRef<"textarea"> {
  autogrow?: boolean;
  containerClasses?: string;
  noStyles?: boolean;
  nextFieldOnReturn?: boolean;
}

export const Textarea = forwardRef<TextareaRef, TextareaProps>(
  (
    {
      autogrow,
      className,
      containerClasses,
      noStyles,
      nextFieldOnReturn,
      onInput,
      ...props
    },
    ref
  ) => {
    // when textarea changes, write value to parent node to use in ::after psuedoelement (See CSS)
    const onInputHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.parentElement)
        e.target.parentElement.dataset.replicatedValue = e.target.value;
      onInput?.(e);
    };

    const onEnterPress: React.KeyboardEventHandler<HTMLTextAreaElement> = (
      e
    ) => {
      if (nextFieldOnReturn && e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        const form = e.currentTarget.form;

        if (form) {
          const elements = Array.from(form.elements) as HTMLElement[];
          const index = elements.indexOf(e.currentTarget);
          const nextElement = elements[index + 1];

          if (nextElement) {
            nextElement.focus();
          }
        }
      }
    };

    const classes = clsx(className, "w-full block outline-none");
    const textareaWrapperClasses = clsx(
      "invalid:form-control__invalid",
      containerClasses,
      {
        "form-control-container form-control-input form-control__focus-within":
          !noStyles,
        "form-control__invalid": props["aria-invalid"],
        "form-control__disabled": props.disabled,
        [css.autogrow]: autogrow,
      }
    );
    return (
      <div className={textareaWrapperClasses} data-component="Textarea">
        <textarea
          className={classes}
          data-component="Textarea"
          onInput={onInputHandler}
          ref={ref}
          onKeyDown={onEnterPress}
          {...props}
        />
      </div>
    );
  }
);

Textarea.displayName = "Textarea";
