import clsx from "clsx";
import PT from "prop-types";

import { THRESHOLDS } from "../constants";

const { FAIR_THRESHOLD, EXCELLENT_THRESHOLD } = THRESHOLDS;

export const MissionCloudScore = ({ className, score }) => (
  <div
    aria-label="Mission Cloud Score"
    className={clsx(
      "mr-1 text-2xl font-semibold",
      {
        "text-status-error": score < FAIR_THRESHOLD,
        "text-black": score >= FAIR_THRESHOLD && score < EXCELLENT_THRESHOLD,
        "text-status-good": score >= EXCELLENT_THRESHOLD,
      },
      className
    )}
  >
    {score.toString()}
  </div>
);

MissionCloudScore.propTypes = {
  className: PT.string,
  score: PT.number,
};
