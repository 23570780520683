import { Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";

import { ActivityFeedGroup } from "./components";
import {
  ACTIVITY_FEED_GROUPS,
  ACTIVITY_FEED_INITIAL_CHANGES,
  ACTIVITY_FEED_MESSAGES,
} from "./constants";
import { ActivityFeedProvider } from "./contexts";
import { ActivityFeedEmptyIcon } from "./icons";
import { ActivityFeedChanges } from "./types";

interface Props {
  /**
   * List of activity items to display in the Activity Feed.
   */
  activities: any[];

  /**
   * Change values are displayed in a Change Indicator next to each group's title.
   */
  changes?: ActivityFeedChanges;

  /**
   * Whether there was an error fetching the activity feed.
   */
  error?: boolean;

  /**
   * Color of each individual activity item's title.
   */
  itemTitleColor?: "default" | "action";

  /**
   * Whether the Activity Feed is currently loading.
   */
  loading?: boolean;
}

export const ActivityFeed = ({
  activities = [],
  changes = ACTIVITY_FEED_INITIAL_CHANGES,
  error = false,
  itemTitleColor = "default",
  loading = false,
}: Props) => {
  const feedAvailable = !loading && !error && activities.length > 0;
  const feedEmpty = !loading && (!feedAvailable || error);

  return (
    <Card title="Activity Feed" className="flex !h-full flex-col">
      <div className="my-2 flex h-[30rem] grow flex-col space-y-2 overflow-auto px-2">
        <ActivityFeedProvider value={{ itemTitleColor }}>
          {feedAvailable &&
            ACTIVITY_FEED_GROUPS.map(({ id, title, startTime, endTime }) => (
              <ActivityFeedGroup
                activities={activities}
                changes={changes}
                endTime={endTime}
                groupId={id}
                key={id}
                startTime={startTime}
                title={title}
              />
            ))}
        </ActivityFeedProvider>

        {feedEmpty && <EmptyFeed />}

        {loading && <Spinner className="m-auto" />}
      </div>
    </Card>
  );
};

const EmptyFeed = () => (
  <div className="flex h-full flex-col items-center justify-center space-y-[40px] text-center text-default">
    <div className="text-xl font-semibold">
      {ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_EMPTY}
    </div>
    <ActivityFeedEmptyIcon height={180} width={185} />
    <div className="font-normal">
      {ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_EMPTY_EXTRA}
    </div>
  </div>
);
