import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { EngagementType } from "@m/api/public/types";
import { Banner, Link, SpinnerScreen, useModalState } from "@m/ui";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { HTMLContent } from "@mc/components/HTMLContent";
import { Page } from "@mc/components/Page";
import { ROLES } from "@mc/constants";
import { NotFound } from "@mc/pages/404";

import { useEngagementDetails } from "../api";
import {
  EngagementDetailsLists,
  EngagementDetailsPageHeader,
  EngagementStatus,
} from "../components";

import { EngagementsElevateProjectPage } from "./EngagementsElevateProjectPage";

export const EngagementsDetailsPage = () => {
  const { engagementId } = useParams<{ engagementId: string }>();
  const engagementSettingsModalState = useModalState();
  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });

  const {
    data: { engagementDetails },
    loading,
  } = useEngagementDetails(engagementId);
  const { visible, engagementType } = engagementDetails || {};

  const [visibilityBannerDismissed, setVisibilityBannerDismissed] =
    useState(false);
  const [shouldShowAllItems, setShouldShowAllItems] = useState(false);

  const shouldShowVisibilityBanner = useMemo(
    () => !visible && !visibilityBannerDismissed && isEngagementManager,
    [visible, visibilityBannerDismissed, isEngagementManager]
  );

  if (engagementDetails === null) return <NotFound />;

  if (engagementType === EngagementType.Elevate)
    return <EngagementsElevateProjectPage />;

  return (
    <>
      <Page
        data-testid="engagement-details-page"
        width="large"
        title={
          <EngagementDetailsPageHeader
            engagementDetails={engagementDetails || {}}
            loading={loading}
            shouldShowAllItems={shouldShowAllItems}
            setShouldShowAllItems={setShouldShowAllItems}
            engagementSettingsModalState={engagementSettingsModalState}
          />
        }
      >
        {loading ? (
          <SpinnerScreen fitToParent={true} />
        ) : (
          <div className="gap-2 space-y-3">
            {shouldShowVisibilityBanner ? (
              <Banner
                data-testid="customer-engagement-visibility-banner"
                label={
                  <div>
                    This project is not visible to customers.{" "}
                    <Link
                      onClick={() => {
                        engagementSettingsModalState.open();
                      }}
                    >
                      Make it visible
                    </Link>
                  </div>
                }
                actions={
                  <Link onClick={() => setVisibilityBannerDismissed(true)}>
                    Dismiss
                  </Link>
                }
                icon={null}
              />
            ) : null}

            <div data-testid="engagement-details-header">
              <HTMLContent className="text-2xl font-semibold">
                {engagementDetails?.title || ""}
              </HTMLContent>
            </div>

            <EngagementStatus engagementId={engagementId} />

            <EngagementDetailsLists
              engagementId={engagementId}
              shouldShowAllItems={shouldShowAllItems}
            />
          </div>
        )}
      </Page>
    </>
  );
};
