import { useMemo } from "react";

import { FlaggedResource } from "@m/api/public/types";
import { Table } from "@m/ui";
import { createAccessorFromLabel } from "@m/ui/components/Table/utils";

import { useFlaggedRecommendationResources } from "../api";
import { CLOUD_SCORE_MESSAGES, LABELS, SCORECARD_TITLES } from "../constants";

export interface FlaggedRecommendationResourcesTableProps {
  recommendationId: string;
  resourceColumns: string[];
  [key: string]: any;
}

export const FlaggedRecommendationResourcesTable = ({
  recommendationId,
  resourceColumns,
  ...props
}: FlaggedRecommendationResourcesTableProps) => {
  const {
    data: resources,
    loading,
    pagination,
  } = useFlaggedRecommendationResources(recommendationId);

  const headers = [
    { label: "Account Name", accessor: "accountName" },
    { label: "Account ID", accessor: "accountId" },
  ];
  resourceColumns.forEach((c) => {
    headers.push({ label: c, accessor: createAccessorFromLabel(c) });
  });

  const rows = useMemo(() => {
    return resources.map((resource: Partial<FlaggedResource>) => {
      const metadata = Object.fromEntries(
        Object.entries(resource.resourceMetadata).map(([label, value]) => [
          createAccessorFromLabel(label),
          value,
        ])
      );

      return {
        ...metadata,
        accountId: resource.accountId,
        accountName: resource.accountName,
        resourceId: resource.id,
      };
    });
  }, [resources]);

  return (
    <Table
      emptyMessage={CLOUD_SCORE_MESSAGES.GET_RESOURCES_EMPTY(LABELS.FLAGGED)}
      headers={headers}
      loading={loading}
      manualRowIdKey="resourceId"
      rows={rows}
      toggleAllRowsSelectedTitle={
        SCORECARD_TITLES.TOGGLE_ALL_RESOURCES_SELECTED
      }
      toggleRowSelectedTitle={SCORECARD_TITLES.TOGGLE_RESOURCE_SELECTED}
      {...pagination}
      {...props}
    />
  );
};
