import { formatFullDate, toDollars } from "@m/utils";

import { RioReportBarDatum } from "../types";

import { CostLabel, SavingsLabel } from "./RioGraphLabels";

interface RioGraphTooltipProps {
  rioReportBarDatum: RioReportBarDatum;
  dateFormat: string;
}

export const RioGraphTooltip = ({
  rioReportBarDatum,
  dateFormat,
}: RioGraphTooltipProps) => {
  const { cost, savings, date } = rioReportBarDatum;

  const onDemandEquivalent = parseFloat(cost) + parseFloat(savings);

  return (
    <div className="flex w-fit flex-col gap-y-1 rounded-xl border bg-white p-2 text-xs font-semibold shadow-lg shadow-gray-300">
      <div className="font-bold">{formatFullDate(date, dateFormat)}</div>

      <div className="flex flex-col gap-y-0.5">
        <div className="flex justify-between gap-2 text-indigo-500">
          <SavingsLabel />
          <div aria-labelledby="savings">{toDollars(savings)}</div>
        </div>
        <div className="flex justify-between gap-2 text-subdued">
          <CostLabel />
          <div aria-labelledby="cost">{toDollars(cost)}</div>
        </div>
      </div>

      <div className="flex justify-between gap-2">
        <div id="on-demand-equivalent">On-Demand Equivalent</div>
        <div aria-labelledby="on-demand-equivalent">
          {toDollars(onDemandEquivalent)}
        </div>
      </div>
    </div>
  );
};
