import { Square2StackIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { Tooltip } from "@m/ui";

export const CopyButton = ({ textToCopy }: { textToCopy: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        // This will remove the "Copied!" tooltip after 2 seconds
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <Tooltip content="Copied!" side="right" color="light" open={isCopied}>
      <div
        data-testid="copy-to-clipboard"
        className="cursor-pointer"
        onClick={handleCopyToClipboard}
      >
        <Square2StackIcon className="h-2 w-2 hover:text-action" />
      </div>
    </Tooltip>
  );
};
