import { ReactNode } from "react";

import { useReportCSPViolation } from "@mc/hooks/useReportCSPViolation";

interface Props {
  children: ReactNode;
}

export const ReportCSPViolationProvider = ({ children }: Props) => {
  useReportCSPViolation();
  return <>{children}</>;
};
