import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import PT from "prop-types";

import { ACTIVITY_STREAM_OPERATION } from "@mc/features/ActivityFeed/constants";

const icons = {
  [ACTIVITY_STREAM_OPERATION.CREATE]: (
    <EyeSlashIcon aria-label="Ignore Check Icon" className="h-2.5 w-2.5" />
  ),
  [ACTIVITY_STREAM_OPERATION.DELETE]: (
    <EyeIcon aria-label="Monitor Check Icon" className="h-2.5 w-2.5" />
  ),
};
export const CheckMonitorIcon = ({ operation }) => {
  return icons[operation];
};

CheckMonitorIcon.propTypes = {
  className: PT.string,
  operation: PT.oneOf([
    ACTIVITY_STREAM_OPERATION.CREATE,
    ACTIVITY_STREAM_OPERATION.DELETE,
  ]),
};
