import PT from "prop-types";

export const SeriesPropTypes = PT.shape({
  id: PT.oneOfType([PT.string, PT.number]),
  data: PT.arrayOf(
    PT.shape({
      x: PT.oneOfType([PT.number, PT.string, PT.instanceOf(Date)]),
      y: PT.oneOfType([PT.number, PT.string, PT.instanceOf(Date)]),
    })
  ),
});
