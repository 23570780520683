import { DotIcon } from "@mc/icons";

export const CostLabel = () => (
  <span id="cost" className="flex items-center gap-1 text-subdued">
    <DotIcon height={6} width={6} className="fill-slate-400" /> Cost
  </span>
);

export const SavingsLabel = () => (
  <span id="savings" className="flex items-center gap-1 text-indigo-500">
    <DotIcon height={6} width={6} className="fill-indigo-500" /> Savings
  </span>
);
