import clsx from "clsx";

interface CloudScoreTrendTimeRangeSelectorProps {
  onChange: (key: string) => void;
  options: { key: string; shortText: string }[];
  selectedOption: string;
}

/**
 * Time range selector to be used with the Cloud Score Trend Graph.
 */
export const CloudScoreTrendTimeRangeSelector = ({
  onChange,
  options,
  selectedOption,
}: CloudScoreTrendTimeRangeSelectorProps) => {
  const handleChange = ({ target }) => onChange(target.id);

  return (
    <div className="flex">
      {options.map((option) => {
        const isActiveOption = selectedOption === option.key;

        return (
          <button
            key={option.key}
            id={option.key}
            onClick={handleChange}
            className={clsx(
              "my-auto h-fit whitespace-nowrap border-r px-1 text-sm font-normal text-default last:border-none hover:bg-gray-100",
              { "bg-blue-700 bg-opacity-10 text-action": isActiveOption }
            )}
          >
            {option.shortText}
          </button>
        );
      })}
    </div>
  );
};
