import { ReactNode } from "react";

import { HTMLContent } from "@mc/components/HTMLContent";

export interface CloudScoreOverlayHeaderProps {
  badge?: ReactNode;
  description?: ReactNode;
  icon: ReactNode;
  name: string;
}

export const CloudScoreOverlayHeader = ({
  badge,
  description,
  icon,
  name,
}: CloudScoreOverlayHeaderProps) => (
  <div
    data-testid="cloud-score-overlay-header"
    className="flex flex-col space-y-1"
  >
    <div className="flex items-center space-x-1">
      {icon}
      <div className="text-xl font-semibold text-default">{name}</div>
      {badge}
    </div>
    {description && (
      <div className="text-sm text-subdued">
        <HTMLContent>{description}</HTMLContent>
      </div>
    )}
  </div>
);
