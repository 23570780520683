import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeSlashIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import PT from "prop-types";

import { CHECK_STATUS } from "../constants";

const icons = {
  [CHECK_STATUS.OK]: (
    <ShieldCheckIcon
      aria-label="Optimized Check Icon"
      className="h-2.5 w-2.5 text-status-good"
    />
  ),
  [CHECK_STATUS.WARNING]: (
    <ExclamationTriangleIcon
      aria-label="Warning Check Icon"
      className="h-2.5 w-2.5 text-status-warning"
    />
  ),
  [CHECK_STATUS.ERROR]: (
    <ExclamationCircleIcon
      aria-label="Critical Check Icon"
      className="h-2.5 w-2.5 text-status-error"
    />
  ),
  [CHECK_STATUS.IGNORED]: (
    <EyeSlashIcon
      aria-label="Ignored Check Icon"
      className="h-2.5 w-2.5 text-accent"
    />
  ),
};

export const CheckStatusIcon = ({ status, className }) => {
  if (!status) return null;

  return <div className={className}>{icons[status]}</div>;
};

CheckStatusIcon.propTypes = {
  className: PT.string,
  status: PT.oneOf(Object.keys(CHECK_STATUS)),
};
