import clsx from "clsx";
import { ReactElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip, toast } from "@m/ui";
import { formatFullDate, toTimeLocaleLong } from "@m/utils";

import { ACTIVITY_FEED_MESSAGES } from "../constants";
import { useActivityFeedContext } from "../contexts";

interface Props {
  icon?: ReactElement;
  link?: string;
  message?: ReactNode;
  timestamp?: string;
  category: string;
}

export const ActivityFeedItem = ({
  icon,
  link,
  message,
  timestamp,
  category,
}: Props) => {
  const { itemTitleColor } = useActivityFeedContext();
  const navigate = useNavigate();

  const handleClick = () => {
    if (link === undefined) {
      return;
    }
    if (link === null) {
      toast.error(ACTIVITY_FEED_MESSAGES.OPEN_ACTIVITY_LINK_FAILURE);
    } else {
      navigate(link);
    }
  };

  const itemTitleClasses = clsx("font-semibold", {
    "text-default": itemTitleColor === "default",
    "text-action": itemTitleColor === "action",
  });

  return (
    <div
      aria-label="Activity Feed Item"
      className={clsx(
        "flex flex-row flex-nowrap items-center space-x-1 break-words px-1 py-0.5 text-left",
        {
          "cursor-pointer rounded-lg hover:bg-slate-50": link !== undefined,
        }
      )}
      data-timestamp={timestamp}
      onClick={handleClick}
    >
      {icon && (
        <div className="mr-1 flex-col align-middle text-action">{icon}</div>
      )}
      <div className="flex flex-col">
        <div className={itemTitleClasses}>
          {message && (
            <span
              className="text-sm font-normal text-default"
              aria-label="Activity Item Description"
            >
              {message}
            </span>
          )}
        </div>
        <div
          className="decoration mr-1 inline-flex text-xs text-subdued"
          aria-label="Activity Item Category"
        >
          <Tooltip
            content={toTimeLocaleLong(timestamp)}
            className="w-[10rem] text-center"
            side="left"
          >
            <span className="mr-1 underline decoration-dashed underline-offset-2">
              {formatFullDate(timestamp, "LLL d")}
            </span>
          </Tooltip>
          {category}
        </div>
      </div>
    </div>
  );
};
