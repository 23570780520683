import clsx from "clsx";

import { Suspensed } from "../Suspensed";
import { Tooltip } from "../Tooltip";

import { convertToPercentage } from "./utils";

export const ProgressBar = ({
  className,
  completedPercentage,
  loading,
}: {
  className?: string;
  completedPercentage: number | string;
  loading: boolean;
}) => {
  const percentage = convertToPercentage(completedPercentage);
  const classes = clsx(className, "flex items-center");
  return (
    <div className={classes}>
      <Suspensed
        loading={loading}
        width="100%"
        height="10px"
        className="rounded-full"
      >
        <Tooltip content={`${percentage}%`} asChild>
          <div className="h-[10px] w-full rounded-full bg-gray-200 dark:bg-blue-200">
            <div
              className="h-[10px] rounded-full bg-blue-400"
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
        </Tooltip>
      </Suspensed>
    </div>
  );
};
