import { createContext } from "react";

import { Activity } from "../types";

export const ActivityContext = createContext<{
  activities: Activity[];
  pinnedActivityIndex: number;
  setActivity: (activity: Activity) => void;
  setActivityLineElement: (element: Element) => void;
  setPinnedActivityIndex: (index: number) => void;
}>(undefined);
