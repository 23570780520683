import { generateUniqueId } from "@m/api";
import {
  EngagementMilestoneOwner,
  EngagementMilestoneStatus,
} from "@m/api/public/types";

import { TEMP_NOTE_ID_PREFIX } from "../components/EditEngagementsDetailsNotes";
import { TEMP_TASK_ID_PREFIX } from "../components/EditMilestoneTasks";

export const TABLE_HEADERS_ENGAGEMENT_MILESTONES = [
  { accessor: "status", width: 24, cellClasses: "px-1" },
  { accessor: "title", cellClasses: "px-1" },
  {
    accessor: "dueDate",
    width: 140,
    cellClasses: "text-right",
  },
  { accessor: "owner", width: 24 },
  { accessor: "details", width: 10 },
];

export const DEFAULT_INITIAL_ENGAGEMENTS_MILESTONE = {
  // The initialized task and note will be filtered out before storing if not used
  tasks: [
    {
      id: `${TEMP_TASK_ID_PREFIX}-${generateUniqueId()}`,
      title: "",
      complete: false,
    },
  ],
  noteList: [
    {
      id: `${TEMP_NOTE_ID_PREFIX}-${generateUniqueId()}`,
      content: "",
      createdAt: null,
      createdBy: null,
      updatedAt: null,
    },
  ],
  owner: EngagementMilestoneOwner.Mission,
  status: EngagementMilestoneStatus.Open,
  percentageComplete: 0,
};

export const DATE_FORMAT_ENGAGEMENT_MILESTONES = "LLL d, yyyy";

export const MESSAGES_ENGAGEMENTS_MILESTONES = {
  EMPTY_MILESTONES: "Unable to find Milestone data",
  GET_ITEMS_ERROR: "Unable to access engagement Milestones data",
  NO_MILESTONE_DETAILS: "No Milestone details found",
  UPDATE_ERROR: "Unable to update Milestone data",
  UPDATE_SUCCESS: "Successfully updated Milestone data",
};

export const DEFAULT_SORT_ENGAGEMENTS_MILESTONES = "DUE_DATE_ASC";
