import * as Sentry from "@sentry/react";

import { toast } from "@m/ui";

export function handleGraphQLErrors(errors, notifier) {
  notifier = notifier || toast;
  const explanation = errors.length ? errors[0].message : "";

  // It is anticipated this error will be thrown during CloudFormation Deployment
  // We will handle that specific error inside useValidateBootstrapRoleDeployed
  if (errors?.[0]?.path?.[0]?.includes("validateBootstrapRoleDeployed")) return;

  notifier.error("We encountered an unexpected error.");
  if (explanation) {
    Sentry.withScope(function (scope) {
      scope.setLevel("warning");
      Sentry.captureException(new Error(explanation));
    });
  }
}

export function handleHTTPError(err, logout, options = {}) {
  const notifier = options?.notifier || toast;
  const onMaintenanceResponse = options?.onMaintenanceResponse;

  const { statusCode, response, result } = err;
  if (!response) {
    // This block represents general (not HTTP) connection/network failures
    // (i.e., the request failed for a reason like the GraphQL API is unreachable)
    notifier.error(
      `We're having trouble reaching ${process.env.REACT_APP_SERVICES_API} (${err.message}).`
    );
    return;
  }

  /* If the Services API is in maintenance mode, show the global maintenance
   * dialog */
  const maintenanceResponse =
    statusCode === 503 && response.headers.has("X-Mission-Maintenance");
  if (maintenanceResponse) {
    onMaintenanceResponse();
    return;
  }

  const invalidSession =
    statusCode === 401 ||
    (statusCode === 400 &&
      result &&
      result.errors.some((e) => {
        return e.message.includes("CSRF");
      }));

  const responseURL = new URL(response.url);
  const servicesURL = new URL(process.env.REACT_APP_SERVICES_API);

  if (invalidSession) {
    if (responseURL.origin === servicesURL.origin) {
      logout();
    }
  } else {
    let msg;
    if (result) {
      msg = `GraphQL Error: ${responseURL.origin}`;
      if (result.message) msg += ` - ${result.message}`;
    } else {
      msg = `HTTP ${statusCode}: ${responseURL.origin}`;
    }
    notifier.error(msg);
  }
  return err;
}
