import { dt } from "@m/utils";

import { getTrackedTime } from "../utils";

export const getStartDateTimeDifference = (
  dueDate: string,
  isOverdue: boolean
) => {
  const today = dt.now().startOf("day").toISO();

  const diff = isOverdue
    ? getTrackedTime(dueDate, today, true)
    : getTrackedTime(today, dueDate, true);

  return diff === "" ? "0 days" : diff;
};
