import { LIST_USERS } from "../api";

import { DownloadableReport } from "./DownloadableReport";

export function ActiveUsersReport() {
  return (
    <DownloadableReport
      query={LIST_USERS}
      context={{ clientName: "v4" }}
      title="Active Users"
      description="CSV data which includes a list of invited and authenticated customers."
    />
  );
}
