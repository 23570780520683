import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const ECS_LABEL = AWS_RESOURCES_LABELS.ecsClusters;

export const TABLE_HEADERS_ECS_CLUSTERS = [
  { label: "Name", accessor: "clusterName", sort: "CLUSTER_NAME" },
  { label: "Account Name", accessor: "accountName", sort: "ACCOUNT" },
  { label: "Account ID", accessor: "accountId" },
  { label: "Region", accessor: "region", sort: "REGION" },
  { label: "ARN", accessor: "clusterArn", sort: "CLUSTER_ARN" },
  { label: "Status", accessor: "status", sort: "STATUS" },
  { label: "Tags", accessor: "tags", sort: "TAGS" },
];
