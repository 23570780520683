import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

import { useEntitlements } from "@m/api";
import { Banner } from "@m/ui";

import { SKUS } from "@mc/constants";

export const AccountBanner = () => {
  const entitlements = useEntitlements();
  const sku = entitlements?.[0]; // todo(djbowers): update logic for multiple marketplace offerings
  const bannerText = getBannerText(sku);
  if (!bannerText) return null;
  return <Banner label={bannerText} icon={Cog6ToothIcon} className="mb-3" />;
};

const getBannerText = (sku?: string): ReactNode => {
  switch (sku) {
    case SKUS.CLOUD_SCORE:
      return (
        <>
          Add up to 10 accounts for free to your Cloud Score. Additional
          accounts will be charged according to your subscription details.
        </>
      );

    case SKUS.CLOUD_GATEWAY:
      return null;

    default:
      return (
        <>
          For help or questions regarding your AWS Account configurations,
          please reach out to your Customer Success Manager or open a support
          ticket.
        </>
      );
  }
};
