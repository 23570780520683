import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_BOOKMARKS } from "./useBookmarks";

export const SAVE_BOOKMARK = gql(/* GraphQL */ `
  mutation SaveBookmark($input: BookmarkCompanyMappingInput!) {
    addBookmarkToCompany(input: $input) {
      ok
      message
    }
  }
`);

export const useSaveBookmark = () => {
  const [mutate, { data, ...result }] = useMutation(SAVE_BOOKMARK, {
    onCompleted: () => toast.success("Successfully saved Bookmark"),
    onError: () => toast.error("Unable to save Bookmark"),
    refetchQueries: [GET_BOOKMARKS],
  });

  const saveBookmark = (
    name: string,
    url: string,
    description: string,
    order: number,
    bookmarkId?: string
  ) =>
    mutate({
      variables: {
        input: {
          bookmark: {
            linkDisplayName: name,
            linkShortDescription: description,
            url:
              url.startsWith("http://") || url.startsWith("https://")
                ? url
                : `https://${url}`,
            ...(bookmarkId ? { bookmarkId } : {}),
          },
          order,
        },
      },
    });

  const bookmarkSaved = data?.addBookmarkToCompany?.ok || false;

  return [saveBookmark, { data: bookmarkSaved, ...result }] as const;
};
