import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  BaseFilter,
  FilterBar,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  useElasticacheClusterFilters,
  useElasticacheClusterSearchTerms,
  useElasticacheClusters,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  ELASTICACHE_LABEL,
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  TABLE_HEADERS_ELASTICACHE_CLUSTERS,
} from "../constants";
import { createAccountOptions } from "../utils";

export const ElasticacheClustersPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    search: StringParam,
    sort: withDefault(StringParam, "CREATE_TIME_DESC"),
  });

  const {
    data: {
      filters: { accounts },
    },
  } = useElasticacheClusterFilters();

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useElasticacheClusterSearchTerms();

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, refetchSearchTerms, query });

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { clusters },
    loading,
    pagination,
  } = useElasticacheClusters(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );
  const handleCloseDrawer = () => setDrawerDetails(null);

  const handleSortChange = (sort: string) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleClearAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      search: undefined,
    });
  };

  const rows = useMemo(() => {
    return clusters.map((cluster) => {
      const {
        tags = "{}",
        cacheClusterName,
        tagTotal,
        createTime,
      } = cluster || {};
      const parsedTags = JSON.parse(tags);

      return {
        ...cluster,
        tags: (
          <ResourceTagsBadge
            count={tagTotal}
            onClick={setDrawerDetails}
            details={{ name: cacheClusterName, tags: parsedTags }}
          />
        ),
        createTime: formatFullDate(createTime),
      };
    });
  }, [clusters]);

  const hasActiveFilters =
    !!query.missionManaged || query.accounts?.length > 0 || !!searchTerm;

  return (
    <Page
      width="full"
      title={<ResourcesPageHeader title={ELASTICACHE_LABEL} />}
    >
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2"
      >
        <Search
          ariaLabel="Elasticache Cluster Search Input"
          disabled={loading}
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <BaseFilter
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <BaseFilter
          ariaLabel="Accounts Filter"
          disabled={accounts.length < 1 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleClearAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(ELASTICACHE_LABEL)}
        headers={TABLE_HEADERS_ELASTICACHE_CLUSTERS}
        label={ELASTICACHE_LABEL}
        loading={loading}
        onCloseDrawer={handleCloseDrawer}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
