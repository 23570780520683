import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { OnboardingRequestStatus } from "@m/api/public/types";

export const START_ONBOARDING = gql(/* GraphQL */ `
  mutation StartOnboarding($input: UpdateRegistrationInput!) {
    updateMarketplaceRegistration(input: $input) {
      ok
      message
    }
  }
`);

export const useStartOnboarding = () => {
  const [mutate, result] = useMutation(START_ONBOARDING, {
    refetchQueries: ["GetMarketplaceRegistrations"],
  });

  const startOnboarding = (customerIdentifier: string, productCode: string) => {
    mutate({
      variables: {
        input: {
          customerIdentifier,
          onboardingStatus: OnboardingRequestStatus.Started,
          productCode,
        },
      },
    });
  };

  return [startOnboarding, result] as const;
};
