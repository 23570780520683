import { Logout, useAuth } from "@m/login";

import { MissionControlLogin } from "@mc/app/components";
import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

export const RootPathRedirect = () => {
  const { user } = useAuth();

  if (!user) return <MissionControlLogin />;

  /** If a user does not have a company slug in local storage, log out to refresh  */
  if (!user?.company?.slug) return <Logout />;

  return <Navigate replace to={PATHS.ROOT} />;
};
