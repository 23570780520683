import clsx from "clsx";
import { ComponentPropsWithoutRef, FC } from "react";

import tailwindConfig from "@m/tailwind-config";

type tailwindSpacing = keyof typeof tailwindConfig.theme.spacing;

export interface SpinnerProps extends ComponentPropsWithoutRef<"div"> {
  /** Sizes */
  size?: Exclude<tailwindSpacing, "px" | 0 | 0.5>;
}

export const Spinner: FC<SpinnerProps> = ({
  className,
  size = 2.5,
  ...props
}) => {
  const containerClasses = clsx(`w-${size} h-${size}`, className);
  const svgClasses = clsx(`w-${size} h-${size}`, "animate-spin");
  return (
    <div
      className={containerClasses}
      role="status"
      aria-live="polite"
      aria-label="Loading"
      {...props}
    >
      <svg
        className={svgClasses}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-20"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-70"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};
