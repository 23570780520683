import {
  MaintenanceWindowNeeded,
  PreferredContactMethods,
  RequestType,
} from "../types";

interface RequestDescriptionArgs {
  accountIds: string;
  category: string;
  description: string;
  desiredCompletionDate: string;
  maintenanceWindowNeeded: MaintenanceWindowNeeded;
  preferredContactMethod: PreferredContactMethods;
  priority: string;
  repositoryUrl: string;
  requestType: RequestType;
  schedulingLink: string;
  subcategory: string;
}

export const createRequestDescription = ({
  accountIds,
  category,
  description,
  desiredCompletionDate,
  maintenanceWindowNeeded,
  preferredContactMethod,
  priority,
  repositoryUrl,
  requestType,
  schedulingLink,
  subcategory,
}: RequestDescriptionArgs) => {
  let header = `Request Type: ${
    requestType === RequestType.DevOpsDesk ? "DevOps" : "AI"
  }\n`;
  if (category) header += `Request Category: ${category}\n`;
  if (subcategory) header += `Request Subcategory: ${subcategory}\n`;
  header += `Request Priority: ${priority}\n`;
  header += `Repository URL: ${repositoryUrl || "not reported"}\n`;
  header += `Maintenance Window Needed: ${maintenanceWindowNeeded}\n`;
  header += `Desired Completion Date: ${
    desiredCompletionDate || "not reported"
  }\n`;
  header += `Preferred Contact Method: ${preferredContactMethod}\n`;
  header += `Scheduling Link: ${schedulingLink || "not reported"}\n`;
  header += `Account IDs: ${accountIds || "not reported"}\n`;
  return `${header}\n${description}`;
};
