import { Badge } from "@m/ui";

import { ResourceDetails } from "./ResourceDetailsDrawer";

interface Props {
  count: number;
  details: ResourceDetails;
  onClick: (details: ResourceDetails) => void;
}

export const ResourceTagsBadge = ({ count, details, onClick }: Props) => {
  const handleClick = () => onClick(details);

  return (
    <Badge
      data-testid="resource-tags-badge"
      label={count}
      onClick={handleClick}
      size="small"
      status="active"
      strong={true}
    />
  );
};
