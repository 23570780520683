const title = "Mission Cloud Logo";

export const Blue = (props) => (
  <svg
    role="img"
    aria-labelledby="blue-logo-title"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title id="blue-logo-title">{title}</title>
    <path
      d="M19.8394 10.8396C19.8394 5.34494 15.4156 0.885254 9.9651 0.885254C9.84431 0.885254 9.70843 0.885254 9.58764 0.900475C5.69228 1.09834 2.58203 4.35559 2.58203 8.34343C2.58203 10.1699 3.23126 11.8442 4.31834 13.138C4.01637 12.5139 3.85029 11.829 3.85029 11.0832C3.85029 7.61283 6.64347 4.79699 10.0859 4.79699C14.6758 4.79699 18.4051 8.55652 18.4051 13.1836C18.4051 14.5078 18.1786 15.7712 17.7558 16.9584C19.0543 15.2537 19.8394 13.138 19.8394 10.8396Z"
      fill="#004C97"
    />
    <path
      d="M0 10.9308C0 16.4255 4.4238 20.8851 9.87429 20.8851C9.99508 20.8851 10.131 20.8851 10.2517 20.8699C14.1622 20.6721 17.2574 17.4148 17.2574 13.427C17.2574 11.6005 16.6081 9.92619 15.5211 8.63242C15.823 9.25648 15.9891 9.94141 15.9891 10.6872C15.9891 14.1576 13.1959 16.9734 9.7535 16.9734C5.16362 16.9734 1.43434 13.2139 1.43434 8.58676C1.43434 7.26256 1.66081 5.99923 2.08357 4.81201C0.785112 6.51674 0 8.63242 0 10.9308Z"
      fill="#004C97"
    />
  </svg>
);

export const Mark = (props) => (
  <svg
    role="img"
    aria-labelledby="logomark-title"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.13 3.17 17.99 18.16"
    {...props}
  >
    <title id="logomark-title">{title}</title>
    <path
      d="M12.497 21.302c3.779-.194 6.772-3.392 6.772-7.314 0-4.032-3.2-7.327-7.131-7.314 3.048 0 5.517 2.503 5.517 5.576 0 3.087-2.483 5.576-5.53 5.576-3.946 0-7.146-3.281-7.146-7.314 0-4.032 3.2-7.328 7.131-7.314-4.965-.014-8.979 4.06-8.979 9.066 0 5.006 4.028 9.066 8.993 9.066.124-.014.248-.027.373-.027Z"
      fill="url(#a)"
    ></path>
    <path
      d="M11.752 3.198c-3.78.195-6.773 3.393-6.773 7.314 0 4.033 3.228 7.314 7.131 7.314-3.048 0-5.517-2.503-5.517-5.576 0-3.073 2.469-5.576 5.531-5.576 3.945 0 7.145 3.282 7.145 7.314 0 4.033-3.2 7.314-7.145 7.314 4.966 0 8.993-4.06 8.993-9.066 0-5.005-4.027-9.066-8.993-9.066-.124.014-.248.028-.372.028Z"
      fill="url(#b)"
    ></path>
    <defs>
      <linearGradient
        id="a"
        x1="4.03"
        y1="8.247"
        x2="18.841"
        y2="16.729"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0E3"></stop>
        <stop offset=".5" stopColor="#00589F"></stop>
        <stop offset="1" stopColor="#293895"></stop>
      </linearGradient>
      <linearGradient
        id="b"
        x1="21.788"
        y1="12.252"
        x2="5.187"
        y2="12.252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F26F21"></stop>
        <stop offset=".5" stopColor="#F9A51A"></stop>
        <stop offset="1" stopColor="#FDE000"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export const Full = (props) => (
  <svg
    role="img"
    aria-labelledby="full-logo-title"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.13 3.17 65.74 18.16"
    {...props}
  >
    <title id="full-logo-title">{title}</title>
    <path
      d="M57.917 8.148c-2.248 0-4.069 1.836-4.069 4.102 0 2.267 1.821 4.102 4.07 4.102 2.248 0 4.068-1.835 4.068-4.102 0-2.266-1.82-4.102-4.069-4.102Zm0 6.605a2.491 2.491 0 0 1-2.482-2.503c0-1.376 1.117-2.503 2.482-2.503A2.491 2.491 0 0 1 60.4 12.25c0 1.377-1.117 2.503-2.483 2.503ZM68.869 16.255V11.22c0-1.696-1.365-3.073-3.048-3.073s-3.048 1.377-3.048 3.073v5.034h1.806V11.22c0-.681.552-1.237 1.228-1.237.676 0 1.227.556 1.227 1.237v5.034h1.835ZM40.938 16.352c1.641 0 2.91-.82 2.91-2.489v-.027c0-1.419-1.296-1.947-2.441-2.309-.924-.306-1.765-.542-1.765-1.112v-.028c0-.459.4-.793 1.103-.793.648 0 1.462.292 2.207.751l.717-1.293c-.827-.556-1.903-.904-2.883-.904-1.558 0-2.786.918-2.786 2.42v.028c0 1.515 1.297 2.002 2.455 2.322.924.292 1.752.5 1.752 1.112v.028c0 .528-.441.862-1.214.862-.8 0-1.724-.334-2.565-.973l-.8 1.223a5.394 5.394 0 0 0 3.31 1.182ZM47.6 16.352c1.641 0 2.91-.82 2.91-2.489v-.027c0-1.419-1.296-1.947-2.441-2.309-.924-.306-1.766-.542-1.766-1.112v-.028c0-.459.4-.793 1.104-.793.648 0 1.462.292 2.207.751l.717-1.293c-.828-.556-1.904-.904-2.883-.904-1.559 0-2.786.918-2.786 2.42v.028c0 1.515 1.297 2.002 2.455 2.322.924.292 1.752.5 1.752 1.112v.028c0 .528-.441.862-1.214.862-.8 0-1.724-.334-2.566-.973l-.8 1.223a5.394 5.394 0 0 0 3.31 1.182ZM36.055 7.272c-.607 0-1.103-.5-1.103-1.112 0-.612.496-1.113 1.103-1.113s1.104.501 1.104 1.113c-.014.626-.497 1.112-1.104 1.112ZM36.91 8.26h-1.738v7.995h1.738V8.26ZM52.18 7.272c-.608 0-1.104-.5-1.104-1.112 0-.612.496-1.113 1.103-1.113s1.104.501 1.104 1.113c0 .626-.497 1.112-1.104 1.112ZM53.048 8.26H51.31v7.995h1.738V8.26ZM34.11 16.255V11.22c0-1.696-1.365-3.073-3.048-3.073-.827 0-1.586.334-2.138.89a3.035 3.035 0 0 0-2.138-.89c-1.682 0-3.048 1.377-3.048 3.073v5.034h1.807V11.22c0-.681.552-1.237 1.227-1.237.676 0 1.228.556 1.228 1.237v5.048-.028l1.807.028V11.22c0-.681.552-1.237 1.228-1.237.675 0 1.227.556 1.227 1.237v5.034h1.848Z"
      fill="currentColor"
    ></path>
    <path
      d="M12.497 21.302c3.779-.194 6.772-3.392 6.772-7.314 0-4.032-3.2-7.327-7.131-7.314 3.048 0 5.517 2.503 5.517 5.576 0 3.087-2.483 5.576-5.53 5.576-3.946 0-7.146-3.281-7.146-7.314 0-4.032 3.2-7.328 7.131-7.314-4.965-.014-8.979 4.06-8.979 9.066 0 5.006 4.028 9.066 8.993 9.066.124-.014.248-.027.373-.027Z"
      fill="url(#a)"
    ></path>
    <path
      d="M11.752 3.198c-3.78.195-6.773 3.393-6.773 7.314 0 4.033 3.228 7.314 7.131 7.314-3.048 0-5.517-2.503-5.517-5.576 0-3.073 2.469-5.576 5.531-5.576 3.945 0 7.145 3.282 7.145 7.314 0 4.033-3.2 7.314-7.145 7.314 4.966 0 8.993-4.06 8.993-9.066 0-5.005-4.027-9.066-8.993-9.066-.124.014-.248.028-.372.028Z"
      fill="url(#b)"
    ></path>
    <defs>
      <linearGradient
        id="a"
        x1="4.03"
        y1="8.247"
        x2="18.841"
        y2="16.729"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0E3"></stop>
        <stop offset=".5" stopColor="#00589F"></stop>
        <stop offset="1" stopColor="#293895"></stop>
      </linearGradient>
      <linearGradient
        id="b"
        x1="21.788"
        y1="12.252"
        x2="5.187"
        y2="12.252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F26F21"></stop>
        <stop offset=".5" stopColor="#F9A51A"></stop>
        <stop offset="1" stopColor="#FDE000"></stop>
      </linearGradient>
    </defs>
  </svg>
);
