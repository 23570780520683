import clsx from "clsx";
import {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ElementType,
  HTMLInputTypeAttribute,
  ReactElement,
  forwardRef,
} from "react";

export type InputRef = HTMLInputElement;

export interface InputProps
  extends Omit<ComponentPropsWithRef<"input">, "type"> {
  /** Add classes to the input */
  container?: ComponentPropsWithRef<"div">;

  /** Show any element before the input. */
  /** TODO: Merge leftAddon and leftIcon */
  leftAddon?: ReactElement;

  /** Show an icon before the input. */
  leftIcon?: ElementType;

  /** Show any element after the input. */
  /** TODO: Merge rightAddon and rightIcon */
  rightAddon?: ReactElement;

  /** Show an icon after the input. */
  rightIcon?: ElementType;

  type?: Omit<
    HTMLInputTypeAttribute,
    "button" | "radio" | "checkbox" | "submit"
  >;
}

const AddonContainer = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"span">) => (
  <span
    className={clsx(
      className,
      "inline-flex items-center justify-center px-1 text-default"
    )}
    {...props}
  />
);

export const Input = forwardRef<InputRef, InputProps>(
  (
    {
      className,
      container,
      leftAddon,
      leftIcon: LeftIcon,
      rightAddon,
      rightIcon: RightIcon,
      type = "text",
      ...props
    },
    ref
  ) => {
    const { className: containerClassName, ...containerProps } =
      container || {};
    const containerClasses = clsx(
      containerClassName,
      "flex form-control-container form-control__focus-within",
      {
        "form-control__invalid": props["aria-invalid"],
        "form-control__disabled": props.disabled,
      }
    );
    const inputClasses = clsx(
      className,
      "w-full form-control-input form-control__no-focus"
    );
    const iconClasses = clsx("h-2.5 w-2.5");
    return (
      <div
        className={containerClasses}
        data-component="Input"
        {...containerProps}
      >
        {(LeftIcon || leftAddon) && (
          <AddonContainer className="pr-0">
            {LeftIcon ? <LeftIcon className={iconClasses} /> : leftAddon}
          </AddonContainer>
        )}
        <input
          type={type as HTMLInputTypeAttribute}
          className={inputClasses}
          ref={ref}
          {...props}
        />
        {(RightIcon || rightAddon) && (
          <AddonContainer className="pl-0">
            {RightIcon ? <RightIcon className={iconClasses} /> : rightAddon}
          </AddonContainer>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";
