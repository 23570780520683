import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";

export interface CardPropsStrict extends PropsWithChildren {
  /** Boolean to enable animating the hover state of the Card */
  animateOnHover?: boolean;

  /** Additional classes */
  className?: string;

  /** Level of section heading */
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  /** An optional title for the Card */
  title?: ReactNode;
}

interface CardProps extends CardPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

export const Card = ({
  animateOnHover,
  children,
  className,
  headingLevel = "h2",
  title = null,
  ...props
}: CardProps) => {
  const Tag = headingLevel;

  const classes = clsx("rounded border bg-white p-0", className, {
    "delay-50 cursor-pointer transition duration-300 ease-in-out hover:-translate-y-[2px] hover:!shadow-lg":
      animateOnHover,
  });

  return (
    <div
      className={classes}
      style={{ boxShadow: "0 5px 20px rgba(228, 228, 231, .35)" }}
      {...props}
    >
      {title && (
        <Tag className="border-b px-3 py-2 text-base font-semibold">
          {title}
        </Tag>
      )}
      {children}
    </div>
  );
};
