import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ASG_LABEL, GET_FILTERS_ERROR } from "../constants";

export const GET_ASG_FILTERS = gql(/* GraphQL */ `
  query AutoScalingGroupFilters {
    autoScalingGroupFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      availabilityZones
    }
  }
`);

export const useAutoScalingGroupFilters = () => {
  const onError = () => toast.error(GET_FILTERS_ERROR(ASG_LABEL));

  const { data, loading, error } = useQuery(GET_ASG_FILTERS, {
    onError,
  });

  const filters = data?.autoScalingGroupFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];

  return {
    data: { filters: { accounts } },
    error,
    loading,
  };
};
