import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { AutoScalingGroupFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { ASG_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export const GET_AUTO_SCALING_GROUPS = gql(/* GraphQL */ `
  query AutoScalingGroups(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [AutoScalingGroupSortEnum]
    $where: AutoScalingGroupFilter
  ) {
    autoScalingGroups(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountName
          accountId
          name
          minSize
          maxSize
          desiredCapacity
          availabilityZones
          tagTotal
          tags
          createTime
        }
      }
    }
  }
`);

export const useAutoScalingGroups = (query) => {
  const where: AutoScalingGroupFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(ASG_LABEL));

  const { data, loading, error, fetchMore } = useQuery(
    GET_AUTO_SCALING_GROUPS,
    {
      variables: {
        sort: query.sort,
        ...INITIAL_PAGINATION,
        searchTerm: query.search,
        where,
      },
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.autoScalingGroups || {};
  const autoScalingGroups = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { autoScalingGroups },
    error,
    loading,
    pagination,
  };
};
