import { useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Field,
  Fields,
  Input,
  InputProps,
  Spinner,
  Textarea,
  TextareaProps,
  useRowSelectProps,
} from "@m/ui";

import { Overlay } from "@mc/components/Overlay";
import { UpdateLayout } from "@mc/layouts";

import { useCheckDetails, useCreateCloudScoreRecommendation } from "../api";
import { CLOUD_SCORE_MESSAGES } from "../constants";
import { CheckStatusIcon } from "../icons";

import { FlaggedCheckResourcesTable } from "./FlaggedCheckResourcesTable";

interface CreateRecommendationOverlayParams extends Params {
  checkId: string;
}

export function CreateRecommendationOverlay() {
  const navigate = useNavigate();
  const { checkId } = useParams<CreateRecommendationOverlayParams>();

  const {
    data: { check },
    loading,
    error,
  } = useCheckDetails(checkId);

  const [createRecommendation, { loading: createLoading }] =
    useCreateCloudScoreRecommendation();

  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");

  const rowSelectProps = useRowSelectProps(true);
  const { isAllRowsSelected, selectedRowIds } = rowSelectProps;

  const handleClose = () => {
    navigate("..");
  };
  const handleChangeNotes: TextareaProps["onChange"] = (e) => {
    const { value } = e.target;
    setNotes(value);
  };
  const handleChangeTitle: InputProps["onChange"] = (e) => {
    const { value } = e.target;
    setTitle(value);
  };
  const handleCreateRecommendation = () => {
    createRecommendation({
      title,
      notes,
      isAllResourcesSelected: isAllRowsSelected,
      selectedResourceIds: selectedRowIds.map((resourceId) => ({
        resourceId,
      })),
      checkId,
    });
    navigate(-1);
  };

  const submitEnabled = title.length > 0 && !createLoading;

  return (
    <Overlay
      data-testid="create-recommendation-overlay"
      title={
        <div className="flex flex-row items-center text-black">
          Add Recommendation for{" "}
          <div className="ml-1 mr-0.5">
            <CheckStatusIcon status={check?.status} />
          </div>
          {check?.name}
        </div>
      }
      open={true}
      onClose={handleClose}
      buttons={[
        <Button key="cancel-button" onClick={() => navigate(-1)}>
          Cancel
        </Button>,
        <Button
          key="add-button"
          disabled={!submitEnabled}
          kind="primary"
          onClick={handleCreateRecommendation}
        >
          Add Recommendation
        </Button>,
      ]}
    >
      <UpdateLayout
        fields={
          <Fields>
            <Field label="Title" htmlFor="title">
              <Input id="title" onChange={handleChangeTitle} value={title} />
            </Field>
            <Field label="Notes" htmlFor="notes">
              <Textarea id="notes" onChange={handleChangeNotes} value={notes} />
            </Field>
          </Fields>
        }
      >
        {!loading && !error && (
          <FlaggedCheckResourcesTable
            checkId={check.id}
            resourceColumns={check.flaggedResourceFields}
            {...rowSelectProps}
          />
        )}

        {!loading && error && (
          <div className="flex h-full items-center justify-center text-subdued">
            {CLOUD_SCORE_MESSAGES.CHECK_DETAILS_FAILURE}
          </div>
        )}

        {loading && (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        )}
      </UpdateLayout>
    </Overlay>
  );
}
