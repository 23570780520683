import { gql, useQuery } from "@m/api";
import { Workspace } from "@m/api/public/types";
import { toast } from "@m/ui";

import { ENGAGEMENT_STATUS, MESSAGES_ENGAGEMENTS } from "../constants";

export const GET_ENGAGEMENT_WORKSPACES = gql`
  query EngagementWorkspaces($where: WorkspaceFilter) {
    workspaces(where: $where) {
      edges {
        node {
          budget
          dueDate
          id
          isAssociatedWithEngagement
          startDate
          title
        }
      }
    }
  }
`;

interface QueryResponse {
  workspaces: {
    edges: {
      node: Workspace;
    }[];
  };
}

const { IN_PROGRESS, OKAY_TO_START, ON_HOLD } = ENGAGEMENT_STATUS;

export const useEngagementWorkspaces = () => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS.GET_ENGAGEMENT_WORKSPACES_ERROR);

  const { data, loading, error } = useQuery<QueryResponse>(
    GET_ENGAGEMENT_WORKSPACES,
    {
      onError,
      variables: {
        where: {
          statusIn: [IN_PROGRESS, OKAY_TO_START, ON_HOLD],
        },
      },
    }
  );

  const workspaces = data?.workspaces?.edges?.map((edge) => edge.node) || [];
  return { data: { workspaces }, loading, error };
};
