import { gql } from "@mc/utils/graphql";

export const LIST_CASES = gql`
  query GetCases($before: String, $after: String) {
    cases(channel: EMAIL, before: $before, after: $after) @csv {
      edges {
        node {
          number
          channel
          createdAt
          openedBy {
            fullName
            email
            lastActive
          }
          company {
            name
          }
        }
      }
    }
  }
`;
