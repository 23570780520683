import { PropsWithChildren, ReactNode } from "react";

import { PageTitle } from "@mc/components/PageTitle";

interface Props extends PropsWithChildren {
  navigation: ReactNode;
  title: string;
}

export const NavigationLayout = ({ children, navigation, title }: Props) => (
  <div className="flex h-screen flex-col">
    {title && <PageTitle border="none" padding="small" title={title} />}
    <div className="flex grow">
      <div className="min-w-[190px] px-3 py-4">{navigation}</div>
      <div className="flex grow flex-col">{children}</div>
    </div>
  </div>
);
