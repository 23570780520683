import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  ApplicationLoadBalancersPage,
  AutoScalingGroupsPage,
  ClassicLoadBalancersPage,
  Ec2InstancesPage,
  EcsClustersPage,
  EksClustersPage,
  ElasticacheClustersPage,
  LambdaFunctionsPage,
  NetworkLoadBalancersPage,
  RdsInstancesPage,
  RedshiftClustersPage,
} from "@mc/features/AwsResources";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

export const resourcesRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.RESOURCES_EC2} />,
  },
  {
    path: "application-load-balancers",
    element: <ApplicationLoadBalancersPage />,
  },
  { path: "ec2-auto-scaling", element: <AutoScalingGroupsPage /> },
  {
    path: "classic-load-balancers",
    element: <ClassicLoadBalancersPage />,
  },
  { path: "ec2", element: <Ec2InstancesPage /> },
  { path: "ecs", element: <EcsClustersPage /> },
  { path: "eks", element: <EksClustersPage /> },
  { path: "elasticache", element: <ElasticacheClustersPage /> },
  { path: "lambda", element: <LambdaFunctionsPage /> },
  {
    path: "network-load-balancers",
    element: <NetworkLoadBalancersPage />,
  },
  { path: "rds", element: <RdsInstancesPage /> },
  { path: "redshift", element: <RedshiftClustersPage /> },
  { path: "*", element: <NotFound /> },
];
