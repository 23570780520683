import { TooltipContentProps as ContentProps } from "@radix-ui/react-tooltip";
import clsx from "clsx";

import { Tooltip } from "@m/ui";
import { dt, formatDate, toProperCase, toTimeLocaleSimple } from "@m/utils";

interface TimestampProps {
  isoTime: string | null;
  longFormat?: boolean;
  verbiage?: string;
  side?: ContentProps["side"];
  titleCase?: boolean;
  className?: string;
}

export const Timestamp = ({
  isoTime,
  longFormat = false,
  verbiage = "",
  side = "left",
  titleCase = false,
  className,
}: TimestampProps) => {
  if (!isoTime) return null;
  if (!dt.fromISO(isoTime).isValid) return null;

  const date = formatDate(isoTime, { longFormat });
  const displayTime = titleCase ? toProperCase(date) : date;
  const tooltipTime = toTimeLocaleSimple(isoTime, dt.DATETIME_MED);

  return (
    <Tooltip content={tooltipTime} side={side} className="z-50">
      <span
        className={clsx(
          "select-text whitespace-nowrap underline decoration-dashed underline-offset-2",
          className
        )}
      >
        {verbiage} {displayTime}
      </span>
    </Tooltip>
  );
};
