import { Navigate as BaseNavigate, NavigateProps } from "react-router-dom";

import { useAuth } from "@m/login";

import { generateCompanyPath } from "@mc/utils/companyPath";

interface Props extends NavigateProps {
  to: string;
}

/**
 * Custom Navigate component that prefixes paths passed into the `to`
 * prop with company slug.
 */
export const Navigate = ({ to, ...props }: Props) => {
  const { user } = useAuth();

  return (
    <BaseNavigate to={generateCompanyPath(to, user.company.slug)} {...props} />
  );
};
