import { CalendarIcon } from "@heroicons/react/20/solid";
import ReactDatePicker from "react-datepicker";

import { dt } from "@m/utils";

interface RioGraphDateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: [Date | null, Date | null]) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export const RioGraphDateRangePicker = ({
  startDate,
  endDate,
  onChange,
  minDate,
  maxDate = dt.now().toJSDate(),
  disabled = false,
}: RioGraphDateRangePickerProps) => {
  return (
    <ReactDatePicker
      className="h-4 w-[250px] rounded-md border border-layout-border !px-4"
      disabled={disabled}
      enableTabLoop={false}
      endDate={endDate}
      icon={<CalendarIcon />}
      isClearable
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      peekNextMonth
      placeholderText="Select date range"
      selected={startDate}
      selectsRange
      showIcon
      showMonthDropdown
      showYearDropdown
      startDate={startDate}
      todayButton="Today"
    />
  );
};
