import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES_ENGAGEMENTS } from "../constants";
import { TConfigurationDetails } from "../pages";

import { GET_ENGAGEMENTS_LIST } from "./useEngagementsList";

export const CREATE_ENGAGEMENT_WITH_TEMPLATE = gql`
  mutation CreateEngagementWithTemplateInput(
    $input: CreateEngagementWithTemplateInput!
  ) {
    createEngagementWithTemplate(input: $input) {
      ok
      message
      engagement {
        id
      }
    }
  }
`;

export const useCreateEngagementWithTemplate = () => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS.CREATE_ENGAGEMENT_WITH_TEMPLATE_SUCCESS);

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS.CREATE_ENGAGEMENT_WITH_TEMPLATE_ERROR);

  const [mutate, { loading }] = useMutation(CREATE_ENGAGEMENT_WITH_TEMPLATE, {
    onCompleted,
    onError,
  });

  const createEngagementWithTemplate = (
    templateDetails: TConfigurationDetails
  ) => {
    return mutate({
      variables: {
        input: {
          sourceId: templateDetails.linkedProjectId,
          engagementType: templateDetails.template,
          title: templateDetails.title,
          originalBudget: templateDetails.originalBudget,
          budgetSpent: 0,
          startDate: templateDetails.startDate,
          dueDate: templateDetails.dueDate,
        },
      },
      refetchQueries: [
        {
          query: GET_ENGAGEMENTS_LIST,
          variables: { where: {} },
        },
      ],
    });
  };

  return [createEngagementWithTemplate, { loading }] as const;
};
