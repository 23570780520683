import { EngineerAssistSupportBanner } from ".";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

import { Button } from "@m/ui";

import { PATHS } from "@mc/constants";

export const EngineerAssistEmpty = () => {
  const handleDetailsClick = () =>
    window.open("https://www.missioncloud.com/engineer-assist", "_blank");

  return (
    <div className="flex h-full flex-col items-center justify-center gap-5">
      <div className="flex w-3/5 flex-col gap-2">
        <div>
          <div className="text-xl font-semibold text-default">
            Engineer Assist
          </div>
          <div className="text-2xl font-semibold text-default">
            Your on-demand team for common everyday tasks in your AWS
            environment.
          </div>
        </div>
        <div className="text-lg font-normal text-subdued">
          Simply describe what you need help with and we’ll coordinate,
          estimate, and handle it with our AWS-certified engineers. Reach out to
          your Customer Success Manager or Account Executive for pricing.
        </div>
      </div>
      <div className="mb-2 flex gap-3">
        <Button kind="primary" to={PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE}>
          Add New Request
        </Button>
        <Button
          kind="secondary"
          rightIcon={ArrowUpRightIcon}
          rel="noopener noreferrer"
          onClick={handleDetailsClick}
        >
          More Details
        </Button>
      </div>
      <EngineerAssistSupportBanner className="w-3/5" />
    </div>
  );
};
