export const REGEX_PATTERNS = {
  // ^                          - Start of the line or string.

  // (100|[1-9]?[0-9])          - Matches either:
  //    100                     - the specific number "100"
  //    |                       - OR
  //    [1-9]?                  - a single non-zero digit (from 1 to 9), zero or one time
  //                             (making it optional, so it matches numbers from 1-9).
  //    [0-9]                   - any single digit from 0 to 9.
  //                             This ensures that numbers like "01" to "09" are not matched,
  //                             but numbers "10" to "99" are.

  // $                          - End of the line or string.
  PERCENT: "^(100|[1-9]?[0-9])$",
  // ^                          - Start of the line or string.
  // (0|[1-9][0-9]{0,2})        - Matches either:
  //    0                       - a single 0
  //    |                       - OR
  //    [1-9]                   - a non-zero digit (from 1 to 9), followed by
  //    [0-9]{0,2}              - up to two digits (0-9).
  //                            - This ensures numbers don't start with zeroes like 001, 0123, etc.
  //
  // (,?[0-9]{3})*              - Matches groups of:
  //    ,?                      - an optional comma (making the comma optional), followed by
  //    [0-9]{3}                - exactly three digits (0-9).
  //                            - This group can be repeated zero or more times.
  //
  // ([.][0-9]{1,2})?           - Matches:
  //    [.]                     - a literal period, followed by
  //    [0-9]{1,2}              - one or two digits.
  //                            - This entire group is optional, making fractional parts like ".00" optional.
  //
  // $                          - End of the line or string.
  CURRENCY: "^(0|[1-9][0-9]{0,2})(,?[0-9]{3})*([.][0-9]{1,2})?$",
};
