import { useAuth } from "@m/login";
import { Button } from "@m/ui";

import { PATHS } from "@mc/constants";
import { ErrorPage } from "@mc/pages";
import { useNavigate } from "@mc/router";

export const SubscriptionDoesNotExistError = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <ErrorPage
      title="Subscription doesn't exist"
      content={
        <div>
          <div>Your product registration cannot be completed.</div>
          <div>
            Please check your AWS Marketplace to reconfirm your information.
          </div>
        </div>
      }
      action={
        user && (
          <Button onClick={() => navigate(PATHS.ROOT)} kind="primary">
            Back
          </Button>
        )
      }
    />
  );
};
