import { gql } from "@m/api/public";

export const GET_METERED_CHARGES_TRANSACTIONS_REPORT = gql(/* GraphQL */ `
  query GetMarketplaceMeteredChargesReport(
    $where: MarketplaceMeteringTransactionFilter
  ) {
    marketplaceMeteringTransactions(where: $where) @csv {
      edges {
        node {
          product: catalogItem {
            name: displayName
          }
          billingPeriod {
            time: isoformat
          }
          dimension {
            name: displayName
          }
          meteredQuantity: quantity
          reportedDate
          chargeDate
        }
      }
    }
  }
`);

export const GET_AGGREGATED_METERED_CHARGES_REPORT = gql(/* GraphQL */ `
  query GetAggregatedMeteredChargesReport(
    $billingPeriodType: AggregateBillingPeriod!
    $dimensionTypeIds: [String]
  ) {
    aggregateMarketplaceMeteringTransactions(
      billingPeriodType: $billingPeriodType
      dimensionTypeIds: $dimensionTypeIds
    ) @csv {
      edges {
        node {
          day
          month
          year
          dimensionTypeId
          meteredQuantity
        }
      }
    }
  }
`);
