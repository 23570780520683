import { CustomLayerProps } from "@nivo/line";
import { Fragment } from "react";

interface CrosshairLayerProps extends CustomLayerProps {
  currentSlice: {
    points: {
      id: string;
      y: number;
      color: string;
    }[];
    x: number;
    y: number;
  };
}

export const CrosshairLayer = ({ currentSlice }: CrosshairLayerProps) => {
  if (!currentSlice || currentSlice.points.length < 1) return null;

  return (
    <svg>
      {currentSlice.points.map(({ id, y, color }) => {
        return (
          <Fragment key={id}>
            <circle cx={currentSlice.x} cy={y} r={8} fill="#FFFFFF" />
            <circle cx={currentSlice.x} cy={y} r={4} fill={color} />
          </Fragment>
        );
      })}
    </svg>
  );
};
