export const DEFAULT_COLUMN = {
  width: "auto",
};

export const ICON_COLUMN = {
  id: "icon",
  sort: "",
  width: 40,
  minWidth: 40,
  maxWidth: 10,
  Cell: ({ row }) => <div>{row.original.icon}</div>,
};

export const SELECT_COLUMN = {
  id: "selection",
  sort: "",
  width: 30,
  minWidth: 30,
  maxWidth: 10,
};
