import { ReactNode } from "react";

import { Buttons } from "@m/ui";

interface Props {
  buttons?: ReactNode[];
}

export const OverlayFooter = ({ buttons = [] }: Props) => (
  <div data-testid="overlay-footer" className="border-t p-1">
    <Buttons align="right">
      {buttons.map((button, i) => (
        <div key={i}>{button}</div>
      ))}
    </Buttons>
  </div>
);
