import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_OPTIMIZATION_HISTORY = gql`
  query checkResult($id: ID!) {
    scorecardCheckResult(id: $id) {
      __typename
      id
      activityStream {
        edges {
          node {
            __typename
            ... on ScorecardChangeActivityStream {
              id
              changes
              timestamp
              name
            }
            ... on ScorecardIgnoredCheckActivityStream {
              id
              checkDefinitionName
              timestamp
              operation
              actor {
                name
                firstName
                lastName
                email
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useOptimizationHistory = (id) => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.GET_OPTIMIZATION_HISTORY_ERROR);

  const { data, loading } = useQuery(GET_OPTIMIZATION_HISTORY, {
    variables: {
      id,
    },
    onError,
  });

  const activities =
    data?.scorecardCheckResult?.activityStream?.edges?.map(
      (edge) => edge.node
    ) || [];

  return {
    data: { activities },
    loading,
  };
};
