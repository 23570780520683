import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";

export const GET_MEMBERSHIP_OPTIONS = gql(`
  query GetTeamMembersRoles {
    jobRoles
    users(isStaff: true, disabled: false, sort: FULL_NAME_ASC) {
      edges {
        node {
          databaseId
          name
          email
          firstName
          lastName
        }
      }
    }
  }
`);

export const useMembershipOptions = () => {
  const { data, loading, error } = useQuery(GET_MEMBERSHIP_OPTIONS, {
    context: { clientName: "v4" },
  });

  const roles = data?.jobRoles || [];
  const users = data?.users?.edges.map((edge) => edge.node) || [];

  return { data: { roles, users }, loading, error };
};
