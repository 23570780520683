import { TrashIcon } from "@heroicons/react/24/outline";
import PT from "prop-types";

export const DeleteRecommendationButton = ({ disabled, onClick }) => {
  return (
    <button
      aria-label="Delete Recommendation"
      className="mt-0.5 flex space-x-1 text-gray-400"
      disabled={disabled}
      onClick={onClick}
    >
      <TrashIcon className="h-2.5 w-2.5 text-red-500" />
      <div className="text-sm font-semibold text-red-500">Delete</div>
    </button>
  );
};

DeleteRecommendationButton.propTypes = {
  disabled: PT.bool.isRequired,
  onClick: PT.func.isRequired,
};
