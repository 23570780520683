import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

export const GET_INTERCOM_MESSAGES = gql`
  query useGetIntercomMessages($conversationId: ID!, $sort: [MessageSortEnum]) {
    conversation(conversationId: $conversationId) {
      messages(sort: $sort) {
        edges {
          node {
            id
            createdAt
            createdBy {
              name
              email
              firstName
              lastName
              isStaff
            }
            comment
            caseId
          }
        }
      }
    }
  }
`;

export const useGetIntercomMessages = (conversationId) => {
  const onError = () => {
    toast.error(MESSAGES.INTERCOM_MESSAGE_FAILURE);
  };

  const { loading, data, refetch } = useQuery(GET_INTERCOM_MESSAGES, {
    onError,
    variables: {
      conversationId,
    },
  });

  return {
    data: data?.conversation?.messages?.edges || [],
    loading,
    refetch,
  };
};
