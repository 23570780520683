import { CaseCategory, CaseSubcategory } from "@m/api/public/types";

import { CaseType } from "../types";

export interface CaseCategoryDefinition {
  name: string;
  value: CaseCategory;
  subcategories: CaseSubcategoryDefinition[];
}

export interface CaseSubcategoryDefinition {
  name: string;
  value: CaseSubcategory;
}

export const CASE_CATEGORY_DEFINITIONS: {
  [key in CaseType]: CaseCategoryDefinition[];
} = {
  [CaseType.Operations]: [
    {
      name: "Change Request",
      value: CaseCategory.Request,
      subcategories: [
        {
          name: "Management Request",
          value: CaseSubcategory.Change,
        },
        {
          name: "Deployment Request",
          value: CaseSubcategory.Change,
        },
      ],
    },
    {
      name: "Service Query/Request",
      value: CaseCategory.Request,
      subcategories: [
        {
          name: "Service Reporting",
          value: CaseSubcategory.OtherRequest,
        },
        {
          name: "Monitoring",
          value: CaseSubcategory.OtherRequest,
        },
        {
          name: "Backup",
          value: CaseSubcategory.OtherRequest,
        },
        {
          name: "Patching",
          value: CaseSubcategory.OtherRequest,
        },
        {
          name: "Alert Notification",
          value: CaseSubcategory.OtherRequest,
        },
        {
          name: "Tagging",
          value: CaseSubcategory.OtherRequest,
        },
        {
          name: "Other",
          value: CaseSubcategory.OtherRequest,
        },
      ],
    },
    {
      name: "Incident Report",
      value: CaseCategory.Incident,
      subcategories: [
        {
          name: "Access Issue",
          value: CaseSubcategory.Infrastructure,
        },
        {
          name: "Resource Availability",
          value: CaseSubcategory.Infrastructure,
        },
        {
          name: "Site/Application Issue",
          value: CaseSubcategory.Infrastructure,
        },
        {
          name: "Performance Issue",
          value: CaseSubcategory.Infrastructure,
        },
        {
          name: "Security Issue",
          value: CaseSubcategory.Infrastructure,
        },
        {
          name: "Other",
          value: CaseSubcategory.Infrastructure,
        },
      ],
    },
    {
      name: "Other Request",
      value:
        `${CaseCategory.Request}:${CaseSubcategory.OtherRequest}` as CaseCategory,
      subcategories: [],
    },
  ],
  [CaseType.Account]: [
    {
      name: "AWS Account",
      value: CaseCategory.Request,
      subcategories: [
        {
          name: "Create New AWS Account",
          value: CaseSubcategory.AwsAccount,
        },
        {
          name: "Link AWS Account",
          value: CaseSubcategory.AwsAccount,
        },
        {
          name: "Remove AWS Account",
          value: CaseSubcategory.AwsAccount,
        },
        {
          name: "Close My AWS Account",
          value: CaseSubcategory.AwsAccount,
        },
        {
          name: "Unable to Access AWS Account",
          value: CaseSubcategory.AwsAccount,
        },
        {
          name: "Other AWS Account Request/Issue",
          value: CaseSubcategory.AwsAccount,
        },
      ],
    },
    {
      name: "RI/Savings Plans",
      value: CaseCategory.Request,
      subcategories: [
        {
          name: "Question about RI/Savings Plan",
          value: CaseSubcategory.ReservedInstanceSupport,
        },
        {
          name: "Purchase RI/Savings Plan",
          value: CaseSubcategory.ReservedInstanceSupport,
        },
        {
          name: "Cancel RI/Savings Plan",
          value: CaseSubcategory.ReservedInstanceSupport,
        },
        {
          name: "Other RI/Savings Plan Issue",
          value: CaseSubcategory.ReservedInstanceSupport,
        },
      ],
    },
    {
      name: "Service Quota Increase",
      value: CaseCategory.Request,
      subcategories: [
        {
          name: "Increase Service Quota",
          value: CaseSubcategory.ServiceLimitIncrease,
        },
        {
          name: "Question about Service Quota Request",
          value: CaseSubcategory.ServiceLimitIncrease,
        },
        {
          name: "Other Service Quota Increase Request or Issue",
          value: CaseSubcategory.ServiceLimitIncrease,
        },
      ],
    },
  ],
  [CaseType.Billing]: [
    {
      name: "Billing",
      value: CaseCategory.Billing,
      subcategories: [
        {
          name: "Charge Inquiry",
          value: CaseSubcategory.ChargeInquiry,
        },
        {
          name: "Payment Issue",
          value: CaseSubcategory.PaymentIssue,
        },
        {
          name: "Service Credit Request",
          value: CaseSubcategory.ServiceCreditRequest,
        },
        {
          name: "Dispute a Charge",
          value: CaseSubcategory.DisputeACharge,
        },
        {
          name: "Other Billing Request/Issue",
          value: CaseSubcategory.OtherBillingRequest,
        },
      ],
    },
  ],
  [CaseType.AwsGuidance]: [
    {
      name: "Cost Management",
      value: CaseCategory.Consulting,
      subcategories: [
        {
          name: "Visualization and Reporting",
          value: CaseSubcategory.Configuration,
        },
        {
          name: "Reserved Instance Operations",
          value: CaseSubcategory.Reservation,
        },
        {
          name: "Optimization Guidance",
          value: CaseSubcategory.SavingsOpportunities,
        },
        {
          name: "Forecasting and Budgeting",
          value: CaseSubcategory.Other,
        },
      ],
    },
    {
      name: "Performance",
      value: CaseCategory.Consulting,
      subcategories: [
        {
          name: "Metrics Review",
          value: CaseSubcategory.Other,
        },
        {
          name: "Operations Best Practices",
          value: CaseSubcategory.Other,
        },
        {
          name: "Security Best Practices",
          value: CaseSubcategory.Other,
        },
        {
          name: "Cost Allocation",
          value: CaseSubcategory.Other,
        },
      ],
    },
    {
      name: "Solutions Architecture",
      value: CaseCategory.Consulting,
      subcategories: [
        {
          name: "Technology Roadmapping",
          value: CaseSubcategory.Other,
        },
        {
          name: "Architecture Best Practices",
          value: CaseSubcategory.Other,
        },
        {
          name: "Modernization Review",
          value: CaseSubcategory.Other,
        },
      ],
    },
  ],
  [CaseType.Support]: [
    {
      name: "Request",
      value: CaseCategory.Request,
      subcategories: [
        {
          name: "General Guidance",
          value: CaseSubcategory.GeneralGuidance,
        },
      ],
    },
  ],
};
