import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { MissionControlLogin } from "@mc/app/components";
import { AuthenticatedProviders } from "@mc/providers";

/** Renders child routes, if the user is logged in, otherwise renders login page. */
export const AuthenticatedOutlet = () => {
  const { user } = useAuth();

  if (!user) return <MissionControlLogin />;

  return (
    <AuthenticatedProviders>
      <Outlet />
    </AuthenticatedProviders>
  );
};
