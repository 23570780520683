export const AWS_RESOURCE_STATUS_TAGS = {
  CRITICAL: "Critical",
  LIGHT: "Light",
  NONE: "None",
  NON_CRITICAL: "Non-Critical",
  NOT_APPLICABLE: "N/A",
  ROBUST: "Robust",
  STANDARD: "Standard",
  STANDARD_AUTO: "Standard (Auto)",
  STANDARD_MANUAL: "Standard (Manual)",
};
