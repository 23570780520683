import { useContext, useEffect, useMemo } from "react";

import { ScorecardResult } from "@m/api/public/types";

import { CloudScoreTrendContext } from "../contexts";
import { getPillar } from "../utils";

interface LoadScoreChangeInput {
  scoreResults: ScorecardResult[];
  pillarId: string;
  scoreKey?: string;
}

export const useLoadScoreChange = ({
  scoreResults,
  pillarId,
  scoreKey = "score",
}: LoadScoreChangeInput) => {
  const { onLoad } = useContext(CloudScoreTrendContext);

  const scoreChange = useMemo(() => {
    const filteredScoreResults = scoreResults.filter(
      (scoreResult) => scoreResult.pillar === getPillar(pillarId)?.pillar
    );
    const lastScore = filteredScoreResults[0]?.[scoreKey];
    const endIndex = filteredScoreResults.length - 1;
    const firstScore = filteredScoreResults[endIndex]?.[scoreKey];
    return parseFloat((lastScore - firstScore).toFixed(1));
  }, [scoreResults, pillarId, scoreKey]);

  useEffect(() => {
    if (!isNaN(scoreChange) && onLoad) onLoad(scoreChange);
  }, [scoreChange, onLoad]);
};
