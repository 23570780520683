import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { RdsInstanceFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { GET_RESOURCES_FAILURE, RDS_LABEL } from "../constants";

export const RDS_INSTANCES_QUERY = gql(/* GraphQL */ `
  query RdsInstances(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [RdsInstanceSortEnum]
    $where: RDSInstanceFilter
  ) {
    rdsInstances(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          databaseName
          instanceId
          status
          instanceClass
          availabilityZone
          isMultiAz
          isEncrypted
          accountName
          accountId
          engine
          createTime
          tagTotal
          tags
          backupPolicy
          monitoringPolicy
        }
      }
    }
  }
`);

export const useRdsInstances = (query) => {
  const where: RdsInstanceFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;
  if (query.zones) where.availabilityZoneIn = query.zones;
  if (query.statuses) where.statusIn = query.statuses;
  if (query.classes) where.instanceClassIn = query.classes;
  if (query.isMultiAz === "Multiple Zones") where.multiAz = true;
  if (query.isMultiAz === "Single Zone") where.multiAz = false;
  if (query.isEncrypted === "Encrypted") where.storageEncrypted = true;
  if (query.isEncrypted === "Unencrypted") where.storageEncrypted = false;
  if (query.engines) where.engineIn = query.engines;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(RDS_LABEL));

  const { data, loading, error, fetchMore } = useQuery(RDS_INSTANCES_QUERY, {
    variables: {
      sort: query.sort,
      ...INITIAL_PAGINATION,
      searchTerm: query.search,
      where,
    },
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const { edges, pageInfo, totalCount } = data?.rdsInstances || {};
  const rdsInstances = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { rdsInstances },
    error,
    loading,
    pagination,
  };
};
