import { generateMockId } from "@m/api/testing/utils";

import { DEFAULT_COLUMN } from "../constants";

export class ExampleHeader {
  constructor({ label = "Header 1", width = DEFAULT_COLUMN.width }) {
    this.id = generateMockId();
    this.accessor = label.toLowerCase().replace(" ", "");
    this.label = label;
    this.sort = label.toUpperCase().replace(" ", "_");
    this.width = width;
  }
}
