import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const AWS_ACCOUNTS = gql(/* GraphQL */ `
  query AwsAccounts {
    awsAccounts {
      edges {
        node {
          awsAccountOnboardingStatus
          databaseId
          displayName
          id
          isSyncing
          lastCompletedSynced
          name
          onboardingStatus
          payerAccountId
          syncDisableReasonString
          syncsEnabled
        }
      }
    }
  }
`);

export const useAwsAccounts = () => {
  const { data, ...result } = useQuery(AWS_ACCOUNTS, {
    onError: () => toast.error("Unable to access AWS account data"),
  });

  const accounts = data?.awsAccounts?.edges.map((edge) => edge.node) || [];

  return {
    data: { accounts },
    ...result,
  };
};
