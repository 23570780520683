import { toHyphenLowercase } from "@m/utils";

import { SCORECARD_DETAILS } from "../constants";

export const getPillar = (id) => {
  let pillar;

  pillar = Object.values(SCORECARD_DETAILS).find(
    (pillar) => pillar.id === toHyphenLowercase(id)
  );

  if (!pillar)
    pillar = Object.values(SCORECARD_DETAILS).find(
      (pillar) => toHyphenLowercase(pillar.pillar) === toHyphenLowercase(id)
    );

  return pillar;
};
