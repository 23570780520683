import { ReactNode } from "react";

export const WidgetMainSection = ({
  badge,
  label,
  detail,
}: {
  badge: ReactNode;
  label: string;
  detail: string;
}) => {
  return (
    <div className="flex flex-col gap-0.5">
      <div className="flex items-center gap-2">
        <div className="text-xs font-semibold uppercase tracking-wide text-subdued">
          {label}
        </div>
        {badge}
      </div>
      <div className="text-xl font-bold">{detail}</div>
    </div>
  );
};
