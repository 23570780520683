import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import {
  DEFAULT_SORT_RISK_LOG,
  MESSAGES_ENGAGEMENTS_RISK_LOG,
} from "../constants";
import { IRiskLogItem } from "../types";

import { GET_RISK_LOG_ITEMS } from "./useRiskLogItems";

export const CREATE_RISK_LOG_ITEM = gql`
  mutation CreateRiskLogItem($input: CreateRiskLogItemInput!) {
    createRiskLogItem(input: $input) {
      ok
      message
    }
  }
`;

export const useCreateRiskLogItem = (engagementId: string) => {
  const onCompleted = () =>
    toast.success(MESSAGES_ENGAGEMENTS_RISK_LOG.SUCCESS_CREATING_RISK_LOG_ITEM);

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_RISK_LOG.ERROR_CREATING_RISK_LOG_ITEM);

  const [mutate, { loading }] = useMutation(CREATE_RISK_LOG_ITEM, {
    onCompleted,
    onError,
  });

  const createRiskLogItem = (newRiskLogItem: Partial<IRiskLogItem>) => {
    return mutate({
      variables: {
        input: { engagementId, ...newRiskLogItem },
      },
      refetchQueries: [
        {
          query: GET_RISK_LOG_ITEMS,
          variables: { id: engagementId, sort: DEFAULT_SORT_RISK_LOG },
        },
      ],
    });
  };

  return [createRiskLogItem, { loading }] as const;
};
