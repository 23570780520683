import { useNavigate } from "react-router-dom";

import { useAuth } from "@m/login";
import { Button } from "@m/ui";

import { PATHS } from "@mc/constants";
import { ErrorPage } from "@mc/pages";

export const AccountAlreadyExistsError = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <ErrorPage
      title="AWS account has already been onboarded to Mission Cloud"
      content={
        <div className="space-y-5 text-xl font-normal text-gray-500">
          {!user && (
            <div>
              Request an invite to Mission Control from
              <br />
              your company's administrator.
            </div>
          )}

          <div className="space-y-2">
            {!user && <div>Already have an account?</div>}
            <Button onClick={() => navigate(PATHS.ROOT)} kind="primary">
              {user ? "Back" : "Sign In"}
            </Button>
          </div>
        </div>
      }
    />
  );
};
