import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLB_LABEL, GET_FILTERS_ERROR } from "../constants";

export const GET_CLASSIC_LOAD_BALANCER_FILTERS = gql(`
  query ClassicLoadBalancerFilters {
    classicLoadBalancerFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
      regions
    }
  }
`);

export const useClassicLoadBalancerFilters = () => {
  const onError = () => {
    toast.error(GET_FILTERS_ERROR(CLB_LABEL));
  };

  const { data, loading, error } = useQuery(GET_CLASSIC_LOAD_BALANCER_FILTERS, {
    onError,
  });

  const filters = data?.classicLoadBalancerFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];
  const regions = filters.regions || [];

  return {
    data: { filters: { accounts, regions } },
    error,
    loading,
  };
};
