import { createContext, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useAuth } from "@m/login";

interface UsersnapApi {
  init: (options: any) => Promise<void>;
  destroy: () => Promise<void>;
  logEvent: (eventName: string) => Promise<void>;
  on: (eventName: string, callback: (event: any) => void) => void;
  off: (eventName: string, callback: (event: any) => void) => void;
}

export const UsersnapContext = createContext<UsersnapApi | null>(null);
export const useUsersnapAPI = () => useContext(UsersnapContext);

type props =
  | string
  | {
      eventKey: string;
      fallback?: () => void;
    };

/* const [fireEventFunction, isUsersnapLoaded] = useUsersnapEvent(...) */
export const useUsersnapEvent = (props: props): [() => void, boolean] => {
  const isString = typeof props === "string";
  const eventKey = isString ? props : props.eventKey;
  const fallback = isString ? () => null : props.fallback;

  const api = useUsersnapAPI();

  const eventFn = () => {
    if (!api?.logEvent) return fallback?.();
    api.logEvent(eventKey);
  };
  const isUsersnapLoaded = api !== null;
  return [eventFn, isUsersnapLoaded];
};

export const UsersnapProvider = ({ children }) => {
  const {
    user: { email, isStaff },
  } = useAuth();
  const [api, setApi] = useState<UsersnapApi | null>(null);

  // End early if there isn't an API key loaded
  if (!process.env.REACT_APP_USERSNAP_API_KEY) return children;

  // End early if this is on localhost
  if (location.hostname === "localhost") return children;

  const scrptSrc = `https://widget.usersnap.com/global/load/${process.env.REACT_APP_USERSNAP_API_KEY}?onload=onUsersnapCXLoad`;

  // We can set this function any time. It will only run when `onUsersnapCXLoad` is executed from the script
  window["onUsersnapCXLoad"] = (usersnapAPI: UsersnapApi) => {
    // If callback doesn't give us an api for some reason, don't try to use it
    if (!usersnapAPI) return;

    // If we've already set the api in state, don't try to set it again
    if (api) return;

    usersnapAPI.init({
      user: {
        userId: email,
        email: email,
        type: isStaff ? "staff" : "customer",
      },
    });
    usersnapAPI.on("open", function (event) {
      event.api.setValue("visitor", email);
    });

    setApi(usersnapAPI);
  };

  return (
    <UsersnapContext.Provider value={api}>
      <Helmet>
        <script defer src={scrptSrc} />
      </Helmet>
      {children}
    </UsersnapContext.Provider>
  );
};
