import { CheckIcon } from "@heroicons/react/24/outline";

export type TWizardStageProps = {
  label: string;
  status?: "INACTIVE" | "ACTIVE" | "OK";
  sublabel?: string;
};

export const WizardStage = ({
  label,
  status = "INACTIVE",
  sublabel,
}: TWizardStageProps) => {
  return (
    <div
      aria-label={`${label} Stage`}
      className="flex items-center justify-between p-0.5"
    >
      <div className="flex flex-col gap-1 font-semibold text-default">
        <div className="text-xs">{label}</div>
        {sublabel && <div className="text-sm">{sublabel}</div>}
      </div>
      <div className="flex w-2 justify-center">{icons[status]}</div>
    </div>
  );
};

const icons = {
  OK: (
    <CheckIcon
      aria-label="Completed Stage Icon"
      className="h-2 w-2 text-status-good"
    />
  ),
  INACTIVE: (
    <div
      aria-label="Inactive Stage Icon"
      className="h-[10px] w-[10px] rounded-full border border-slate-400"
    />
  ),
  ACTIVE: (
    <div
      aria-label="Active Stage Icon"
      className="h-[10px] w-[10px] rounded-full bg-layout-active"
    />
  ),
};
