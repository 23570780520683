import { Placeholder } from "../components";

export const createPlaceholders = (columns, loading) =>
  [1, 0.8, 0.6, 0.4, 0.2].map((opacity, i) => {
    const row = {};
    for (const col of columns) {
      row[col.accessor] = (
        <Placeholder key={i} opacity={opacity} loading={loading} />
      );
    }
    return row;
  });
