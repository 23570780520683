import { useEngagementStatus } from "../api";

import { EngagementBudgetWidget } from "./EngagementBudgetWidget";
import { EngagementWorkWidget } from "./EngagementWorkWidget";

export const EngagementStatus = ({
  engagementId,
}: {
  engagementId: string;
}) => {
  const {
    data: { workStatus, budgetStatus },
    loading,
  } = useEngagementStatus(engagementId);

  return (
    <div className="grid grid-cols-2 gap-2">
      <EngagementWorkWidget workStatus={workStatus} loading={loading} />
      <EngagementBudgetWidget budgetStatus={budgetStatus} loading={loading} />
    </div>
  );
};
