import { useMemo, useState } from "react";

import {
  SCORECARD_TREND_OPTIONS,
  SCORECARD_TREND_TIME_RANGES,
} from "../constants";
import { CloudScoreTrendContext } from "../contexts";
import { getPillar } from "../utils";

import { CloudScoreTrendChangeIndicator } from "./CloudScoreTrendChangeIndicator";
import { CloudScoreTrendGraph1Month } from "./CloudScoreTrendGraph1Month";
import { CloudScoreTrendGraph1Year } from "./CloudScoreTrendGraph1Year";
import { CloudScoreTrendGraph3Months } from "./CloudScoreTrendGraph3Months";
import { CloudScoreTrendGraph6Months } from "./CloudScoreTrendGraph6Months";
import { CloudScoreTrendTimeRangeSelector } from "./CloudScoreTrendTimeRangeSelector";

const { ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR } =
  SCORECARD_TREND_TIME_RANGES;

interface CloudScoreTrendGraphProps {
  className?: string;
  pillarId: string;
}

export const CloudScoreTrendGraph = ({
  className,
  pillarId,
}: CloudScoreTrendGraphProps) => {
  const [selectedRange, setSelectedRange] = useState<string>(SIX_MONTHS.key);
  const [scoreChange, setScoreChange] = useState<number | null>(null);

  const handleChangeSelectedRange = (range) => {
    setSelectedRange(range);
    if (range !== selectedRange) {
      setScoreChange(null);
    }
  };

  const scorecardTrendContext = useMemo(
    () => ({ className, onLoad: setScoreChange, selectedRange }),
    [className, selectedRange]
  );

  const pillar = getPillar(pillarId);
  if (!pillar) return null;

  const summaryName = pillar.pillar ? pillar.name : "Overall";

  return (
    <div aria-label="Cloud Score Progress Graph">
      <div className="flex justify-between">
        <div className="flex flex-wrap items-center gap-x-2">
          <div className="whitespace-nowrap text-base font-semibold text-default">
            {summaryName} Progress
          </div>
          <CloudScoreTrendChangeIndicator
            scoreChange={scoreChange}
            selectedRange={
              selectedRange as keyof typeof SCORECARD_TREND_TIME_RANGES
            }
          />
        </div>
        <CloudScoreTrendTimeRangeSelector
          options={SCORECARD_TREND_OPTIONS}
          selectedOption={selectedRange}
          onChange={handleChangeSelectedRange}
        />
      </div>

      <CloudScoreTrendContext.Provider value={scorecardTrendContext}>
        {selectedRange === ONE_MONTH.key && (
          <CloudScoreTrendGraph1Month pillarId={pillarId} />
        )}
        {selectedRange === THREE_MONTHS.key && (
          <CloudScoreTrendGraph3Months pillarId={pillarId} />
        )}
        {selectedRange === SIX_MONTHS.key && (
          <CloudScoreTrendGraph6Months pillarId={pillarId} />
        )}
        {selectedRange === ONE_YEAR.key && (
          <CloudScoreTrendGraph1Year pillarId={pillarId} />
        )}
      </CloudScoreTrendContext.Provider>
    </div>
  );
};
