import { useEffect } from "react";

import { batchUpdate } from "@m/api/utils";
import { useAuth } from "@m/login";
import { Button, Modal, toast } from "@m/ui";

import {
  useScorecardCompanyConfiguration,
  useUpdateScorecardConfiguration,
} from "../api";
import {
  SCORECARD_CONFIGURATION_AUTHORIZED_ROLES,
  SCORECARD_CONFIGURATION_DESCRIPTION,
  SCORECARD_CONFIGURATION_READONLY_DESCRIPTION,
  SCORECARD_CONFIGURATION_TITLE,
  UPDATE_SCORECARD_CONFIGURATION_ERROR,
  UPDATE_SCORECARD_CONFIGURATION_SUCCESS,
} from "../constants";
import { useUpdateConfigurationProps } from "../hooks";

import { ScorecardConfigurationsList } from "./ScorecardConfigurationsList";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ScorecardSettingsModal = ({ isOpen = false, onClose }: Props) => {
  const {
    user: { roles },
  } = useAuth();

  const {
    data: { scorecardAcctConfigs, notIgnoredCount },
    loading: accountConfigsLoading,
  } = useScorecardCompanyConfiguration();

  const [updateConfiguration, { loading: updateLoading }] =
    useUpdateScorecardConfiguration();

  const {
    selectedRowIds,
    selectInitialRows,
    toggleRowSelected,
    toggleSelectAll,
    toggleSelectNone,
  } = useUpdateConfigurationProps();

  const handleSave = async () => {
    const updateConfigurationpayload = [];
    scorecardAcctConfigs.forEach((config) => {
      if (selectedRowIds.includes(config.account.databaseId)) {
        updateConfigurationpayload.push({
          accountId: config.account.databaseId,
          included: true,
        });
      } else if (config.description !== "Warning") {
        updateConfigurationpayload.push({
          accountId: config.account.databaseId,
          included: false,
        });
      }
    });

    const responses = await batchUpdate(
      updateConfigurationpayload,
      updateConfiguration
    );

    const failed = responses.filter(
      (response) => response.status === "rejected"
    );
    failed.length > 0
      ? toast.error(UPDATE_SCORECARD_CONFIGURATION_ERROR)
      : toast.success(UPDATE_SCORECARD_CONFIGURATION_SUCCESS);

    onClose();
  };

  useEffect(() => {
    if (scorecardAcctConfigs.length > 0)
      selectInitialRows(scorecardAcctConfigs);
  }, [scorecardAcctConfigs, selectInitialRows]);

  const isAdminOrAnalyst = SCORECARD_CONFIGURATION_AUTHORIZED_ROLES.some(
    (role) => roles.includes(role)
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      header={
        <Modal.Title className="pb-1 text-center font-semibold">
          {SCORECARD_CONFIGURATION_TITLE}
        </Modal.Title>
      }
    >
      <div className="space-y-3">
        <div>
          <div className="mt-0.5 text-left text-sm text-subdued">
            {isAdminOrAnalyst
              ? SCORECARD_CONFIGURATION_DESCRIPTION
              : SCORECARD_CONFIGURATION_READONLY_DESCRIPTION}
          </div>
        </div>

        <ScorecardConfigurationsList
          accountConfigsLoading={accountConfigsLoading}
          isAdminOrAnalyst={isAdminOrAnalyst}
          scorecardAcctConfigs={scorecardAcctConfigs}
          selectedRowIds={selectedRowIds}
          toggleRowSelected={toggleRowSelected}
          toggleSelectNone={toggleSelectNone}
          toggleSelectAll={toggleSelectAll}
          notIgnoredCount={notIgnoredCount}
        />

        <Modal.Actions>
          {isAdminOrAnalyst ? (
            <>
              <Button
                onClick={handleSave}
                disabled={accountConfigsLoading || selectedRowIds.length === 0}
                loading={updateLoading}
                kind="primary"
                aria-label="Save Cloud Score Configurations"
              >
                Save
              </Button>
              <Button
                aria-label="Cloud Score Config Cancel"
                onClick={onClose}
                kind="primary"
                fill="none"
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button onClick={onClose} kind="primary">
              OK
            </Button>
          )}
        </Modal.Actions>
      </div>
    </Modal>
  );
};
