import { ErrorPage } from "@mc/pages";

export const AccountAlreadyExistsError = () => (
  <ErrorPage
    title="AWS account has already been onboarded to Mission Cloud"
    content={
      <div>
        Request an invite to Mission Control from
        <br />
        your company's administrator.
      </div>
    }
  />
);
