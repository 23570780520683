import {
  LifebuoyIcon,
  RectangleGroupIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";

import {
  ENGINEER_ASSIST_FEATURE_FLAGS,
  FEATURE_FLAGS,
  PATHS,
  SUPPORT_CASES_FEATURE_FLAGS,
} from "@mc/constants";

import { AccessRequired } from "../AccessRequired";

import {
  CloudScoreLink,
  DropdownLinks,
  EngagementsGroup,
  ResourcesSidebarLinks,
  SidebarGroup,
  SidebarLink,
  SidebarWrapper,
  TalkToExpertCard,
} from "./components";

export const Sidebar = () => {
  return (
    <SidebarWrapper>
      <SidebarGroup>
        <SidebarLink
          icon={Squares2X2Icon}
          title="Dashboard"
          to={PATHS.DASHBOARD}
        />
        <SidebarLink
          ariaLabel="Go to Support Cases"
          feature={SUPPORT_CASES_FEATURE_FLAGS}
          matchFeatures="any"
          icon={LifebuoyIcon}
          title="Cases"
          to={PATHS.SUPPORT_CASES}
        />
        <DropdownLinks
          defaultOpen={false}
          feature={FEATURE_FLAGS.AWS_RESOURCES_ALLOW_ACCESS}
          icon={RectangleGroupIcon}
          path="/resources/*"
          title="Resources"
        >
          <ResourcesSidebarLinks />
        </DropdownLinks>
      </SidebarGroup>

      <SidebarGroup
        title="Insights"
        feature={[
          FEATURE_FLAGS.CLOUD_SCORE_ALLOW_ACCESS,
          FEATURE_FLAGS.RIO_REPORTING_ALLOW_ACCESS,
        ]}
        matchFeatures="any"
      >
        <CloudScoreLink />
        <SidebarLink
          ariaLabel="Go to RIO Reporting"
          feature={FEATURE_FLAGS.RIO_REPORTING_ALLOW_ACCESS}
          title="RIO"
          to={PATHS.RIO_REPORTING}
        />
      </SidebarGroup>

      <AccessRequired
        feature={[
          FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS,
          ...ENGINEER_ASSIST_FEATURE_FLAGS,
        ]}
        matchFeatures="any"
      >
        <EngagementsGroup />
      </AccessRequired>

      <div className="px-1">
        <AccessRequired feature={FEATURE_FLAGS.CLOUD_SCORE_ALLOW_ACCESS}>
          <AccessRequired
            children={null}
            fallback={<TalkToExpertCard />}
            feature={FEATURE_FLAGS.CLOUD_SCORE_RECOMMENDATIONS_ALLOW_ACCESS}
          />
        </AccessRequired>
      </div>
    </SidebarWrapper>
  );
};
