import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import {
  DEFAULT_SORT_ENGAGEMENTS_CHECKLIST,
  MESSAGES_ENGAGEMENTS_CHECKLIST,
} from "../constants";
import { IEngagementsChecklistItem } from "../types";

export const GET_ENGAGEMENTS_CHECKLIST_ITEMS = gql`
  query EngagementChecklistItems(
    $id: ID!
    $sort: [EngagementChecklistItemSortEnum]
  ) {
    engagement(id: $id) {
      __typename
      id
      checklist(sort: $sort) {
        edges {
          node {
            dueDate
            id
            noteList {
              edges {
                node {
                  id
                  content
                  createdAt
                  updatedAt
                }
              }
            }
            open
            owner
            title
          }
        }
      }
    }
  }
`;

interface IEngagementChecklistQueryParams {
  sort?: string;
}

export const useEngagementsChecklistItems = (
  engagementId: string,
  query?: IEngagementChecklistQueryParams
): {
  data: { checklistItems: IEngagementsChecklistItem[] };
  loading: boolean;
} => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_CHECKLIST.GET_ITEMS_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENTS_CHECKLIST_ITEMS, {
    variables: {
      id: engagementId,
      sort: query?.sort || DEFAULT_SORT_ENGAGEMENTS_CHECKLIST,
    },
    onError,
  });
  const checklistItems =
    data?.engagement?.checklist?.edges?.map((edge) => {
      return edge.node;
    }) || [];

  return { data: { checklistItems }, loading };
};
