export const getPaginationInfo = (currentPage, pageSize, totalCount) => {
  if (!currentPage || !pageSize || !totalCount) return undefined;

  const pageCount = Math.ceil(totalCount / pageSize);
  const lastPageTotal = totalCount % pageSize || pageSize;

  const hasNext = currentPage < pageCount;
  const hasPrevious = currentPage > 1;

  const pageIndex = currentPage - 1;
  const pageLength = hasNext ? pageSize : lastPageTotal;

  const firstItem = pageIndex * pageSize + 1;
  const lastItem = pageIndex * pageSize + pageLength;

  return {
    pageCount,
    lastPageTotal,
    hasNext,
    hasPrevious,
    pageIndex,
    pageLength,
    firstItem,
    lastItem,
  };
};
