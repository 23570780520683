import { ErrorPage } from "@mc/pages";

export const ConnectCloudScoreAccountError = () => {
  return (
    <ErrorPage
      title="Unable to onboard AWS account"
      content="Something unexpected happened."
    />
  );
};
