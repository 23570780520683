import {
  BriefcaseIcon,
  CheckBadgeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { MissionLogo } from "@m/ui";

import { PATHS } from "@mc/constants";

export const ACTIVITY_NAMESPACES = {
  account: {
    title: "Account",
    icon: <BriefcaseIcon className="h-3 w-3" />,
  },
  mission: {
    title: "Mission",
    icon: <MissionLogo className="h-3 w-3" logo="bluemark" />,
  },
  scorecard: {
    title: "Cloud Score",
    icon: <CheckBadgeIcon className="h-3 w-3" />,
    link: PATHS.CLOUD_SCORE,
  },
  team: {
    title: "Team",
    icon: <UserIcon className="h-3 w-3" />,
    link: PATHS.SETTINGS_USERS,
  },
};
