import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const ASG_LABEL = AWS_RESOURCES_LABELS.autoScalingGroups;

export const TABLE_HEADERS_ASG = [
  { label: "Name", sort: "NAME" },
  { label: "Account Name", accessor: "accountName", sort: "AWS_ACCOUNT__NAME" },
  { label: "Account ID", accessor: "accountId", sort: "ACCOUNT" },
  { label: "Min", accessor: "minSize", sort: "MIN_SIZE" },
  { label: "Max", accessor: "maxSize", sort: "MAX_SIZE" },
  { label: "Desired", accessor: "desiredCapacity", sort: "DESIRED_COUNT" },
  { label: "Availability Zones", accessor: "availabilityZones" },
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  { label: "Tags", sort: "TAGS" },
];
