import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  CheckDetailsOverlay,
  CreateRecommendationOverlay,
  RecommendationDetailsOverlay,
  UpdateRecommendationOverlay,
} from "@mc/features/CloudScore/components";

export const cloudScoreOverlayRoutes: RouteObject[] = [
  {
    path: PATHS._CLOUD_SCORE_CHECK_DETAILS,
    element: <CheckDetailsOverlay />,
  },
  {
    path: PATHS._CLOUD_SCORE_CHECK_DETAILS_TABNAME,
    element: <CheckDetailsOverlay />,
  },
  {
    path: PATHS._CLOUD_SCORE_UPDATE_RECOMMENDATION,
    element: <UpdateRecommendationOverlay />,
  },
  {
    path: PATHS._CLOUD_SCORE_CREATE_RECOMMENDATION,
    element: <CreateRecommendationOverlay />,
  },
  {
    path: PATHS._CLOUD_SCORE_RECOMMENDATION_DETAILS,
    element: <RecommendationDetailsOverlay />,
  },
  {
    path: PATHS._CLOUD_SCORE_RECOMMENDATION_DETAILS_TABNAME,
    element: <RecommendationDetailsOverlay />,
  },
];
