export const AWS_RESOURCES_LABELS = {
  applicationLoadBalancers: "Application Load Balancers",
  autoScalingGroups: "Auto Scaling Groups",
  classicLoadBalancers: "Classic Load Balancers",
  ec2Instances: "EC2 Instances",
  ecsClusters: "ECS Clusters",
  eksClusters: "EKS Clusters",
  elasticacheClusters: "ElastiCache Clusters",
  lambdaFunctions: "Lambda Functions",
  networkLoadBalancers: "Network Load Balancers",
  rdsInstances: "RDS Instances",
  redshiftClusters: "Redshift Clusters",
};
