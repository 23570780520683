import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_CUSTOMER_REPOS } from "./useCustomerRepositories";

export const GRANT_ACCESS_SUCCESS =
  "An invitation to collaborate on the repository has been sent by @mission-engineering-access[bot].";

export const GRANT_ACCESS_FAILURE =
  "We failed to grant access to this repository.";

export const GRANT_ACCESS = gql`
  mutation useGrantAccess($input: AddCollaboratorToGitHubRepoInput!) {
    addCollaboratorToGitHubRepo(input: $input) {
      ok
      message
    }
  }
`;

export function useGrantAccess() {
  const onError = () => {
    toast.error(GRANT_ACCESS_FAILURE);
  };

  const onCompleted = () => {
    toast.success(GRANT_ACCESS_SUCCESS);
  };

  const [mutate, { loading }] = useMutation(GRANT_ACCESS, {
    context: { clientName: "v4" },
    onError,
    onCompleted,
    refetchQueries: [{ query: GET_CUSTOMER_REPOS }],
  });

  const grantAccess = (employee, repository) => {
    mutate({
      variables: {
        input: {
          userId: employee.id,
          repoId: repository.id,
        },
      },
    });
  };

  return [grantAccess, { loading }];
}
