import { Button } from "@m/ui";

import { MESSAGES } from "../constants";

export const CaseListError = () => {
  const handleTryAgain = () => window.location.reload();

  return (
    <div className="flex-1">
      <div id="case-list">
        {MESSAGES.CASE_LIST_FAILURE}
        <br />
        <Button onClick={handleTryAgain}>Try Again</Button>
      </div>
    </div>
  );
};
