import clsx from "clsx";
import { ReactNode } from "react";

import { Spinner } from "@m/ui";

interface Props {
  actions?: ReactNode;
  className?: string;
  description: string;
  image?: ReactNode;
  loading?: boolean;
  title: string;
}

export const EmptyState = ({
  actions,
  className,
  description,
  image,
  loading = false,
  title,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex flex-col gap-y-4 rounded-lg border border-layout-border bg-zinc-50 px-3 py-[56px] text-center",
        className
      )}
    >
      {image && <div className="flex items-center justify-center">{image}</div>}
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-center gap-2">
          {loading && <Spinner />}
          <div className="text-2xl font-bold text-default">{title}</div>
        </div>
        <div className="text-lg font-medium text-subdued">{description}</div>
      </div>
      {actions && <div>{actions}</div>}
    </div>
  );
};
