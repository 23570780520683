import { FEATURE_FLAGS, PATHS } from "@mc/constants";
import { useScorecardScore } from "@mc/features/CloudScore/api";

import { SidebarLink } from "./SidebarLink";

export const CloudScoreLink = () => {
  const {
    data: { score },
    loading: scoreLoading,
  } = useScorecardScore();

  return (
    <SidebarLink
      ariaLabel="Go to Cloud Score"
      count={score > 0 ? score : null}
      feature={FEATURE_FLAGS.CLOUD_SCORE_ALLOW_ACCESS}
      loading={scoreLoading}
      title="Cloud Score"
      to={PATHS.CLOUD_SCORE}
    />
  );
};
