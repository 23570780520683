import { GET_CLOUD_SCORE_RECOMMENDATIONS } from ".";
import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { ScorecardRecommendationStatus } from "@m/api/public/types";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES } from "../constants";

import { GET_RECOMMENDATION_DETAILS } from "./useRecommendationDetails";

export const UPDATE_CLOUD_SCORE_RECOMMENDATION_STATUS = gql(/* GraphQL */ `
  mutation UpdateCloudScoreRecommendationStatus(
    $input: UpdateScorecardRecommendationStatusInput!
  ) {
    updateScorecardRecommendationStatus(input: $input) {
      ok
      message
    }
  }
`);

export const useUpdateCloudScoreRecommendationStatus = () => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.UPDATE_RECOMMENDATION_STATUS_FAILURE);

  const [mutate, result] = useMutation(
    UPDATE_CLOUD_SCORE_RECOMMENDATION_STATUS,
    {
      onError,
    }
  );

  const updateRecommendationStatus = (
    recommendationId: string,
    status: ScorecardRecommendationStatus
  ) => {
    mutate({
      variables: {
        input: {
          scorecardRecommendationId: recommendationId,
          recommendationStatus: status,
        },
      },
      refetchQueries: [
        {
          query: GET_RECOMMENDATION_DETAILS,
          variables: { id: recommendationId },
        },
        {
          query: GET_CLOUD_SCORE_RECOMMENDATIONS,
        },
      ],
    });
  };

  return [updateRecommendationStatus, result] as const;
};
