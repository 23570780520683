export const getLeastRecentSync = (awsAccounts) => {
  if (awsAccounts.length === 0) return null;

  const syncedAccounts = awsAccounts.filter(
    (account) => account.lastCompletedSynced !== null
  );

  if (syncedAccounts.length === 0) return null;

  const syncTimes = syncedAccounts.map(
    (account) => new Date(account.lastCompletedSynced)
  );

  const leastRecentSync = Math.min.apply(null, syncTimes);

  return new Date(leastRecentSync).toISOString();
};
