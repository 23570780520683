import { Field, Spinner, Toggle, ToggleContainer, ToggleLabel } from "@m/ui";

import { usePhotoOptOut, useUpdatePhotoOptOut } from "../api";

export function StaffPhotoOptOut() {
  const { loading, error, data: useInitials } = usePhotoOptOut();

  const [togglePhotoOptOut, { loading: updateLoading }] =
    useUpdatePhotoOptOut();

  return (
    <Field label="Avatar">
      {loading || error || useInitials === undefined ? (
        <Spinner />
      ) : (
        <ToggleContainer
          as="div"
          className="flex items-center gap-2 text-sm font-medium"
        >
          <Toggle
            disabled={updateLoading}
            checked={!useInitials}
            onChange={togglePhotoOptOut}
          />
          <ToggleLabel className="cursor-pointer">
            Use photo from ChartHop
          </ToggleLabel>
        </ToggleContainer>
      )}
    </Field>
  );
}
