import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ASG_LABEL, GET_SEARCH_TERMS_ERROR } from "../constants";

export const GET_ASG_SEARCH_TERMS = gql(/* GraphQL */ `
  query AutoScalingGroupsSearchTerms($searchTerm: String) {
    autoScalingGroupSearchTerms(searchTerm: $searchTerm) {
      searchTerms
    }
  }
`);

export const useAutoScalingGroupsSearchTerms = (searchTerm: string) => {
  const onError = () => toast.error(GET_SEARCH_TERMS_ERROR(ASG_LABEL));

  const { data, loading, error, refetch } = useQuery(GET_ASG_SEARCH_TERMS, {
    variables: { searchTerm },
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const searchTerms = data?.autoScalingGroupSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    loading,
    error,
    refetch,
  };
};
