import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { MutableRefObject } from "react";

import { CaseWaitingOn } from "@m/api/public/types";
import { Banner, Link } from "@m/ui";

import { CASE_WAITING_ON_BANNER_MESSAGE } from "../constants";

export function CaseWaitingOnBanner({
  waitingOn,
  textBoxRef,
}: {
  waitingOn?: CaseWaitingOn;
  textBoxRef: MutableRefObject<any>;
}) {
  if (!waitingOn) {
    return null;
  }

  const shouldShowReplyAction =
    textBoxRef.current && waitingOn === CaseWaitingOn.CustomerResponse;

  return (
    <Banner
      data-testid="awaiting-response-banner"
      label={<div>{CASE_WAITING_ON_BANNER_MESSAGE[waitingOn]}</div>}
      actions={
        shouldShowReplyAction && (
          <Link
            data-testid="awaiting-response-reply-link"
            className="text-yellow-600"
            onClick={() => {
              textBoxRef.current.focus();
            }}
          >
            Reply
          </Link>
        )
      }
      icon={InformationCircleIcon}
      status="warning"
    />
  );
}
