import { Badge, Link } from "@m/ui";

export const ScorecardConfigurationsListHeader = ({
  accountConfigsLoading,
  isAdminOrAnalyst,
  notIgnoredCount,
  toggleSelectAll,
  toggleSelectNone,
  scorecardAcctConfigs,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1">
        <p className="font-semibold">Accounts</p>
        {!accountConfigsLoading && (
          <Badge size="small" label={notIgnoredCount} className="flex-none" />
        )}
      </div>

      {isAdminOrAnalyst && (
        <div className="flex items-center gap-1 text-xs">
          <Link
            aria-label="All button"
            onClick={() => toggleSelectAll(scorecardAcctConfigs)}
          >
            All
          </Link>
          <Link aria-label="None button" onClick={toggleSelectNone}>
            None
          </Link>
        </div>
      )}
    </div>
  );
};
