import clsx from "clsx";
import { ComponentPropsWithRef, ReactNode, forwardRef } from "react";

import { Textarea } from "@m/ui";

export interface ReplyBoxProps extends ComponentPropsWithRef<"textarea"> {
  buttons?: ReactNode;
}

export const ReplyBox = forwardRef<HTMLTextAreaElement, ReplyBoxProps>(
  ({ onChange, className, value, buttons, ...props }, ref) => {
    const containerClasses = clsx(
      "flex w-full flex-col form-control-container form-control__focus-within p-2 gap-2",
      {
        "opacity-60": props.disabled,
      },
      className
    );
    return (
      <div
        className={containerClasses}
        data-component="ReplyBox"
        data-testid="ReplyBox"
      >
        <Textarea
          autogrow
          className="text-sm"
          id="comment"
          name="comment"
          noStyles
          onChange={onChange}
          placeholder="Send a message"
          ref={ref}
          rows={1}
          value={value}
          {...props}
        />
        {buttons}
      </div>
    );
  }
);

ReplyBox.displayName = "ReplyBox";
