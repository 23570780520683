import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

/**
 * Renders child routes, if the user is not logged in,
 * otherwise logs the user out and refreshes the page
 * so that they can log in with SSO.
 */
export const SsoOutlet = () => {
  const { user, logout } = useAuth();

  if (user) {
    logout();
    window.location.reload();
    return null;
  }

  return <Outlet />;
};
