import { EngagementBudgetStatus } from "@m/api/public/types";
import { fromDollars } from "@m/utils";

export const getBudgetRemaining = (
  totalBudget = 0,
  budget: EngagementBudgetStatus
): number => {
  if (!budget) return;

  return fromDollars(totalBudget) - fromDollars(budget.budgetSpent);
};
