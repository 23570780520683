import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { Dropdown, DropdownItem } from "@m/ui";

export const SortDropdown = ({
  sort,
  onSortChange,
}: {
  sort: string;
  onSortChange: (sort: "OPENED_AT_ASC" | "OPENED_AT_DESC") => void;
}) => {
  return (
    <Dropdown
      direction="bottom-start"
      trigger={
        <div
          className="flex items-center rounded-lg bg-gray-100 px-1 py-0.5"
          data-testid="sort-dropdown"
        >
          <div className="whitespace-nowrap text-sm font-semibold">
            {sort === "OPENED_AT_ASC" ? "Oldest First" : "Newest First"}
          </div>
          <ChevronDownIcon className="h-2.5 w-2.5" />
        </div>
      }
    >
      <DropdownItem
        onClick={() => {
          onSortChange("OPENED_AT_DESC");
        }}
      >
        Newest First
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          onSortChange("OPENED_AT_ASC");
        }}
      >
        Oldest First
      </DropdownItem>
    </Dropdown>
  );
};
