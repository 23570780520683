import { useMemo } from "react";

import { ActivityStreamNode } from "@m/api/public/types";
import { getMilliseconds, toHyphenLowercase } from "@m/utils";

import { ChangeIndicator } from "@mc/components/ChangeIndicator";

import { ACTIVITY_STREAMS } from "../constants";
import { ActivityFeedChanges } from "../types";
import { groupByTimestamp, groupByTypename } from "../utils";

export interface ActivityFeedGroupProps {
  activities: ActivityStreamNode[];
  changes?: ActivityFeedChanges;
  endTime: string;
  groupId: string;
  startTime: string;
  title: string;
}

export const ActivityFeedGroup = ({
  activities,
  changes,
  endTime,
  groupId,
  startTime,
  title,
}: ActivityFeedGroupProps) => {
  const activityItems = useMemo(() => {
    const filteredActivities = groupByTimestamp(activities, {
      startTime,
      endTime,
    });

    const items = [];

    if (filteredActivities.length === 0) return items;

    for (const stream of Object.values(ACTIVITY_STREAMS)) {
      const groupedActivities = groupByTypename(
        filteredActivities,
        stream.__typename
      );
      if (groupedActivities.length === 0) continue;

      const ActivityFeedItem = stream.component;
      if (stream.rollup) {
        const mostRecentActivity = groupedActivities[0];
        const { id, timestamp } = mostRecentActivity;
        items.push({
          timestamp,
          element: (
            <ActivityFeedItem
              key={id}
              activities={groupedActivities}
              timestamp={timestamp}
            />
          ),
        });
      } else {
        items.push(
          ...groupedActivities.map((activity: ActivityStreamNode) => ({
            timestamp: activity.timestamp,
            element: <ActivityFeedItem key={activity.id} activity={activity} />,
          }))
        );
      }
    }

    items.sort(
      (a, b) => getMilliseconds(b.timestamp) - getMilliseconds(a.timestamp)
    );
    return items.map(({ element }) => element);
  }, [activities, startTime, endTime]);

  const id = `activities-from-${toHyphenLowercase(title)}`;

  let change = 0.0;
  if (changes && groupId in changes) change = changes[groupId];

  return activityItems.length > 0 ? (
    <div className="flex flex-col space-y-1">
      <div className="ml-1 flex items-center space-x-2">
        <div id={id} className="text-sm font-bold uppercase text-subdued">
          {title}
        </div>
        <ChangeIndicator change={change} size="small" />
      </div>
      <div aria-labelledby={id} className="flex flex-col space-y-1">
        {activityItems}
      </div>
    </div>
  ) : null;
};
