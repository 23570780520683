import clsx from "clsx";
import { ChangeEvent, FC, ReactNode, SelectHTMLAttributes } from "react";

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  disabledMsg?: string;
  onChange?: (_e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  options: ReactNode[];
  value?: string;
}

// TODO(jamesmoody): Remove Select in favor of DynamicSelect once usage has been fully removed from the codebase
export const Select: FC<ISelectProps> = ({
  className,
  disabledMsg,
  onChange,
  options,
  value,
  ...props
}) => {
  return (
    <select
      className={clsx(
        className,
        "form-control-container form-control-input form-control__focus w-full",
        {
          "form-control__disabled": props.disabled,
        }
      )}
      data-component="Select"
      onChange={onChange}
      value={value}
      {...props}
    >
      {(props.placeholder || disabledMsg) && (
        <option value="" disabled={true}>
          {props.disabled && disabledMsg
            ? disabledMsg
            : props.placeholder
            ? props.placeholder
            : ""}
        </option>
      )}
      {options}
    </select>
  );
};
