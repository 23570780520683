import { linearGradientDef } from "@nivo/core";
import {
  Layer,
  LineSvgProps,
  ResponsiveLine,
  Serie,
  SliceTooltip,
} from "@nivo/line";
import { useMemo, useRef, useState } from "react";

import { EmptyMessage, Spinner } from "@m/ui";

import { ActivityLayer, ActivityPopover, CrosshairLayer } from "./components";
import {
  LINE_GRAPH_DEFAULT_LAYERS,
  LINE_GRAPH_DEFAULT_MARGIN,
} from "./constants";
import { ActivityContext } from "./contexts";
import { Activity } from "./types";

interface LineGraphProps extends LineSvgProps {
  ActivityPanelComponent?: React.ComponentType;
  activities?: Activity[];
  ariaLabel?: string;
  className?: string;
  curve?: LineSvgProps["curve"];
  data: Serie[];
  emptyMessage?: string;
  enableGridX?: boolean;
  enablePoints?: boolean;
  enableSlices?: LineSvgProps["enableSlices"];
  height?: number;
  loading?: boolean;
  margin?: LineSvgProps["margin"];
  sliceTooltip?: SliceTooltip;
  title?: string;
}

export const LineGraph = ({
  ActivityPanelComponent,
  activities = [],
  ariaLabel,
  className,
  curve = "monotoneX",
  data = [],
  emptyMessage = "No line graph data to display.",
  enableGridX = false,
  enablePoints = false,
  enableSlices = "x",
  height = 250,
  loading = false,
  margin = LINE_GRAPH_DEFAULT_MARGIN,
  sliceTooltip,
  title,
  ...props
}: LineGraphProps) => {
  const popoverButtonRef = useRef<HTMLButtonElement>(null);

  const [activity, setActivity] = useState<Activity | null>(null);
  const [activityLineElement, setActivityLineElement] =
    useState<Element | null>(null);
  const [pinnedActivityIndex, setPinnedActivityIndex] = useState<number | null>(
    null
  );

  const activityContext = useMemo(
    () => ({
      activities,
      setActivity,
      setActivityLineElement,
      pinnedActivityIndex,
      setPinnedActivityIndex,
    }),
    [activities, pinnedActivityIndex]
  );

  const gradientDefs = useMemo(
    () => [
      linearGradientDef("fadeOut", [
        { offset: 0, color: "inherit" },
        { offset: 100, color: "inherit", opacity: 0 },
      ]),
    ],
    []
  );

  const layers: Layer[] = useMemo(
    () => [...LINE_GRAPH_DEFAULT_LAYERS, ActivityLayer, CrosshairLayer],
    []
  );

  let isEmpty = false;
  if (data.length < 1) {
    // at least 1 line is required to plot a line graph
    isEmpty = true;
  } else {
    // at least two data points are required to plot a line on the graph
    const firstLine = data[0].data;
    if (firstLine.length < 2) isEmpty = true;
  }

  return (
    <div aria-label={ariaLabel} className={className} style={{ height }}>
      {title && <div className="font-semibold text-default">{title}</div>}

      {loading && (
        <div className="mt-2 flex h-5/6 items-center justify-center rounded-md border-2 border-dashed">
          <Spinner />
        </div>
      )}

      {!loading && isEmpty && (
        <EmptyMessage
          message={emptyMessage}
          className="!mx-0 flex h-5/6 flex-col justify-center text-subdued"
        />
      )}

      {!loading && !isEmpty && (
        <ActivityContext.Provider value={activityContext}>
          <ResponsiveLine
            curve={curve}
            data={data}
            defs={gradientDefs}
            enableGridX={enableGridX}
            enablePoints={enablePoints}
            enableSlices={enableSlices}
            layers={layers}
            margin={margin}
            sliceTooltip={sliceTooltip}
            {...props}
          />

          {ActivityPanelComponent && (
            <ActivityPopover
              activity={activity}
              activityLineElement={activityLineElement}
              ActivityPanelComponent={ActivityPanelComponent}
              popoverButtonRef={popoverButtonRef}
            />
          )}
        </ActivityContext.Provider>
      )}
    </div>
  );
};
