import {
  DateParam,
  createEnumParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { RioReportGranularityType } from "@m/api/public/types";
import { Button, Tabs, Timestamp } from "@m/ui";
import { dt, toDollars, toPercent } from "@m/utils";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { PageTitle } from "@mc/components/PageTitle";

import { useRioReport } from "../api";
import {
  RioDetailsTable,
  RioGraph,
  RioGraphDateRangePicker,
} from "../components";
import { RioDetailsTab } from "../types";

const DEFAULT_RIO_GRAPH_DAYS = 30; // 1 month
const DAILY_GRANULARITY_MAX_DAYS = 90; // 3 months
const MONTHLY_GRANULARITY_MAX_DAYS = 730; // 2 years

export const RioReportingPage = () => {
  const [{ startDate: selectedStartDate, endDate: selectedEndDate }, setDates] =
    useQueryParams({
      startDate: withDefault(DateParam, null),
      endDate: withDefault(DateParam, null),
    });
  const [selectedDetailsTab, setSelectedDetailsTab] = useQueryParam(
    "details",
    withDefault(createEnumParam(RIO_DETAILS_TABS), RioDetailsTab.ACCOUNTS)
  );

  const defaultStartDate = dt
    .now()
    .minus({ days: DEFAULT_RIO_GRAPH_DAYS })
    .startOf("day")
    .toJSDate();
  const defaultEndDate = dt.now().startOf("day").toJSDate();

  const startDate = selectedStartDate ?? defaultStartDate;
  const endDate = selectedEndDate ?? defaultEndDate;

  const granularity = getRioReportGranularity(startDate, endDate);

  const {
    data: {
      rioReportDetails,
      savings,
      savingsRate,
      onDemandEquivalent,
      firstReportDate,
      lastUpdated,
      accounts,
      regions,
      instanceTypes,
      operatingSystems,
      services,
    },
    loading,
  } = useRioReport(granularity, startDate, endDate);

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setDates({ startDate: start ?? null, endDate: end ?? null });
  };

  const handleBarClick = (date: Date) => {
    if (granularity === RioReportGranularityType.Daily) {
      setDates({ startDate: date, endDate: date });
    } else if (granularity === RioReportGranularityType.Monthly) {
      setDates({
        startDate: dt.fromJSDate(date).startOf("month").toJSDate(),
        endDate: dt.fromJSDate(date).endOf("month").toJSDate(),
      });
    } else if (granularity === RioReportGranularityType.Yearly) {
      setDates({
        startDate: dt.fromJSDate(date).startOf("year").toJSDate(),
        endDate: dt.fromJSDate(date).endOf("year").toJSDate(),
      });
    }
  };

  const handleAllTimeClick = () => {
    setDates({ startDate: firstReportDate, endDate: null });
  };

  const disabled = firstReportDate === null;

  return (
    <Page
      title={
        <PageTitle
          title="Reserved Instance Operations"
          padding="small"
          actions={
            <Timestamp
              isoTime={lastUpdated}
              verbiage="Updated"
              side="bottom"
              longFormat={true}
              className="text-xs font-medium text-subdued"
            />
          }
        />
      }
      width="full"
      className="flex flex-col gap-y-5"
    >
      <div className="flex flex-col gap-y-5">
        <div>
          <PageHeading
            heading="Costs"
            marginBottom="none"
            actions={
              <div className="flex gap-x-2">
                <Button
                  onClick={handleAllTimeClick}
                  size="small"
                  fill="none"
                  disabled={disabled}
                >
                  All Time
                </Button>
                <RioGraphDateRangePicker
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  onChange={handleDateChange}
                  minDate={firstReportDate}
                  disabled={disabled}
                />
              </div>
            }
          />
          <RioGraph
            granularity={granularity}
            loading={loading}
            onBarClick={handleBarClick}
            rioReportDetails={rioReportDetails}
          />
        </div>
        <div className="flex gap-x-3">
          <Tile title="Gross Savings" content={toDollars(savings)} />
          <Tile
            title="On-Demand Equivalent"
            content={toDollars(onDemandEquivalent)}
          />
          <Tile
            title="Effective Savings Rate"
            content={toPercent(savingsRate) ?? "-"}
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-4">
        <Tabs
          onTabChange={(tab) => setSelectedDetailsTab(tab as RioDetailsTab)}
          selectedTab={selectedDetailsTab}
          tabs={RIO_DETAILS_TABS}
        />
        <RioDetailsTable
          selectedRioTab={selectedDetailsTab}
          accounts={accounts}
          regions={regions}
          instanceTypes={instanceTypes}
          operatingSystems={operatingSystems}
          services={services}
        />
      </div>
    </Page>
  );
};

const Tile = ({ content, title }: { content: string; title: string }) => (
  <div className="flex w-full flex-col gap-y-0.5 rounded bg-gray-50 px-3 py-2">
    <div className="text-center text-xs font-semibold">{title}</div>
    <div className="text-center text-2xl font-semibold">{content}</div>
  </div>
);

const RIO_DETAILS_TABS = Object.values(RioDetailsTab);

const getRioReportGranularity = (
  startDate: Date,
  endDate: Date
): RioReportGranularityType => {
  const selectedRange =
    dt.fromJSDate(endDate).diff(dt.fromJSDate(startDate), "days").days + 1;

  switch (true) {
    case selectedRange <= DAILY_GRANULARITY_MAX_DAYS:
      return RioReportGranularityType.Daily;
    case selectedRange <= MONTHLY_GRANULARITY_MAX_DAYS:
      return RioReportGranularityType.Monthly;
    default:
      return RioReportGranularityType.Yearly;
  }
};
