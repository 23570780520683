import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";

import { generateUniqueId } from "@m/api";
import { EngagementMilestoneTask } from "@m/api/public/types";
import { TInputEventHandler } from "@m/types";

import { EmptyCircleIcon } from "@mc/icons";

export const TEMP_TASK_ID_PREFIX = "temp-task-id";

export interface IEditMilestoneTasksProps {
  tasks: Partial<EngagementMilestoneTask>[];
  onUpdate: (tasks: Partial<EngagementMilestoneTask>[]) => void;
}

export const EditMilestoneTasks = ({
  tasks = [],
  onUpdate,
}: IEditMilestoneTasksProps) => {
  const handleTaskComplete = (taskId: string, isComplete: boolean) => {
    const updatedTasks = updateTasks(tasks, taskId, { complete: !isComplete });

    onUpdate(updatedTasks);
  };

  const handleTaskTitle: TInputEventHandler = (e) => {
    const {
      target: { id, value },
    } = e;
    const updatedTasks = updateTasks(tasks, id, { title: value });
    onUpdate(updatedTasks);
  };

  const handleTaskRemove = (taskId: string) => {
    const updatedTasks = tasks.filter((taskItem) => taskItem.id !== taskId);
    onUpdate(updatedTasks);
  };

  const handleAddTask = () => {
    const updatedTasks = [
      ...tasks,
      {
        id: `${TEMP_TASK_ID_PREFIX}-${generateUniqueId()}`,
        title: "",
        complete: false,
      },
    ];

    onUpdate(updatedTasks);
  };

  return (
    <div>
      {tasks.map((task: EngagementMilestoneTask) => (
        <Task
          key={task.id}
          task={task}
          handleTaskTitle={handleTaskTitle}
          handleTaskComplete={handleTaskComplete}
          handleTaskRemove={handleTaskRemove}
        />
      ))}
      <div
        data-testid="add-task"
        className="mt-1 flex cursor-pointer flex-row items-center font-semibold text-action"
        onClick={handleAddTask}
      >
        <PlusIcon className="h-2.5 w-2.5" />
        <div className="ml-1">Add Subtask</div>
      </div>
    </div>
  );
};

const Task = ({
  task,
  handleTaskTitle,
  handleTaskComplete,
  handleTaskRemove,
}: {
  task;
  handleTaskTitle;
  handleTaskComplete;
  handleTaskRemove;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="mx-0.5 flex items-center rounded-md p-0.5 hover:bg-slate-50"
      data-testid="milestone-tasklist-item"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <TaskCompleted task={task} onClick={handleTaskComplete} />
      <input
        aria-label="Task Input"
        id={task.id}
        className="h-[24px] w-full cursor-default bg-transparent p-1 pl-[10px] focus:outline-none"
        value={task?.title || ""}
        placeholder="Add a task name"
        maxLength={99}
        onChange={handleTaskTitle}
      />
      {isHovered && <TaskRemove task={task} onClick={handleTaskRemove} />}
    </div>
  );
};

const TaskCompleted = ({
  task,
  onClick,
}: {
  task: EngagementMilestoneTask;
  onClick: (id: string, isComplete: boolean) => void;
}) => {
  const handleComplete = () => {
    onClick(task.id, task.complete);
  };

  return (
    <div
      aria-label="Complete Task"
      className="cursor-pointer"
      onClick={handleComplete}
    >
      {task.complete ? (
        <CheckCircleIcon
          data-testid="completed-task"
          className="h-2.5 w-2.5 text-action"
        />
      ) : (
        <EmptyCircleIcon
          data-testid="open-task"
          aria-label="Resolved"
          className="text- h-2.5 w-2.5 text-zinc-300"
        />
      )}
    </div>
  );
};

const TaskRemove = ({
  task,
  onClick,
}: {
  task: EngagementMilestoneTask;
  onClick: (id: string) => void;
}) => {
  const handleClick = () => {
    onClick(task.id);
  };

  return (
    <XMarkIcon
      aria-label="Remove Task"
      onClick={handleClick}
      className="h-3 w-3 cursor-pointer text-subdued"
    />
  );
};

export const updateTasks = (
  tasks: Partial<EngagementMilestoneTask>[],
  taskId: string,
  updates: Partial<EngagementMilestoneTask>
) => {
  return tasks.map((task) =>
    task.id === taskId ? { ...task, ...updates } : task
  );
};
