import { useLazyQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const CAN_REGISTER_AWS_ACCOUNT_ID = gql(/* GraphQL */ `
  query useCanRegisterAwsAccountId($id: String!) {
    canRegisterAwsAccountId(id: $id)
  }
`);

export const useCanRegisterAwsAccountId = () => {
  const [fetchQuery, { data, refetch, called, ...result }] = useLazyQuery(
    CAN_REGISTER_AWS_ACCOUNT_ID,
    { notifyOnNetworkStatusChange: true }
  );

  const validateAccountId = (id: string) => {
    if (called) refetch({ id });
    else fetchQuery({ variables: { id } });
  };

  const canBeRegistered = data?.canRegisterAwsAccountId;

  return [
    validateAccountId,
    { data: canBeRegistered, called, ...result },
  ] as const;
};
