import { CalendarIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { Link, generatePath } from "react-router-dom";

import { Badge, ProgressBar, Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";
import { dt, formatDate, fromSnakeCaseToProperCase } from "@m/utils";

import { PATHS } from "@mc/constants";
import { useEngagementsList } from "@mc/features/Engagements/api";
import {
  ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME,
  ENGAGEMENT_BUDGET_BADGE_STATUS,
  ENGAGEMENT_STATUS,
  ENGAGEMENT_WORK_BADGE_STATUS,
} from "@mc/features/Engagements/constants";
import { IEngagement } from "@mc/features/Engagements/types";

export const ProjectSummaryWidget = () => {
  const {
    data: { engagements },
    loading,
    error,
  } = useEngagementsList();

  const projectSummaryWidgetCards = useMemo(
    () =>
      engagements
        .filter(
          (engagement) =>
            engagement.source.status !== ENGAGEMENT_STATUS.COMPLETED
        )
        .map((engagement) => (
          <ProjectSummaryWidgetCard
            key={engagement.id}
            engagement={engagement}
          />
        )),
    [engagements]
  );

  if (!loading && (projectSummaryWidgetCards.length === 0 || error)) {
    // If there are no projects to show, we won't add the widget to the dashboard instead of displaying an empty state UI.
    // This helps preserve dashboard real estate for more actionable features for customers.
    return null;
  }

  return (
    <Card title="Project Status" data-testid="project-summary-widget">
      <div className="flex flex-col">
        {!loading && (
          <div className="flex flex-col text-sm font-medium text-action">
            {projectSummaryWidgetCards}
          </div>
        )}

        {loading && <Spinner className="m-auto my-5" />}
      </div>
    </Card>
  );
};

const ProjectSummaryWidgetCard = ({
  engagement,
}: {
  engagement: IEngagement;
}) => {
  const { title, workStatus, id } = engagement;
  const { percentageComplete } = workStatus || {};

  const linkTo = generatePath(PATHS.ENGAGEMENTS_PROJECTS_DETAILS, {
    engagementId: id,
  });

  return (
    <Link
      data-testid={`project-summary-widget-card-${id}`}
      to={linkTo}
      className="flex justify-between border-t px-1 py-0.5 first:border-0 hover:bg-slate-50"
    >
      <div className="w-full px-3 py-2">
        <div className="text-sm font-medium text-default">{title}</div>
        <div className="py-0.5 text-xs font-normal text-subdued">
          Updated {getFormattedLastUpdateTime(engagement)}
        </div>
        <ProgressBar
          className="py-1"
          loading={false}
          completedPercentage={percentageComplete}
        />
        <WorkAndBudgetStatus engagement={engagement} />
      </div>
    </Link>
  );
};

const WorkAndBudgetStatus = ({ engagement }: { engagement: IEngagement }) => {
  const { workStatus, budgetStatus } = engagement;
  const workStatusLabel = fromSnakeCaseToProperCase(workStatus?.status);
  const workBadgeStatus = ENGAGEMENT_WORK_BADGE_STATUS[workStatus?.status];
  const budgetBadgeStatus =
    ENGAGEMENT_BUDGET_BADGE_STATUS[budgetStatus?.status];

  return (
    <>
      {workStatus && (
        <div className="flex py-1.5 text-sm text-default">
          <div className="inline-flex flex-1 gap-0.5">
            <CalendarIcon className="h-3 w-3 self-center stroke-2 text-subdued" />
            <span className="self-center text-subdued">Work Status</span>
          </div>
          <Badge
            data-testid="work-status-badge"
            strong={true}
            label={workStatusLabel}
            status={workBadgeStatus}
            className={"h-[22px] self-center"}
          />
        </div>
      )}
      {budgetStatus && (
        <div className="flex py-1.5 text-sm text-default">
          <div className="inline-flex flex-1 gap-0.5">
            <CurrencyDollarIcon className="h-3 w-3 self-center stroke-2 text-subdued" />
            <div className="self-center text-subdued"> Budget Status</div>
          </div>
          <Badge
            data-testid="budget-status-badge"
            strong={true}
            label={ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME[budgetStatus?.status]}
            status={budgetBadgeStatus}
            className={"h-[22px] self-center"}
          />
        </div>
      )}
    </>
  );
};

export function getFormattedLastUpdateTime(engagement: IEngagement) {
  const { updateTime: engagementUpdateTime, workStatus } = engagement;

  // The updateTime value for work status will always be the same as budget status since we update changes to either at the same time
  const workStatusUpdateTime = workStatus?.updateTime;
  if (!workStatusUpdateTime) {
    return formatDate(engagementUpdateTime, { longFormat: true });
  }

  const lastUpdateTime =
    Number(dt.fromISO(engagementUpdateTime)) >
    Number(dt.fromISO(workStatusUpdateTime))
      ? engagementUpdateTime
      : workStatusUpdateTime;

  return formatDate(lastUpdateTime, { longFormat: true });
}
