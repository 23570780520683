import { Spinner } from "@m/ui";

import { ScorecardConfigurationsListHeader } from "./ScorecardConfigurationsListHeader";
import { ScorecardConfigurationsListItem } from "./ScorecardConfigurationsListItem";

export const ScorecardConfigurationsList = ({
  accountConfigsLoading,
  isAdminOrAnalyst,
  scorecardAcctConfigs,
  selectedRowIds,
  toggleRowSelected,
  notIgnoredCount,
  toggleSelectAll,
  toggleSelectNone,
}) => {
  return (
    <div>
      <ScorecardConfigurationsListHeader
        notIgnoredCount={notIgnoredCount}
        isAdminOrAnalyst={isAdminOrAnalyst}
        accountConfigsLoading={accountConfigsLoading}
        toggleSelectAll={toggleSelectAll}
        toggleSelectNone={toggleSelectNone}
        scorecardAcctConfigs={scorecardAcctConfigs}
      />
      <div className="mt-1 flex flex-col overflow-y-auto">
        {accountConfigsLoading ? (
          <div className="m-auto">
            <Spinner />
          </div>
        ) : (
          scorecardAcctConfigs?.map((config) => (
            <ScorecardConfigurationsListItem
              key={config.account.databaseId}
              config={config}
              isAdminOrAnalyst={isAdminOrAnalyst}
              selectedRowIds={selectedRowIds}
              toggleRowSelected={toggleRowSelected}
            />
          ))
        )}
      </div>
    </div>
  );
};
