import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import PT from "prop-types";

import { useAuth } from "@m/login";
import { Dropdown, DropdownItem } from "@m/ui";

import { useResendInvitation } from "../api";
import { MESSAGES } from "../constants";
import { USER_MANAGEMENT_ROLES } from "../constants/roles";
import { useUserManagementAccess } from "../hooks/useUserManagementAccess";

const ResendInviteActionItem = ({ onClick }) => (
  <DropdownItem onClick={onClick} aria-label="Resend Email Invite">
    Resend Invite
  </DropdownItem>
);

const UpdateRoleActionItem = ({ user, onClick }) => {
  const managementRoles = Object.keys(USER_MANAGEMENT_ROLES);

  return managementRoles.map((role) =>
    MESSAGES.UPDATE_ROLE[user.role] === role ? null : (
      <DropdownItem
        onClick={() => onClick(role)}
        key={role}
        aria-label={`Change to ${role}`}
      >
        Change to {role}
      </DropdownItem>
    )
  );
};

const RemoveUserActionItem = ({ onClick }) => (
  <DropdownItem negative={true} onClick={onClick} aria-label="Remove User">
    Remove
  </DropdownItem>
);

export const UserActionsKabob = ({
  openRemoveUserModal,
  openUpdateRoleModal,
  user,
}) => {
  const { user: currentUser } = useAuth();
  const [resendUserInvite] = useResendInvitation();
  const { allowResendInvite, allowUpdateRole, allowRemoveUser } =
    useUserManagementAccess();

  if (
    (!allowResendInvite && !allowUpdateRole && !allowRemoveUser) ||
    user.accountId === currentUser.id
  ) {
    return null;
  }

  const isSSO = currentUser?.company?.sso === true;
  const showResendItem =
    user.lastActive === null && isSSO === false && allowResendInvite === true;
  const handleResendInvite = () => resendUserInvite(user.accountId);
  const handleUpdateRole = (role) => openUpdateRoleModal(user, role);
  const handleRemoveUser = () => openRemoveUserModal(user);

  return (
    <Dropdown
      aria-label="kabob menu"
      trigger={
        <EllipsisVerticalIcon
          aria-label="kabob"
          className="h-2.5 w-2.5 cursor-pointer text-slate-400"
        />
      }
      direction="bottom-end"
    >
      {showResendItem && (
        <ResendInviteActionItem onClick={handleResendInvite} />
      )}
      {allowUpdateRole && (
        <UpdateRoleActionItem user={user} onClick={handleUpdateRole} />
      )}
      {allowRemoveUser && <RemoveUserActionItem onClick={handleRemoveUser} />}
    </Dropdown>
  );
};

UserActionsKabob.propTypes = {
  openRemoveUserModal: PT.func.isRequired,
  openUpdateRoleModal: PT.func.isRequired,
  user: PT.shape({
    name: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    email: PT.string,
  }),
};
