import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const NLB_LABEL = AWS_RESOURCES_LABELS.networkLoadBalancers;

export const TABLE_HEADERS_NLB = [
  { label: "Name", sort: "NAME" },
  { label: "Account Name", accessor: "accountName", sort: "AWS_ACCOUNT__NAME" },
  { label: "Account ID", accessor: "accountId", sort: "ACCOUNT" },
  { label: "State", accessor: "stateCode", sort: "STATE_CODE" },
  { label: "Region", sort: "REGION" },
  { label: "DNS Name", accessor: "endpoint", sort: "DNS_NAME" },
  { label: "VPC", accessor: "vpcId", sort: "VPC_ID" },
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  {
    label: "Monitoring",
    accessor: "monitoringPolicy",
    sort: "MONITORING_POLICY",
  },
  { label: "Tags", sort: "TAGS" },
];
