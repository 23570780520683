import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { useApolloClient, useQuery } from "@mc/utils/graphql";

import { CLOUD_SCORE_MESSAGES } from "../constants";

export const GET_SCORECARD_SUMMARY = gql(`
  query CloudScoreSummary {
    scorecards(first: 1) {
      edges {
        node {
          id
          name
          date
          createdTime
          score
          potentialMonthlySavings
          summaries {
            edges {
              node {
                id
                name
                pillar
                score
                scoreChange
                totalChecks
                ok
                warning
                error
              }
            }
          }
        }
      }
    }
  }
`);

export const useCloudScoreSummary = () => {
  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.SCORECARD_SUMMARY_FAILURE);

  const { data, ...result } = useQuery(GET_SCORECARD_SUMMARY, { onError });

  const scorecard = data?.scorecards?.edges.map((edge) => edge.node)[0];
  const summaries = scorecard?.summaries?.edges.map((edge) => edge.node);
  const overallSummary = summaries?.find((summary) => !summary.pillar);

  return {
    data: {
      score: scorecard?.score,
      scoreChange: overallSummary?.scoreChange,
      potentialMonthlySavings: scorecard?.potentialMonthlySavings,
      totalChecks: overallSummary?.totalChecks,
      optimizedChecks: overallSummary?.ok,
      warningChecks: overallSummary?.warning,
      criticalChecks: overallSummary?.error,
      pillars: summaries?.filter((summary) => summary.pillar !== null) || [],
    },
    ...result,
  };
};

/**
 * Reads the scorecard summary last updated time from the Apollo cache.
 */
export const useScorecardLastUpdated = () => {
  const client = useApolloClient();
  const data = client.readQuery({ query: GET_SCORECARD_SUMMARY });
  const scorecard = data?.scorecards?.edges.map((edge) => edge.node)[0];
  return scorecard?.createdTime;
};
