import clsx from "clsx";

import { useAuth } from "@m/login";
import { Link, MissionLogo } from "@m/ui";

import { CompanySwitcher } from "@mc/components/CompanySwitcher";
import { CONTACT_US_URL, PATHS } from "@mc/constants";
import { useIsStaff } from "@mc/hooks";
import { useNavigate } from "@mc/router";

export const FullScreenLayout = ({ children }) => {
  const isStaff = useIsStaff();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleClickLogo = () => {
    if (user) navigate(PATHS.ROOT);
  };

  return (
    <div className="flex h-screen flex-col justify-between">
      <div className="m-3">
        {isStaff ? (
          <div className="flex w-fit items-center gap-2">
            <MissionLogo
              logo="mark"
              style={{ height: 20 }}
              color="white"
              onClick={handleClickLogo}
              className="cursor-pointer hover:opacity-80"
            />
            <CompanySwitcher />
          </div>
        ) : (
          <MissionLogo
            onClick={handleClickLogo}
            className={clsx("h-5 w-5", {
              "cursor-pointer hover:opacity-80": user,
            })}
          />
        )}
      </div>

      <div className="flex flex-col items-center overflow-y-auto pb-5">
        {children}
      </div>

      <div className="m-3 text-center text-sm font-medium text-default">
        Need help? <Link to={CONTACT_US_URL}>Contact us</Link>
        {user && (
          <>
            {" | "}
            <Link onClick={logout}>Log out</Link>
          </>
        )}
      </div>
    </div>
  );
};
