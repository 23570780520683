import { BanknotesIcon, CheckBadgeIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { generatePath, useNavigate } from "react-router-dom";

import { Spinner, Timestamp } from "@m/ui";
import { Card } from "@m/ui/components/Card";
import { toDollars } from "@m/utils";

import { PATHS } from "@mc/constants";

import { useScorecardLastUpdated } from "../api";
import { CHECK_STATUS, CloudScorePillarIds } from "../constants";
import { CheckStatusIcon } from "../icons";

import { RadialCloudScoreGauge } from "./RadialCloudScoreGauge";

interface Props {
  criticalChecks?: number;
  loading?: boolean;
  okChecks?: number;
  potentialMonthlySavings?: number;
  score?: number;
  totalChecks?: number;
  warningChecks?: number;
}

export const CurrentScoreSection = ({
  criticalChecks = 0,
  loading = false,
  okChecks = 0,
  potentialMonthlySavings = 0.0,
  score = 0.0,
  totalChecks = 0,
  warningChecks = 0,
}: Props) => {
  const navigate = useNavigate();
  const lastUpdated = useScorecardLastUpdated();

  const handleClick = () => {
    navigate(
      generatePath(PATHS.CLOUD_SCORE_PILLAR_DETAILS, {
        pillarId: CloudScorePillarIds.AllChecks,
      })
    );
  };

  const available = score > 0;

  return (
    <Card
      animateOnHover={true}
      className="text-default"
      data-testid="current-score-section"
      onClick={handleClick}
      title={
        <div className="flex justify-between">
          <div>Summary</div>
          {lastUpdated && (
            <div className="text-sm font-medium text-subdued">
              <Timestamp
                verbiage="Updated"
                isoTime={lastUpdated}
                longFormat={true}
              />
            </div>
          )}
        </div>
      }
    >
      {loading && (
        <div className="flex justify-center">
          <Spinner className="m-4" />
        </div>
      )}

      {!loading && available && (
        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div className="p-6">
            <RadialCloudScoreGauge
              criticalChecks={criticalChecks}
              okChecks={okChecks}
              score={score}
              totalChecks={totalChecks}
              warningChecks={warningChecks}
            />
          </div>
          <CurrentScoreTable
            criticalChecks={criticalChecks}
            okChecks={okChecks}
            potentialMonthlySavings={potentialMonthlySavings}
            totalChecks={totalChecks}
            warningChecks={warningChecks}
          />
        </div>
      )}

      {!loading && !available && (
        <div className="flex items-center justify-center p-3 text-xs font-semibold uppercase text-subdued">
          Cloud Score Not Available
        </div>
      )}
    </Card>
  );
};

const CurrentScoreTable = ({
  criticalChecks,
  okChecks,
  potentialMonthlySavings,
  totalChecks,
  warningChecks,
}) => {
  return (
    <div className="grid grid-flow-row px-5 py-3">
      <CurrentScoreTableRow
        icon={<BanknotesIcon className="h-3 w-3 text-status-good" />}
        text="Potential Monthly Savings"
        value={
          <span className="text-status-good">
            {toDollars(potentialMonthlySavings)}
          </span>
        }
      />
      <CurrentScoreTableRow
        icon={<CheckStatusIcon status={CHECK_STATUS.OK} />}
        text="Optimized Checks"
        value={okChecks}
      />
      <CurrentScoreTableRow
        icon={<CheckStatusIcon status={CHECK_STATUS.ERROR} />}
        text="Critical Checks"
        value={criticalChecks}
      />
      <CurrentScoreTableRow
        icon={<CheckStatusIcon status={CHECK_STATUS.WARNING} />}
        text="Warning Checks"
        value={warningChecks}
      />
      <CurrentScoreTableRow
        icon={<CheckBadgeIcon className="h-3 w-3 text-status-neutral" />}
        text="Total Checks"
        value={totalChecks}
      />
    </div>
  );
};

const CurrentScoreTableRow = ({ icon, text, value }) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-between gap-2 border-b py-1 font-semibold text-default last:border-none"
      )}
    >
      <div className="h-3">{icon}</div>
      <div className="text-md grow">{text}</div>
      <div className="text-2xl">{value}</div>
    </div>
  );
};
