import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { ScorecardRecommendationStatus } from "@m/api/public/types";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES } from "../constants";

import { GET_CHECK_DETAILS } from "./useCheckDetails";
import { GET_CLOUD_SCORE_RECOMMENDATIONS } from "./useCloudScoreRecommendations";

export const CREATE_CLOUD_SCORE_RECOMMENDATION = gql(/* GraphQL */ `
  mutation CreateCloudScoreRecommendation(
    $input: CreateScorecardRecommendationInput!
  ) {
    createScorecardRecommendation(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateCloudScoreRecommendation = () => {
  const onCompleted = () =>
    toast.success(
      CLOUD_SCORE_MESSAGES.CREATE_CLOUD_SCORE_RECOMMENDATION_SUCCESS
    );

  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.CREATE_CLOUD_SCORE_RECOMMENDATION_FAILURE);

  const [mutate, result] = useMutation(CREATE_CLOUD_SCORE_RECOMMENDATION, {
    onCompleted,
    onError,
  });

  const createRecommendation = ({
    title,
    notes,
    isAllResourcesSelected,
    selectedResourceIds,
    checkId,
  }) =>
    mutate({
      variables: {
        input: {
          ...(isAllResourcesSelected
            ? { excludedResources: selectedResourceIds }
            : { includedResources: selectedResourceIds }),
          notes,
          scorecardResultId: checkId,
          title,
        },
      },
      refetchQueries: [
        { query: GET_CLOUD_SCORE_RECOMMENDATIONS },
        {
          query: GET_CHECK_DETAILS,
          variables: {
            id: checkId,
            where: {
              scorecardResultId: checkId,
              scorecardRecStatus: ScorecardRecommendationStatus.Active,
            },
          },
        },
      ],
    });

  return [createRecommendation, result] as const;
};
