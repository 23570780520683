import { useState } from "react";

import { Button, Card, Select } from "@m/ui";

export const GrantAccessForm = ({
  employees,
  repositories,
  grantAccess,
  grantAccessLoading,
  disabled,
}) => {
  const [employeeId, setEmployeeId] = useState("");
  const [repositoryId, setRepositoryId] = useState("");

  const resetForm = () => {
    setEmployeeId("");
    setRepositoryId("");
  };

  const handleRepositoryChange = (e) => {
    e.preventDefault();
    setRepositoryId(e.target.value);
  };

  const handleEmployeeChange = (e) => {
    e.preventDefault();
    setEmployeeId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const employee = employees[employeeId];
    const repository = repositories[repositoryId];
    if (employee && repository) {
      await grantAccess(employee, repository);
      resetForm();
    }
  };

  const grantAccessDisabled =
    disabled || employeeId === "" || repositoryId === "";

  return (
    <Card className="px-4 py-2">
      <label className="font-bold" htmlFor="grant-access-form">
        Give Mission Employee Access
      </label>

      <form
        id="grant-access-form"
        className="mt-2 flex items-center space-x-3"
        onSubmit={handleSubmit}
      >
        <Select
          aria-label="Employee"
          value={employeeId}
          onChange={handleEmployeeChange}
          placeholder="Choose an Employee..."
          options={
            employees &&
            employees.map((employee, i) => (
              <option key={employee.id} value={i}>
                {employee.name}
              </option>
            ))
          }
        />

        <Select
          aria-label="Repository"
          value={repositoryId}
          onChange={handleRepositoryChange}
          placeholder="Choose a Repository..."
          options={
            repositories &&
            repositories.map((repo, i) => (
              <option key={repo.id} value={i}>
                {repo.fullName}
              </option>
            ))
          }
        />

        <Button
          kind="primary"
          disabled={grantAccessDisabled}
          loading={grantAccessLoading}
          type="submit"
        >
          Grant Access
        </Button>
      </form>
    </Card>
  );
};
