import { ReactNode } from "react";

import { AuthContext } from "@m/login";
import { useAuth0Logout, useUserSession } from "@m/login/hooks";
import { UserSession } from "@m/types";

interface Props {
  children: ReactNode;
  defaultUser?: UserSession;
}

export const AuthProvider = ({ defaultUser = null, children }: Props) => {
  const { user, updateUserSession } = useUserSession(defaultUser);
  const logout = useAuth0Logout();

  return (
    <AuthContext.Provider value={{ user, updateUserSession, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
