import { dt } from "@m/utils";

import { getStartDateTimeDifference } from "./getStartDateTimeDifference";

export const getRelativeDate = (date: string) => {
  const currentDate = dt.now().startOf("day").toISO();
  const isToday = dt.fromISO(date).startOf("day").toISO() === currentDate;
  const isPast =
    !isToday && currentDate > dt.fromISO(date).startOf("day").toISO();
  const startDateTimeDifference = getStartDateTimeDifference(date, isPast);

  if (isToday) {
    return "Today";
  } else if (!isToday && isPast) {
    return `${startDateTimeDifference} ago`;
  } else if (startDateTimeDifference !== "0 days") {
    return startDateTimeDifference;
  }
};
