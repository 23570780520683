import { Card, Dropdown, Suspensed } from "@m/ui";
import { dt, formatFullDate } from "@m/utils";

import { useStoredPaymentMethod, useUpdatePaymentMethod } from "../api";
import {
  AmericanExpress,
  CreditCard,
  DinersClub,
  Discover,
  JCB,
  MasterCard,
  Visa,
} from "../icons";

import { UpdatePaymentMethod } from "./UpdatePaymentMethod";

export const StoredPaymentMethod = (): JSX.Element => {
  const {
    data: { storedPaymentMethod },
    loading,
  } = useStoredPaymentMethod();

  const [updatePaymentMethod] = useUpdatePaymentMethod();

  return (
    <Card className="flex w-fit items-center gap-x-2 px-1 py-2">
      <Suspensed loading={loading} height="2rem" width="200px">
        {storedPaymentMethod ? (
          <div className="flex items-center gap-x-2">
            <div>{getCardIcon(storedPaymentMethod.cardType)}</div>
            <div className="flex flex-col gap-y-0.5">
              <p className="text-sm font-semibold text-default">
                {cardNumberDisplayString(
                  storedPaymentMethod.maskedCreditCardNumber
                )}
              </p>
              <p className="text-sm font-medium text-subdued">
                {expirationDateDisplayString(
                  storedPaymentMethod.expirationDate
                )}
              </p>
            </div>
          </div>
        ) : (
          <div className="text-sm font-medium text-subdued">
            No payment method on record.
          </div>
        )}
      </Suspensed>

      {!loading && (
        <Dropdown>
          <UpdatePaymentMethod
            apiLoginID={process.env.REACT_APP_AUTHORIZENET_LOGIN}
            clientKey={process.env.REACT_APP_AUTHORIZENET_CLIENT_KEY}
            environment={process.env.REACT_APP_AUTHORIZENET_ENVIRONMENT}
            onSubmit={updatePaymentMethod}
          />
        </Dropdown>
      )}
    </Card>
  );
};

const iconComponents = {
  AMERICANEXPRESS: <AmericanExpress />,
  DISCOVER: <Discover />,
  JCB: <JCB />,
  VISA: <Visa />,
  MASTERCARD: <MasterCard />,
  DINERSCLUB: <DinersClub />,
};

const getCardIcon = (cardType: string) =>
  iconComponents[String(cardType).toUpperCase()] || <CreditCard />;

const cardNumberDisplayString = (cardNumber: string) =>
  `•••• •••• •••• ${cardNumber}`;

const expirationDateDisplayString = (dateString: string) =>
  `Expires ${formatFullDate(dt.fromISO(dateString), "MM/yy")}`;
