import PT from "prop-types";

import { Timestamp } from "@m/ui";

export const OptimizationHistoryItem = ({ icon, message, timestamp }) => {
  return (
    <div
      aria-label="Optimization History Item"
      className="relative mb-1 flex h-4"
    >
      <div className="justify-center align-middle">{icon}</div>
      <span className="ml-2 max-h-3 font-medium text-default">{message}</span>
      <div
        className="ml-1 font-normal text-subdued"
        aria-label="Optimization History Item Change Date"
      >
        <Timestamp isoTime={timestamp} side="right" />
      </div>
    </div>
  );
};

OptimizationHistoryItem.propTypes = {
  icon: PT.node.isRequired,
  message: PT.node.isRequired,
  timestamp: PT.string.isRequired,
};
