import { ChangeEvent, forwardRef, useMemo } from "react";

import {
  Banner,
  DatePicker,
  DatePickerProps,
  Field,
  Input,
  Suspensed,
  VALIDATION_MESSAGES,
} from "@m/ui";
import { formatFullDate, fromDollars } from "@m/utils";

import { IModifiedEngagementTemplateFill } from "../api";
import { REGEX_PATTERNS } from "../constants";
import { TConfigurationDetails } from "../pages";

export const EngagementsConfigurationDetails = forwardRef<
  HTMLFormElement,
  {
    details: TConfigurationDetails;
    templateFill: IModifiedEngagementTemplateFill[];
    loading: boolean;
    onDetailsChange: (_details: Partial<TConfigurationDetails>) => void;
  }
>(({ details, templateFill, loading, onDetailsChange }, ref) => {
  const { startDate, dueDate, originalBudget } = details;
  const formattedStartDate = formatFullDate(startDate, "yyyy-MM-dd");
  const formattedDueDate = formatFullDate(dueDate, "yyyy-MM-dd");
  const formattedBudget = fromDollars(originalBudget)?.toFixed(2);

  const handleDetailsChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { name, value },
    } = e;
    onDetailsChange({
      [name]: value,
    });
  };

  const handleDateChange: DatePickerProps["onChange"] = (date, e) => {
    handleDetailsChange(e);
  };

  const memoedFill = useMemo(
    () =>
      templateFill
        .sort((a, b) => a.order - b.order)
        .map(({ fill, id }) => {
          const titleField = fill.find(
            (fillField) => fillField.fieldName === "title"
          );

          return (
            <Suspensed
              className="mt-1"
              key={id}
              loading={loading}
              width="300px"
              height="30px"
            >
              <FillLabel label={titleField?.value} />
            </Suspensed>
          );
        }),
    [templateFill, loading]
  );

  return (
    <div className="flex flex-col gap-4">
      <Banner label="Project dates and budget have successfully synced. You can edit them now or at a later time" />
      <div>
        <div className="font-semibold">Work</div>
        <div className="text-subdued">
          The progress of the project over the specified time period will be
          measured.
        </div>
      </div>
      <form ref={ref}>
        <div className="flex flex-row gap-4">
          <Field
            className="w-[250px]"
            flag="required"
            htmlFor="startDate"
            label="Start Date"
          >
            <DatePicker
              data-testid="engagement-config-start-date"
              id="startDate"
              name="startDate"
              onChange={handleDateChange}
              required={true}
              value={formattedStartDate || ""}
            />
          </Field>
          <Field
            label="Due Date"
            className="w-[250px]"
            flag="required"
            htmlFor="dueDate"
          >
            <DatePicker
              data-testid="engagement-config-due-date"
              id="dueDate"
              name="dueDate"
              onChange={handleDateChange}
              required={true}
              value={formattedDueDate || ""}
            />
          </Field>
        </div>
        <Field
          className="mt-4"
          flag="required"
          htmlFor="originalBudget"
          label="Original Budget"
          description="A financial picture of the project over the specified time period
          will be measured."
        >
          <Input
            className="!w-[220px] text-right"
            data-testid="engagement-config-original-budget"
            leftAddon={<>&#36;</>}
            id="originalBudget"
            name="originalBudget"
            onChange={handleDetailsChange}
            pattern={REGEX_PATTERNS.CURRENCY}
            required={true}
            title={VALIDATION_MESSAGES.INVALID_CURRENCY_FORMAT}
            type="number"
            value={formattedBudget}
          />
        </Field>
      </form>
      {memoedFill.length > 0 && (
        <div>
          <div className="font-semibold">Milestones</div>
          <div className="max-w-4xl text-subdued">
            Actions or events marking a significant change or stage in project
            development can be recorded. The following have been provided as a
            starting point. They can be edited later.
          </div>
          <div className="mt-2">{memoedFill}</div>
        </div>
      )}
    </div>
  );
});
// there is a known issues with forwardRef losing the display name for debugging
// as forwardRef will just make the display name ForwardRef
EngagementsConfigurationDetails.displayName = "EngagementsConfigurationDetails";

const FillLabel = ({ label }: { label: string }) => {
  return (
    <div data-testid="fill-title" className="mb-2 flex flex-row items-center">
      <div className="mr-2 h-[10px] w-[10px] rounded-full bg-layout-active" />
      <div className="h-8 font-semibold leading-5">{label}</div>
    </div>
  );
};
