import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { generatePath } from "react-router-dom";

import { SyncDisableReasonString } from "@m/api/public/types";
import { Link, Spinner, Tooltip } from "@m/ui";
import { formatDate, fromSnakeCaseToProperCase } from "@m/utils";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";
import { DotIcon } from "@mc/icons";

interface Props {
  awsAccountId: string;
  isOnboarded: boolean;
  isSyncing: boolean;
  lastCompletedSynced: string;
  syncDisableReasonString: SyncDisableReasonString;
  syncsEnabled: boolean;
}

const SYNC_MESSAGES = {
  CONNECTED: "Connected",
  NOT_CONNECTED: "Not Connected",
  SYNCING: "Syncing",
  WARNING: "Sync issue",
};

export const AccountStatus = ({
  awsAccountId,
  isOnboarded,
  isSyncing,
  lastCompletedSynced,
  syncDisableReasonString,
  syncsEnabled,
}: Props) => {
  const userCanConnectAccounts = useAccessRequired({
    feature: FEATURE_FLAGS.ACCOUNTS_ALLOW_CONNECT_AWS_ACCOUNT,
    mutation: MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST,
  });

  const isConnected = isOnboarded && syncsEnabled && !isSyncing;
  const isWarning = isOnboarded && !syncsEnabled && !isSyncing;
  const pendingConnection = !isOnboarded && userCanConnectAccounts;
  const notConnected = !isOnboarded && !userCanConnectAccounts;

  let syncMessage: string;
  if (
    isWarning &&
    syncDisableReasonString !== SyncDisableReasonString.NoReasonSet
  )
    syncMessage = fromSnakeCaseToProperCase(syncDisableReasonString);
  else if (lastCompletedSynced)
    syncMessage = `Last synced ${formatDate(lastCompletedSynced)}`;

  const tooltipDisabled = !syncMessage || isSyncing;

  return (
    <div className="flex items-center gap-[6px] text-sm font-semibold">
      {isSyncing && <Spinner className="text-accent" size={1.5} />}
      {isConnected && (
        <DotIcon className="fill-status-good" height="6px" width="6px" />
      )}
      {isWarning && (
        <DotIcon className="fill-status-warning" height="6px" width="6px" />
      )}
      {notConnected && (
        <DotIcon className="fill-status-neutral" height="6px" width="6px" />
      )}

      <Tooltip content={syncMessage} disabled={tooltipDisabled} side="bottom">
        <div
          className={clsx({
            "cursor-help border-b border-dashed": !tooltipDisabled,

            // text color
            "text-accent": isSyncing || notConnected,
            "border-status-good text-status-good": isConnected,
            "border-status-warning text-status-warning": isWarning,
          })}
        >
          {isSyncing && SYNC_MESSAGES.SYNCING}
          {isConnected && SYNC_MESSAGES.CONNECTED}
          {isWarning && SYNC_MESSAGES.WARNING}
          {pendingConnection && (
            <Link
              rightIcon={ArrowRightIcon}
              to={generatePath(PATHS.SETTINGS_ACCOUNTS_CONNECT, {
                id: awsAccountId,
              })}
            >
              Connect
            </Link>
          )}
          {notConnected && SYNC_MESSAGES.NOT_CONNECTED}
        </div>
      </Tooltip>
    </div>
  );
};
