export interface CloudFormationTemplateUrlConfig {
  stackName: string;
  templateFileName: string;
}

export const CLOUD_FORMATION_TEMPLATE_URL_CONFIGS: {
  [TEMPLATE_KEY: string]: CloudFormationTemplateUrlConfig;
} = {
  ONBOARDING: {
    stackName: "mission-iam-access",
    templateFileName: "onboarding.yml",
  },
  CLOUD_SCORE: {
    stackName: "mission-cloudscore-access",
    templateFileName: "scorecard.yml",
  },
  IDENTIFY_PAYER: {
    stackName: "mission-identify-payer-account",
    templateFileName: "mission-control-gateway.yml",
  },
  CLOUD_GATEWAY_PAYER: {
    stackName: "mission-cloudgateway-payer-access",
    templateFileName: "mission-control-gateway-billing.yml",
  },
  CLOUD_GATEWAY_CHILD: {
    stackName: "mission-cloudgateway-child-access",
    templateFileName: "mission-control-gateway-linked-accounts.yml",
  },
  AI_GATEWAY_PAYER: {
    stackName: "mission-aigateway-payer-access",
    templateFileName: "mission-control-ai-gateway.yml",
  },
  AI_GATEWAY_CHILD: {
    stackName: "mission-aigateway-child-access",
    templateFileName: "mission-control-ai-gateway-linked-accounts.yml",
  },
  CLOUD_SECURE_PAYER: {
    stackName: "mission-cloudsecure-payer-access",
    templateFileName: "cloudsecure.yml",
  },
  CLOUD_SECURE_CHILD: {
    stackName: "mission-cloudsecure-child-access",
    templateFileName: "cloudsecure-linked-accounts.yml",
  },
};

export const CLOUD_FORMATION_IAM_ROLES = {
  MISSION_CLOUD_SCORE: "MissionCloudScore",
  MISSION_ACCOUNT_DISCOVERY: "MissionAccountDiscovery",
  MISSION_MARKETPLACE_METERING: "MissionMarketplaceMetering",
  MISSION_ENGINEERING: "MissionEngineering",
  MISSION_INVENTORY: "MissionInventory",
};

export const CLOUD_FORMATION_POLL_INTERVAL = 15000; // milliseconds
export const CLOUD_FORMATION_MAX_ATTEMPTS = 40; // 40 attempts * 15 seconds = 10 minutes

export const CLOUD_FORMATION_REGION = "us-east-1";

export const CLOUD_FORMATION_URL_BASE =
  "https://console.aws.amazon.com/cloudformation/home";
export const CLOUD_FORMATION_TEMPLATE_URL_BASE =
  "https://s3-us-west-2.amazonaws.com/bootstrap.missioncloud.com/src";
