import { UserSession } from "@m/types";
import { recursiveToCamel } from "@m/utils";

export const saveToLocalStorage = (session: any): UserSession => {
  if (session.csrf) {
    window.localStorage.setItem("csrf", session.csrf);
    delete session.csrf;
  }

  const user = recursiveToCamel(session);

  window.localStorage.setItem("user", JSON.stringify(user));

  return user as UserSession;
};
