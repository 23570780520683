import clsx from "clsx";
import { ButtonHTMLAttributes, ElementType, FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import tailwindConfig from "@m/tailwind-config";

type tailwindFontSizes = keyof typeof tailwindConfig.theme.fontSize;

export interface LinkPropsStrict {
  /** Set the component HTML element. Warning: Using non-standard markup can produce accessibility issues. */
  as?: "button" | "a" | "div" | "span";

  /** Additional classes */
  className?: string;

  /** Disabled link, unclickable */
  disabled?: boolean;

  /** Link style */
  kind?: "secondary" | "primary" | "destructive" | "warning";

  /** Link text size */
  fontSize?: "inherit" | tailwindFontSizes;

  /** Show an icon before the button's label. */
  leftIcon?: ElementType;

  /** Show an icon after the button's label. */
  rightIcon?: ElementType;

  /** Link role attribute */
  role?: undefined | string;

  /** Button type (if button element) */
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

interface LinkProps extends LinkPropsStrict {
  /** Unstrict Props */
  [propName: string]: any;
}

const defaultProps = {
  as: "button",
};

export const Link: FC<LinkProps> = ({
  as = defaultProps.as,
  children,
  className,
  disabled = false,
  fontSize = "inherit",
  kind = "primary",
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  role,
  type,
  ...props
}) => {
  const getTagName = () => {
    // If `as` is passed in and isn't default, it is always used
    if (as !== defaultProps.as) return as;

    // If `href` is passed in, the element becomes an anchor
    if (props.href) return "a";

    // If 'to' is passed in, the element becomes a react-router-dom Link component
    if (props.to) return RouterLink;

    // Otherwise use default
    return as;
  };

  const getTypeAttribute = () => {
    const tagName = getTagName();
    if (tagName !== "button") return undefined;
    if (type) return type;
    return "button";
  };

  const getRoleAttribute = () => {
    if (role) return props.role;

    const tagName = getTagName();
    if (tagName === "button") return "button";
    if (tagName === "a") return "link";

    return undefined;
  };

  const Element: any = getTagName();

  const classes = clsx(
    "leading-tight focus-visible:ring-4 focus-visible:outline-0 ring-blue-700/[.15] inline-block bg-none border-transparent font-semibold",

    // Hover styles
    "hover:opacity-[.85]",

    {
      // Kind styles
      "text-subdued": kind === "secondary",
      "text-action": kind === "primary",
      "text-negative": kind === "destructive",
      "text-yellow-700": kind === "warning",

      // Disabled styles
      "opacity-60 cursor-default pointer-events-none": disabled,

      // Font Size
      "text-[1em]": fontSize === "inherit",
      [`text-${fontSize}`]: fontSize !== "inherit",
    },
    className
  );

  const contentClsx = clsx({
    "inline-flex gap-0.5 items-center": LeftIcon || RightIcon,
  });

  const iconClsx = clsx("h-[1.15em] w-[1.15em]");

  return (
    <Element
      className={classes}
      data-component="Link"
      disabled={disabled}
      role={getRoleAttribute()}
      type={getTypeAttribute()}
      {...props}
    >
      <span className={contentClsx}>
        {LeftIcon && <LeftIcon className={iconClsx} />}
        <span>{children}</span>
        {RightIcon && <RightIcon className={iconClsx} />}
      </span>
    </Element>
  );
};
