import { Tooltip } from "@m/ui";
import { dt, formatFullDate } from "@m/utils";

interface Props {
  isPending?: boolean;
  timestamp: string;
}

export const MeteredChargeDateTooltip = ({ isPending, timestamp }: Props) => {
  const tooltipText = isPending
    ? `This charge was sent to AWS on ${formatFullDate(
        timestamp,
        "MMMM d, yyyy, H:mm a"
      )} and is awaiting confirmation`
    : formatFullDate(timestamp, "MMMM d, yyyy, h:mm:ss a");

  return (
    <Tooltip content={tooltipText} side="bottom" className="z-50 max-w-sm">
      <div
        className="select-text whitespace-nowrap underline decoration-dashed underline-offset-2"
        aria-label="Charge Date"
      >
        {isPending ? (
          <PendingAWSConfirmation />
        ) : (
          <FormattedTimestamp timestamp={timestamp} />
        )}
      </div>
    </Tooltip>
  );
};

const FormattedTimestamp = ({ timestamp }: { timestamp: string | null }) => {
  const currentDate = dt.now();
  const inputDate = dt.fromISO(timestamp);

  const formattedDate =
    inputDate.year < currentDate.year
      ? formatFullDate(inputDate, "MMMM d, yyyy")
      : formatFullDate(inputDate, "MMMM d");

  return <>{formattedDate}</>;
};

const PendingAWSConfirmation = () => (
  <div className="flex items-center">
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className="h-1 w-1 animate-pulse"
    >
      <circle cx="10" cy="10" r="10" className="fill-gray-300" />
    </svg>
    <span className="ml-1 text-gray-400">Pending</span>
  </div>
);
