import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import {
  GitRepoAccessPage,
  GitRepoConnectionsPage,
} from "@mc/features/GitRepositories";
import { Navigate } from "@mc/router";

export const gitRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.STAFF_GIT_ACCESS} />,
  },
  {
    path: "access",
    element: <GitRepoAccessPage />,
  },
  {
    path: "connections",
    element: <GitRepoConnectionsPage />,
  },
];
