import { CaseCategory } from "@m/api/public/types";

export const getCaseType = (category: CaseCategory) => {
  switch (category) {
    case CaseCategory.Alerts:
    case CaseCategory.Aws:
      return "Alert";
    case CaseCategory.DevopsDesk:
      return "Engineer Assist";
    default:
      return "Support";
  }
};
