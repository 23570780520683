import { useEffect, useState } from "react";

import { useModalState } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";

import { useTeamMembers } from "../api";
import { InviteButton, InviteUsersModal, UsersList } from "../components";

export function UserManagementPage() {
  const {
    data: { registeredUsers, unregisteredUsers },
    loading,
    refetch,
  } = useTeamMembers();

  const defaultSort = "ACCOUNT__EMAIL_ASC";
  const [sort, setSort] = useState(defaultSort);

  const inviteModal = useModalState();

  useEffect(() => {
    let variables = {};
    if (sort) variables["sort"] = sort;
    refetch(variables);
  }, [refetch, sort]);

  return (
    <Page data-testid="user-management-page">
      <PageHeading
        heading="Users"
        description="People from your team who have access to Mission Control"
        actions={<InviteButton onClick={inviteModal.open} disabled={loading} />}
      />

      <UsersList
        defaultSort={defaultSort}
        loading={loading}
        onSortChange={setSort}
        users={registeredUsers}
      />

      {inviteModal.isOpen && (
        <InviteUsersModal
          open={inviteModal.isOpen}
          onClose={inviteModal.close}
          users={unregisteredUsers}
        />
      )}
    </Page>
  );
}
