import { PaperClipIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import { AccessRequired } from "@mc/components/AccessRequired";

interface AttachmentPillProps {
  loading: boolean;
  fileName: string;
  className?: string;
  onRemove?: () => void;
  onClick?: () => void;
}

export function AttachmentPill({
  loading,
  fileName,
  className = "",
  onRemove,
  onClick,
}: AttachmentPillProps) {
  return (
    <div
      data-testid="attachment-pill"
      className={clsx(
        className,
        "border-1 flex w-fit items-center gap-0.5 rounded-full border border-gray-300 px-1 py-0.5 text-left hover:opacity-[.75]",
        {
          "cursor-wait": loading,
          "cursor-pointer": !loading,
          "bg-gray-100": loading,
        }
      )}
    >
      <button
        data-testid="attachment-download"
        className="flex items-center gap-0.5 text-xs font-semibold"
        onClick={onClick}
      >
        <PaperClipIcon className="h-2 w-2" />
        <p className="line-clamp-1">{fileName}</p>
      </button>

      {onRemove && (
        <AccessRequired mutation="deleteCaseAttachment">
          <button
            disabled={loading}
            type="button"
            data-testid="remove-button"
            className={clsx({
              "text-accent hover:text-subdued": !loading,
            })}
            onClick={onRemove}
          >
            <XMarkIcon className="h-2 w-2" />
          </button>
        </AccessRequired>
      )}
    </div>
  );
}
