import { gql, useMutation } from "@mc/utils/graphql";

import { GET_SCORECARD_CONFIGURATIONS } from "./useScorecardConfigurations";

export const UPDATE_SCORECARD_CONFIGURATION = gql`
  mutation UpdateScorecardConfiguration(
    $input: UpdateScorecardIgnoreAccountStatusInput!
  ) {
    updateScorecardIgnoreAccountStatus(input: $input) {
      ok
      message
    }
  }
`;

export const useUpdateScorecardConfiguration = (): [
  (input: UpdateScorecardIgnoreAccountStatusInput) => void,
  { loading: boolean }
] => {
  const [mutate, { loading }] = useMutation(UPDATE_SCORECARD_CONFIGURATION);

  const updateConfiguration = ({ accountId, included }) => {
    return mutate({
      variables: {
        input: {
          accountId,
          included,
          process: "TRUSTED_ADVISOR",
        },
      },
      refetchQueries: [
        {
          query: GET_SCORECARD_CONFIGURATIONS,
        },
      ],
    });
  };

  return [updateConfiguration, { loading }];
};

interface UpdateScorecardIgnoreAccountStatusInput {
  accountId: string;
  included: boolean;
}
