export const MISSION_MANAGED_FILTER_LABEL = "Mission Managed Filter";

export const MISSION_MANAGED_FILTER_OPTIONS = {
  MANAGED: "Managed",
  UNMANAGED: "Unmanaged",
};

export const MISSION_MANAGED_FILTER_ICON = (
  <>
    <img src="/favicon.png" alt="Mission Logo" className="mr-1 inline h-2" />
  </>
);
