import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";

import { Conversation } from "@m/api/public/types";
import { Avatar, Drawer, Spinner, Tooltip } from "@m/ui";
import {
  getDurationText,
  toTimeLocaleLong,
  toTimeLocaleSimple,
} from "@m/utils";

import { useGetIntercomMessages } from "../api";

export enum ConversationStates {
  ACTIVE = "active",
  INITIATED = "initiated",
  CLOSED = "closed",
}

export function IntercomActivity({ activity }: { activity: Conversation }) {
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const {
    createdAt,
    createdBy,
    createdBy: { name: createdByName },
    closedAt,
    closedBy,
    messages: { totalCount },
    conversationId,
  } = activity || {};
  const closedByName = closedBy?.name;

  const pluralizedExpandLabel = `See ${totalCount} message${
    totalCount > 1 ? "s" : ""
  }`;

  const handleChatHistoryToggle = () => setIsChatHistoryOpen((prev) => !prev);

  return (
    <div data-testid="intercom-activity" className="flex gap-1.5">
      <Avatar
        account={{
          ...createdBy,
        }}
        className="text-sm"
      />
      <div className="min-w-0 grow space-y-0.5">
        <div className="flex items-center justify-between">
          <p className="text-sm font-semibold">{createdByName}</p>
          <p className="text-xs text-subdued">{toTimeLocaleLong(createdAt)}</p>
        </div>
        <div className="text-sm">
          <button
            className="mt-1 flex w-full cursor-pointer items-center justify-between rounded-md border p-1 hover:opacity-[.75]"
            onClick={handleChatHistoryToggle}
          >
            <div>
              Live Chat {closedAt ? "resolved" : "initiated"} by{" "}
              <span className="font-semibold">
                {closedAt ? closedByName : createdByName}
              </span>{" "}
              {closedAt && (
                <>
                  in{" "}
                  <span className="font-semibold">
                    {getDurationText(createdAt, closedAt)}
                  </span>
                </>
              )}
            </div>
            <div className="inline-flex items-center gap-0.5 font-semibold text-action">
              {pluralizedExpandLabel}
              <ChevronRightIcon className="h-[1.15em] w-[1.15em]" />
            </div>
          </button>
        </div>
      </div>
      <ChatHistoryDrawer
        isChatHistoryOpen={isChatHistoryOpen}
        conversationId={conversationId}
        createdAt={createdAt}
        closedAt={closedAt}
        onClose={handleChatHistoryToggle}
      />
    </div>
  );
}

const ChatHistoryDrawer = ({
  isChatHistoryOpen,
  conversationId,
  createdAt,
  closedAt,
  onClose,
}: {
  isChatHistoryOpen: boolean;
  conversationId: string;
  createdAt: string;
  closedAt: string;
  onClose: () => void;
}) => {
  return (
    <Drawer
      backdrop={true}
      onClose={onClose}
      open={isChatHistoryOpen}
      title="Live Chat Details"
    >
      <div className="m-3">
        <div className="block w-full text-center text-xs text-subdued">
          Live Chat started at {toTimeLocaleLong(createdAt)}
        </div>
        <MessageHistory conversationId={conversationId} />
        {closedAt && (
          <div className="block w-full text-center text-xs text-subdued">
            Live Chat resolved at {toTimeLocaleLong(closedAt)}
          </div>
        )}
      </div>
    </Drawer>
  );
};

const MessageHistory = ({ conversationId }: { conversationId: string }) => {
  const { data, loading } = useGetIntercomMessages(conversationId);

  return (
    <div className="flex flex-col">
      {loading ? (
        <div className="flex h-full items-center justify-center">
          <Spinner className="m-4" />
        </div>
      ) : (
        data?.map(
          ({ node }) => node && <Message key={node.id} message={node} />
        )
      )}
    </div>
  );
};

const Message = ({ message }: { message }) => {
  const { comment, createdAt, createdBy } = message;
  const isAuthorStaff =
    createdBy.isStaff || (createdBy?.email || "").includes("@missioncloud.com");

  return (
    <div
      data-testid="live-chat-message"
      className={clsx("my-1 flex flex-col", { "justify-end": !isAuthorStaff })}
    >
      <Avatar
        account={{
          ...createdBy,
        }}
        includeName={true}
        className={clsx("text-sm font-semibold", {
          "justify-end": !isAuthorStaff,
        })}
      />
      <div
        className={clsx("flex", {
          "justify-end": !isAuthorStaff,
        })}
      >
        <div
          className={clsx(
            "my-1 flex w-fit rounded-xl p-1 text-xs font-semibold antialiased",
            {
              "bg-form-active text-inverse": !isAuthorStaff,
            },
            {
              "bg-gray-300": isAuthorStaff,
            }
          )}
        >
          {comment}
        </div>
      </div>
      <div
        className={clsx("flex", {
          "justify-end": !isAuthorStaff,
        })}
      >
        <Tooltip
          content={toTimeLocaleLong(createdAt)}
          side={"bottom"}
          className="z-50"
        >
          <div className="select-text border-b border-dashed text-xs text-subdued">
            {toTimeLocaleSimple(createdAt).toLowerCase()}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
