import { gql, useMutation } from "@mc/utils/graphql";

import { GET_TEAM_MEMBERS } from "./useTeamMembers";

export const SEND_EMAIL_INVITE = gql`
  mutation useSendEmailInvite($input: EmailInvitationInput!) {
    sendEmailInvite(input: $input) {
      ok
      message
    }
  }
`;

export const useSendEmailInvite = () => {
  const [mutate, { loading }] = useMutation(SEND_EMAIL_INVITE, {
    refetchQueries: [{ query: GET_TEAM_MEMBERS }],
  });

  const sendEmailInvite = (email) =>
    mutate({
      variables: {
        input: {
          email,
        },
      },
    });

  return [sendEmailInvite, { loading }];
};
