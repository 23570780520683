import { toast } from "@m/ui";

import { downloadFile } from "./download";

export const fetchWithCSVDownload = async (uri, options) => {
  // a custom fetch implementation for our GraphQL client
  // that automatically detects text/csv attachments
  // and translates them into createObjectURL calls that automatically
  // download the file attachment
  const csv = options.headers["x-csv-filename"];
  if (csv) {
    try {
      const response = await fetch(uri, options);
      const blob = await response.blob();
      const download = (window.URL || window.webkitURL).createObjectURL(blob);
      downloadFile(download, `mission-control-${csv}`, "text/csv");
    } catch (err) {
      toast.error(
        "Something went wrong while generating your download.  Please try again."
      );
    }
    return new Promise((resolve) => {
      // CSV downloads are generally invoked using the useCSVDownload hook,
      // and callers don't actually *consume* the response data (because it's
      // raw CSV, not a GraphQL JSON response)
      //
      // despite this, we still must resolve the original fetch() promise;
      // resolve it with an empty Response so that the Apollo link chain
      // considers the request a success
      const blob = new Blob([JSON.stringify({ data: {} })], {
        type: "application/json",
      });
      resolve(new Response(blob, { status: 200 }));
    });
  }
  return fetch(uri, options);
};
