import { visitorType } from "./visitorType";

export const setHeapUser = (user, heaplib) => {
  heaplib = heaplib || window.heap;
  if (!heaplib || !heaplib.identify) return;

  heaplib.identify(user.email);
  heaplib.addUserProperties({
    Email: user.email,
    Name: user.name,
    Company: user.company.name,
    Staff: user.isStaff,
    VisitorType: visitorType(user),
  });
};
