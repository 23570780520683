import { Fragment } from "react";

import { Drawer } from "@m/ui";

export interface ResourceDetails {
  instanceId?: string;
  name: string;
  newrelicPermalink?: string;
  tags: string;
}

interface Props {
  details: ResourceDetails;
  open: boolean;
  onClose: () => void;
}

export const ResourceDetailsDrawer = ({ details, open, onClose }: Props) => {
  if (!details) return null;

  const { name, instanceId, tags, newrelicPermalink } = details;
  const tagCount = Object.keys(tags).length;

  return (
    <Drawer title={name} open={open} onClose={onClose}>
      <div className="p-2">
        {instanceId && (
          <div className="mb-3">
            <h3 className="pb-1 text-sm font-semibold">ID</h3>
            <p>{instanceId}</p>
          </div>
        )}
        {tagCount > 0 && (
          <div className="pb-2">
            <h3 className="pb-1 text-sm font-semibold">Tags</h3>
            <dl className="grid grid-cols-2 break-words rounded-lg border">
              {Object.entries(tags).map(([tag, value]) => (
                <Fragment key={tag}>
                  <dt className="border-b p-2 text-sm">{tag}</dt>
                  <dd className="border-b p-2 text-sm font-bold text-gray-800">
                    {value}
                  </dd>
                </Fragment>
              ))}
            </dl>
          </div>
        )}
        {newrelicPermalink && (
          <div className="mb-3">
            <p>
              <a
                className="flex gap-1 text-blue-700 hover:text-blue-500"
                href={newrelicPermalink}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: "20px" }}
                  title="View in New Relic"
                  alt="View in New Relic"
                  src="/icons/nr-logo.png"
                />
                View in New Relic
              </a>
            </p>
          </div>
        )}
      </div>
    </Drawer>
  );
};
