import { Cog8ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import clsx from "clsx";
import { ReactNode, useEffect } from "react";
import Overflow from "react-overflow-indicator";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { useAuth } from "@m/login";
import { Button, MissionLogo } from "@m/ui";

import { CompanySwitcher } from "@mc/components/CompanySwitcher";
import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { useSidebar } from "../contexts";

import { SupportDropdown } from "./SupportDropdown";
import { UserDropdown } from "./UserDropdown";

export const SidebarWrapper = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const { open, closeSidebar } = useSidebar();

  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    const content = document.querySelector("#default-layout-content");
    if (!isDesktop && open) disableBodyScroll(content);
    else enableBodyScroll(content);
    return () => clearAllBodyScrollLocks();
  }, [isDesktop, open]);

  return (
    <nav
      data-testid="sidebar"
      aria-hidden={!isDesktop && !open}
      className={clsx(
        "flex h-screen flex-col",
        "border-r border-neutral-100 bg-neutral-50",
        {
          hidden: !isDesktop && !open,
          "static block w-[255px] flex-shrink-0 flex-grow-0": isDesktop,
          "fixed bottom-0 left-0 top-0 z-[9998] block w-full max-w-[500px]":
            !isDesktop && open,
        }
      )}
    >
      <div className="flex items-center justify-between gap-1 p-2">
        <Link
          to={generateCompanyPath(PATHS.ROOT, user.company.slug)}
          onClick={closeSidebar}
        >
          <MissionLogo
            logo="mark"
            style={{ height: 20 }}
            className="hover:opacity-80"
          />
        </Link>
        <CompanySwitcher />
        {!isDesktop && (
          <button onClick={closeSidebar} className="text-accent">
            <XMarkIcon className="h-2.5 w-2.5" />
          </button>
        )}
      </div>

      <Overflow className="relative flex grow flex-col overflow-y-auto">
        <Overflow.Content>
          <menu className="flex flex-col gap-3 px-1">{children}</menu>
        </Overflow.Content>
        <Overflow.Indicator direction="up">
          <Gradient toDirection="bottom" />
        </Overflow.Indicator>
        <Overflow.Indicator direction="down">
          <Gradient toDirection="top" />
        </Overflow.Indicator>
      </Overflow>

      <div className="flex flex-col gap-2 p-2">
        <div className="max-h-3">
          <Button
            leftIcon={Cog8ToothIcon}
            fill="subdued"
            kind="primary"
            size="small"
            to={PATHS.SETTINGS_ACCOUNTS}
            className="w-full"
          >
            Configure Accounts
          </Button>
        </div>

        <div className="flex items-center justify-between">
          <UserDropdown />
          <SupportDropdown />
        </div>
      </div>
    </nav>
  );
};

const Gradient = ({ toDirection }: { toDirection: "bottom" | "top" }) => (
  <span
    className={clsx(
      "pointer-events-none absolute left-0 right-0 h-5 from-neutral-50",
      {
        "top-0 border-t bg-gradient-to-b": toDirection === "bottom",
        "bottom-0 border-b bg-gradient-to-t": toDirection === "top",
      }
    )}
  />
);
