import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS_CHECKLIST } from "../constants";
import { IEngagementsChecklistItem } from "../types";

export const GET_ENGAGEMENTS_CHECKLIST_ITEM = gql`
  query EngagementChecklistItem(
    $id: ID!
    $where: EngagementChecklistItemFilter
  ) {
    engagement(id: $id) {
      id
      checklist(where: $where) {
        edges {
          node {
            dueDate
            id
            noteList {
              edges {
                node {
                  id
                  content
                  createdAt
                  updatedAt
                  createdBy {
                    name
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            open
            owner
            title
            createdBy {
              name
              email
              firstName
              lastName
            }
            createTime
          }
        }
      }
    }
  }
`;

interface IEngagementChecklistParams {
  actionItemId: string;
}

export const useEngagementsChecklistItem = (
  engagementId: string,
  params?: IEngagementChecklistParams
): {
  data: { checklistItem: IEngagementsChecklistItem };
  loading: boolean;
} => {
  const where = {} as { id: string };

  if (params?.actionItemId) where.id = params.actionItemId;

  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_CHECKLIST.GET_ITEMS_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENTS_CHECKLIST_ITEM, {
    variables: {
      id: engagementId,
      where,
    },
    onError,
  });
  const checklistItems =
    data?.engagement?.checklist?.edges?.map((edge) => {
      return {
        ...edge.node,
        noteList: (edge.node.noteList.edges || []).map((edge) => edge.node),
      };
    }) || [];

  return { data: { checklistItem: checklistItems[0] }, loading };
};
