import { createContext, useContext } from "react";

interface IActivityFeedContext {
  itemTitleColor: string;
}

export const ActivityFeedContext = createContext<IActivityFeedContext>({
  itemTitleColor: "default",
});

export const ActivityFeedProvider = ActivityFeedContext.Provider;

export const useActivityFeedContext = () => useContext(ActivityFeedContext);
