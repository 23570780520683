function createResourceEmptyMessage(label: string) {
  return `No ${label} Resources Found`;
}

function createResourceFailureMessage(label: string) {
  return `We encountered an issue accessing your ${label} resource data.`;
}

export const CLOUD_SCORE_MESSAGES = {
  // Success messages
  CREATE_CLOUD_SCORE_RECOMMENDATION_SUCCESS:
    "Created recommendation successfully",
  DELETE_CLOUD_SCORE_RECOMMENDATION_SUCCESS:
    "Deleted recommendation successfully",
  UPDATE_CLOUD_SCORE_RECOMMENDATION_SUCCESS:
    "Updated recommendation successfully",
  UPDATE_CHECK_SOURCE_IGNORE_FLAG_SUCCESS: "Check was set to ignore",
  UPDATE_CHECK_SOURCE_MONITOR_FLAG_SUCCESS: "Check was set to monitor",

  // Failure messages
  CHECK_DETAILS_FAILURE: "Unable to get Cloud Score check details",
  CHECK_RESULTS_FAILURE: "Unable to get Cloud Score checks",
  CREATE_CLOUD_SCORE_RECOMMENDATION_FAILURE:
    "Unable to create Cloud Score recommendation",
  DELETE_CLOUD_SCORE_RECOMMENDATION_FAILURE: "Unable to delete recommendation",
  GET_OPTIMIZATION_HISTORY_ERROR: "Unable to retrieve optimization history.",
  GET_RESOURCES_FAILURE: createResourceFailureMessage,
  GET_SCORECARD_TREND_ACTIVITIES_ERROR:
    "Unable to get Cloud Score trend activity data",
  GET_SCORECARD_TREND_DAILY_ERROR: "Unable to get daily Cloud Score trend data",
  GET_SCORECARD_TREND_MONTHLY_ERROR:
    "Unable to get monthly Cloud Score trend data",
  GET_SCORECARD_TREND_WEEKLY_ERROR:
    "Unable to get weekly Cloud Score trend data",
  RECOMENDATION_DETAILS_FAILURE:
    "Unable to get Cloud Score recommendation details",
  SCORECARD_RECOMMENDATIONS_FAILURE:
    "Unable to get Cloud Score recommendations",
  SCORECARD_SUMMARY_FAILURE: "Unable to get Cloud Score summary",
  UPDATE_RECOMMENDATION_STATUS_FAILURE:
    "Unable to update the status of this recommendation",
  UPDATE_SCORECARD_RECOMMENDATION_FAILURE:
    "Unable to update Cloud Score recommendation",
  UPDATE_CHECK_SOURCE_IGNORE_FLAG_ERROR: "Unable to ignore check",
  UPDATE_CHECK_SOURCE_MONITOR_FLAG_ERROR: "Unable to monitor check",

  // Empty messages
  CHECK_LIST_EMPTY: "No checks to display.",
  CURRENT_SCORE_EMPTY: "No Cloud Score data to display.",
  GET_RESOURCES_EMPTY: createResourceEmptyMessage,
  GET_SCORECARD_TREND_DAILY_EMPTY:
    "No daily Cloud Score trend data to display.",
  GET_SCORECARD_TREND_MONTHLY_EMPTY:
    "No monthly Cloud Score trend data to display.",
  GET_SCORECARD_TREND_WEEKLY_EMPTY:
    "No weekly Cloud Score trend data to display.",
  IGNORED_CHECK:
    "Flagged resources do not display because this check has been ignored.",
  SCORECARD_RECOMMENDATIONS_EMPTY: "No recommendations to review.",
};

export const CHECK_STATUS_MESSAGES = {
  OK: "optimized",
  WARNING: "warning",
  ERROR: "critical",
};
