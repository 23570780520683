import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ECS_LABEL, GET_FILTERS_ERROR } from "../constants";

export const GET_ECS_CLUSTER_FILTERS = gql(/* GraphQL */ `
  query useEcsClusterFilters {
    ecsClusterFilters {
      accounts {
        edges {
          node {
            databaseId
            name
          }
        }
      }
    }
  }
`);

export const useEcsClusterFilters = () => {
  const onError = () => {
    toast.error(GET_FILTERS_ERROR(ECS_LABEL));
  };

  const { data, loading, error } = useQuery(GET_ECS_CLUSTER_FILTERS, {
    onError,
  });

  const filters = data?.ecsClusterFilters || {};
  const accounts = filters.accounts?.edges?.map(({ node }) => node) || [];

  return {
    data: { filters: { accounts } },
    error,
    loading,
  };
};
