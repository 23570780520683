import PT from "prop-types";

import { Avatar } from "../Avatar/Avatar";

export const AvatarGroup = ({ chatParticipants }) => {
  return (
    <div data-component="AvatarGroup" className="flex shrink-0">
      {chatParticipants[1] ? (
        <Avatar account={chatParticipants[1]} className="-mr-1" />
      ) : (
        <Avatar className="-mr-1" />
      )}
      {chatParticipants[0] && (
        <Avatar className="-mr-1" account={chatParticipants[0]} />
      )}
    </div>
  );
};

AvatarGroup.propTypes = {
  chatParticipants: PT.array.isRequired,
};
