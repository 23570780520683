import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
  actions?: ReactNode[];
  onClose: () => void;
  title: ReactNode;
}

export const OverlayHeader = ({ title, actions = [], onClose }: Props) => {
  const hasActions = actions.length > 0;

  return (
    <div
      data-testid="overlay-header"
      className={clsx("flex justify-between", {
        "border-b": !!title || hasActions,
      })}
    >
      <div className="m-2 font-medium">{title}</div>

      <div className="flex items-center">
        {hasActions && (
          <div className="flex space-x-1">
            {actions.map((action, i) => (
              <div key={i}>{action}</div>
            ))}
          </div>
        )}
        <button aria-label="Close Overlay" onClick={onClose} className="m-2">
          <XMarkIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};
