import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import {
  DEFAULT_SORT_ENGAGEMENTS_DECISIONS,
  MESSAGES_ENGAGEMENTS_DECISIONS,
} from "../constants";
import { EngagementDecisionType } from "../types";

export const GET_ENGAGEMENTS_DECISION_ITEMS = gql(/* GraphQL */ `
  query EngagementDecisionItems($id: ID!, $sort: [EngagementDecisionSortEnum]) {
    engagement(id: $id) {
      id
      decisions(sort: $sort) {
        edges {
          node {
            id
            dueDate
            owner
            title
            description
            status
            completionNotes {
              edges {
                node {
                  id
                  content
                }
              }
            }
            latestCompletionNote {
              id
              content
            }
            noteList {
              edges {
                node {
                  id
                  content
                  createdAt
                  updatedAt
                }
              }
            }
            createTime
            createdBy {
              id
            }
            impact
            completedTime
          }
        }
      }
    }
  }
`);

export const useEngagementsDecisionItems = (
  engagementId: string,
  query?: { sort: string }
): {
  data: { decisionItems: EngagementDecisionType[] };
  loading: boolean;
} => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_DECISIONS.GET_ITEMS_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENTS_DECISION_ITEMS, {
    variables: {
      id: engagementId,
      sort: query?.sort || DEFAULT_SORT_ENGAGEMENTS_DECISIONS,
    },
    onError,
  });

  const decisionItems =
    data?.engagement?.decisions?.edges?.map((edge) => {
      const item = {
        ...edge.node,
        noteList: (edge?.node?.noteList?.edges || []).map((edge) => edge.node),
        completionNotes: (edge?.node?.completionNotes?.edges || []).map(
          (edge) => edge?.node
        ),
      };
      return item;
    }) || [];

  return { data: { decisionItems }, loading };
};
