interface Props {
  onMouseDown?: () => void;
}

export const Backdrop = ({ onMouseDown }: Props) => (
  <div
    data-testid="backdrop"
    className="fixed inset-0 bg-black/30"
    aria-hidden="true"
    onMouseDown={onMouseDown}
  />
);
