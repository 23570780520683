import clsx from "clsx";

interface TabsProps {
  onTabChange: (tab: string) => void;
  selectedTab: string;
  tabs: string[];
}

export const Tabs = ({ onTabChange, selectedTab, tabs }: TabsProps) => {
  return (
    <div className="flex gap-x-1" role="tablist">
      {tabs.map((tab) => (
        <Tab
          key={tab}
          onClick={() => onTabChange(tab)}
          selected={tab === selectedTab}
          tab={tab}
        />
      ))}
    </div>
  );
};

const Tab = ({
  onClick,
  selected = false,
  tab,
}: {
  onClick: () => void;
  selected: boolean;
  tab: string;
}) => {
  return (
    <button
      aria-selected={selected}
      className={clsx(
        "rounded-full px-2 py-1 text-sm font-semibold text-[#003161] hover:bg-layout-active/[.07]",
        {
          "bg-layout-active/[.07]": selected,
        }
      )}
      onClick={onClick}
      role="tab"
    >
      {tab}
    </button>
  );
};
