import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

import { Spinner } from "../Spinner";

interface Props extends ComponentPropsWithoutRef<"div"> {
  fitToParent?: boolean;
}

export const SpinnerScreen = ({ fitToParent, ...props }: Props) => (
  <div
    className={clsx("flex items-center justify-center", {
      "h-screen w-screen": !fitToParent,
      "h-full w-full": fitToParent,
    })}
    {...props}
  >
    <Spinner className="text-subdued" />
  </div>
);
