import { CasePriority } from "@m/api/public/types";
import { Badge, TBadgeSize, TBadgeStatus } from "@m/ui";
import { toProperCase } from "@m/utils";

import { CASE_PRIORITY } from "../constants";

interface CasePriorityBadgeProps {
  priority: CasePriority;
  size?: TBadgeSize;
}

export function CasePriorityBadge({
  priority,
  size = "default",
}: CasePriorityBadgeProps) {
  const priorityStatuses = {
    [CASE_PRIORITY.LOW]: "default",
    [CASE_PRIORITY.MODERATE]: "active",
    [CASE_PRIORITY.HIGH]: "warning",
    [CASE_PRIORITY.CRITICAL]: "negative",
  };

  return (
    <div className="grid justify-items-stretch">
      <Badge
        className="justify-self-center"
        data-testid="case-priority"
        label={toProperCase(priority)}
        size={size}
        status={priorityStatuses[priority] as TBadgeStatus}
      />
    </div>
  );
}
