const Visa = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="42"
      viewBox="0 0 65 42"
      fill="none"
    >
      <path
        d="M24.4333 29.2933L27.2132 12.8495H31.6597L28.8777 29.2933H24.4333ZM44.9424 13.204C44.0617 12.8708 42.6812 12.5133 40.9574 12.5133C36.5635 12.5133 33.4688 14.7436 33.4423 17.94C33.4175 20.3029 35.6519 21.6211 37.3384 22.4076C39.0694 23.2138 39.6511 23.7277 39.6428 24.4474C39.6317 25.5497 38.2606 26.053 36.9824 26.053C35.2029 26.053 34.2574 25.8038 32.797 25.1901L32.2239 24.9288L31.5999 28.6098C32.6385 29.069 34.5589 29.4665 36.5531 29.4872C41.2273 29.4872 44.2616 27.2824 44.2961 23.8688C44.3127 21.9982 43.1281 20.5748 40.5627 19.4011C39.0085 18.6403 38.0567 18.1327 38.0668 17.3625C38.0668 16.6789 38.8724 15.9481 40.6134 15.9481C42.0673 15.9253 43.1208 16.2449 43.9414 16.5781L44.3399 16.7678L44.9424 13.204ZM56.3849 12.8492H52.9491C51.8848 12.8492 51.0881 13.142 50.6208 14.2129L44.017 29.2825H48.6863C48.6863 29.2825 49.4495 27.2563 49.6222 26.8114C50.1324 26.8114 54.6684 26.8184 55.3168 26.8184C55.4499 27.3942 55.8578 29.2825 55.8578 29.2825H59.9839L56.3849 12.8487V12.8492ZM50.9335 23.4677C51.3014 22.5202 52.7052 18.8708 52.7052 18.8708C52.679 18.9146 53.0703 17.9188 53.2947 17.3014L53.5952 18.7191C53.5952 18.7191 54.4466 22.6444 54.6246 23.4674H50.9335V23.4677ZM20.6585 12.8492L16.3053 24.0632L15.8415 21.7844C15.031 19.1574 12.5061 16.3112 9.68335 14.8863L13.6639 29.2671L18.3685 29.2618L25.3689 12.8491L20.6585 12.849"
        fill="#0E4595"
      />
      <path
        d="M12.2433 12.8486H5.07319L5.01636 13.1908C10.5946 14.5519 14.2855 17.8413 15.818 21.794L14.2589 14.2374C13.9899 13.1961 13.2092 12.8853 12.2434 12.8491"
        fill="#F2AE14"
      />
    </svg>
  );
};

export default Visa;
