import { getMilliseconds } from "@m/utils";

export const groupByTypename = (activities, __typename) =>
  activities.filter((activity) => __typename === activity.__typename);

export const groupByOperation = (activities, operation) =>
  activities.filter((activity) => operation === activity.operation);

export const groupByTimestamp = (activities, { startTime, endTime }) =>
  activities.filter((activity) => {
    const timestamp = getMilliseconds(activity.timestamp);
    const startMs = getMilliseconds(startTime);
    const endMs = getMilliseconds(endTime);

    return timestamp >= startMs && timestamp <= endMs;
  });
