import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import { MESSAGES_ENGAGEMENTS } from "../constants";

export const GET_ENGAGEMENT_TYPES = gql`
  query EngagementTypes {
    engagementTypes {
      id
      description
      display
    }
  }
`;

export const useEngagementTypes = () => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS.GET_ENGAGEMENT_TYPES_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENT_TYPES, {
    onError,
  });

  const types = data?.engagementTypes || [];

  return { data: { types }, loading };
};
