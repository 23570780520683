import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

import { Breadcrumbs, BreadcrumbsProps } from "@m/ui";

import { useSidebar } from "@mc/components/Sidebar";

type BasePageTitleProps = {
  actions?: ReactNode;
  border?: "bottom" | "none";
  description?: ReactNode;
  tabs?: ReactNode;
  padding?: "small" | "large" | "none";
  [key: string]: any;
};

// Require _either_ breadcrumbs or title to exist
interface BreadcrumbPageTitleProps extends BasePageTitleProps {
  breadcrumbs: BreadcrumbsProps["crumbs"];
}

interface TitlePageTitleProps extends BasePageTitleProps {
  parentTitle?: ReactNode;
  title: ReactNode;
}

export type PageTitleProps = BreadcrumbPageTitleProps | TitlePageTitleProps;

export const PageTitle = ({
  actions = null,
  border = "bottom",
  breadcrumbs,
  className,
  description,
  parentTitle,
  tabs,
  title,
  padding = "large",
  ...props
}: PageTitleProps) => {
  const { toggleSidebar } = useSidebar();
  const handleHamburgerClick = () => toggleSidebar?.();

  const ParentTitle = "h1";
  const Title = parentTitle ? "h2" : "h1";

  return (
    <div
      className={clsx(
        "w-full",
        {
          // border
          "border-b": border === "bottom",

          // horizontal padding
          "px-3": padding !== "none",

          // vertical padding
          "py-1.5 text-sm": padding === "small",
          "py-4": padding === "large",

          // tabs override
          "pb-0": tabs,
        },
        className
      )}
      data-component="PageTitle"
      {...props}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <div>
          <div
            className={clsx("flex items-center gap-2", {
              "py-0.5": padding === "small",
            })}
          >
            <button
              aria-label="Open Navigation Menu"
              className="md:hidden"
              onClick={handleHamburgerClick}
            >
              <Bars3BottomLeftIcon className="h-2.5 w-2.5" />
            </button>

            <div>
              {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}

              {parentTitle && (
                <ParentTitle className="text-sm font-medium text-subdued">
                  {parentTitle}
                </ParentTitle>
              )}
              {title && (
                <Title
                  className={clsx("font-semibold", {
                    "text-2xl": padding === "large",
                  })}
                >
                  {title}
                </Title>
              )}
              {description && (
                <div className="mt-0 text-sm text-subdued">{description}</div>
              )}
            </div>
          </div>
          {tabs && ( // Negative margin to factor in the border
            <div
              className={clsx({ "mt-1": padding === "large" })}
              style={{ marginBottom: -1 }}
            >
              {tabs}
            </div>
          )}
        </div>
        {actions}
      </div>
    </div>
  );
};
