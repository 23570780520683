import { ActivityFeedItemAwsAccountResourcesAvailable } from "../components/ActivityFeedItemAwsAccountResourcesAvailable";
import { ActivityFeedItemCompanyMembership } from "../components/ActivityFeedItemCompanyMembership";
import { ActivityFeedItemCompanyTeam } from "../components/ActivityFeedItemCompanyTeam";
import { ActivityFeedItemScorecardChange } from "../components/ActivityFeedItemScorecardChange";
import { ActivityFeedItemScorecardIgnoredCheck } from "../components/ActivityFeedItemScorecardIgnoredCheck";
import { ActivityFeedItemScorecardRecommendation } from "../components/ActivityFeedItemScorecardRecommendation";
import { ActivityFeedItemUserInvitation } from "../components/ActivityFeedItemUserInvitation";

interface ActivityStreams {
  [__typename: string]: {
    __typename: string;
    type: string;
    component: React.FC<{
      activity?: any;
      activities?: any[];
      timestamp?: string;
    }>;
    rollup: boolean;
  };
}

export const ACTIVITY_STREAMS: ActivityStreams = {
  AwsAccountResourcesAvailableActivityStream: {
    __typename: "AwsAccountResourcesAvailableActivityStream",
    type: "aws.aws_account_resources_available",
    rollup: false,
    component: ActivityFeedItemAwsAccountResourcesAvailable,
  },
  CompanyMembershipActivityStream: {
    __typename: "CompanyMembershipActivityStream",
    type: "mission.company_membership",
    rollup: false,
    component: ActivityFeedItemCompanyMembership,
  },
  CompanyTeamActivityStream: {
    __typename: "CompanyTeamActivityStream",
    type: "mission.company_team",
    rollup: false,
    component: ActivityFeedItemCompanyTeam,
  },
  ScorecardIgnoredCheckActivityStream: {
    __typename: "ScorecardIgnoredCheckActivityStream",
    type: "mission.scorecard_ignored_check",
    rollup: false,
    component: ActivityFeedItemScorecardIgnoredCheck,
  },
  ScorecardChangeActivityStream: {
    __typename: "ScorecardChangeActivityStream",
    type: "mission.scorecard_change",
    rollup: false,
    component: ActivityFeedItemScorecardChange,
  },
  ScorecardRecommendationActivityStream: {
    __typename: "ScorecardRecommendationActivityStream",
    type: "mission.scorecard_recommendation",
    rollup: true,
    component: ActivityFeedItemScorecardRecommendation,
  },
  UserInvitationActivityStream: {
    __typename: "UserInvitationActivityStream",
    type: "mission.user_invitation",
    rollup: false,
    component: ActivityFeedItemUserInvitation,
  },
};
