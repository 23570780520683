import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import {
  DEFAULT_SORT_ENGAGEMENTS_MILESTONES,
  MESSAGES_ENGAGEMENTS_MILESTONES,
} from "../constants";
import { IEngagementMilestoneItem } from "../types";

export const GET_ENGAGEMENTS_MILESTONE_ITEMS = gql`
  query EngagementMilestoneItems(
    $id: ID!
    $sort: [EngagementMilestoneSortEnum]
  ) {
    engagement(id: $id) {
      __typename
      id
      milestones(sort: $sort) {
        edges {
          node {
            dueDate
            engagementId
            id
            owner
            percentageComplete
            status
            title
          }
        }
      }
    }
  }
`;

export const useEngagementMilestoneItems = (
  engagementId: string,
  query?: { sort: string }
): {
  data: { milestoneItems: IEngagementMilestoneItem[] };
  loading: boolean;
} => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_MILESTONES.GET_ITEMS_ERROR);

  const { data, loading } = useQuery(GET_ENGAGEMENTS_MILESTONE_ITEMS, {
    variables: {
      id: engagementId,
      sort: query?.sort || DEFAULT_SORT_ENGAGEMENTS_MILESTONES,
    },
    onError,
  });

  const milestoneItems =
    data?.engagement?.milestones?.edges?.map((edge) => edge.node) || [];

  return { data: { milestoneItems }, loading };
};
