import { RouteObject } from "react-router-dom";

import { MarketplaceSignupPage } from "@mc/features/Signup";
import { AccountAlreadyExistsError } from "@mc/features/Signup/components";

import { AwsMarketplaceRedirect } from "./redirects";

export const signupRoutes: RouteObject[] = [
  {
    index: true,
    element: <AwsMarketplaceRedirect />,
  },
  {
    path: "account-already-exists",
    element: <AccountAlreadyExistsError />,
  },
  {
    path: ":token",
    element: <MarketplaceSignupPage />,
  },
];
