import {
  oneDayAgo,
  oneWeekAgo,
  rightNow,
  thirtyDaysAgo,
  twoDaysAgo,
  twoWeeksAgo,
} from "../utils";

export const ACTIVITY_FEED_GROUPS = [
  {
    id: "today",
    title: "Today",
    startTime: oneDayAgo(),
    endTime: rightNow(),
  },
  {
    id: "yesterday",
    title: "Yesterday",
    startTime: twoDaysAgo(),
    endTime: oneDayAgo(),
  },
  {
    id: "thisWeek",
    title: "This Week",
    startTime: oneWeekAgo(),
    endTime: twoDaysAgo(),
  },
  {
    id: "lastWeek",
    title: "Last Week",
    startTime: twoWeeksAgo(),
    endTime: oneWeekAgo(),
  },
  {
    id: "last30Days",
    title: "Last 30 Days",
    startTime: thirtyDaysAgo(),
    endTime: twoWeeksAgo(),
  },
];
