import PT from "prop-types";

import { EmptyMessage, Spinner } from "@m/ui";

import { ACTIVITY_STREAMS } from "@mc/features/ActivityFeed/constants";

import { useOptimizationHistory } from "../api";

import { OptimizationHistoryItemCheckIgnore } from "./OptimizationHistoryItemCheckIgnore";
import { OptimizationHistoryItemCheckStatus } from "./OptimizationHistoryItemCheckStatus";

export const OptimizationHistory = ({ checkId }) => {
  const {
    data: { activities },
    loading,
  } = useOptimizationHistory(checkId);

  const activityItemMap = new Map([
    [
      ACTIVITY_STREAMS.ScorecardChangeActivityStream.__typename,
      OptimizationHistoryItemCheckStatus,
    ],
    [
      ACTIVITY_STREAMS.ScorecardIgnoredCheckActivityStream.__typename,
      OptimizationHistoryItemCheckIgnore,
    ],
  ]);

  const empty = !loading && activities.length < 1;
  const ready = !loading && !empty;

  return (
    <div aria-label="Optimization History">
      {loading && (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}

      {empty && (
        <EmptyMessage
          message="There are currently no status changes to show."
          className="!m-0 border-2 border-dashed bg-gray-50 p-5 font-semibold text-gray-400"
        />
      )}

      {ready &&
        activities.map((item) => {
          const { __typename } = item;
          const ItemComponent = activityItemMap.get(__typename);
          return <ItemComponent key={item.id} item={item} />;
        })}
    </div>
  );
};

OptimizationHistory.propTypes = {
  checkId: PT.string.isRequired,
};
