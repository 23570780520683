import { InformationCircleIcon } from "@heroicons/react/24/outline";
import PT from "prop-types";

import { Avatar, Tooltip } from "@m/ui";

import { GENERIC_ROLE_DESCRIPTION } from "@mc/features/MyTeam/constants";

export const MyTeamListItem = ({ teamMember: { account, jobRole } }) => {
  return (
    <div aria-label="My Team Member" className="flex items-center gap-1">
      <Avatar account={account} className="text-xs" />
      <div className="grow">
        <div
          aria-label="Team Member Name"
          className="text-sm font-medium text-default"
        >
          {account.name}
        </div>
        <div aria-label="Team Member Role" className="text-xs text-subdued">
          {jobRole?.name ?? ""}
        </div>
      </div>
      <Tooltip
        side="left"
        content={jobRole?.description || GENERIC_ROLE_DESCRIPTION}
        className="w-[10rem] text-center"
        label="Team Member Description"
      >
        <InformationCircleIcon
          aria-label="Team Member Info"
          className="h-3 w-3 text-gray-300"
        />
      </Tooltip>
    </div>
  );
};

MyTeamListItem.propTypes = {
  teamMember: PT.shape({
    account: PT.shape({
      firstName: PT.string,
      lastName: PT.string,
      name: PT.string.isRequired,
      email: PT.string,
    }).isRequired,
    jobRole: PT.shape({
      name: PT.string.isRequired,
      description: PT.string,
    }).isRequired,
  }).isRequired,
};
