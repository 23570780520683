import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

export const GET_MISSION_EMPLOYEES_FAILURE =
  "We encountered a problem fetching employee user data.";

export const GET_MISSION_EMPLOYEES = gql`
  query useMissionEmployees {
    users(isStaff: true, disabled: false, sort: FULL_NAME_ASC) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export function useMissionEmployees() {
  const onError = () => toast.error(GET_MISSION_EMPLOYEES_FAILURE);

  const { loading, data } = useQuery(GET_MISSION_EMPLOYEES, {
    context: { clientName: "v4" },
    onError,
  });

  const missionEmployees = data?.users?.edges.map((edge) => edge.node) || [];

  return {
    data: { missionEmployees },
    loading,
  };
}
