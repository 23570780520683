import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

interface Props extends ComponentPropsWithoutRef<"div"> {
  row?: boolean;
}

export const Fields = ({ className, row, ...props }: Props) => {
  const classes = clsx(className, {
    "space-y-3": !row,
    "flex gap-3": row,
  });
  return <div data-component="Fields" className={classes} {...props} />;
};
