export function CloudScoreNoAccessIcon({
  className = "",
  width = 100,
  height = 100,
}) {
  return (
    <svg
      width="213"
      height="169"
      viewBox="0 0 213 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
      className={className}
    >
      <g opacity="0.6" filter="url(#filter0_d_1625_5497)">
        <path
          d="M40.8875 57.0282L23.4524 60.0606C18.8507 60.861 15.7694 65.2388 16.57 69.8388C17.3706 74.4388 21.7501 77.5191 26.3517 76.7187L43.7868 73.6863C48.3885 72.886 51.4698 68.5081 50.6692 63.9081C49.8686 59.3081 45.4891 56.2279 40.8875 57.0282Z"
          fill="#F9F9FA"
        />
      </g>
      <g opacity="0.5" filter="url(#filter1_d_1625_5497)">
        <path
          d="M188.482 37.1514L146.358 26.7304C138.943 24.8962 131.445 29.4178 129.61 36.8297C127.775 44.2416 132.298 51.7372 139.713 53.5715L181.837 63.9924C189.252 65.8267 196.75 61.3051 198.585 53.8931C200.42 46.4812 195.897 38.9857 188.482 37.1514Z"
          fill="#E5F2ED"
        />
      </g>
      <path
        d="M19.986 129.277C19.986 80.5971 58.6399 41.5054 106.324 41.5054C144.091 41.5054 177.317 66.8512 188.126 100.923"
        stroke="#5EB583"
        strokeWidth="7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.809 109.306C192.014 117.463 192.152 118.421 192.746 129.281"
        stroke="#D1D3D4"
        strokeWidth="7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <g filter="url(#filter2_d_1625_5497)">
        <path
          d="M158.621 109.284L136.482 103.275C130.639 101.689 124.615 105.139 123.029 110.98C121.442 116.822 124.893 122.843 130.737 124.429L152.876 130.438C158.719 132.024 164.743 128.574 166.329 122.732C167.916 116.891 164.465 110.87 158.621 109.284Z"
          fill="#F9F9FA"
        />
      </g>
      <path
        d="M131.399 109.754L134.617 115.368L138.207 113.309L142.455 120.722L144.057 114.828"
        stroke="#D1D3D4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.546 119.12L142.455 120.722"
        stroke="#D1D3D4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.983 118.424L149.585 119.89L152.807 120.764L153.205 119.299L149.983 118.424Z"
        fill="#D1D3D4"
      />
      <path
        d="M155.021 116.999L155.57 118.409L157.662 117.38L155.775 124.336L157.574 124.826L159.976 115.978L158.382 115.547L155.021 116.999Z"
        fill="#D1D3D4"
      />
      <g filter="url(#filter3_d_1625_5497)">
        <path
          d="M117.767 72.4461L59.0919 85.6429C48.765 87.9655 42.277 98.2169 44.6005 108.54L44.6014 108.544C46.9249 118.867 57.18 125.353 67.5068 123.03L126.182 109.833C136.509 107.511 142.997 97.2595 140.674 86.9364L140.673 86.9323C138.349 76.6092 128.094 70.1235 117.767 72.4461Z"
          fill="#E5F2ED"
        />
      </g>
      <path
        d="M60.5815 112.368L66.0509 103.734L71.5747 107.227L78.7891 95.828L80.9065 105.24"
        stroke="#5EB583"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.3568 97.9489L78.7891 95.828"
        stroke="#5EB583"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.395 91.1471L107.203 92.7157L109.367 89.7331L111.865 100.839L114.74 100.191L111.563 86.0729L109.015 86.6418L105.395 91.1471Z"
        fill="#5EB583"
      />
      <path
        d="M103.219 95.3249L92.6985 97.6912L93.2258 100.034L103.747 97.6675L103.219 95.3249Z"
        fill="#5EB583"
      />
      <path
        d="M98.2092 92.1597L95.8658 92.6868L98.2329 103.204L100.576 102.677L98.2092 92.1597Z"
        fill="#5EB583"
      />
      <path
        d="M117.694 96.1836L118.238 98.5973C118.238 98.5973 120.205 98.9571 122.389 98.4676C122.389 98.4676 127.967 97.5473 126.942 93.0044C126.942 93.0044 126.427 90.1473 122.879 90.6158C122.879 90.6158 125.683 89.1475 125.084 86.4829C125.084 86.4829 124.544 83.1531 119.979 84.1821C119.979 84.1821 117.209 84.6548 115.543 86.6167L117.389 88.2733C117.389 88.2733 118.648 86.9095 120.088 86.5833C120.088 86.5833 121.167 86.2904 121.883 86.9346C122.527 87.5161 122.498 88.5577 121.875 89.1643C121.377 89.6453 120.443 90.1766 118.703 90.5531L119.192 92.7367C119.192 92.7367 123.41 91.3395 123.925 93.611C123.925 93.611 124.436 95.3052 122.113 96.0832C121.632 96.2422 121.134 96.3342 120.632 96.3761C119.878 96.4388 118.682 96.4681 117.698 96.1878L117.694 96.1836Z"
        fill="#5EB583"
      />
      <g opacity="0.6">
        <path
          d="M180.125 47.525L187.356 40.3006L191.98 44.9188L201.521 35.3812V45.0276"
          stroke="#5EB583"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M191.855 35.3812H201.521"
          stroke="#5EB583"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M13.935 61.9862L17.4459 65.0357L19.396 62.7936L24.0284 66.8178L23.7187 62.4422"
          stroke="#D1D3D4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.6429 67.1231L24.0284 66.8177"
          stroke="#D1D3D4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1625_5497"
          x="6.44345"
          y="54.9017"
          width="46.3523"
          height="31.9435"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1625_5497"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1625_5497"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1625_5497"
          x="119.202"
          y="24.3223"
          width="81.7915"
          height="50.0782"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1625_5497"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1625_5497"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1625_5497"
          x="112.643"
          y="100.889"
          width="56.0721"
          height="39.934"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1625_5497"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1625_5497"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_1625_5497"
          x="34.1289"
          y="69.9747"
          width="109.016"
          height="63.527"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1625_5497"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1625_5497"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
