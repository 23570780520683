import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { MarketplaceMeteringTransactionSortEnum } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE =
  "Unable to get subscription transactions";

export const GET_METERED_CHARGES_TRANSACTIONS = gql(/* GraphQL */ `
  query GetMarketplaceMeteredCharges(
    $where: MarketplaceMeteringTransactionFilter
    $sort: [MarketplaceMeteringTransactionSortEnum]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    marketplaceMeteringTransactions(
      where: $where
      sort: $sort
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          id
          company {
            id
          }
          billingPeriod {
            isoformat
          }
          dimension {
            id
            atlasName
            displayName
          }
          quantity
          sentTimestamp
          marketplaceProductCode
          successfullySent
          catalogItem {
            id
            displayName
          }
          reportedDate
          chargeDate
          account {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useMeteredCharges = (query) => {
  const onError = () =>
    toast.error(METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE);

  const where = {
    ...(query?.products?.length && { catalogItemNameIn: query.products }),
    ...(query?.chargeDateStart && { chargeDateGte: query.chargeDateStart }),
    ...(query?.chargeDateEnd && { chargeDateLte: query.chargeDateEnd }),
    ...(query?.reportedDateStart && {
      reportedDateGte: query.reportedDateStart,
    }),
    ...(query?.reportedDateEnd && { reportedDateLte: query.reportedDateEnd }),
    ...(query?.dimensionCodes?.length && {
      dimensionCodeIn: query.dimensionCodes,
    }),
  };

  const { data, fetchMore, ...result } = useQuery(
    GET_METERED_CHARGES_TRANSACTIONS,
    {
      onError,
      variables: {
        ...INITIAL_PAGINATION,
        where,
        sort: MarketplaceMeteringTransactionSortEnum.SuccessfullySentTimestampDesc,
      },
    }
  );

  const {
    edges = [],
    pageInfo = {},
    totalCount = 0,
  } = data?.marketplaceMeteringTransactions ?? {};

  const meteredCharges =
    edges
      .map((edge) => {
        return edge?.node;
      })
      .filter((charge) => charge !== null) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { meteredCharges },
    pagination,
    ...result,
  };
};
