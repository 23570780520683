import { FormEventHandler, useState } from "react";

import { useAuth } from "@m/login";
import { Button, Field, Input, MissionLogo } from "@m/ui";

export const LOGIN_ERROR = "There was a problem logging into your account.";

export const LocalLogin = () => {
  const { updateUserSession } = useAuth();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const url = process.env.REACT_APP_SERVICES_API.concat(
    "/api/public/v1/login-dev"
  );

  const establishLoginSession: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw LOGIN_ERROR;
        }
        return response.json();
      })
      .then((data) => {
        if (!data.id) {
          setErrorMessage(LOGIN_ERROR);
          return;
        }
        const company = data.current_company;
        delete data.current_company;

        updateUserSession({
          company,
          ...data,
        });
      })
      .catch(() => {
        setErrorMessage(LOGIN_ERROR);
      });
  };
  return (
    <div className="flex h-screen items-center justify-center bg-layout-inverse">
      <div className="rounded-lg bg-white p-5">
        <form onSubmit={establishLoginSession} className="space-y-3">
          <MissionLogo logo="mark" className="m-auto h-[52px]" />

          <div className="space-y-2 text-center">
            <h1 className="p-0 text-lg font-semibold">Mission Control</h1>

            {errorMessage ? (
              <p className="m-0 text-sm text-red-500">{errorMessage}</p>
            ) : (
              <p className="m-0 text-sm text-gray-500">
                Enter your email address to continue.
              </p>
            )}
          </div>

          <Field htmlFor="email" label="Email">
            <Input
              name="email"
              size={30}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Field>
          <Button type="submit" kind="primary" size="large" className="w-full">
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};
