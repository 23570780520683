export enum DETAIL_ITEM_STATUS {
  ARCHIVED = "ARCHIVED",
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
  COMPLETED = "COMPLETED",
}

export enum DETAIL_ITEM_TYPE {
  MILESTONE = "milestone",
  RISK = "risk",
  ACTION_ITEM = "action item",
  DECISION = "decision",
}

export enum ENGAGEMENTS_OWNER_OPTIONS {
  MISSION = "MISSION",
  CUSTOMER = "CUSTOMER",
}

export enum DETAIL_ITEM_IMPACT {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}
