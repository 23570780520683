import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

interface Props {
  title: string;
  content: ReactNode;
  action?: ReactNode;
}

export const ErrorPage = ({ title, content, action = null }: Props) => {
  return (
    <div className="my-5 flex max-w-screen-md flex-col items-center gap-5">
      <ExclamationCircleIcon className="h-[100px] rounded-full border-[3px] p-3 text-action" />
      <div className="flex flex-col gap-3 text-center">
        <div className="text-4xl font-semibold text-default">{title}</div>
        <div className="text-xl font-normal text-subdued">{content}</div>
      </div>
      {action}
    </div>
  );
};
