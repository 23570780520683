import { useState } from "react";

import { Banner, Button } from "@m/ui";
import { setReturnToUrl } from "@m/utils";

import { Auth0Login } from "@mc/app/components";
import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

interface AccountSignInProps {
  companyId: string;
  companyName: string;
  token: string;
}

export const AccountSignIn = ({
  companyId,
  companyName,
  token,
}: AccountSignInProps) => {
  const [showLogin, setShowLogin] = useState(false);

  const handleClickSignIn = () => {
    const path = generateCompanyPath(PATHS.SIGNUP_TOKEN, companyId, { token });
    setReturnToUrl(path);
    setShowLogin(true);
  };

  if (showLogin) return <Auth0Login fitToParent />;

  return (
    <div
      className="flex flex-col items-center gap-y-5"
      data-testid="account-sign-in"
    >
      <div className="flex flex-col items-center gap-y-2">
        <h1 className="text-4xl font-bold">Sign in to your account</h1>
        <p className="text-xl text-subdued">
          Please sign in to add this product to{" "}
          <span className="font-semibold text-default">{companyName}</span>
        </p>
      </div>
      <Banner
        label={
          <div className="text-action">
            If you don't have access, please request an invite from your
            workspace admin.
          </div>
        }
      />
      <Button
        className="w-full max-w-xs"
        kind="primary"
        onClick={handleClickSignIn}
      >
        Sign in
      </Button>
    </div>
  );
};
