import {
  ENGAGEMENT_ACTIVITY_FEED_MAP,
  EngagementActivityFeedGroupConfig,
} from "../constants";
import { EngagementActivityStreamNode } from "../types";

// ISO8601 supports lexicographical sorting
// https://www.gnu.org/software/emacs/manual/html_node/calc/ISO-8601.html
export const sortByTimestamp = (
  activities: Array<EngagementActivityStreamNode>
): Array<EngagementActivityStreamNode> =>
  activities.sort((a, b) =>
    a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0
  );

export const filterActivitiesByGroup = (
  activities: Array<EngagementActivityStreamNode>,
  group: EngagementActivityFeedGroupConfig
): Array<EngagementActivityStreamNode> => {
  return activities.reduce((acc, item) => {
    const activityStreamItem = ENGAGEMENT_ACTIVITY_FEED_MAP[item.__typename];
    if (activityStreamItem?.group !== group) return acc;
    return [...acc, item];
  }, []);
};
