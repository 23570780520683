import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES } from "../constants";

import { GET_CLOUD_SCORE_DETAILS } from "./useCloudScoreDetails";
import { GET_SCORECARD_SCORE } from "./useScorecardScore";

export const UPDATE_CHECK_SOURCE_IGNORE_FLAG = gql(/* GraphQL */ `
  mutation updateIgnoredFlag($input: UpdateCheckSourceIgnoreInput!) {
    updateCheckSourceIgnore(input: $input) {
      ok
      message
      updatedScores
    }
  }
`);

export const useUpdateCheckIgnoreFlag = () => {
  const onCompleted = (ignored: boolean) => {
    const message = ignored
      ? CLOUD_SCORE_MESSAGES.UPDATE_CHECK_SOURCE_IGNORE_FLAG_SUCCESS
      : CLOUD_SCORE_MESSAGES.UPDATE_CHECK_SOURCE_MONITOR_FLAG_SUCCESS;

    toast.success(message);
  };

  const onError = (ignored: boolean) => {
    const message = ignored
      ? CLOUD_SCORE_MESSAGES.UPDATE_CHECK_SOURCE_IGNORE_FLAG_ERROR
      : CLOUD_SCORE_MESSAGES.UPDATE_CHECK_SOURCE_MONITOR_FLAG_ERROR;

    toast.error(message);
  };

  const [mutate, { loading }] = useMutation(UPDATE_CHECK_SOURCE_IGNORE_FLAG);

  const update = async ({ ignored, source, sourceId }) => {
    await mutate({
      onCompleted: () => onCompleted(ignored),
      onError: () => onError(ignored),
      variables: {
        input: {
          ignored,
          source,
          sourceId,
        },
      },
      refetchQueries: [
        { query: GET_SCORECARD_SCORE },
        { query: GET_CLOUD_SCORE_DETAILS },
      ],
    });
  };

  return [update, { loading }] as const;
};
