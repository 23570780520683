import { PencilIcon } from "@heroicons/react/24/outline";
import PT from "prop-types";

export const UpdateRecommendationButton = ({ onClick }) => {
  return (
    <button
      className="mt-0.5 flex space-x-1 text-action hover:text-blue-500"
      onClick={onClick}
    >
      <PencilIcon className="h-2.5 w-2.5" />
      <div className="text-sm font-semibold">Edit</div>
    </button>
  );
};

UpdateRecommendationButton.propTypes = {
  onClick: PT.func.isRequired,
};
