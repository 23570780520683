export const SCORECARD_PILLARS = {
  COST_OPTIMIZATION: {
    name: "Cost Optimization",
    pillar: "COST_OPTIMIZATION",
  },
  OPERATIONAL_EXCELLENCE: {
    name: "Operations",
    pillar: "OPERATIONAL_EXCELLENCE",
  },
  SECURITY: {
    name: "Security",
    pillar: "SECURITY",
  },
  RELIABILITY: {
    name: "Reliability",
    pillar: "RELIABILITY",
  },
  PERFORMANCE: {
    name: "Performance",
    pillar: "PERFORMANCE",
  },
};
