import {
  GET_CHECK_DETAILS,
  GET_CLOUD_SCORE_RECOMMENDATIONS,
  GET_RECOMMENDATION_DETAILS,
} from ".";
import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { CLOUD_SCORE_MESSAGES, RECOMMENDATION_STATUS } from "../constants";

export const UPDATE_SCORECARD_RECOMMENDATION = gql(/* GraphQL */ `
  mutation updateRecommendation($input: UpdateScorecardRecommendationInput!) {
    updateScorecardRecommendation(input: $input) {
      ok
      message
    }
  }
`);

export const useUpdateScorecardRecommendation = () => {
  const onCompleted = () =>
    toast.success(
      CLOUD_SCORE_MESSAGES.UPDATE_CLOUD_SCORE_RECOMMENDATION_SUCCESS
    );

  const onError = () =>
    toast.error(CLOUD_SCORE_MESSAGES.UPDATE_SCORECARD_RECOMMENDATION_FAILURE);

  const [mutate, result] = useMutation(UPDATE_SCORECARD_RECOMMENDATION, {
    onCompleted,
    onError,
  });

  const updateRecommendation = ({
    id,
    title,
    notes,
    isAllResourcesSelected,
    selectedResourceIds,
    check,
  }) => {
    return mutate({
      variables: {
        input: {
          ...(isAllResourcesSelected
            ? { excludedResources: selectedResourceIds }
            : { includedResources: selectedResourceIds }),
          notes,
          scorecardRecommendationId: id,
          title,
        },
      },
      refetchQueries: [
        {
          query: GET_RECOMMENDATION_DETAILS,
          variables: { id },
        },
        {
          query: GET_CLOUD_SCORE_RECOMMENDATIONS,
        },
        ...(check.id
          ? [
              {
                query: GET_CHECK_DETAILS,
                variables: {
                  id: check.id,
                  where: {
                    scorecardResultId: check.id,
                    scorecardRecStatus: RECOMMENDATION_STATUS.ACTIVE,
                  },
                },
              },
            ]
          : []),
      ],
    });
  };

  return [updateRecommendation, result] as const;
};
