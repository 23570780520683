import { ScorecardRecommendationStatus } from "@m/api/public/types";
import { Tooltip } from "@m/ui";

import { CheckMarkIcon, DotIcon, XIcon } from "@mc/icons";

import { RECOMMENDATION_STATUS } from "../constants";

interface Props {
  status: ScorecardRecommendationStatus;
}

export const RecommendationStatusIcon = ({ status }: Props) => {
  return (
    <Tooltip content={RECOMMENDATION_STATUS[status]} side="left">
      <div
        aria-label="Status"
        className="flex h-2.5 w-2.5 items-center justify-center"
      >
        {icons[status]}
      </div>
    </Tooltip>
  );
};

const icons = {
  [RECOMMENDATION_STATUS.RESOLVED]: (
    <CheckMarkIcon className="fill-blue-700 stroke-blue-700" />
  ),
  [RECOMMENDATION_STATUS.ARCHIVED]: <XIcon className="fill-gray-400" />,
  [RECOMMENDATION_STATUS.ACTIVE]: <DotIcon className="fill-green-500" />,
};
