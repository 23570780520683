import { NavLink } from "react-router-dom";

import { useAuth } from "@m/login";

import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

export const GitReposTabs = () => {
  const { user } = useAuth();

  const nav = ({ isActive }) =>
    isActive
      ? "text-subdued no-underline cursor-default"
      : "text-action underline";

  return (
    <div className="pb-2">
      <NavLink
        className={nav}
        to={generateCompanyPath(PATHS.STAFF_GIT_ACCESS, user.company.slug)}
      >
        Access
      </NavLink>
      <span> | </span>
      <NavLink
        className={nav}
        to={generateCompanyPath(PATHS.STAFF_GIT_CONNECTIONS, user.company.slug)}
      >
        Connections
      </NavLink>
    </div>
  );
};
