import { ServicenowAttachmentDownloadPayload } from "@m/api/public/types";
import { downloadFile } from "@m/api/utils";
import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { MESSAGES } from "../constants";

export const CREATE_ATTACHMENT_DOWNLOAD_URL = gql`
  mutation useCreateAttachmentDownloadUrl(
    $input: ServicenowAttachmentDownloadInput!
  ) {
    createServicenowAttachmentDownloadUrl(input: $input) {
      ok
      message
      url
      expires
    }
  }
`;

export const useCreateAttachmentDownloadUrl = (
  sysId: string,
  attachmentName: string,
  attachmentType: string
) => {
  const onError = (error) => {
    toast.dismiss();
    toast.error(
      error?.createServicenowAttachmentDownloadUrl?.message ||
        MESSAGES.DOWNLOAD_URL_CREATION_FAILURE
    );
  };

  const onPending = (message: string) => {
    toast.dismiss();
    toast.warning(message);
  };

  const onCompleted = (data: {
    createServicenowAttachmentDownloadUrl: ServicenowAttachmentDownloadPayload;
  }) => {
    const payload = data.createServicenowAttachmentDownloadUrl;
    if (payload?.pending) return onPending(payload.message);
    if (!payload?.ok) return onError(data);
    downloadFile(payload.url, attachmentName, attachmentType);
  };

  return useMutation(CREATE_ATTACHMENT_DOWNLOAD_URL, {
    variables: { input: { sysId } },
    onError,
    onCompleted,
  });
};
