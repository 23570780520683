import { useCallback, useState } from "react";

export const useUpdateConfigurationProps = () => {
  const [selectedConfigIds, setSelectedConfigIds] = useState(new Set());
  const [selectedConfigDirty, setSelectedConfigDirty] = useState(false);

  const selectRow = useCallback(
    (rowId) => setSelectedConfigIds((prev) => new Set(prev).add(rowId)),
    []
  );

  const selectInitialRows = useCallback(
    (rowIds) => {
      if (!selectedConfigDirty) {
        rowIds.forEach((row) => {
          if (row.included && row.description !== "Warning") {
            selectRow(row.account.databaseId);
          }
        });
        setSelectedConfigDirty(true);
      }
    },
    [selectedConfigDirty, selectRow]
  );

  const deselectRow = useCallback(
    (rowId) =>
      setSelectedConfigIds((prev) => {
        prev.delete(rowId);
        return new Set(prev);
      }),
    []
  );

  const toggleSelectAll = useCallback(
    (rowIds) => {
      rowIds.forEach((row) => {
        if (row.description !== "Warning") {
          selectRow(row.account.databaseId);
        }
      });
    },
    [selectRow]
  );

  const toggleSelectNone = useCallback(() => {
    setSelectedConfigIds(new Set());
  }, []);

  const toggleRowSelected = useCallback(
    (rowId, checked) => {
      let isSelect = checked;

      if (!isSelect) selectRow(rowId);
      else deselectRow(rowId);
    },
    [deselectRow, selectRow]
  );

  return {
    selectedRowIds: Array.from(selectedConfigIds),
    selectInitialRows,
    showSelectAll: true,
    toggleSelectAll,
    toggleSelectNone,
    toggleRowSelected,
  };
};
