import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

interface ChangeIndicatorProps {
  change: number;
  flipStatus?: boolean;
  showIcon?: boolean;
  showZero?: boolean;
  size?: "default" | "small";
  [key: string]: any;
}

export const ChangeIndicator = ({
  change,
  flipStatus = false,
  showIcon = true,
  showZero = false,
  size = "default",
  ...props
}: ChangeIndicatorProps) => {
  const isZero = change === 0;

  if ((isZero && !showZero) || change === undefined || change === null)
    return null;

  let isPositive = change > 0;
  if (flipStatus) isPositive = !isPositive;

  const direction = change > 0 ? DIRECTIONS.UP : DIRECTIONS.DOWN;

  const green = clsx("text-green-500", { "bg-green-50": showIcon });
  const red = clsx("text-red-500", { "bg-red-50": showIcon });

  let changeIcon =
    direction === DIRECTIONS.UP ? (
      <ArrowTrendingUpIcon
        aria-label="Trending Up Icon"
        className={clsx(getSizes(size).icon)}
      />
    ) : (
      <ArrowTrendingDownIcon
        aria-label="Trending Down Icon"
        className={clsx(getSizes(size).icon)}
      />
    );
  if (isZero && showZero) changeIcon = null;

  const changeText =
    direction === DIRECTIONS.UP ? "+" + change.toString() : change.toString();

  return (
    <div
      className={clsx(
        "flex w-min items-center gap-[4px] rounded-full px-1 py-0.5",
        { [green]: isPositive && !isZero },
        { [red]: !isPositive && !isZero },
        { "text-subdued": isZero }
      )}
      {...props}
    >
      {showIcon && changeIcon}
      <div className={clsx(getSizes(size).text)}>{changeText}</div>
    </div>
  );
};

const DIRECTIONS = {
  UP: "up",
  DOWN: "down",
};

const getSizes = (size) => {
  const sizes = {
    small: {
      icon: "w-2 h-2",
      text: "text-sm",
    },
    default: {
      icon: "w-3 h-3",
      text: "text-base",
    },
  };
  return sizes[size] || sizes.default;
};
