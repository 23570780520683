import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import PT from "prop-types";

import { getPaginationInfo } from "../utils";

export const TableFooter = ({
  currentPage = 1,
  fetchMore,
  pageInfo,
  pageSize = 25,
  setCurrentPage,
  totalCount,
}) => {
  if (totalCount === 0) return null;

  const {
    pageCount,
    lastPageTotal,
    hasNext,
    hasPrevious,
    firstItem,
    lastItem,
  } = getPaginationInfo(currentPage, pageSize, totalCount);

  const gotoFirstPage = async () => {
    await fetchMore({
      variables: {
        first: pageSize,
        after: null,
        last: null,
        before: null,
      },
    });
    setCurrentPage(1);
  };
  const gotoFirstPageTitle = "Go to First Page";

  const gotoPreviousPage = async () => {
    await fetchMore({
      variables: {
        first: null,
        after: null,
        last: pageSize,
        before: pageInfo.startCursor,
      },
    });
    setCurrentPage(currentPage - 1);
  };
  const gotoPreviousPageTitle = "Go to Previous Page";

  const gotoNextPage = async () => {
    await fetchMore({
      variables: {
        first: pageSize,
        after: pageInfo.endCursor,
        last: null,
        before: null,
      },
    });
    setCurrentPage(currentPage + 1);
  };
  const gotoNextPageTitle = "Go to Next Page";

  const gotoLastPage = async () => {
    await fetchMore({
      variables: {
        first: null,
        after: null,
        last: lastPageTotal,
        before: null,
      },
    });
    setCurrentPage(pageCount);
  };
  const gotoLastPageTitle = "Go to Last Page";

  const arrow = "h-2 w-2";
  const enabled = "text-action";
  const disabled = "text-action opacity-50";

  const paginationButtons = (
    <div className="flex items-center space-x-1">
      <button
        aria-label={gotoFirstPageTitle}
        className={hasPrevious ? enabled : disabled}
        disabled={!hasPrevious}
        onClick={gotoFirstPage}
        title={gotoFirstPageTitle}
      >
        <ChevronDoubleLeftIcon className={arrow} />
      </button>

      <button
        aria-label={gotoPreviousPageTitle}
        className={hasPrevious ? enabled : disabled}
        disabled={!hasPrevious}
        onClick={gotoPreviousPage}
        title={gotoPreviousPageTitle}
      >
        <ChevronLeftIcon className={arrow} />
      </button>

      <span aria-label="Page Number">{`Page ${currentPage} of ${pageCount}`}</span>

      <button
        aria-label={gotoNextPageTitle}
        className={hasNext ? enabled : disabled}
        disabled={!hasNext}
        onClick={gotoNextPage}
        title={gotoNextPageTitle}
      >
        <ChevronRightIcon className={arrow} />
      </button>

      <button
        aria-label={gotoLastPageTitle}
        className={hasNext ? enabled : disabled}
        disabled={!hasNext}
        onClick={gotoLastPage}
        title={gotoLastPageTitle}
      >
        <ChevronDoubleRightIcon className={arrow} />
      </button>
    </div>
  );

  return (
    <div className="flex justify-between border-t p-2 text-xs text-subdued">
      <div>{pageCount > 1 && paginationButtons}</div>
      <span aria-label="Item Count">{`${firstItem} - ${lastItem} of ${totalCount} total`}</span>
    </div>
  );
};

TableFooter.propTypes = {
  currentPage: PT.number,
  fetchMore: PT.func,
  pageInfo: PT.object,
  pageSize: PT.number,
  setCurrentPage: PT.func,
  tableTheme: PT.string,
  totalCount: PT.number,
};
