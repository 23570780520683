import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { EKS_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export const GET_EKS_CLUSTER_SEARCH_TERMS = gql(/* GraphQL */ `
  query EksClusterSearchTerms($searchTerm: String) {
    eksClusterSearchTerms(searchTerm: $searchTerm) {
      searchTerms
    }
  }
`);

export const useEksClusterSearchTerms = (searchTerm: string) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(EKS_LABEL));

  const { data, loading, error, refetch } = useQuery(
    GET_EKS_CLUSTER_SEARCH_TERMS,
    {
      variables: { searchTerm },
      onError,
    }
  );

  const searchTerms = data?.eksClusterSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    error,
    loading,
    refetch,
  };
};
