import { CaseWaitingOn } from "@m/api/public/types";
import { Badge } from "@m/ui";

export const CaseWaitingOnBadge = ({
  waitingOn,
}: {
  waitingOn?: CaseWaitingOn;
}) => {
  let badgeText = "";
  let isCustomerActionNeeded = false;

  switch (waitingOn) {
    case CaseWaitingOn.CustomerHold:
      badgeText = "On Hold";
      break;
    case CaseWaitingOn.MaintenanceWindow:
      badgeText = "Waiting on Maintenance Window";
      break;
    case CaseWaitingOn.MajorCaseResolution:
      badgeText = "Waiting on Major Case";
      break;
    case CaseWaitingOn.VendorResponse:
      badgeText = "Waiting on Vendor Response";
      break;
    default:
      badgeText = "Response Needed";
      isCustomerActionNeeded = true;
      break;
  }

  return (
    <div className="flex">
      <Badge
        className="h-fit justify-self-center"
        status={isCustomerActionNeeded ? "warning" : null}
        size="small"
        strong={isCustomerActionNeeded}
        label={badgeText}
      />
    </div>
  );
};
