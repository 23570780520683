import { ActivityFeed } from "@mc/features/ActivityFeed";

import { useDashboardActivityStream } from "../api";

export const DashboardActivityFeed = () => {
  const {
    data: { activities },
    loading,
    error,
  } = useDashboardActivityStream();

  return (
    <ActivityFeed activities={activities} loading={loading} error={error} />
  );
};
