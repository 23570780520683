import { gql, useQuery } from "@mc/utils/graphql";

export const GET_SCORECARD_SCORE = gql`
  query useScorecardScore {
    scorecards(first: 1) {
      edges {
        node {
          score
        }
      }
    }
  }
`;

export const useScorecardScore = () => {
  const { data, loading, error } = useQuery(GET_SCORECARD_SCORE);

  const scorecard = data?.scorecards?.edges.map((edge) => edge.node)[0] || {};

  return {
    data: {
      score: scorecard?.score || 0,
    },
    loading,
    error,
  };
};
