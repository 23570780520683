import clsx from "clsx";
import { ReactNode, useState } from "react";

interface SuspensedProps {
  children?: ReactNode;
  className?: string;
  height?: string;
  loading?: boolean;
  width?: string;
  _DEBUG_CLICK_PREVIEW?: boolean;
}

export const Suspensed = ({
  children = null,
  className,
  height = "2rem",
  loading,
  width = "100%",
  _DEBUG_CLICK_PREVIEW = true,
}: SuspensedProps): JSX.Element => {
  const [showChildrenTemporarily, setShowChildrenTemporarily] = useState(false);
  const style = { width, height };

  const _DEBUG_TOGGLE_CLICK_PREVIEW = () => {
    if (process.env.NODE_ENV === "development" && _DEBUG_CLICK_PREVIEW) {
      setShowChildrenTemporarily(true);
      setTimeout(() => setShowChildrenTemporarily(false), 1000);
    }
  };

  const classes = clsx(
    "h-full w-full animate-pulse bg-gray-100 rounded",
    className
  );

  return (
    <>
      {loading && !showChildrenTemporarily ? (
        <div
          aria-label="content loading"
          aria-busy="true"
          aria-live="polite"
          className={classes}
          style={style}
          onClick={_DEBUG_TOGGLE_CLICK_PREVIEW}
        />
      ) : (
        children
      )}
    </>
  );
};
