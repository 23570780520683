import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const REDSHIFT_LABEL = AWS_RESOURCES_LABELS.redshiftClusters;

export const TABLE_HEADERS_REDSHIFT_CLUSTERS = [
  { label: "Name", accessor: "clusterName", sort: "CLUSTER_NAME" },
  { label: "Account Name", accessor: "accountName", sort: "ACCOUNT" },
  { label: "Account ID", accessor: "accountId", sort: "ACCOUNT_ID" },
  { label: "Node Type", accessor: "nodeType", sort: "NODE_TYPE" },
  { label: "Status", accessor: "status", sort: "STATUS" },
  { label: "Zone", accessor: "availabilityZone", sort: "AVAILABILITY_ZONE" },
  { label: "Region", accessor: "region", sort: "REGION" },
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  { label: "Tags", accessor: "tags", sort: "TAGS" },
];
