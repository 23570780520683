import validator from "email-validator";
import PT from "prop-types";
import { useState } from "react";

import { Button, MissionLogo, Modal, toast } from "@m/ui";

import { batchUpdate } from "@mc/utils/graphql";

import { useSendEmailInvite } from "../api";
import { MESSAGES } from "../constants";

import { InviteUsersList } from "./InviteUsersList";
import { TagInputField } from "./TagInputField";

const InviteMessage = ({ numInvites = 0 }) => {
  let msg;

  if (numInvites < 1) msg = "Invite users";
  else if (numInvites === 1) msg = "Invite user";
  else if (numInvites > 1) msg = `Invite ${numInvites} users`;

  return <>{msg}</>;
};

export const InviteUsersModal = ({ open, onClose, users }) => {
  const [sendEmailInvite, { loading }] = useSendEmailInvite();

  const [email, setEmail] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [tags, setTags] = useState(new Set());

  const isValidEmail = validator.validate(email);
  const includesUntaggedEmail = isValidEmail && !tags.has(email);

  const resetSelections = () => {
    setSelectedUsers(new Set());
    setTags(new Set());
    setEmail("");
  };

  const addUser = (user) => {
    if (tags.has(user.email)) return;

    setSelectedUsers((prevUsers) => new Set(prevUsers).add(user));
  };

  const addTag = (tag) => setTags((prev) => new Set(prev).add(tag));

  const removeTag = (tag) => {
    setTags((prevTags) => {
      prevTags.delete(tag);
      return new Set(prevTags);
    });
  };

  const removeUser = (user) => {
    setSelectedUsers((prevUsers) => {
      prevUsers.delete(user);
      return new Set(prevUsers);
    });
  };

  const addSelection = (selectedUser) => {
    addTag(selectedUser.email);
    addUser(selectedUser);
  };

  const removeSelection = (selectedUser) => {
    removeUser(selectedUser);
    removeTag(selectedUser.email);
  };

  const handleEnter = () => {
    if (!isValidEmail) return;
    const listedUser = users.find((user) => user.email === email);
    if (listedUser) addSelection(listedUser);
    else addTag(email);
    setEmail("");
  };

  const handleRemoveEmail = (email) => {
    const selectedUser = [...selectedUsers].find(
      (user) => user.email === email
    );
    if (selectedUser) return removeSelection(selectedUser);
    removeTag(email);
  };

  const closeModal = () => {
    resetSelections();
    onClose();
  };

  const isDisabled = () => {
    if (loading) return true;
    if (email.length > 0 && isValidEmail) return false;
    if (email.length < 1 && tags.size > 0) return false;
    return true;
  };

  const sendInvites = async () => {
    const taggedEmails = includesUntaggedEmail ? [...tags, email] : [...tags];

    const responses = await batchUpdate(taggedEmails, sendEmailInvite);

    const failed = responses.filter(
      (response) => response.status === "rejected"
    );

    if (failed.length > 0) toast.error(MESSAGES.SEND_EMAIL_INVITES_ERROR);

    closeModal();
  };

  const listedEmails = includesUntaggedEmail ? tags.size + 1 : tags.size;

  return (
    <Modal open={open} onClose={closeModal}>
      <div className="space-y-3 text-center">
        <div className="flex items-center justify-center">
          <MissionLogo logo="mark" className="h-5" />
        </div>
        <div className="space-y-1">
          <Modal.Title className="text-xl font-semibold text-default">
            Invite your team to <br /> Mission Control
          </Modal.Title>
          <Modal.Description className="text-sm font-medium text-subdued">
            Select or add team members to give access
          </Modal.Description>
        </div>
        <TagInputField
          inputValue={email}
          onEnter={handleEnter}
          removeTag={handleRemoveEmail}
          selectedUsers={selectedUsers}
          setInput={setEmail}
          tags={tags}
        />
        {users.length > 0 && (
          <InviteUsersList
            addSelection={addSelection}
            removeSelection={removeSelection}
            selectedUsers={selectedUsers}
            users={users}
          />
        )}
        <Modal.Actions className="mt-3">
          <Button
            disabled={isDisabled()}
            kind="primary"
            onClick={sendInvites}
            aria-label="Send Email Invite"
          >
            <InviteMessage numInvites={listedEmails} />
          </Button>
          <Button onClick={closeModal} kind="primary" fill="none">
            Cancel
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};

InviteUsersModal.propTypes = {
  open: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  users: PT.array.isRequired,
};
