import clsx from "clsx";

interface Props {
  criticalChecks: number;
  okChecks: number;
  totalChecks: number;
  warningChecks: number;
  height?: "small" | "large";
}

export const ScoreGaugeLinear = ({
  criticalChecks,
  okChecks,
  totalChecks,
  warningChecks,
  height = "small",
}: Props) => {
  let criticalValue = getColorWidth(criticalChecks, totalChecks);
  let warningValue = getColorWidth(warningChecks, totalChecks);
  const okValue = getColorWidth(okChecks, totalChecks);

  if (criticalValue > 0 && criticalValue < 1) criticalValue = 1;
  if (warningValue > 0 && warningValue < 1) warningValue = 1;

  const heightClasses = {
    "h-0.5": height === "small",
    "h-1.5": height === "large",
  };

  return (
    <div
      data-testid="score-gauge-linear"
      className="flex justify-between space-x-0.5"
    >
      {criticalChecks > 0 && (
        <div
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={criticalValue}
          className={clsx("rounded-lg bg-status-error", heightClasses)}
          data-testid="critical-checks-meter"
          role="meter"
          style={{ width: `${criticalValue}%` }}
        />
      )}
      {warningChecks > 0 && (
        <div
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={warningValue}
          className={clsx("rounded-lg bg-status-warning", heightClasses)}
          data-testid="warning-checks-meter"
          role="meter"
          style={{ width: `${warningValue}%` }}
        />
      )}
      {okChecks > 0 && (
        <div
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={okValue}
          className={clsx("rounded-lg bg-status-good", heightClasses)}
          data-testid="optimized-checks-meter"
          role="meter"
          style={{ width: `${okValue}%` }}
        />
      )}
    </div>
  );
};

const getColorWidth = (numerator: number, denominator: number): number => {
  const numFloat = (numerator / denominator) * 100;
  return Math.floor(numFloat);
};
