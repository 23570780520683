import { ChangeEventHandler, useEffect, useState } from "react";

import { Field, Select } from "@m/ui";

import {
  CASE_CATEGORY_DEFINITIONS,
  CaseCategoryDefinition,
} from "../constants";
import { CaseType } from "../types";

import { ServiceLimitInfoBox } from "./ServiceLimitInfoBox";

export const CategorySelector = ({ caseType }: { caseType: CaseType }) => {
  const categories: CaseCategoryDefinition[] =
    CASE_CATEGORY_DEFINITIONS[caseType];

  const [categoryName, setCategoryName] = useState<string>(categories[0]?.name);

  useEffect(() => setCategoryName(categories[0]?.name), [categories]);

  const categoryDefinition: CaseCategoryDefinition =
    categories.find((category) => category.name === categoryName) ||
    categories[0];

  const subcategories = categoryDefinition?.subcategories ?? [];

  const handleChangeCategory: ChangeEventHandler<HTMLSelectElement> = ({
    currentTarget: { options, selectedIndex },
  }) => setCategoryName(options[selectedIndex].text);

  if (categories.length === 1 && subcategories.length === 1)
    return (
      <>
        <select name="categorySelect" className="hidden">
          <option value={categories[0].value} />
        </select>
        <select name="subcategorySelect" className="hidden">
          <option value={subcategories[0].value} />
        </select>
      </>
    );

  return (
    <Field htmlFor="category" label="Category" row="2/3" rowAlign="top">
      <div className="flex flex-col gap-1">
        {categories.length > 1 ? (
          <Select
            id="category"
            name="categorySelect"
            onChange={handleChangeCategory}
            options={categories.map(({ name, value }) => (
              <option key={name} value={value}>
                {name}
              </option>
            ))}
          />
        ) : (
          <select id="category" name="categorySelect" className="hidden">
            <option value={categories[0].value}>{categories[0].name}</option>
          </select>
        )}

        {subcategories.length > 0 && (
          <Select
            aria-label="Subcategory"
            name="subcategorySelect"
            options={subcategories.map(({ name, value }) => (
              <option key={`${categoryName}:${name}`} value={value}>
                {name}
              </option>
            ))}
          />
        )}

        {caseType === CaseType.Account &&
          categoryName === "Service Quota Increase" && <ServiceLimitInfoBox />}
      </div>
    </Field>
  );
};
