import clsx from "clsx";
import { useContext, useState } from "react";

import { ActivityContext } from "../contexts";
import { Activity } from "../types";

interface ActivityLineProps {
  lineWidth?: number;
  activity: Activity;
  index: number;
  x: number;
  y: number;
}

export const ActivityLine = ({
  lineWidth = 8,
  activity,
  index,
  x,
  y,
}: ActivityLineProps) => {
  const [svgElement, setSvgElement] = useState<Element | null>(null);
  const [hover, setHover] = useState(false);

  const {
    setActivity,
    setActivityLineElement,
    pinnedActivityIndex,
    setPinnedActivityIndex,
  } = useContext(ActivityContext);

  const isPinned = pinnedActivityIndex === index;
  const isPinActive = pinnedActivityIndex !== null;
  const popoverDisabled = isPinActive;

  const openPopover = () => {
    setActivityLineElement(svgElement);
    setActivity(activity);
  };

  const closePopover = () => {
    setActivityLineElement(null);
    setActivity(null);
  };

  const handleClick = () => {
    if (isPinned) setPinnedActivityIndex(null);
    else {
      setPinnedActivityIndex(index);
      openPopover();
    }
  };

  const handleMouseEnter = () => {
    setHover(true);
    if (!popoverDisabled) openPopover();
  };

  const handleMouseLeave = () => {
    setHover(false);
    if (!popoverDisabled) closePopover();
  };

  return (
    <g
      className="cursor-pointer"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={setSvgElement}
    >
      <rect
        x={x - lineWidth / 2}
        width={lineWidth}
        height={y}
        fill="transparent"
      />
      <path
        className={clsx({
          "stroke-orange-300": !hover && !isPinned && popoverDisabled,
          "stroke-orange-500": !hover && !isPinned,
          "stroke-orange-800": hover && !isPinned,
          "stroke-blue-600": isPinned,
        })}
        d={`M ${x},0 V ${y} `}
        strokeOpacity="0.75"
        strokeDasharray="3 3"
      />
    </g>
  );
};
