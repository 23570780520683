import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemProjectStart: EngagementActivityFeedComponent =
  ({ timestamp, model: engagement }) => {
    return (
      <EngagementActivityFeedItem timestamp={timestamp}>
        <div>
          <span className="font-semibold">{engagement?.title}</span> was shared
          with you
        </div>
      </EngagementActivityFeedItem>
    );
  };
