import { useCallback } from "react";

import { IndeterminateCheckbox } from "../components";
import { TABLE_TITLES } from "../constants";

export const useSelectRowCheckbox = ({
  isAllRowsSelected,
  manualRowIdKey,
  selectedRowIds,
  toggleRowSelected,
  toggleRowSelectedTitle = TABLE_TITLES.TOGGLE_ROW_SELECTED,
}) => {
  const renderSelectRowCheckbox = useCallback(
    ({ row }) => {
      const isSelected =
        selectedRowIds && selectedRowIds.includes(row.original[manualRowIdKey]);

      let checked = isSelected;
      if (isAllRowsSelected) checked = !checked;

      return (
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps({
            onChange: (e) => {
              const { checked } = e.target;
              row.toggleRowSelected(checked);
              toggleRowSelected(row.original[manualRowIdKey], checked);
            },
            checked,
            title: toggleRowSelectedTitle,
          })}
        />
      );
    },
    [
      isAllRowsSelected,
      manualRowIdKey,
      selectedRowIds,
      toggleRowSelected,
      toggleRowSelectedTitle,
    ]
  );

  return {
    renderSelectRowCheckbox,
  };
};
