import {
  RiskLogItemImpact,
  RiskLogItemOwner,
  RiskLogItemProbability,
  RiskLogItemType,
} from "@m/api/public/types";

import { IRiskLogItem } from "../types";

export const TABLE_HEADERS_RISK_LOG = [
  { accessor: "status", width: 24, cellClasses: "px-1" },
  { accessor: "riskSummary", cellClasses: "px-1" },
  { accessor: "impact", width: 24 },
  {
    accessor: "probability",
    width: 24,
  },
  {
    accessor: "dateNeeded",
    width: 140,
    cellClasses: "text-right",
  },
  { accessor: "owner", width: 24 },
  { accessor: "details", width: 10 },
];

export const DATE_FORMAT_RISK_DETAILS = "LL/dd/yyyy";
export const DATE_FORMAT_RISK_LOG = "LLL d, yyyy";
export const DEFAULT_SORT_RISK_LOG = "DATE_NEEDED_ASC";

export enum ENGAGEMENTS_RISK_LOG_ITEM_STATUS {
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
  ARCHIVED = "ARCHIVED",
}

export enum ENGAGEMENTS_RISK_LOG_ITEM_SEVERITY {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum ENGAGEMENTS_RISK_LOG_ITEM_TYPE {
  BUDGET = "BUDGET",
  TECHNICAL = "TECHNICAL",
  SCHEDULE = "SCHEDULE",
}

export const DEFAULT_INITIAL_RISK_LOG_ITEM: Partial<IRiskLogItem> = {
  owner: RiskLogItemOwner.Mission,
  impact: RiskLogItemImpact.Low,
  itemType: RiskLogItemType.Schedule,
  probability: RiskLogItemProbability.Low,
};

export const MESSAGES_ENGAGEMENTS_RISK_LOG = {
  EMPTY_RISK_LOG: "No Risks found",
  EMPTY_RISK_LOG_ITEM: "Unable to find Risk Log data",
  GET_ITEMS_ERROR: "Unable to access Risk Log data",
  NO_RISK_DETAILS: "No risk details",
  SUCCESS_CREATING_RISK_LOG_ITEM: "Successfully created Risk Log item",
  ERROR_CREATING_RISK_LOG_ITEM: "Unable to create Risk Log item",
  SUCCESS_UPDATING_RISK_LOG: "Successfully updated Risk Log item",
  ERROR_UPDATING_RISK_LOG: "Unable to update Risk Log item",
};
