import { useMemo } from "react";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";
import { dt } from "@m/utils";

import {
  ACTIVITY_FEED_MESSAGES,
  ACTIVITY_STREAMS,
} from "@mc/features/ActivityFeed/constants";
import { useQuery } from "@mc/utils/graphql";

export const GET_SCORECARD_ACTIVITY_STREAM = gql(`
  query ScorecardActivityStream(
    $activityStreamFilter: ActivityStreamFilter
    $scorecardFilter: ScorecardFilter
    $first: Int
    $after: String
  ) {
    activityStream(where: $activityStreamFilter, first: $first, after: $after) {
      edges {
        node {
          __typename
          ... on ScorecardChangeActivityStream {
            id
            timestamp
            operation
            changes
            name
            pillar
            latestResult {
              id
            }
          }
          ... on ScorecardIgnoredCheckActivityStream {
            id
            checkDefinitionName
            timestamp
            operation
            actor {
              name
              firstName
              lastName
              email
              id
            }
            latestResult {
              pillar
              id
            }
          }
        }
      }
    }
    scorecards(where: $scorecardFilter) {
      edges {
        node {
          id
          created
          score
        }
      }
    }
  }
`);

export const useScorecardActivityStream = () => {
  const onError = () =>
    toast.error(ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_FAILURE);

  const cutoff = useMemo(() => dt.utc().minus({ days: 30 }).toISO(), []);

  const streamTypes = [
    ACTIVITY_STREAMS.ScorecardChangeActivityStream,
    ACTIVITY_STREAMS.ScorecardIgnoredCheckActivityStream,
  ].map((stream) => stream.type);

  const { data, loading, error } = useQuery(GET_SCORECARD_ACTIVITY_STREAM, {
    variables: {
      activityStreamFilter: {
        timestampGt: cutoff,
        typeIn: streamTypes,
      },
      scorecardFilter: {
        createdGt: cutoff,
      },
    },
    onError,
  });

  const activities =
    data?.activityStream?.edges?.map((edge) => edge.node) || [];

  const scorecards = data?.scorecards?.edges?.map((edge) => edge.node) || [];

  return {
    data: { activities, scorecards },
    loading,
    error: error !== undefined,
  };
};
