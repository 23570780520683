import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST = gql(/* GraphQL */ `
  mutation CreateAwsAccountOnboardingRequest(
    $input: CreateAwsAccountOnboardingRequestInput!
  ) {
    createAwsAccountOnboardingRequest(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateAwsAccountOnboardingRequest = (
  awsAccountId: string,
  requestedIamRoles: string[],
  awsAccountName?: string
) => {
  const [createRequest, { data, ...result }] = useMutation(
    CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST,
    {
      variables: {
        input: {
          awsAccountId,
          requestedIamRoles,
          awsAccountName,
        },
      },
      onCompleted: () =>
        toast.success(`Successfully onboarded AWS account ${awsAccountId}`),
      onError: (err) =>
        toast.error(
          err?.message?.includes("exists")
            ? `The account with id ${awsAccountId} already exists`
            : `Unable to create onboarding request for account id ${awsAccountId}`
        ),
    }
  );

  const requestCreated = data?.createAwsAccountOnboardingRequest?.ok || false;

  return [createRequest, { data: requestCreated, ...result }] as const;
};
