import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const ELASTICACHE_LABEL = AWS_RESOURCES_LABELS.elasticacheClusters;

export const TABLE_HEADERS_ELASTICACHE_CLUSTERS = [
  { label: "Name", accessor: "cacheClusterName", sort: "CACHE_CLUSTER_NAME" },
  { label: "Account Name", accessor: "accountName", sort: "ACCOUNT" },
  { label: "Account ID", accessor: "accountId" },
  { label: "Engine", accessor: "engine", sort: "ENGINE" },
  { label: "Node Type", accessor: "nodeType", sort: "NODE_TYPE" },
  { label: "Region", accessor: "region", sort: "REGION" },
  { label: "Zone", accessor: "availabilityZone" }, // todo: add backend support for sorting by zone
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  { label: "Tags", accessor: "tags", sort: "TAGS" },
];
