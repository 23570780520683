import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { GET_RESOURCES_FAILURE, LAMBDA_LABEL } from "../constants";

export const GET_LAMBDA_SEARCH_TERMS = gql(/* GraphQL */ `
  query LambdaFunctionsSearchTerms($searchTerm: String) {
    lambdaFunctionsSearchTerms(searchTerm: $searchTerm) {
      searchTerms
    }
  }
`);

export const useLambdaSearchTerms = (searchTerm: string) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(LAMBDA_LABEL));

  const { data, loading, error, refetch } = useQuery(GET_LAMBDA_SEARCH_TERMS, {
    variables: { searchTerm },
    onError,
  });

  const searchTerms = data?.lambdaFunctionsSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    error,
    loading,
    refetch,
  };
};
