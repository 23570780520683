import { toast } from "@m/ui";

import { gql, useMutation } from "@mc/utils/graphql";

import { GET_CUSTOMER_REPOS } from "./useCustomerRepositories";

export const REVOKE_ACCESS_FAILURE =
  "We failed to revoke access from this repository.";

export const REVOKE_ACCESS = gql`
  mutation useRevokeAccess($input: RemoveCollaboratorFromGitHubRepoInput!) {
    removeCollaboratorFromGitHubRepo(input: $input) {
      ok
      message
    }
  }
`;

export function useRevokeAccess() {
  const onError = () => {
    toast.error(REVOKE_ACCESS_FAILURE);
  };

  const [mutate, { loading }] = useMutation(REVOKE_ACCESS, {
    context: { clientName: "v4" },
    onError,
    refetchQueries: [{ query: GET_CUSTOMER_REPOS }],
  });

  const revokeAccess = (employee, repository) => {
    const msg = `Revoke access to ${repository.name} from ${employee.name}?`;
    if (window.confirm(msg)) {
      mutate({
        variables: {
          input: {
            userId: employee.id,
            repoId: repository.id,
          },
        },
      });
    }
  };

  return [revokeAccess, { loading }];
}
