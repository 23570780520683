import { EmptyState } from "@mc/components/EmptyState";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";

import { CloudScoreNoAccessIcon } from "../icons";

export const CloudScoreIneligiblePage = () => {
  return (
    <Page title={<PageTitle title="Cloud Score" padding="small" />}>
      <EmptyState
        image={<CloudScoreNoAccessIcon width={300} height={220} />}
        title="AWS Business Support or higher is required."
        description="One or more of your AWS Accounts must have AWS Business Support or higher in order to be eligible for Cloud Score."
      />
    </Page>
  );
};
