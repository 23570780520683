import { useNavigate, useParams } from "react-router-dom";

import { Modal, Spinner } from "@m/ui";
import { toProperCase } from "@m/utils";

import { useEngagementDetailItem } from "../api";
import { EngagementsDetailItemForm } from "../components";
import { DETAIL_ITEM_TYPE } from "../constants";

export const EngagementsDetailItemEditModal = ({
  detailType,
}: {
  detailType: DETAIL_ITEM_TYPE;
}) => {
  const navigate = useNavigate();

  const { engagementId, milestoneId, actionItemId, riskId, decisionId } =
    useParams<{
      engagementId: string;
      milestoneId: string;
      actionItemId: string;
      riskId: string;
      decisionId: string;
    }>();

  let itemId;
  switch (detailType) {
    case DETAIL_ITEM_TYPE.MILESTONE:
      itemId = milestoneId;
      break;
    case DETAIL_ITEM_TYPE.ACTION_ITEM:
      itemId = actionItemId;
      break;
    case DETAIL_ITEM_TYPE.RISK:
      itemId = riskId;
      break;
    case DETAIL_ITEM_TYPE.DECISION:
      itemId = decisionId;
      break;
    default:
      break;
  }

  const handleClose = () => navigate(`..`);

  const {
    data: { detailItem },
    loading: isLoading,
  } = useEngagementDetailItem(engagementId, {
    detailType,
    itemId,
  });

  return (
    <Modal
      open={true}
      size="lg"
      header={
        <Modal.Title className="pb-1 text-center font-semibold">
          Edit {toProperCase(detailType)}
        </Modal.Title>
      }
      onClose={handleClose}
      aria-label="Engagement Detail Item Edit Modal"
    >
      {isLoading ? (
        <Spinner className="m-auto" />
      ) : (
        <EngagementsDetailItemForm
          detailType={detailType}
          detailItem={detailItem}
        />
      )}
    </Modal>
  );
};
