import clsx from "clsx";
import { useMemo } from "react";

import { AwsAccount, AwsAccountOnboardingStatus } from "@m/api/public/types";
import { Badge, Button, Table } from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";
import { sortNameAsc } from "@mc/utils/sort";

import { useAwsAccounts } from "../api";
import { AccountBanner, AccountConsole, AccountStatus } from "../components";

export const AccountsPage = () => {
  const {
    loading,
    data: { accounts },
  } = useAwsAccounts();

  const rows = useMemo(() => {
    return accounts
      .sort(sortByAccountName) // first, sort accounts by name
      .sort((a, b) => Number(isPayerAccount(b)) - Number(isPayerAccount(a))) // move payer account(s) to top of list
      .sort((a, b) => Number(isOnboarded(b)) - Number(isOnboarded(a))) // move accounts that need onboarding to bottom of list
      .map((account) => ({
        accountId: (
          <AccountId
            accountId={account.databaseId}
            isOnboarded={isOnboarded(account)}
          />
        ),
        accountName: (
          <AccountName
            accountId={account.databaseId}
            accountName={account.displayName || account.name}
            isOnboarded={isOnboarded(account)}
            isPayerAccount={isPayerAccount(account)}
          />
        ),
        status: (
          <AccountStatus
            awsAccountId={account.databaseId}
            isOnboarded={isOnboarded(account)}
            isSyncing={account.isSyncing}
            lastCompletedSynced={account.lastCompletedSynced}
            syncDisableReasonString={account.syncDisableReasonString}
            syncsEnabled={account.syncsEnabled}
          />
        ),
        console: <AccountConsole awsAccountId={account.databaseId} />,
      }));
  }, [accounts]);

  return (
    <Page data-testid="accounts-page" className="flex flex-col">
      <PageHeading
        heading="AWS Accounts"
        actions={
          <AccessRequired
            feature={FEATURE_FLAGS.ACCOUNTS_ALLOW_ADD_AWS_ACCOUNT}
            mutation={MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST}
          >
            <Button
              to={PATHS.SETTINGS_ACCOUNTS_ADD}
              fill="solid"
              kind="primary"
              size="small"
            >
              Add New Account
            </Button>
          </AccessRequired>
        }
      />
      <AccountBanner />
      <Table
        headers={TABLE_HEADERS_AWS_ACCOUNTS}
        loading={loading}
        rows={rows}
        showHeader={false}
      />
    </Page>
  );
};

const AccountId = ({ accountId, isOnboarded }) => (
  <div
    className={clsx("text-sm font-semibold text-subdued", {
      "opacity-40": !isOnboarded,
    })}
  >
    {accountId}
  </div>
);

const AccountName = ({
  accountName,
  accountId,
  isOnboarded,
  isPayerAccount,
}) => {
  const displayName = accountName === accountId ? "-" : accountName;

  return (
    <div className="flex items-center gap-2">
      <div
        className={clsx("text-sm font-semibold text-default", {
          "opacity-40": !isOnboarded,
        })}
      >
        {displayName}
      </div>
      {isPayerAccount && (
        <Badge
          label="Payer Account"
          status="positive"
          size="small"
          strong={false}
        />
      )}
    </div>
  );
};

const TABLE_HEADERS_AWS_ACCOUNTS = [
  { label: "Account ID", accessor: "accountId" },
  { label: "Account Name", accessor: "accountName", width: "100%" },
  {
    label: "Status",
    accessor: "status",
    width: 300,
  },
  {
    label: "Console",
    accessor: "console",
    width: 150,
  },
];

const isPayerAccount = (
  account: Pick<AwsAccount, "databaseId" | "payerAccountId">
): boolean => {
  const { payerAccountId, databaseId } = account;

  return payerAccountId === databaseId;
};

const isOnboarded = (
  account: Pick<AwsAccount, "awsAccountOnboardingStatus">
): boolean => {
  const { awsAccountOnboardingStatus } = account;

  // AWS accounts onboarded prior to Cloud Gateway release will have an onboarding status
  // of null. We will consider these accounts onboarded until we can finish migrating all
  // legacy AWS accounts to the new awsAccountOnboardingStatus
  return (
    awsAccountOnboardingStatus === AwsAccountOnboardingStatus.Completed ||
    awsAccountOnboardingStatus === null
  );
};

const sortByAccountName = (
  a: Pick<AwsAccount, "name">,
  b: Pick<AwsAccount, "name">
) => {
  const isNumberOnly = (name: string) => /^[0-9]+$/.test(name);

  // Check if either name is only numbers
  const aIsNumberOnly = isNumberOnly(a.name);
  const bIsNumberOnly = isNumberOnly(b.name);

  if (aIsNumberOnly && bIsNumberOnly) {
    return sortNameAsc(a, b); // If both are numbers, regular alphabetical comparison
  } else if (aIsNumberOnly) {
    return 1; // a comes after b
  } else if (bIsNumberOnly) {
    return -1; // b comes after a
  } else {
    // Regular alphabetical comparison
    return sortNameAsc(a, b);
  }
};
