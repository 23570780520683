import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import PT from "prop-types";

import { useCSVDownload } from "@mc/hooks";

export const DownloadResourcesButton = ({
  disabled = false,
  id,
  name = "resources",
  query,
  totalResources = 0,
}) => {
  const filename = `${name}.csv`;
  const variables = { id };

  const [fetch, { loading }] = useCSVDownload({
    filename,
    query,
    variables,
  });
  const disableBtn = totalResources < 1 || loading || disabled;

  return (
    <button
      className={clsx("mt-0.5 flex space-x-1", {
        "text-action hover:text-blue-500": !disableBtn,
        "text-gray-400": disableBtn,
      })}
      disabled={disableBtn}
      onClick={fetch}
      id="download-resources-button"
    >
      <ArrowDownTrayIcon className="h-2.5 w-2.5" />
      <div className="text-sm font-semibold">Download Resources</div>
    </button>
  );
};

DownloadResourcesButton.propTypes = {
  disabled: PT.bool,
  id: PT.string.isRequired,
  name: PT.string,
  query: PT.object.isRequired,
  totalResources: PT.number,
};
