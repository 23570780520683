import PT from "prop-types";

import { Avatar } from "@m/ui";

export const ActivityFeedPerson = ({ actor }) => {
  const showAvatar = !!actor.name?.trim();

  return (
    <span className="ml-0.5">
      {showAvatar && <Avatar account={actor} includeName />}
      {!showAvatar && <span>{actor.email}</span>}
    </span>
  );
};

ActivityFeedPerson.propTypes = {
  actor: PT.shape({
    name: PT.string,
    email: PT.string.isRequired,
  }).isRequired,
};
