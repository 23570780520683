export const batchUpdate = async (inputs, mutate) => {
  const requests = [...inputs];

  const responses = await Promise.allSettled(
    requests.map(
      (input) =>
        /* eslint-disable no-async-promise-executor */
        /* TODO: Fix eslint error */
        new Promise(async (resolve, reject) => {
          try {
            const result = await mutate(input);
            resolve({ input, result });
          } catch (error) {
            reject({ input, error });
          }
        })
    )
  );

  return responses;
};
