import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { AggregateBillingPeriod } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE } from "./";

export const GET_AGGREGATED_METERED_CHARGES = gql(/* GraphQL */ `
  query GetAggregatedMeteredCharges(
    $billingPeriodType: AggregateBillingPeriod!
    $dimensionTypeIds: [String]
    $catalogItemNames: [String]
    $afterDate: ArrowTypeScalar
    $beforeDate: ArrowTypeScalar
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    aggregateMarketplaceMeteringTransactions(
      billingPeriodType: $billingPeriodType
      dimensionTypeIds: $dimensionTypeIds
      catalogItemNames: $catalogItemNames
      afterDate: $afterDate
      beforeDate: $beforeDate
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          day
          dimensionTypeId
          hour
          id
          meteredQuantity
          month
          year
          billingPeriodType
          marketplaceRegistrationSignupToken
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
    userProfile {
      currentCompany {
        marketplaceRegistrations {
          edges {
            node {
              signupToken
              catalogItem {
                displayName
              }
            }
          }
        }
      }
    }
  }
`);

export const useAggregatedMeteredCharges = ({
  query,
  billingPeriodType,
}: {
  query;
  billingPeriodType: AggregateBillingPeriod;
}) => {
  const onError = () =>
    toast.error(METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE);

  const { dimensionCodes, products, chargeDateStart, chargeDateEnd } =
    query || {};

  const { data, fetchMore, ...result } = useQuery(
    GET_AGGREGATED_METERED_CHARGES,
    {
      onError,
      variables: {
        ...INITIAL_PAGINATION,
        ...(dimensionCodes && { dimensionTypeIds: dimensionCodes }),
        ...(products && { catalogItemNames: products }),
        ...(chargeDateStart && { afterDate: chargeDateStart }),
        ...(chargeDateEnd && { beforeDate: chargeDateEnd }),
        billingPeriodType,
      },
    }
  );

  const {
    edges = [],
    pageInfo = {},
    totalCount = 0,
  } = data?.aggregateMarketplaceMeteringTransactions ?? {};
  const aggregatedMeteredCharges =
    edges
      .map((edge) => {
        return edge?.node;
      })
      .filter((charge) => charge !== null) ?? [];

  const productNameBySignupToken = new Map();
  (
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges || []
  ).forEach((edge) => {
    productNameBySignupToken.set(
      edge.node.signupToken,
      edge.node.catalogItem.displayName
    );
  });

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { aggregatedMeteredCharges, productNameBySignupToken },
    pagination,
    ...result,
  };
};
