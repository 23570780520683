import { RECOMMENDATION_STATUS } from "@mc/features/CloudScore/constants";

import { ACTIVITY_NAMESPACES, ACTIVITY_STREAM_OPERATION } from "../constants";
import { groupByOperation } from "../utils";

import { ActivityFeedItem } from "./ActivityFeedItem";

export const ActivityFeedItemScorecardRecommendation = ({
  activities,
  timestamp,
}) => {
  const { title: category, link } = ACTIVITY_NAMESPACES.scorecard;
  const createdActivities = groupByOperation(
    activities,
    ACTIVITY_STREAM_OPERATION.CREATE
  );

  const resolvedActivities = groupByOperation(
    activities,
    ACTIVITY_STREAM_OPERATION.UPDATE
  ).filter(
    (activity) =>
      activity.changes.scorecard_rec_status[1] ===
      RECOMMENDATION_STATUS.RESOLVED
  );

  let message;
  const created = createdActivities.length;
  const resolved = resolvedActivities.length;

  if (created < 1 && resolved > 0) {
    message = `${resolved} AWS optimization recommendation${
      resolved > 1 ? "s" : ""
    } resolved`;
  } else {
    message = `${created} new AWS optimization recommendation${
      created > 1 ? "s" : ""
    } created${resolved > 0 ? ", " + resolved + " resolved" : ""}`;
  }

  return (
    <ActivityFeedItem
      link={link}
      message={message}
      timestamp={timestamp}
      category={category}
    />
  );
};
