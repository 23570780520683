import { Button } from "@m/ui";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { EmptyState } from "@mc/components/EmptyState";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { MUTATIONS, PATHS } from "@mc/constants";
import { EngagementsArtboard } from "@mc/features/Engagements/icons";

export const EngagementsEmptyPage = () => {
  const allowCreateProject = useAccessRequired({
    mutation: MUTATIONS.CREATE_ENGAGEMENT_WITH_TEMPLATE,
  });

  return (
    <Page
      data-testid="engagements-projects-empty-page"
      title={
        <PageTitle
          actions={
            allowCreateProject && (
              <Button
                fill="subdued"
                kind="primary"
                size="small"
                to={PATHS.ENGAGEMENTS_PROJECTS_CONFIGURATION}
              >
                Create Project
              </Button>
            )
          }
          breadcrumbs={[{ path: PATHS.ENGAGEMENTS_PROJECTS, name: "Projects" }]}
          padding="small"
        />
      }
    >
      <EmptyState
        image={<EngagementsArtboard height={180} width={185} />}
        title="There are no projects recorded."
        description="When projects are created in Mission Control, you'll see them here."
        actions={
          allowCreateProject && (
            <Button
              kind="primary"
              to={PATHS.ENGAGEMENTS_PROJECTS_CONFIGURATION}
            >
              Create Project
            </Button>
          )
        }
      />
    </Page>
  );
};
