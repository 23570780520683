import { DateTime, dt } from "@m/utils";

import { CHAT_END_HOUR, CHAT_OFF_DAYS, CHAT_START_HOUR } from "../constants";

export const isChatDisabled = (d?: DateTime) => {
  const date = d || dt.now().setZone("America/New_York");

  const isChatOffDay = CHAT_OFF_DAYS.includes(date.weekday);
  const isOutsideChatHours =
    date.hour < CHAT_START_HOUR || date.hour >= CHAT_END_HOUR;

  return isChatOffDay || isOutsideChatHours;
};
