import { Cog6ToothIcon } from "@heroicons/react/20/solid";

import { Button } from "@m/ui";

type Props = {
  onClick?: () => void;
};

export const SettingsButton = ({ onClick }: Props) => (
  <Button
    fill="subdued"
    kind="primary"
    size="small"
    onClick={onClick}
    leftIcon={Cog6ToothIcon}
  >
    Settings
  </Button>
);
