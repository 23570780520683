import { AWS_RESOURCES_LABELS } from "./labels.constants";

export const EKS_LABEL = AWS_RESOURCES_LABELS.eksClusters;

export const TABLE_HEADERS_EKS_CLUSTERS = [
  { label: "Name", accessor: "clusterName", sort: "NAME" },
  { label: "Account Name", accessor: "accountName", sort: "ACCOUNT" },
  { label: "Account ID", accessor: "accountId" },
  { label: "Region", accessor: "region", sort: "REGION" },
  { label: "ARN", accessor: "arn", sort: "ARN" },
  { label: "Status", accessor: "status", sort: "STATUS" },
  { label: "Created", accessor: "createTime", sort: "CREATE_TIME" },
  { label: "Tags", accessor: "tags", sort: "TAGS" },
];

export const WHERE_FILTERS_EKS_CLUSTERS = {
  account: "accountIn",
};
