import {
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { EngagementMilestoneStatus } from "@m/api/public/types";
import { Dropdown, DropdownItem } from "@m/ui";

import { PATHS } from "@mc/constants";

import { useUpdateEngagementsMilestone } from "../api";
import { IEngagementMilestoneItem } from "../types";

export const MilestoneAction = ({
  milestone,
  engagementId,
}: {
  milestone: IEngagementMilestoneItem;
  engagementId: string;
}) => {
  const navigate = useNavigate();

  const { id: milestoneId, status } = milestone;

  const [updateMilestone] = useUpdateEngagementsMilestone(engagementId);

  const handleEdit = () => {
    const editPath = generatePath(PATHS._ENGAGEMENT_MILESTONE_EDIT, {
      engagementId,
      milestoneId,
    });
    navigate("../" + editPath);
  };

  const handleUpdate = (e: ChangeEvent<HTMLButtonElement>) => {
    const newStatus = e.target.value as EngagementMilestoneStatus;
    const { ...milestoneCopy } = milestone;
    delete milestoneCopy.noteList;

    updateMilestone({
      ...milestoneCopy,
      status: newStatus,
    });
  };

  return (
    <Dropdown
      className="w-[165px] p-1"
      trigger={
        <EllipsisVerticalIcon
          aria-label="Milestone Actions"
          className="h-3 w-3"
        />
      }
      direction="bottom-end"
    >
      <DropdownItem className="p-1" onClick={handleEdit}>
        <PencilIcon className="mr-1 h-2.5 w-2.5 text-accent" />
        Edit
      </DropdownItem>

      {status !== EngagementMilestoneStatus.Archived && (
        <DropdownItem
          className="p-1"
          value={EngagementMilestoneStatus.Archived}
          onClick={handleUpdate}
        >
          <ArchiveBoxIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Archive
        </DropdownItem>
      )}

      {status === EngagementMilestoneStatus.Archived && (
        <DropdownItem
          className="p-1"
          value={EngagementMilestoneStatus.Open}
          onClick={handleUpdate}
        >
          <ArchiveBoxIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Unarchive
        </DropdownItem>
      )}

      {status === EngagementMilestoneStatus.Resolved && (
        <DropdownItem
          className="p-1"
          value={EngagementMilestoneStatus.Open}
          onClick={handleUpdate}
        >
          <ArrowUturnLeftIcon className="mr-1 h-2.5 w-2.5 text-accent" />
          Reopen
        </DropdownItem>
      )}
    </Dropdown>
  );
};
