import { gql } from "@m/api/public";
import { UpdatePaymentMethodInput } from "@m/api/public/types";
import { toast } from "@m/ui";

import { useMutation } from "@mc/utils/graphql";

import { GET_STORED_PAYMENT_METHOD } from "./useStoredPaymentMethod";

export const UPDATE_PAYMENT_METHOD = gql(/* GraphQL */ `
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentProfile(input: $input) {
      ok
      paymentProfile {
        cardType
        expirationDate
        maskedCreditCardNumber
      }
    }
  }
`);

export const useUpdatePaymentMethod = () => {
  const [mutate, { loading }] = useMutation(UPDATE_PAYMENT_METHOD, {
    onError: () => toast.error("Unable to update your payment method."),
    refetchQueries: [GET_STORED_PAYMENT_METHOD],
  });

  const updatePaymentMethod = (data: UpdatePaymentMethodInput) => {
    mutate({ variables: { input: data } });
  };

  return [updatePaymentMethod, { loading }] as const;
};
