import { gql, useQuery } from "@m/api";
import { toast } from "@m/ui";

import {
  DEFAULT_SORT_RISK_LOG,
  MESSAGES_ENGAGEMENTS_RISK_LOG,
} from "../constants";
import { IRiskLogItem } from "../types";

export const GET_RISK_LOG_ITEMS = gql`
  query RiskLogItems(
    $id: ID!
    $sort: [RiskLogItemSortEnum]
    $where: EngagementRiskLogItemFilter
    $searchTerm: String
  ) {
    engagement(id: $id) {
      __typename
      id
      riskLogItems(sort: $sort, where: $where, searchTerm: $searchTerm) {
        edges {
          node {
            createTime
            databaseId
            dateNeeded
            description
            engagementId
            id
            impact
            itemType
            mitigationStrategy
            noteList {
              edges {
                node {
                  id
                  content
                  createdAt
                  updatedAt
                }
              }
            }
            owner
            probability
            status
            title
          }
        }
      }
    }
  }
`;

interface RiskLogItemsResponse {
  engagement: {
    __typename: string;
    id: string;
    riskLogItems: {
      edges: {
        node: IRiskLogItem;
      }[];
    };
  };
}

export const useRiskLogItems = (engagementId: string) => {
  const onError = () =>
    toast.error(MESSAGES_ENGAGEMENTS_RISK_LOG.GET_ITEMS_ERROR);

  const { data, loading } = useQuery<RiskLogItemsResponse>(GET_RISK_LOG_ITEMS, {
    variables: {
      id: engagementId,
      sort: DEFAULT_SORT_RISK_LOG,
    },
    onError,
  });

  const riskLogItems =
    data?.engagement?.riskLogItems?.edges?.map((edge) => edge.node) || [];

  return { data: { riskLogItems }, loading };
};
