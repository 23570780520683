import { SparklesIcon } from "@heroicons/react/20/solid";

import { Banner, Button } from "@m/ui";

import { useUsersnapEvent } from "@mc/providers";

export const TalkToExpertBanner = () => {
  const [openForm, isLoaded] = useUsersnapEvent("talk-to-expert");
  const handleClick = () => openForm();

  return (
    <Banner
      data-testid="talk-to-expert-banner"
      title="Need help improving your score?"
      label="Connect with a Cloud Analyst for guidance on optimization."
      status="empty"
      icon={null}
      actions={
        <Button
          kind="primary"
          fill="subdued"
          className="whitespace-nowrap"
          leftIcon={SparklesIcon}
          onClick={handleClick}
          disabled={!isLoaded}
        >
          Talk to an expert
        </Button>
      }
    />
  );
};
