import { Card } from "@m/ui/components/Card";
import { toHyphenLowercase } from "@m/utils";

import { ChangeIndicator } from "@mc/components/ChangeIndicator";

interface Props {
  change?: number;
  flipTrend?: boolean;
  showTrend?: boolean;
  title: string;
  value: number;
}

export const PillarDetailsCard = ({
  change = 0,
  flipTrend = false,
  showTrend = true,
  title,
  value,
}: Props) => {
  const id = toHyphenLowercase(title);
  return (
    <Card aria-label="Pillar Details Card">
      <div className="flex flex-col gap-1 p-2">
        <div id={id} className="text-sm text-subdued">
          {title}
        </div>
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          <div
            aria-labelledby={id}
            className="text-2xl font-semibold text-default"
          >
            {value}
          </div>
          <ChangeIndicator
            aria-label="Pillar Score Change"
            change={change}
            showIcon={showTrend}
            flipStatus={flipTrend}
          />
        </div>
      </div>
    </Card>
  );
};
