import { gql } from "@m/api/public";
import { EmailNotificationCadence } from "@m/api/public/types";
import { toast } from "@m/ui";

import { useMutation } from "@mc/utils/graphql";

import { MESSAGES_EMAIL_NOTIFICATION } from "../constants";

import { GET_EMAIL_NOTIFICATION_SETTINGS } from "./useEmailNotificationSettings";

export const UPDATE_EMAIL_NOTIFICATIONS = gql(`
  mutation UpdateEmailNotificationPreference(
    $input: UpdateEmailNotificationPreferenceInput!
  ) {
    updateEmailNotificationPreference(input: $input) {
      message
      ok
    }
  }
`);

export const useUpdateEmailNotificationPreference = () => {
  const onError = () =>
    toast.error(
      MESSAGES_EMAIL_NOTIFICATION.UPDATE_EMAIL_NOTIFICATION_PREF_FAILURE
    );

  const onCompleted = () => {
    toast.success(
      MESSAGES_EMAIL_NOTIFICATION.UPDATE_EMAIL_NOTIFICATION_PREF_SUCCESS
    );
  };

  const [mutate, { loading }] = useMutation(UPDATE_EMAIL_NOTIFICATIONS, {
    refetchQueries: [{ query: GET_EMAIL_NOTIFICATION_SETTINGS }],
    onCompleted,
    onError,
  });

  const updatePreference = (
    templateAlias: string,
    cadence: EmailNotificationCadence
  ) => {
    mutate({
      variables: {
        input: {
          templateAlias,
          cadence,
        },
      },
    });
  };

  return [updatePreference, { loading }] as const;
};
