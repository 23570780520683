import PT from "prop-types";

import { Avatar } from "@m/ui";

export const RecommendationReporterField = ({ account }) => (
  <div>
    <div className="text-sm font-bold text-default">Reporter</div>
    <div className="mt-0.5 flex items-center space-x-1">
      <Avatar
        account={account}
        className="text-sm font-semibold text-default"
        includeName
      />
    </div>
  </div>
);

RecommendationReporterField.propTypes = {
  account: PT.shape({
    firstName: PT.string.isRequired,
    lastName: PT.string.isRequired,
    email: PT.string.isRequired,
  }).isRequired,
};
