import md5 from "md5";
import { createRef } from "react";

import { toast } from "@m/ui";

const assetsRef = createRef();

export const RefreshNotification = () => {
  const refresh = () => {
    window.location.reload();
  };
  return (
    <div>
      A new version of Mission Control is available!{" "}
      <button
        className="text-blue-700 underline hover:text-blue-500"
        onClick={refresh}
      >
        Refresh Now
      </button>
    </div>
  );
};

export async function detectVersionChange() {
  fetch(`${window.location.origin}/index.html?${Date.now()}`)
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
      throw new Error("index.html fetch failed");
    })
    .then((assets) => {
      const hash = md5(assets);
      if (assetsRef.current !== hash) {
        if (assetsRef.current) {
          toast.info(RefreshNotification, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
        }
        assetsRef.current = hash;
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
