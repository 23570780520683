export const JOB_LEVELS = {
  DEFAULT: "", // job level for most users is empty by default
  EXECUTIVE: "Executive",
  MANAGER: "Manager",
  INDIVIDUAL_CONTRIBUTOR: "Individual Contributor",
};

export const DEFAULT_JOB_LEVEL_SELECTION = "Select job level";

export const JOB_LEVEL_OPTIONS = [
  {
    disabled: true,
    value: DEFAULT_JOB_LEVEL_SELECTION,
  },
  {
    disabled: false,
    value: JOB_LEVELS.EXECUTIVE,
  },
  {
    disabled: false,
    value: JOB_LEVELS.MANAGER,
  },
  {
    disabled: false,
    value: JOB_LEVELS.INDIVIDUAL_CONTRIBUTOR,
  },
];
