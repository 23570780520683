import { Link } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";

export const NotFound = () => (
  <Page
    align="left"
    aria-label="404 Page"
    title={<PageTitle title="404 Not Found" />}
  >
    <div className="flex-1">
      <p>Oops! This page was removed, renamed, or doesn't exist.</p>
      <Link to={PATHS.DASHBOARD} className="mt-2">
        Return to Dashboard
      </Link>
    </div>
  </Page>
);
