import { useCallback, useMemo, useState } from "react";

import { ScorecardRecommendation } from "@m/api/public/types";
import { Link, Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";
import { dt, getMilliseconds } from "@m/utils";

import {
  useCloudScoreRecommendations,
  useUpdateCloudScoreRecommendationStatus,
} from "../api";
import {
  CLOUD_SCORE_MESSAGES,
  CloudScorePillarIds,
  RECOMMENDATION_STATUS,
  SCORECARD_DETAILS,
} from "../constants";
import { getPillar, sortRecommendations } from "../utils";

import { CloudScoreRecommendationListItem } from "./CloudScoreRecommendationListItem";
import { DeleteRecommendationDialog } from "./DeleteRecommendationDialog";

const { ARCHIVED, ACTIVE } = RECOMMENDATION_STATUS;

interface Props {
  pillarId?: CloudScorePillarIds;
}

export const CloudScoreRecommendationList = ({
  pillarId = CloudScorePillarIds.AllChecks,
}: Props) => {
  const [viewAll, setViewAll] = useState(false);

  const [recommendationToDelete, setRecommendationToDelete] =
    useState<ScorecardRecommendation>(null);

  const {
    data: { recommendations },
    loading,
    error,
  } = useCloudScoreRecommendations();

  const [updateRecommendationStatus, { loading: updateRecommendationLoading }] =
    useUpdateCloudScoreRecommendationStatus();

  const openDeleteDialog = (recommendation: ScorecardRecommendation) => {
    setRecommendationToDelete(recommendation);
  };

  const closeDeleteDialog = () => {
    setRecommendationToDelete(null);
  };

  const handleToggleViewAll = () => {
    setViewAll((prev) => !prev);
  };

  const filterByPillar = useCallback(
    (recommendation: ScorecardRecommendation) => {
      if (pillarId === SCORECARD_DETAILS.all.id) return true;
      const { pillar } = recommendation.scorecardResult;
      return getPillar(pillarId)?.id === getPillar(pillar)?.id;
    },
    [pillarId]
  );

  const filterOutArchived = useCallback(
    (recommendation: ScorecardRecommendation) => {
      return viewAll ? true : recommendation.scorecardRecStatus !== ARCHIVED;
    },
    [viewAll]
  );

  const filterByRecent = useCallback(
    (recommendation: ScorecardRecommendation) => {
      const cutoff = dt.local().minus({ days: 30 }).toISO();
      const isRecent =
        getMilliseconds(recommendation.updatedTime) > getMilliseconds(cutoff);

      // if active recommendation, show, otherwise check for viewAll or use cutoff
      if (recommendation.scorecardRecStatus === ACTIVE) return true;
      else return viewAll ? true : isRecent;
    },
    [viewAll]
  );

  const filteredRecommendations = useMemo(() => {
    return recommendations
      .filter(filterByPillar)
      .filter(filterOutArchived)
      .filter(filterByRecent)
      .sort(sortRecommendations);
  }, [recommendations, filterByPillar, filterOutArchived, filterByRecent]);

  const available = !loading && !error && filteredRecommendations.length > 0;
  const empty = !loading && (!available || error);

  return (
    <>
      <Card
        data-testid="cloud-score-recommendation-list"
        title={
          <div className="flex items-center justify-between">
            Recommendations
            <Link onClick={handleToggleViewAll} fontSize="sm">
              {!viewAll ? "View All" : "View Recent"}
            </Link>
          </div>
        }
      >
        <div className="px-3 py-2">
          {available && (
            <div className="flex flex-col gap-2">
              {filteredRecommendations.map(
                (recommendation: ScorecardRecommendation) => (
                  <CloudScoreRecommendationListItem
                    key={recommendation.id}
                    loading={updateRecommendationLoading}
                    onUpdateStatus={updateRecommendationStatus}
                    onDelete={openDeleteDialog}
                    recommendation={recommendation}
                  />
                )
              )}
            </div>
          )}

          {empty && (
            <div className="flex justify-center text-subdued">
              {CLOUD_SCORE_MESSAGES.SCORECARD_RECOMMENDATIONS_EMPTY}
            </div>
          )}

          {loading && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}
        </div>
      </Card>

      {recommendationToDelete?.id && (
        <DeleteRecommendationDialog
          id={recommendationToDelete.id}
          onClose={closeDeleteDialog}
          onCompleted={closeDeleteDialog}
          open={!!recommendationToDelete.id}
          title={recommendationToDelete.title}
        />
      )}
    </>
  );
};
