import { AWS_CONSOLE_SIGN_IN_URL } from "@mc/constants";

export const AwsConsoleSigninLink = ({ awsAccountId }) => (
  <>
    Please{" "}
    <a
      className="underline"
      href={AWS_CONSOLE_SIGN_IN_URL(awsAccountId)}
      rel="noreferrer"
      target="_blank"
    >
      sign in to this account's AWS console
    </a>{" "}
    to make sure this CloudFormation template runs in the correct account.
  </>
);
