import { generateMockId } from "@m/api/testing/utils";

export class ExampleRow {
  constructor({
    column1 = "Hello",
    column2 = "World",
    button = <></>,
    icon = <></>,
  }) {
    this.id = generateMockId();
    this.column1 = column1;
    this.column2 = column2;
    this.button = button;
    this.icon = icon;
  }
}

export class ExampleAccessorRow {
  constructor({
    header1 = "Row Data 1",
    header2 = "Row Data 2",
    header3 = "Row Data 3",
    icon = <></>,
  }) {
    this.id = generateMockId();
    this.header1 = header1;
    this.header2 = header2;
    this.header3 = header3;
    this.icon = icon;
  }
}
