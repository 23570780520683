export const SCORECARD_CONFIGURATION_TITLE = "Cloud Score Settings";

export const SCORECARD_CONFIGURATION_AUTHORIZED_ROLES = [
  "ADMIN",
  "SCORECARD_ANALYST",
];

export const CONFIGURATION_TOOLTIP_WARNING_MESSAGE =
  "This account is not connected. To troubleshoot the connection, see Account Settings for more information.";

export const SCORECARD_CONFIGURATION_DESCRIPTION =
  "You can choose which accounts that you wish to include in your daily Mission Cloud Score. Changes will appear on your next Cloud Score update.";

export const SCORECARD_CONFIGURATION_READONLY_DESCRIPTION =
  "Your score is calculated on a scale of 0-10 and is based on the accounts selected below and check results from each category.";

export const UPDATE_SCORECARD_CONFIGURATION_ERROR =
  "Unable to update Cloud Score configuration.";

export const UPDATE_SCORECARD_CONFIGURATION_SUCCESS =
  "Cloud Score configuration updated successfully. Changes will appear on your next daily Cloud Score update.";
