import PT from "prop-types";

import { Button } from "@m/ui";

import { useCSVDownload } from "@mc/hooks/useCSVDownload";

export function DownloadableReport({
  context,
  description,
  options,
  query,
  title,
  variables,
}) {
  const filename = `${title.toLowerCase().replace(" ", "-")}.csv`;
  const [fetch, { loading }] = useCSVDownload({
    filename,
    query,
    ...(variables && { variables: variables }),
    context: { ...context } || {},
  });
  return (
    <div className="flex items-center justify-between">
      <div>
        <h3 className="p-0 text-base font-semibold">{title}</h3>
        <div>{description}</div>
        {options && <div className="mt-2">{options}</div>}
      </div>
      <Button onClick={fetch} loading={loading}>
        Download
      </Button>
    </div>
  );
}

DownloadableReport.propTypes = {
  context: PT.object,
  description: PT.node.isRequired,
  options: PT.node,
  query: PT.object,
  title: PT.node.isRequired,
  variables: PT.object,
};
