import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { INITIAL_PAGINATION, usePaginationProps } from "@m/ui";

import { CASE_LIST_STATUS_FILTERS, CASE_LIST_TYPE_FILTERS } from "../constants";

export const GET_CASES = gql(/* GraphQL */ `
  query CaseList(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $where: CaseFilter
    $sort: [CaseSortEnum]
  ) {
    cases(
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      where: $where
      sort: $sort
    ) {
      edges {
        node {
          id
          description
          shortDescription
          state
          number
          sysId
          priority
          lastActivity
          source
          openedBy {
            id
            name
            email
          }
          assignedTo {
            id
            name
            email
            firstName
            lastName
          }
          openedAt
          category
          totalReplies
          waitingOn
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useCaseList = (query) => {
  const statusFilter = CASE_LIST_STATUS_FILTERS[query.status]?.where;
  const typeFilter = CASE_LIST_TYPE_FILTERS[query.type]?.where;

  const where = {
    ...(query.status ? { stateIn: statusFilter } : {}),
    ...typeFilter,
  };

  const { loading, error, data, fetchMore } = useQuery(GET_CASES, {
    variables: {
      ...INITIAL_PAGINATION,
      where,
      search: query.search,
      sort: query.sort || "OPENED_AT_DESC",
    },
  });

  const { edges, pageInfo, totalCount } = data?.cases || {};
  const cases = edges?.map((edge) => edge.node) || [];
  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { cases },
    error,
    loading,
    pageInfo,
    pagination,
  };
};
